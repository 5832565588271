// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient } from '@azure/storage-blob';
import moment from 'moment';
import { Guid } from "guid-typescript";
import config from "../../env.json";
import { Folder } from '@mui/icons-material';
const sasToken = "sv=2021-10-04&ss=btqf&srt=sco&spr=https&st=2023-01-06T19%3A11%3A40Z&se=2023-01-07T01%3A11%3A40Z&sp=rwdlacupi&sig=%2Fu3CtrcSztYf%2BLL9rpus13UVqi92nSAA%2FnWVT%2BlBe6c%3D";
// const sasToken=""
const containerName = config.REACT_APP_CONT_SHARING_CONTAINER;
const storageAccountName = config.REACT_APP_CONT_SHARING_ACC_NAME; // Fill string with your Storage resource name

// Feature flag - disable storage feature to app if not configured~
export const isStorageConfigured = () => {
  return !((!storageAccountName || !sasToken));
};

// return list of blobs in container to display
const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
};
function makeid(length, file) {
  console.log(length, file)
  console.log(file.name.split(" ").join("") + "_" + moment().format("YYYY-MM-DD-h:mm"))
  // for(var i = 0; i < file.length; i++){
  //   // testTime
  //   console.log("Hi")
  //   console.log(file[i].name+file[i].testTime)

  // }
  var result = moment().format("YYYY-MM-DD-h:mm") + file.name.split(" ").join("")
  //+"-"+moment().format("YYYY-MM-DD-h:mm");
  //result = result.replace(" ", "-");
  // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // var charactersLength = characters.length;
  // for ( var i = 0; i < length; i++ ) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  // }
  return result;
}

const createBlobInContainer = async (containerClient, file) => {
  console.log(file)
  let fileName = makeid(10, file);

  console.log("guide value", Guid.value, fileName);
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(fileName);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadBrowserData(file, options);
  // await blobClient.setMetadata({UserName : ''});
  console.log(fileName)

  return fileName;
};

const uploadFileToBlob = async (file, folder, type, sasTokenVal) => {
  console.log(file, folder, sasTokenVal, type)
  console.log("file", file)
  console.log("", file.name)
  let fileName;
  
  if (type === "media") {
    if (!file && !folder) return [];
    if (!folder) folder = containerName;
    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasTokenVal}`
    );
    // get Container - full public read access
    const containerClient = blobService.getContainerClient(folder);
    // containerClient.createIfNotExists();
    // upload file
    if (file || folder) {
      fileName = await createBlobInContainer(containerClient, file);
      // }

    }
    
    return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`
  }
  if (type === 'email') {
    const filesArray = [];
    if (!file) return [];
    if (!folder) folder = containerName;
    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasTokenVal}`
    );
    // get Container - full public read access
    const containerClient = blobService.getContainerClient(folder);
    // containerClient.createIfNotExists();

    // upload file
    if (file) {
      for (let i = 0; i < file.length; i++) {
        //console.log(file[i])
        fileName = await createBlobInContainer(containerClient, file[i]);
        let data = {
          "File_Nm":fileName,
          "EmailAttach_Url": `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`,
          "Display_Nm": file[i].name,
          "ContentType": file[i].type,
          "AttachmentId":0
        }
        filesArray.push(data)
      }
    }
    console.log("files::::::::::::",filesArray)
    return filesArray;
  }

  // get list of blobs in container
  // return getBlobsInContainer(containerClient);

};

// </snippet_uploadFileToBlob>

export default uploadFileToBlob;

