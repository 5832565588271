import { registerPlugin } from '@capacitor/core';

import type { capacitortwilioaudioPlugin } from './definitions';

const capacitortwilioaudio = registerPlugin<capacitortwilioaudioPlugin>(
  'capacitortwilioaudio',
  {
    web: () => import('./web').then(m => new m.capacitortwilioaudioWeb()),
  },
);

export * from './definitions';
export { capacitortwilioaudio };
