import React, { useEffect, useRef, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import getInstance from "@pdftron/webviewer";
import DocIcon from "../../assets/icons/Assets.svg";
import axios from "axios";
import "./Media.css";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import {
  setVideoCallDetails,
  setVideoCallShowFloater,
  setVideoCallRoom,
  setVideoOtherParticipants,
  setVideoLocalParticipant,
  setVideoCallCallStarted,
  setVideoCallEnded,
  setVideoCallScreenTrack,
  setVideoCallPdfTronStarted,
  setVideoCallChats,
} from "../../app/slice/videoCallSlice";
import { Tooltip } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Typography from "@mui/material/Typography";
import BlockUI from "../../layouts/bockUi/BlockUI";
import { useTranslation } from "react-i18next";
import Path from "path";
import uploadFileToBlob, {
  isStorageConfigured,
} from "../../components/azureUploads/azureBlob";
import { rename } from "fs";
import {
  getAllFiles,
  getToken,
  deleteFile,
  deleteFolder,
  createFolder,
  renameFolder,
  renameFile,
  getFolders,
  getFolderFiles,
  createFile,
  getAnnotedFiles,
} from "../../apis/contentSharingApi/contentsharing";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import AocModal from "../../components/AocPopup/AocModal";
import { setDoc } from "../../app/slice/pdfSlice";
import config from '../../env.json';
import ReasonPopup from '../../components/ReasonPopup/ReasonPopup';
import { getFormsForMedia } from '../../apis/forms/formsapi';
import { setShowErrorSnackBar, setShowSuccessSnackBar } from '../../app/slice/personalisationSlice';
import SearchIcon  from '../../assets/icons/search.svg'
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddFileIcon from '../../assets/icons/Add_files.svg'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UploadIcon from '@mui/icons-material/Upload';
import {  Popover } from 'antd';
import AddFolder from '../../assets/icons/Add_folder.svg'
import PopoverMUI from '@mui/material/Popover';

import { showModal } from '../../app/slice/modalSlice';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchInput from './InputDropdown';
import ConfirmationModal from '../../components/logoutModal/LogoutModal'
import Spinner from "../../layouts/spinner/Spinner";


var hasfield: any = false;
var state: string = "start";
var bufferFromIframe: any = null;
var te: any = null;
var blobsign: any = null;
var reason: any = "";
let fileName: any;

const Media = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const room = useSelector(
    (state: any) => state.floaterVideoCall.videoCallRoom
  );
  const pageTitle = useSelector((state: any) => state.sideBar.title);

  const roomObj = useSelector((state: any) => state.floaterVideoCall);
  const token: any = useSelector((state: any) => state.floaterVideoCall);
  const screenTrack = useSelector(
    (state: any) => state.floaterVideoCall.videoCallScreenTrack
  );
  const videoCallScreenShareStarted = useSelector(
    (state: any) => state.floaterVideoCall.videoCallScreenShareStarted
  );
  const videoCallShareUserId = useSelector(
    (state: any) => state.floaterVideoCall.videoCallShareUserId
  );
  const videoCallAnnotaion = useSelector(
    (state: any) => state.floaterVideoCall.videoCallAnnotaion
  );
  const videoCallPdfTronStarted = useSelector(
    (state: any) => state.floaterVideoCall.videoCallPdfTronStarted
  );
  const chatsNew: any = useSelector(
    (state: any) => state.floaterVideoCall.videoCallChats
  );
  const videoCallStarted = useSelector(
    (state: any) => state.floaterVideoCall.videoCallCallStarted
  );
  let [docData, setDocData] = useState<any[]>([]);
  let [blockUI, setBlockUI] = useState(false);
  // let [formData,setFormData] = useState<any[]>([]);
  let [activeClass, setActiveClass] = useState("activedoctab");
  let [activeDocClass, setActiveDocClass] = useState("");
  let [activeFormTabClass, setActiveFormTabClass] = useState("");
  let [activeVideoClass, setActiveVideoClass] = useState("");
  let [activeFormClass, setActiveFormClass] = useState("");
  const [paddingValTop, setPaddingValTop] = useState(0);
  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [paddingValRight, setPaddingValRight] = useState(0);
  const [paddingValLeft, setPaddingValLeft] = useState(0);
  let [hoveredCart, setHoveredCart] = useState(-1);
  let [optionsdiv, setOptionsdiv] = useState(false);
  let [showdocviewer, setShowdocviewer] = useState(false);
  const [AnnotationManagerNew, setAnnotationManagerNew] = useState<any>(null);
  const viewer = useRef<any>(null);
  const serializer = new XMLSerializer();
  const [fileUrl, setFileUrl] = useState("");
  const [instance, setInstance] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const viewerMobile = useRef<any>(null);
  const [mediaHeight, setMediaHeight] = useState(0);

  const [mobileInstance, setMobileInstance] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<any>(false);
  const [allDoc, setAllDoc] = useState<any>([]);
  const [isfolder, setIsFolder] = useState<any>(false);
  // const [isAllFiles,setIsAllFiles]=useState<any>(false)
  const [isform, setIsForm] = useState<any>(false);
  const [localData, setLocalData] = useState<any>([]);
  const [localFormData, setLocalFormData] = useState<any>([]);
  const [parent, setparent] = useState<any>([]);
  const [showPresent, setShowPresent] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [base64Url, setBase64Url] = useState([]);
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [newFolder, setNewFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [dataTree, setDataTree] = useState<any>([]);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [sharePopupDetails, setSharePopupDetails] = useState({});
  const [renameData, setRenameData] = useState({
    id: 0,
    fileType: "",
    newName: "",
  });
  const [renameContentId, setRenameContentId] = useState(-1);
  const [trackPath, setTrackPath] = useState<any>([]);
  const [formId, setFormId] = useState(0);
  const [signFile, setSignFile] = useState(null);
  const [showAocModal, setShowAocModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formType, setFormType] = useState<any>('');
  
  const [options, setOptions] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>([]);

  // all blobs in container
  const [blobList, setBlobList] = useState([]);

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState(null);
  const [fileSelectedName, setFileSelectedName] = useState("");
  const [fileSelectedId, setFileSelectedId] = useState(null);

  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  //Show Permisson icons
  const [selectedFile, setSelectedFile] = useState<any>(false);
  const [writePermisson, setWritePermisson] = useState<any>(false);
  const [readPermisson, setReadPermisson] = useState<any>(false);
  const [showContextMenu, setShowContextMenu] = useState<any>(false);
  const [shareFilePermission, setshareFilePermission] = useState<any>(false);
  const [deleteFilePermission, setDeleteFilePermission] = useState<any>(false);
  const [presentFilePermission, setPresentFilePermission] =
    useState<any>(false);
  const [screenSize, setScreenSize] = useState<any>(false);
  const [renameFilePermission, setRenameFilePermission] = useState<any>(false);
  const [popoverContent, setPopoverContent] = useState<any>();
  const [showErrorMessage, setShowErrorMessage] = useState<any>(false);
  const [parentId, setParentId] = useState<any>([]);
  const [subMenuFolderName, setSubMenuFolderName] = useState<any>();
  const [getPartentFolderNameList, setPartentFolderNameList] = useState<any>(
    []
  );
  const [showPopover, setShowPopover] = useState<any>(false);
  const [isAnnotedFileSelected, setIsAnnotedFileSelected] =
    useState<any>(false);
  const [annotedAllFile, setannotedAllFile] = useState<any>();
  const [parentFolderData, setparentFolderData] = useState<any>([]);
  const [deleteFileOrFolderType, setDeleteFileOrFolderType] = useState<any>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<any>(false);
  const [deleteFileOrFolder, setDeleteFileOrFolder] = useState<any>(false);  
  const [loading, setLoading] = useState<any>(true);
  const [renameFolderOnly, setRrenameFolderOnly] = useState<any>(false);
  const folderTabRef = useRef<any>(null);

  const handleYOrNPopup=(e)=>{
    if(e){
      handleFolderFileDelete(selectedFile)
    }else{
      setShowConfirmationModal(false)
    }
  }
  const onFileChange = (event) => {
    // capture file into state
    setFileSelected(event.target.files[0]);
    console.log("file upload e.targets", event.target.files[0]);
    setFileSelectedName(event.target.files[0].name);
  };

  const handleCreateFolder = (e) => {
    
    console.log("onBlur new folder", e.target.value);
    if(e.target.value.length > 0){
      setFolderName(e.target.value);
      let tempParentId =
      parentFolderData.length > 0 ? parentFolderData[parentFolderData.length - 1].ID : 0;
    console.log("parent folder id..", tempParentId);
    let doc = document;
    doc.addEventListener("mousedown", handleNewFolder);

    let payload = {
      "FolderName": e.target.value,
      "ParentFolderId": tempParentId
    }
    createFolder(payload, (res) => {
      if (res) {
        console.log("folder created... ");
        setNewFolder(false);
        loadFolderFiles(false);
      }
    })
    } else{
      setNewFolder(false);
    }

  };

  const getUploadContent = () => {
    return (
      <div id={"popup1"}>
        <div className="videopopup-row">
          <div className="option_icon_wrapper">
            <img
              src={AddFileIcon}
              style={{ paddingRight: "0.8rem" }}
              alt="docIcon"
            />
          </div>
          <div className="videopopup-details" onClick={handleNewFile}>
            Add Files{" "}
          </div>
        </div>

        <div className="videopopup-row">
          <div className="option_icon_wrapper">
            <img
              src={AddFolder}
              style={{ paddingRight: "0.5rem" }}
              alt="folderIcon"
            />
          </div>
          <div className="videopopup-details" onClick={handleNewFolder}>
            Add Folder
          </div>
        </div>
      </div>
    );
  };

  const getMoreOptionContent = (file, item) => {
    console.log(item);
    if (item == "desktop") {
      return (
        <div id={"popup1"}>
          <div className="videopopup-row">
            {/* <VideocamIcon    className='videofileicons'                   
          />  */}
            <div
              className={
                presentFilePermission && videoCallStarted
                  ? "videopopup-details"
                  : "videopopup-details disabled_icon"
              }
              onClick={() => {
                setOptionsdiv(!optionsdiv);
                if (presentFilePermission) fileSelect(selectedFile, "present");
              }}
            >
              Present{" "}
            </div>
          </div>

          <div className="videopopup-row">
            {/* <ShareIcon    className='videofileicons'                   
          /> */}
            <div
              className={
                renameFilePermission
                  ? "videopopup-details"
                  : "videopopup-details disabled_icon"
              }
              onClick={() => {
                // if(renameFilePermission)
                console.log(selectedFile.type === "Folder")
                if(selectedFile.type === "Folder"){
                  setRrenameFolderOnly(true);
                }
                enableContentRename(selectedFile);
              }}
            >
              Rename
            </div>
          </div>

          <div className="videopopup-row">
            {/* <LinkIcon    className='videofileicons'                   
          />  */}
            {/* <div className="videopopup-details" >Get link</div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div id={"popup1"}>
          <div className="videopopup-row">
            {/* <VideocamIcon    className='videofileicons'                   
          />  */}
            {file?.Permissions.split(",").map((x) => {
              return (
                <>
                  {x == "p" && videoCallStarted ? (
                    <div
                      className="videopopup-details"
                      onClick={() => {
                        setOptionsdiv(!optionsdiv);
                        fileSelect(selectedFile, "present");
                      }}
                    >
                      Present{" "}
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>

          {/* <div className='videopopup-row'>
          <ShareIcon    className='videofileicons'                   
          /> <div className="videopopup-details" >Share</div>
  </div> */}

          <div className="videopopup-row">
            {/* <LinkIcon    className='videofileicons'                   
          />  */}
          <div className="videopopup-details" onClick={() => {
                                fileSelect(file, 'view'); 
                              }} >View</div>
  </div>
  </div>
      )
    }    
  }
  const getMoreMobileOptionContent= (item:any):any=>{
  return(
    <div id={'popup1'}>     
    <div onClick={() => { if(item?.Permissions.split(',').includes("sh"))handleShare(item)}} className={`${item?.Permissions.split(',').includes('sh')?"videopopup-row":"disabled_icon"}`}>Share</div>
    <div onClick={() => {
      if (!room) return window.alert("Please start a meeting first")
       setOptionsdiv(!optionsdiv); 
       fileSelect(item, 'present') }
       } className={`${item?.Permissions.split(',').includes('p') && room ? "videopopup-row":"disabled_icon"}`}>Present</div>
    <div className={`${item?.Permissions.includes('d')?"videopopup-row":"disabled_icon"}`} onClick={()=>{if(item?.Permissions.includes('d')) 
    setShowConfirmationModal(true)
    setDeleteFileOrFolderType(item.type)
    setSelectedFile(item)
    // handleFolderFileDelete(item)
  }
    }>Delete</div>
    <div className={`${item?.Permissions.includes('e')?"videopopup-row":"disabled_icon"}`} onClick={()=>{if(item?.Permissions.includes('e'))enableContentRename(item)}}>Rename</div>
    <div className={`${annotedAllFile?.length > 0 ||isAnnotedFileSelected?"videopopup-row":"disabled_icon"}`} onClick={() => {
                                if (annotedAllFile?.length > 0)
                                  displayAnnotedFileData(annotedAllFile);
                              }}>Annotated Files</div>
    </div>
  )
  };
  const handleUpload = () => {
    //call the api
    console.log("parent id for", parent.parentId);
    getToken((res) => {
      if (res) {
        const temp = res.data.storageAccessToken;
        console.log("token get api", temp);
        onFileUpload(temp);
      }
    });
    // let temp="sv=2021-10-04&ss=btqf&srt=sco&spr=https&st=2023-01-19T16%3A51%3A25Z&se=2023-01-20T07%3A51%3A25Z&sp=rwdlacupi&sig=5XZ7UtPvto1h%2FCKEUsZtu0sWFa7RDnhUhWWhTczo82w%3D";
    // onFileUpload(temp);
  };
  
  const loadFolderFiles = (rename) => {

    let tempselectedFolderData:any = parentFolderData;    
    
      // if (!isAnnotedFileSelected) tempselectedFolderData.pop();
      let currentParentId:any = tempselectedFolderData[tempselectedFolderData.length - 1]

      let temp;
      

    if (parentFolderData.length > 0 ) {

      getFolderFiles(currentParentId.ID, (res) => {
        if (res) {
          // console.log("payload of folder",x.id,res.data.data)
          
          temp = res.data.data;
          if (temp) {
            console.log("folder in data tree child", dataTree);
            getPermissionAccess(currentParentId)
            setDataTree(dataTree);
            setLocalData(temp);
            if (rename) {
              setRenameContentId(-1);
            }
          }
        }
      });
    } else {
      getFolders((response) => {
        if (response) {
          console.log("rename folders data", response.data.data);
          let temp = response.data.data;
          setLocalData(temp);
          setDataTree(temp);
          getPermissionAccess({})
          if (rename) {
            setRenameContentId(-1);
          }
        }
      });
    }
  };
  const onFileUpload = async (tokenVal) => {
    // prepare UI
    setUploading(true);
    let tempParentId =
    parentFolderData.length > 0 ? parentFolderData[parentFolderData.length - 1]?.ID : 0;
    // *** UPLOAD TO AZURE STORAGE ***
    const blobsInContainer: any = await uploadFileToBlob(
      fileSelected,
      folderName,
      'media',
      tokenVal
      // tempParentId
    );
    console.log("file create ... ",  blobsInContainer);
    if (blobsInContainer.length > 0) {
      setAttachedFiles([]);
    }
    // prepare UI for results
    setBlobList(blobsInContainer);
    console.log("file create ... ", blobsInContainer, fileSelected);
    // console.log(fileSelected[0].type)
    // let originalFileName=fileSelectedName;
    console.log( parentFolderData.length,  parentFolderData, parentFolderData[parentFolderData.length - 1])
    
    console.log("parent folder id..", tempParentId);
    let payload = {
      FileName: fileSelectedName,
      ParentFolderId: tempParentId,
      FileDisplayName: fileSelectedName,
      FileType: "pdf",
      SourceType: "azure",
      Storage_url: blobsInContainer,      
    };
    console.log("file created... paylaod", payload);

    console.log("loading folders fresh...");

    // calling api to store file in db
    createFile(payload, (res) => {
      if (res) {
        console.log("file created... ");
        loadFolderFiles(false);
      }
    });

    // reset state/form
    setFileSelected(null);
    setUploading(false);
    setInputKey(Math.random().toString(36));
  };
  // const onCreateFolder = async () => {
  //   // prepare UI
  //   setUploading(true);

  //   // *** UPLOAD TO AZURE STORAGE ***
  //   const blobsInContainer:any = await createContainer(folderName);

  //   // prepare UI for results
  //   // setBlobList(blobsInContainer);

  //   // reset state/form
  //   setFileSelected(null);
  //   setUploading(false);
  //   setInputKey(Math.random().toString(36));
  // };

  const closeSharePopup = () => {
    setShowSharePopup(false);
  };
  const removeFile = (i) => {
    console.log("functioin called for ", i);
    let attfile = attachedFiles.filter((x, index) => i != index);
    setAttachedFiles(attfile);
    // validateGetfiles(attfile);
  };

  const getFiles = (e) => {
    console.log(e.target.files) 
    
    setFileSelected(e.target.files[0]);
    setFileSelectedName(e.target.files[0].name);
    console.log("inside");
    const files: any = Array.from(e.target.files);
    // setFileSelected(files);
    // setFileSelectedName(files);
    console.log("gc files:", files);
    
    let temp: any = [];
    attachedFiles.map((x) => {
      temp.push(x);
      console.log("old attach file content", temp);
    });
    files.map((e: any) => {
      e.testTime = new Date();
      temp.push(e);
      console.log("file name", e.name);
      console.log(temp);
    });
    // temp=temp.concat(files);
    
    // let { errorMesg } = validateGetfiles(e.target.files);

    
    if (temp.length > 10) {
      // footerErrorMsg.push('Max 10 files are allowed');
      let errmsg = "Max 10 files are allowed";
      // errorMessages.push("Max 10 files are allowed");
      // console.log('Max 10 files are allowed');
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      });

      t.push("Max 10 files are allowed");
      setErrorMessages(t);
      setTimeout(function () {
        setErrorMessages([]);
      }, 2000);
    } else {
      setAttachedFiles(temp);

      console.log("attachedFiles : ", attachedFiles);
      console.log("hello world : ", attachedFiles.length);
    }
    if (false) {
      
      // this.footerErrorMsg.push(errorMesg);
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      });
      t.push(false);
      setErrorMessages(t);
      setTimeout(function () {
        setErrorMessages([]);
      }, 2000);
    }
    let doc:any = document;
    doc .getElementById("attachedfiles").value = null
    // e.target.val
    // setTimeout(() => {
    //     this.footerErrorMsg = [];
    // }, 5000);
    // e.target.value = null;
  };
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setIsMobile(true);
      calcHeightWidth();
    } else setIsMobile(false);
    window.addEventListener(
      "resize",
      function (event) {
        let device = getDeviceType();
        if (
          device == "mobile" ||
          device == "tablet" ||
          config.REACT_APP_IS_CROSS_PLATFORM
        ) {
          setIsMobile(true);
          calcHeightWidth();
        } else setIsMobile(false);
      },
      true
    );

    //call the api
    getFolders((res) => {
      if (res) {
        console.log("folders data", res.data.data);
        let temp = res.data.data;
        setLocalData(temp);
        setDataTree(temp);
      }
    });

    //setparent(data);
  }, []);
  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "initial");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, [pageTitle]);

  const getHeight = () => {
    let viewportHeight = window.innerHeight;
    let viewportWidth = window.innerWidth;
    if (viewportWidth < 1200 || config.REACT_APP_IS_CROSS_PLATFORM) {
      setScreenSize(true);
    }
    let div: any = document.getElementById("main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    let doc: any = document;
    setPaddingValTop(divpaddingTop);
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingright);
    setPaddingValLeft(divpaddingleft);
    if (doc.getElementsByClassName("media-main-div")[0])
      setMediaHeight(
        viewportHeight -
          (doc.getElementById("MobHeader").offsetHeight +
            document.getElementById("headerVal")?.offsetHeight +
            doc.getElementById("footer").offsetHeight) -
          divpaddingTop -
          divpaddingbottom
      );
  };

  useEffect(() => {
    console.log(videoCallStarted);
  }, [videoCallStarted]);
  useEffect(() => {
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
    // axios.get("https://mocki.io/v1/807ebf6f-5b3f-40ae-9ea1-dd86bb97a5d7")
    //   .then((res) => {
    //     console.log(res.data);
    //       setDocData(res.data);
    //   })
    //   .catch((err)=>{
    //     console.log("Media fetch Error:"+err);

    //   })
    let data = [
      {
        id: 1,
        filename: "Dosing-Information.png",
        fileurl:
          "https://testblobmedia.blob.core.windows.net/media/dosing Information.png",
        filetype: "JPG",
      },
      {
        id: 2,
        filename: "MIR-Sign-Dr-Abigail-Allan.pdf",
        fileurl:
          "https://testblobmedia.blob.core.windows.net/media/mirformfilled.pdf",
        filetype: "pdf",
      },
      {
        id: 3,
        filename: "MIR-Empty.pdf",
        fileurl:
          "https://testblobmedia.blob.core.windows.net/media/mirformblank.pdf",
        filetype: "pdf",
      },
      {
        id: 4,
        filename: "Feeling-Guide.pdf",
        fileurl:
          "https://testblobmedia.blob.core.windows.net/media/CORDASTINfeelingsv2.pdf",
        filetype: "pdf",
      },
    ];
    getAllFiles((res) => {
      if (res) {
        console.log("all files data", res.data.data);
        data = res.data.data;
        setDocData(data);
        setAllDoc(data);
        setLoading(false)
      }
    });

    // setDocData(data);
    // setAllDoc(data);

    return () => {
      fileSelect(null, "present");
    };
  }, []);

  useEffect(() => {
    
    // if(fileUrl.length>0){
    if (open && fileUrl) {
      
      console.log("inside");
      if (instance) {
        instance.loadDocument(fileUrl);
        //console.log(instance)
      } else {
        WebViewer(
          {
            path: "/webviewerNew/lib",
            initialDoc: fileUrl,
            fullAPI: true,
            licenseKey:config.REACT_APP_PDFTRON_LICENSEKEY
            //  fullAPI: true,
            //  disableVirtualDisplayMode: true,
          },
          viewer.current
        ).then(async (instance: any) => {
          setInstance(instance);
          if (!writePermisson && readPermisson) {
            instance.disableElements(["toolsHeader"]);
          }
          instance.setHeaderItems(function (header) {
            if (videoCallStarted)
              header.push({
                type: "actionButton",
                img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
                onClick: async function () {
                  if (!room)
                    return window.alert("Please start a meeting first");
                  // const res =
                  await saveDocument();
                  // console.log(res)
                },
              });
          });

          var saveDocument = async function () {
            annotationManager.exportAnnotations().then(function (xfdfString) {
              documentViewer
                .getDocument()
                .getFileData({ xfdfString })
                .then(async function (data) {
                  var arr = new Uint8Array(data);
                  var myFile = new Blob([arr], { type: "application/pdf" });
                  // FormData is used to send blob data through fetch
                  var formData = new FormData();
                  formData.append("files", myFile);
                  formData.append("version", "3");
                  formData.append("fileName", fileSelectedName);
                  formData.append(
                    "fileId",
                    fileSelectedId ? fileSelectedId : "0"
                  );
                  formData.append("room", JSON.stringify(roomObj));
                  let config1 = {
                    method: "post",
                    maxBodyLength: Infinity,
                    // url: `${'http://localhost:8080'}/save-file/`,
                    url: `${config.REACT_APP_CONTENT_SHARE_API_BASE}/save-file/`,
                    headers: {
                      Accept: "application/json",
                    },
                    data: formData,
                  };
                  // const saveRes =
                  await axios
                    .request(config1)
                    .then((res) => {
                      console.log("result", res);
                      if (res.status == 200) {
                        dispatch(setShowSuccessSnackBar(true));
                        setOpen(false);
                        fileSelect(null, "present");
                      }
                    })
                    .catch((res) => {
                      if (res) {
                        dispatch(setShowErrorSnackBar(true));
                      }
                    });
                  // console.log(saveRes)
                });
            });
          };

          //instance.disableElements(['header']);
          //instance.disableElements(["toolsHeader"]);

          const {
            documentViewer,
            annotationManager,
            Annotations,
            PDFNet,
            Tools,
            Math,
          } = instance.Core;
          const { WidgetFlags } = Annotations;
          const signatureTool = documentViewer.getTool(
            "AnnotationCreateSignature"
          );
          let signatureAnnotaionCustom1: any = null;
          let signatureAnnotaionCordinate: any = null;
          await PDFNet.initialize();
          setAnnotationManagerNew(annotationManager);
          //instance.UI.openElements(['signaturePanel']);

          // var fdf = new PDFNet.FDFDoc.create(await (await response.blob()).arrayBuffer());
          //
          //   instance.UI.VerificationOptions.addTrustedCertificates([
          //    '/entrust_class_3_intermediate.cer',
          //  ])
          // const response = await fetch('https://testblobmedia.blob.core.windows.net/media/adobe_aatl_eutl.fdf');
          // let t=(await (await response.blob()).arrayBuffer())
          // var fdf = new PDFNet.FDFDoc.createFromMemoryBuffer(t);
          // instance.UI.VerificationOptions.loadTrustList(t);
          // const response2 = await fetch('https://testblobmedia.blob.core.windows.net/media/entrust_class_3_intermediate.cer');
          // let t2=(await (await response2.blob()).arrayBuffer())
          // instance.UI.VerificationOptions.addTrustedCertificates([t2])
          fetch(
            "https://testblobmedia.blob.core.windows.net/media/adobe_aatl_eutl.fdf"
          ).then(function (certExchange) {
            certExchange.blob().then(function (blobCertExchange) {
              instance.UI.VerificationOptions.loadTrustList(blobCertExchange);
              instance.UI.VerificationOptions.addTrustedCertificates([
                "https://testblobmedia.blob.core.windows.net/media/entrust_class_3_intermediate.cer",
              ]);
              console.log("loaded certificates");
            });
          });
          annotationManager.setCurrentUser("Zing User");
          let ip: any = "asdasgd";
          let location: any = "asdasd";
          hasfield = false;
          //let ipAddress: any = await axios.get('https://api.db-ip.com/v2/free/self');
          // if (ipAddress.data) {
          //   ip = ipAddress.data.ipAddress
          //   location = ipAddress.data.city + " " + ipAddress.data.stateProv + "," + ipAddress.data.countryCode
          // }
          async function main() {
            let doc: any;
            try {
              doc = await PDFNet.PDFDoc.createFromURL(fileUrl);
              console.log("doc_pdf", doc);
            } catch (error: any) {
              console.log(error?.message);
              if (error?.message)
                console.log("-----------<>>>>>", error?.message, 761);
              // setShowErrorMessage(true);
            }

            if (doc) {
              try {
                const formData = {
                  formstatus: "opened",
                  formRequestId: formId,
                };
                // const res = await axios.post(
                //   `${process.env.REACT_APP_FORMSTATUS}`,
                //   formData
                // );
                // console.log(res);
              } catch (ex) {
                console.log(ex);
              }
            } else {
              console.log("doc_pdf", doc);
            }
            //const doc=await PDFNet.PDFDoc.createFromBuffer(b)
            doc.initSecurityHandler();
            doc.lock();
            const opts = new PDFNet.PDFDoc.ViewerOptimizedOptions();
            opts.setThumbnailRenderingThreshold(0);
            opts.setThumbnailSize(512);
            await doc.saveViewerOptimized(fileUrl, opts)
            // const approval_signature_field =
            //   await doc.createDigitalSignatureField("PDFTronApprovalSigGraph");
            // const widgetAnnotApproval =
            //   await PDFNet.SignatureWidget.createWithDigitalSignatureField(
            //     doc,
            //     new PDFNet.Rect(300, 200, 200, 100),
            //     approval_signature_field
            //   );
            // const pageCount = await doc.getPageCount();

            // const page1 = await doc.getPage(pageCount);
            // await page1.annotPushBack(widgetAnnotApproval);

            hasfield = true;
            const docbuf = await doc.saveMemoryBuffer(
              PDFNet.SDFDoc.SaveOptions.e_remove_unused
            );
            //instance.loadDocument(docbuf)
            const blob = new Blob([docbuf], { type: "application/pdf" });
            instance.UI.loadDocument(blob);
          }
          // add your own license key as the second parameter, e.g. PDFNet.runWithCleanup(main, 'YOUR_LICENSE_KEY')

          if (!hasfield && state != "signature") PDFNet.runWithCleanup(main);

          async function extractAnnotationSignature(
            annotation: any,
            docViewer: any
          ) {
            
            // Create a new Canvas to draw the Annotation on
            const canvas = document.createElement("canvas");
            // Reference the annotation from the Document
            const pageMatrix = docViewer
              .getDocument()
              .getPageMatrix(annotation.PageNumber);
            // Set the height & width of the canvas to match the annotation
            canvas.height = annotation.Height;
            canvas.width = annotation.Width;
            const ctx: any = canvas.getContext("2d");
            // Translate the Annotation to the top Top Left Corner of the Canvas ie (0, 0)
            ctx.translate(-annotation.X, -annotation.Y);
            // Draw the Annotation onto the Canvas
            annotation.draw(ctx, pageMatrix);
            // Convert the Canvas to a Blob Object for Upload
            
            canvas.toBlob((b: any) => {
              b.arrayBuffer().then((buffer: any) => {
                te = buffer;
                setSignFile(buffer);
              });
            });
          }

          async function VerifyAllAndPrint() {
            const in_public_key_file_path = "/entrust_class_3_intermediate.cer";
            const doc = await documentViewer.getDocument().getPDFDoc();
            doc.initSecurityHandler();
            doc.lock();
            console.log("==========");
            const opts = await PDFNet.VerificationOptions.create(
              PDFNet.VerificationOptions.SecurityLevel
                .e_compatibility_and_archiving
            );

            var fdf = await PDFNet.PDFDoc.createFromURL("/adobe_aatl_eutl.fdf");
            await opts.loadTrustList(fdf);
            // Add trust root to store of trusted certificates contained in VerificationOptions.
            // Use trust level corresponding to an identity trusted even for certification signatures.
            await opts.addTrustedCertificateFromURL(
              in_public_key_file_path,
              {},
              PDFNet.VerificationOptions.CertificateTrustFlag.e_default_trust +
                PDFNet.VerificationOptions.CertificateTrustFlag
                  .e_certification_trust
            );

            // Iterate over the signatures and verify all of them.
            const digsig_fitr =
              await doc.getDigitalSignatureFieldIteratorBegin();
            let verification_status = true;
            for (; await digsig_fitr.hasNext(); await digsig_fitr.next()) {
              const curr = await digsig_fitr.current();
              const result = await curr.verify(opts);
              if (await result.getVerificationStatus()) {
                console.log(
                  "Signature verified, objnum: " +
                    (await (await curr.getSDFObj()).getObjNum())
                );
              } else {
                console.log(
                  "Signature verification failed, objnum: " +
                    (await (await curr.getSDFObj()).getObjNum())
                );
                verification_status = false;
              }

              switch (await result.getDigestAlgorithm()) {
                case PDFNet.DigestAlgorithm.Type.e_SHA1:
                  console.log("Digest algorithm: SHA-1");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA256:
                  console.log("Digest algorithm: SHA-256");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA384:
                  console.log("Digest algorithm: SHA-384");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA512:
                  console.log("Digest algorithm: SHA-512");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_RIPEMD160:
                  console.log("Digest algorithm: RIPEMD-160");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_unknown_digest_algorithm:
                  console.log("Digest algorithm: unknown");
                  break;
              }

              console.log(
                "Detailed verification result: \n\t" +
                  (await result.getDocumentStatusAsString()) +
                  "\n\t" +
                  (await result.getDigestStatusAsString()) +
                  "\n\t" +
                  (await result.getTrustStatusAsString()) +
                  "\n\t" +
                  (await result.getPermissionsStatusAsString())
              );

              const changes = await result.getDisallowedChanges();
              for (let i = 0; i < changes.length; ++i) {
                const change = changes[i];
                console.log(
                  "\tDisallowed change: " +
                    (await change.getTypeAsString()) +
                    ", objnum: " +
                    (await change.getObjNum())
                );
              }

              // Get and print all the detailed trust-related results, if they are available.
              if (await result.hasTrustVerificationResult()) {
                const trust_verification_result =
                  await result.getTrustVerificationResult();
                console.log(
                  (await trust_verification_result.wasSuccessful())
                    ? "Trust verified."
                    : "Trust not verifiable."
                );
                console.log(await trust_verification_result.getResultString());

                const tmp_time_t =
                  await trust_verification_result.getTimeOfTrustVerification();
                switch (
                  await trust_verification_result.getTimeOfTrustVerificationEnum()
                ) {
                  case PDFNet.VerificationOptions.TimeMode.e_current:
                    console.log(
                      "Trust verification attempted with respect to current time (as epoch time):" +
                        tmp_time_t
                    );
                    break;
                  case PDFNet.VerificationOptions.TimeMode.e_signing:
                    console.log(
                      "Trust verification attempted with respect to signing time (as epoch time): " +
                        tmp_time_t
                    );
                    break;
                  case PDFNet.VerificationOptions.TimeMode.e_timestamp:
                    console.log(
                      "Trust verification attempted with respect to secure embedded timestamp (as epoch time): " +
                        tmp_time_t
                    );
                    break;
                }

                const cert_path = await trust_verification_result.getCertPath();
                if (cert_path.length === 0) {
                  console.log("Could not print certificate path.");
                } else {
                  console.log("Certificate path:");
                  for (let i = 0; i < cert_path.length; i++) {
                    console.log("\tCertificate:");
                    const full_cert = cert_path[i];
                    console.log("\t\tIssuer names:");
                    const issuer_dn = await (
                      await full_cert.getIssuerField()
                    ).getAllAttributesAndValues();
                    for (let j = 0; j < issuer_dn.length; j++) {
                      console.log(
                        "\t\t\t" + (await issuer_dn[j].getStringValue())
                      );
                    }
                    console.log("\t\tSubject names:");
                    const subject_dn = await (
                      await full_cert.getSubjectField()
                    ).getAllAttributesAndValues();
                    for (let j = 0; j < subject_dn.length; j++) {
                      console.log(
                        "\t\t\t" + (await subject_dn[j].getStringValue())
                      );
                    }
                    console.log("\t\tExtensions:");
                    const extension_dn = await full_cert.getExtensions();
                    for (let j = 0; j < extension_dn.length; j++) {
                      console.log(
                        "\t\t\t" + (await extension_dn[j].toString())
                      );
                    }
                  }
                }
              } else {
                console.log("No detailed trust verification result available.");
              }

              console.log("==========");
            }

            return verification_status;
          }
          async function save() {
            const doc = await documentViewer.getDocument().getPDFDoc();

            const approval_signature_field =
              await doc.createDigitalSignatureField("PDFTronApprovalSig");
            const widgetAnnotApproval =
              await PDFNet.SignatureWidget.createWithDigitalSignatureField(
                doc,
                new PDFNet.Rect(0, 0, 0, 0),
                approval_signature_field
              );
            const page1 = await doc.getPage(1);
            await page1.annotPushBack(widgetAnnotApproval);
            // const doc=await PDFNet.PDFDoc.createFromBuffer(arr);

            const found_approval_field = await doc.getField(
              "PDFTronApprovalSig"
            );
            console.log(await doc.getField("PDFTronApprovalSigGraph"));
            const sigHandlerId = await doc.addStdSignatureHandlerFromURL(
              "/P360.pfx",
              "123456"
            );
            //  const sigHandlerId = await doc.addStdSignatureHandler('/pkcs7.p7b')
            const found_approval_signature_digsig_field =
              await PDFNet.DigitalSignatureField.createFromField(
                found_approval_field
              );
            await found_approval_signature_digsig_field.setDocumentPermissions(
              PDFNet.DigitalSignatureField.DocumentPermissions
                .e_no_changes_allowed
            );
            // await  doc.saveCustomSignatureBuffer(te, found_approval_signature_digsig_field)
            // (OPTIONAL) Add an appearance to the signature field.

            //  const img = await PDFNet.Image.createFromMemory2(doc, te);

            // const approvalSignatureWidget = await PDFNet.SignatureWidget.createWithDigitalSignatureField(doc, await PDFNet.Rect.init(0,0, 0, 0), found_approval_signature_digsig_field);
            // // await approvalSignatureWidget.createSignatureAppearance(img);
            // const page1 = await doc.getPage(1);

            // page1.annotPushBack(approvalSignatureWidget);

            //Prepare the signature and signature handler for signing.
            // found_approval_signature_digsig_field.setDocumentPermissions(PDFNet.DigitalSignatureField.DocumentPermissions.e_no_changes_allowed);
            console.log("Adding field permissions.");
            const fields_to_lock: Array<string> = ["PDFTronApprovalSig"];
            await found_approval_signature_digsig_field.setFieldPermissions(
              PDFNet.DigitalSignatureField.FieldPermissions.e_lock_all
            );
            await found_approval_signature_digsig_field.signOnNextSaveWithCustomHandler(
              sigHandlerId
            );
            await found_approval_signature_digsig_field.setLocation(location);
            await found_approval_signature_digsig_field.setReason(
              reason ? reason : "Document approval"
            );
            await found_approval_signature_digsig_field.setContactInfo(
              "www.p360.com"
            );

            // save to blob storage
            const buf = await doc.saveMemoryBuffer(
              PDFNet.SDFDoc.SaveOptions.e_remove_unused
            );

            state = "signedFinal";
            instance.loadDocument(buf);

            const blob = new Blob([buf], { type: "application/pdf" });

            let srffile = new File(
              [blob],
              "Signed_" + fileName + "_" + new Date().toISOString() + ".pdf"
            );

            console.log(typeof buf);

            //SendformStatus

            // try {
            //   const formData = {
            //     "formstatus":"completed",
            //     "formRequestId":formId
            // }

            //   const res = await axios.post(
            //     `${process.env.REACT_APP_FORMSTATUS}`,
            //     formData
            //   );
            //   console.log("Form status",res);

            // } catch (ex) {
            //   console.log(ex);
            // }

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = "signed_doc.pdf";
            document.body.appendChild(a);
            a.click();
            

            // try {
            //   const formData = {
            //     "file":srffile,
            //     "formId":formId
            //   };

            //   const res = await axios.post(
            //     `${process.env.REACT_APP_FORMSAPI}`+"savetoblob",
            //     formData
            //   );
            //   console.log(res);
            // } catch (ex) {
            //   console.log(ex);
            // }
          }

          var saveDocumentBlob = async function () {
            // return new Promise(function(resolve) {
            annotationManager.exportAnnotations().then(function (xfdfString) {
              documentViewer
                .getDocument()
                .getFileData({ xfdfString })
                .then(async function (data) {
                  // console.log(data, '---------------><><><><')
                  var arr = new Uint8Array(data);
                  var myFile = new Blob([arr], { type: "application/pdf" });
                  // FormData is used to send blob data through fetch
                  var formData = new FormData();
                  formData.append("files", myFile);
                  formData.append("version", "3");
                  let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "http://localhost:8080/save-file/",
                    headers: {
                      Accept: "application/json",
                    },
                    data: formData,
                  };

                  const saveRes = await axios.request(config);
                  console.log(saveRes);
                });
            });
            // });
          };

          async function addSignDetails() {
            console.log(signatureAnnotaionCustom1);
            const signCanvas = document.createElement("canvas");
            // Reference the annotation from the Document
            const pageMatrix = documentViewer
              .getDocument()
              .getPageMatrix(signatureAnnotaionCustom1.PageNumber);
            signCanvas.height = signatureAnnotaionCustom1.Height;
            signCanvas.width = signatureAnnotaionCustom1.Width;
            const annotationList: any = [];
            const ctx: any = signCanvas.getContext("2d");
            // Translate the signatureAnnotaionCustom1 to the top Top Left Corner of the Canvas ie (0, 0)
            ctx.translate(
              -signatureAnnotaionCustom1.X,
              -signatureAnnotaionCustom1.Y
            );
            //signatureAnnotaionCustom1.Color = new Annotations.Color(1, 215, 88)
            signatureAnnotaionCustom1.setX(signatureAnnotaionCordinate.x);
            signatureAnnotaionCustom1.setY(signatureAnnotaionCordinate.y);
            //signatureAnnotaionCustom1.resize(new Math.rect(signatureAnnotaionCordinate.x, signatureAnnotaionCordinate.y, signatureAnnotaionCordinate.x + 40, signatureAnnotaionCordinate.y + 40))
            signatureAnnotaionCustom1.draw(ctx, pageMatrix);
            signCanvas.toBlob((blob: any) => {
              
              createCustomAnnotation(
                blob,
                [
                  "Signer Name:" + annotationManager.getCurrentUser(),
                  "Signing Time:" +
                    new Date().toISOString().substring(0, 10) +
                    " " +
                    new Date().toISOString().substring(11, 19) +
                    "Z(UTC)",
                  "Signer IP:" + ip,
                  "Signing Reason:" + reason,
                ],
                annotationList,
                signatureAnnotaionCustom1.Width,
                signatureAnnotaionCustom1.Height
              );
              flatSign(annotationList);
            });
            async function createCustomAnnotation(
              signImg: any,
              data: string[],
              annotationList: any,
              width: any,
              height: any
            ) {
              let annotManager = annotationManager;

              class DigitalSignatureAnnotation extends Annotations.CustomAnnotation {
                constructor() {
                  super("digitalSignature"); // provide the custom XFDF element name
                  this.Subject = "DigitalSignature";
                }
                draw(ctx: any, pageMatrix: any) {
                  // the setStyles function is a function on markup annotations that sets up
                  // certain properties for us on the canvas for the annotation's stroke thickness.
                  this.setStyles(ctx, pageMatrix);

                  const helper = new Annotations.CanvasHelper(ctx);
                  const textParams = {
                    fillColor: new Annotations.Color(255, 0, 0),
                    opacity: 1,
                    borderStyle: "solid",
                    fontSize: 4,
                  };
                  // first we need to translate to the annotation's x/y coordinates so that it's
                  // drawn in the correct location
                  ctx.translate(this.X, this.Y);
                  const img = new Image();
                  img.onload = (event) => {
                    ctx.drawImage(event.target, 0, 0, 50, 50);
                  };

                  img.src = URL.createObjectURL(signImg);

                  helper.drawText(
                    "Digitally Signed By",
                    width + 2,
                    0,
                    textParams
                  );
                  let y = helper.measureText("Digitally Signed By")[
                    "actualBoundingBoxAscent"
                  ];

                  let x = width + 2;
                  data.forEach((info) => {
                    helper.drawText(info, x, y, textParams);
                    //console.log(helper.measureText(info))
                    let textHeight =
                      helper.measureText(info)["actualBoundingBoxAscent"];
                    y = y + textHeight;
                  });
                }
              }

              DigitalSignatureAnnotation.prototype.elementName =
                "digitalSignature";
              const customSignAnnotation = new DigitalSignatureAnnotation();
              customSignAnnotation.Width = 300;
              customSignAnnotation.Height = 200;
              customSignAnnotation.setX(signatureAnnotaionCordinate.x);
              customSignAnnotation.setY(signatureAnnotaionCordinate.y);
              customSignAnnotation.Color = new Annotations.Color(255, 0, 0);

              // annotManager.deleteAnnotations(annotManager.getAnnotationsList(), { force: false, isUndoRedo: true });
              annotationList.push(customSignAnnotation);
              annotManager.addAnnotations(annotationList);
              annotManager.drawAnnotationsFromList(annotationList);
            }
          }

          document
            .getElementById("reasonSave")
            ?.addEventListener("click", () => {
              console.log("reasonSave");

              saveDocumentBlob();

              addSignDetails();
            });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars

          async function flatSign(annotationList: any) {
            console.log("inside flatsign");
            state = "signature";
            const doc1 = documentViewer.getDocument();
            console.log(await annotationManager.getAnnotationsList());
            const xfdfString = await annotationManager.exportAnnotations({
              widgets: false,

              annotList: await annotationManager.getAnnotationsList(),
            });

            const data = await doc1.getFileData({
              // saves the document with annotations in it
              xfdfString,
              flatten: true,
            });
            console.log(data, "data--------------");
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: "application/pdf" });
            instance.UI.loadDocument(blob);
          }
          // document.getElementById("save")?.addEventListener("click", async () => {
          //               // setPdfstate("signature");

          //             });
          documentViewer.addEventListener("documentLoaded", async () => {
            const doc = await documentViewer.getDocument().getPDFDoc();
            dispatch(setDoc(doc));
            console.log(doc);
            if (state == "signature") {
              //document.getElementById("reason-popup").style.display = "block";
              save();
              state = "signed";
            }
            // if(state=='detailed')
            // flatSign();
            if (state == "signedFinal") VerifyAllAndPrint();
            annotationManager.addEventListener(
              "annotationSelected",
              async (annotationList: any) => {
                
                annotationList.forEach((annotation: any) => {
                  if (annotation.Subject === "Signature") {
                    

                    signatureAnnotaionCustom1 = annotation;
                    signatureAnnotaionCordinate = {
                      x: annotation.X,
                      y: annotation.Y,
                    };
                    extractAnnotationSignature(annotation, documentViewer);
                  }
                });
              }
            );

            signatureTool.addEventListener(
              "locationSelected",
              (pageCoordinates: any) => {
                //signatureAnnotaionCordinate = pageCoordinates;
                console.log(pageCoordinates);
              }
            );

            annotationManager.on("annotationChanged", async (e: any) => {
              // If annotation change is from import, return
              if (e.imported) {
                return;
              }
              e.forEach((annotation: any) => {
                if (annotation.Subject === "Signature") {
                  signatureAnnotaionCustom1 = annotation;
                  signatureAnnotaionCordinate = {
                    x: annotation.X,
                    y: annotation.Y,
                  };

                  extractAnnotationSignature(annotation, documentViewer);
                }
              });
              const xfdfString =
                await annotationManager.exportAnnotationCommand();
              // Parse xfdfString to separate multiple annotation changes to individual annotation change
              const parser = new DOMParser();
              const commandData = parser.parseFromString(
                xfdfString,
                "text/xml"
              );
              const addedAnnots = commandData.getElementsByTagName("add")[0];
              const modifiedAnnots =
                commandData.getElementsByTagName("modify")[0];
              const deletedAnnots =
                commandData.getElementsByTagName("delete")[0];

              // List of added annotations
              addedAnnots.childNodes.forEach((child) => {
                sendAnnotationChange(child, "add");
              });

              // // List of modified annotations
              modifiedAnnots.childNodes.forEach((child) => {
                sendAnnotationChange(child, "modify");
              });

              // // List of deleted annotations
              deletedAnnots.childNodes.forEach((child) => {
                sendAnnotationChange(child, "delete");
              });
            });
          });
        });
      }
      //}
    } else {
      setInstance(null);
    }
  }, [open, fileUrl]);

  const convertToXfdf = (changedAnnotation, action) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    if (action === "add") {
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
    } else if (action === "modify") {
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
    } else if (action === "delete") {
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
    }
    xfdfString += `</xfdf>`;
    return xfdfString;
  };

  // helper function to send annotation changes to WebSocket server
  const sendAnnotationChange = (annotation, action) => {
    console.log("adasdas");
    if (annotation.nodeType !== annotation.TEXT_NODE) {
      const annotationString = serializer.serializeToString(annotation);

      const annotations = convertToXfdf(annotationString, action);
      console.log("annotation Data from UI ::" + annotationString + "\t");

      if (room)
        room.localParticipant.dataTracks.forEach((element) => {
          element.track.send(
            JSON.stringify({ type: "annotation", value: annotations })
          );
        });

      //setAnnotationData(annotations);

      //  getAnnotationData && getAnnotationData(annotations);
      // connection.send(JSON.stringify({
      //   documentId: DOCUMENT_ID,
      //   annotationId: annotation.getAttribute('name'),
      //   xfdfString: convertToXfdf(annotationString, action)
      // }));
    } else {
      console.log("No data for annotations");
    }
  };

  const remoteAnnote = async (annotationManagerNew) => {
    
    const annotations = await annotationManagerNew.importAnnotCommand(
      videoCallAnnotaion
    );
    console.log(annotations);
    await annotationManagerNew.drawAnnotationsFromList(annotations);
  };

  useEffect(() => {
    console.log(videoCallAnnotaion);
    if (videoCallAnnotaion) {
      if (AnnotationManagerNew) {
        remoteAnnote(AnnotationManagerNew);
      }
    }
  }, [videoCallAnnotaion]);
  const handleOptionsClick = (i) => {
    

    console.log("240");
    if (videoCallStarted) {
      setOptionsdiv(!optionsdiv);
      setHoveredCart(i);
    } else {
    }
  };
  const calcHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    if (!div) return;
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    if (videoCallStarted) {
    }
    // console.log("gc modal toppad",divpaddingTop);
    // console.log("gc modal bottompad",divpaddingbottom);
    // console.log("gc modal leftpad",divpaddingleft);
    // console.log("gc modal rightpad",divpaddingright);
    // console.log("gc modal height",window.innerHeight-divpaddingTop-divpaddingbottom);
    // console.log("gc modal width",window.innerWidth-divpaddingleft-divpaddingright);
    // if(window.innerWidth<=1200){
    setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
    setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);

    //}
  };

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      getHeight();
      calcHeightWidth();
    });
    return () => {
      window.removeEventListener("orientationchange", () => {
        getHeight();
        calcHeightWidth();
      });
    };
  }, []);

  function truncate(source, size) {
    return source?.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  useEffect(() => {
    

    if (showdocviewer && fileUrl) {
      console.log();
      if (mobileInstance) {
        mobileInstance.loadDocument(fileUrl);
        setBlockUI(false)
        //console.log(instance)
      } else {
        console.log("inside");
        
        WebViewer(
          {
            path: "/webviewerNew/lib",
            initialDoc: fileUrl,
            fullAPI: true,
            licenseKey:config.REACT_APP_PDFTRON_LICENSEKEY
            // extension:'SVG'

            //  disableVirtualDisplayMode: true,
          },
          viewerMobile.current
        ).then(async (instance: any) => {
          setMobileInstance(instance);
          setBlockUI(false);
          //instance.disableElements(['header']);
          //instance.disableElements(["toolsHeader"]);
          if (!writePermisson && readPermisson) {
            instance.disableElements(["toolsHeader"]);
          }
          instance.setHeaderItems(function (header) {
            if (videoCallStarted)
              header.push({
                type: "actionButton",
                img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
                onClick: async function () {
                  if (!room)
                    return window.alert("Please start a meeting first");
                  // const res =
                  await saveDocument();
                  // console.log(res)
                },
              });
          });
          const {
            documentViewer,
            annotationManager,
            Annotations,
            PDFNet,
            Tools,
            Math,
          } = instance.Core;
          const { WidgetFlags } = Annotations;
          const signatureTool = documentViewer.getTool(
            "AnnotationCreateSignature"
          );
          let signatureAnnotaionCustom1: any = null;
          let signatureAnnotaionCordinate: any = null;
          await PDFNet.initialize();
          setAnnotationManagerNew(annotationManager);
          //instance.UI.openElements(['signaturePanel']);

          // var fdf = new PDFNet.FDFDoc.create(await (await response.blob()).arrayBuffer());
          //
          //   instance.UI.VerificationOptions.addTrustedCertificates([
          //    '/entrust_class_3_intermediate.cer',
          //  ])
          // const response = await fetch('https://testblobmedia.blob.core.windows.net/media/adobe_aatl_eutl.fdf');
          // let t=(await (await response.blob()).arrayBuffer())
          // var fdf = new PDFNet.FDFDoc.createFromMemoryBuffer(t);
          // instance.UI.VerificationOptions.loadTrustList(t);
          // const response2 = await fetch('https://testblobmedia.blob.core.windows.net/media/entrust_class_3_intermediate.cer');
          // let t2=(await (await response2.blob()).arrayBuffer())
          // instance.UI.VerificationOptions.addTrustedCertificates([t2])
          fetch(
            "https://testblobmedia.blob.core.windows.net/media/adobe_aatl_eutl.fdf"
          ).then(function (certExchange) {
            certExchange.blob().then(function (blobCertExchange) {
              instance.UI.VerificationOptions.loadTrustList(blobCertExchange);
              instance.UI.VerificationOptions.addTrustedCertificates([
                "https://testblobmedia.blob.core.windows.net/media/entrust_class_3_intermediate.cer",
              ]);
              console.log("loaded certificates");
            });
          });
          annotationManager.setCurrentUser("Zing User");
          let ip: any = "asdasgd";
          let location: any = "asdasd";
          hasfield = false;
          //let ipAddress: any = await axios.get('https://api.db-ip.com/v2/free/self');
          // if (ipAddress.data) {
          //   ip = ipAddress.data.ipAddress
          //   location = ipAddress.data.city + " " + ipAddress.data.stateProv + "," + ipAddress.data.countryCode
          // }
          async function main() {
            const doc = await PDFNet.PDFDoc.createFromURL(fileUrl);
            console.log("form url", doc);
            if (doc) {
              try {
                const formData = {
                  formstatus: "opened",
                  formRequestId: formId,
                };
                // const res = await axios.post(
                //   `${process.env.REACT_APP_FORMSTATUS}`,
                //   formData
                // );
                // console.log(res);
              } catch (ex) {
                console.log(ex);
              }
            }
            //const doc=await PDFNet.PDFDoc.createFromBuffer(b)
            doc.initSecurityHandler();
            doc.lock();
            const opts = new PDFNet.PDFDoc.ViewerOptimizedOptions();
            opts.setThumbnailRenderingThreshold(0);
            opts.setThumbnailSize(512);
            await doc.saveViewerOptimized(fileUrl, opts)
            // const approval_signature_field =
            //   await doc.createDigitalSignatureField("PDFTronApprovalSigGraph");
            // const widgetAnnotApproval =
            //   await PDFNet.SignatureWidget.createWithDigitalSignatureField(
            //     doc,
            //     new PDFNet.Rect(300, 200, 200, 100),
            //     approval_signature_field
            //   );
            // const pageCount = await doc.getPageCount();

            // const page1 = await doc.getPage(pageCount);
            // await page1.annotPushBack(widgetAnnotApproval);

            hasfield = true;
            const docbuf = await doc.saveMemoryBuffer(
              PDFNet.SDFDoc.SaveOptions.e_remove_unused
            );
            //instance.loadDocument(docbuf)
            const blob = new Blob([docbuf], { type: "application/pdf" });
            instance.UI.loadDocument(blob);
          }
          // add your own license key as the second parameter, e.g. PDFNet.runWithCleanup(main, 'YOUR_LICENSE_KEY')

          if (!hasfield && state != "signature") PDFNet.runWithCleanup(main);

          async function extractAnnotationSignature(
            annotation: any,
            docViewer: any
          ) {
            
            // Create a new Canvas to draw the Annotation on
            const canvas = document.createElement("canvas");
            // Reference the annotation from the Document
            const pageMatrix = docViewer
              .getDocument()
              .getPageMatrix(annotation.PageNumber);
            // Set the height & width of the canvas to match the annotation
            canvas.height = annotation.Height;
            canvas.width = annotation.Width;
            const ctx: any = canvas.getContext("2d");
            // Translate the Annotation to the top Top Left Corner of the Canvas ie (0, 0)
            ctx.translate(-annotation.X, -annotation.Y);
            // Draw the Annotation onto the Canvas
            annotation.draw(ctx, pageMatrix);
            // Convert the Canvas to a Blob Object for Upload
            
            canvas.toBlob((b: any) => {
              b.arrayBuffer().then((buffer: any) => {
                te = buffer;
                setSignFile(buffer);
              });
            });
          }

          async function VerifyAllAndPrint() {
            const in_public_key_file_path = "/entrust_class_3_intermediate.cer";
            const doc = await documentViewer.getDocument().getPDFDoc();
            doc.initSecurityHandler();
            doc.lock();
            console.log("==========");
            const opts = await PDFNet.VerificationOptions.create(
              PDFNet.VerificationOptions.SecurityLevel
                .e_compatibility_and_archiving
            );

            var fdf = await PDFNet.PDFDoc.createFromURL("/adobe_aatl_eutl.fdf");
            await opts.loadTrustList(fdf);
            // Add trust root to store of trusted certificates contained in VerificationOptions.
            // Use trust level corresponding to an identity trusted even for certification signatures.
            await opts.addTrustedCertificateFromURL(
              in_public_key_file_path,
              {},
              PDFNet.VerificationOptions.CertificateTrustFlag.e_default_trust +
                PDFNet.VerificationOptions.CertificateTrustFlag
                  .e_certification_trust
            );

            // Iterate over the signatures and verify all of them.
            const digsig_fitr =
              await doc.getDigitalSignatureFieldIteratorBegin();
            let verification_status = true;
            for (; await digsig_fitr.hasNext(); await digsig_fitr.next()) {
              const curr = await digsig_fitr.current();
              const result = await curr.verify(opts);
              if (await result.getVerificationStatus()) {
                console.log(
                  "Signature verified, objnum: " +
                    (await (await curr.getSDFObj()).getObjNum())
                );
              } else {
                console.log(
                  "Signature verification failed, objnum: " +
                    (await (await curr.getSDFObj()).getObjNum())
                );
                verification_status = false;
              }

              switch (await result.getDigestAlgorithm()) {
                case PDFNet.DigestAlgorithm.Type.e_SHA1:
                  console.log("Digest algorithm: SHA-1");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA256:
                  console.log("Digest algorithm: SHA-256");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA384:
                  console.log("Digest algorithm: SHA-384");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA512:
                  console.log("Digest algorithm: SHA-512");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_RIPEMD160:
                  console.log("Digest algorithm: RIPEMD-160");
                  break;
                case PDFNet.DigestAlgorithm.Type.e_unknown_digest_algorithm:
                  console.log("Digest algorithm: unknown");
                  break;
              }

              console.log(
                "Detailed verification result: \n\t" +
                  (await result.getDocumentStatusAsString()) +
                  "\n\t" +
                  (await result.getDigestStatusAsString()) +
                  "\n\t" +
                  (await result.getTrustStatusAsString()) +
                  "\n\t" +
                  (await result.getPermissionsStatusAsString())
              );

              const changes = await result.getDisallowedChanges();
              for (let i = 0; i < changes.length; ++i) {
                const change = changes[i];
                console.log(
                  "\tDisallowed change: " +
                    (await change.getTypeAsString()) +
                    ", objnum: " +
                    (await change.getObjNum())
                );
              }

              // Get and print all the detailed trust-related results, if they are available.
              if (await result.hasTrustVerificationResult()) {
                const trust_verification_result =
                  await result.getTrustVerificationResult();
                console.log(
                  (await trust_verification_result.wasSuccessful())
                    ? "Trust verified."
                    : "Trust not verifiable."
                );
                console.log(await trust_verification_result.getResultString());

                const tmp_time_t =
                  await trust_verification_result.getTimeOfTrustVerification();
                switch (
                  await trust_verification_result.getTimeOfTrustVerificationEnum()
                ) {
                  case PDFNet.VerificationOptions.TimeMode.e_current:
                    console.log(
                      "Trust verification attempted with respect to current time (as epoch time):" +
                        tmp_time_t
                    );
                    break;
                  case PDFNet.VerificationOptions.TimeMode.e_signing:
                    console.log(
                      "Trust verification attempted with respect to signing time (as epoch time): " +
                        tmp_time_t
                    );
                    break;
                  case PDFNet.VerificationOptions.TimeMode.e_timestamp:
                    console.log(
                      "Trust verification attempted with respect to secure embedded timestamp (as epoch time): " +
                        tmp_time_t
                    );
                    break;
                }

                const cert_path = await trust_verification_result.getCertPath();
                if (cert_path.length === 0) {
                  console.log("Could not print certificate path.");
                } else {
                  console.log("Certificate path:");
                  for (let i = 0; i < cert_path.length; i++) {
                    console.log("\tCertificate:");
                    const full_cert = cert_path[i];
                    console.log("\t\tIssuer names:");
                    const issuer_dn = await (
                      await full_cert.getIssuerField()
                    ).getAllAttributesAndValues();
                    for (let j = 0; j < issuer_dn.length; j++) {
                      console.log(
                        "\t\t\t" + (await issuer_dn[j].getStringValue())
                      );
                    }
                    console.log("\t\tSubject names:");
                    const subject_dn = await (
                      await full_cert.getSubjectField()
                    ).getAllAttributesAndValues();
                    for (let j = 0; j < subject_dn.length; j++) {
                      console.log(
                        "\t\t\t" + (await subject_dn[j].getStringValue())
                      );
                    }
                    console.log("\t\tExtensions:");
                    const extension_dn = await full_cert.getExtensions();
                    for (let j = 0; j < extension_dn.length; j++) {
                      console.log(
                        "\t\t\t" + (await extension_dn[j].toString())
                      );
                    }
                  }
                }
              } else {
                console.log("No detailed trust verification result available.");
              }

              console.log("==========");
            }

            return verification_status;
          }
          async function save() {
            const doc = await documentViewer.getDocument().getPDFDoc();

            const approval_signature_field =
              await doc.createDigitalSignatureField("PDFTronApprovalSig");
            const widgetAnnotApproval =
              await PDFNet.SignatureWidget.createWithDigitalSignatureField(
                doc,
                new PDFNet.Rect(0, 0, 0, 0),
                approval_signature_field
              );
            const page1 = await doc.getPage(1);
            await page1.annotPushBack(widgetAnnotApproval);
            // const doc=await PDFNet.PDFDoc.createFromBuffer(arr);

            const found_approval_field = await doc.getField(
              "PDFTronApprovalSig"
            );
            console.log(await doc.getField("PDFTronApprovalSigGraph"));
            const sigHandlerId = await doc.addStdSignatureHandlerFromURL(
              "/P360.pfx",
              "123456"
            );
            //  const sigHandlerId = await doc.addStdSignatureHandler('/pkcs7.p7b')
            const found_approval_signature_digsig_field =
              await PDFNet.DigitalSignatureField.createFromField(
                found_approval_field
              );
            await found_approval_signature_digsig_field.setDocumentPermissions(
              PDFNet.DigitalSignatureField.DocumentPermissions
                .e_no_changes_allowed
            );
            // await  doc.saveCustomSignatureBuffer(te, found_approval_signature_digsig_field)
            // (OPTIONAL) Add an appearance to the signature field.

            //  const img = await PDFNet.Image.createFromMemory2(doc, te);

            // const approvalSignatureWidget = await PDFNet.SignatureWidget.createWithDigitalSignatureField(doc, await PDFNet.Rect.init(0,0, 0, 0), found_approval_signature_digsig_field);
            // // await approvalSignatureWidget.createSignatureAppearance(img);
            // const page1 = await doc.getPage(1);

            // page1.annotPushBack(approvalSignatureWidget);

            //Prepare the signature and signature handler for signing.
            // found_approval_signature_digsig_field.setDocumentPermissions(PDFNet.DigitalSignatureField.DocumentPermissions.e_no_changes_allowed);
            console.log("Adding field permissions.");
            const fields_to_lock: Array<string> = ["PDFTronApprovalSig"];
            await found_approval_signature_digsig_field.setFieldPermissions(
              PDFNet.DigitalSignatureField.FieldPermissions.e_lock_all
            );
            await found_approval_signature_digsig_field.signOnNextSaveWithCustomHandler(
              sigHandlerId
            );
            await found_approval_signature_digsig_field.setLocation(location);
            await found_approval_signature_digsig_field.setReason(
              reason ? reason : "Document approval"
            );
            await found_approval_signature_digsig_field.setContactInfo(
              "www.p360.com"
            );

            // save to blob storage
            const buf = await doc.saveMemoryBuffer(
              PDFNet.SDFDoc.SaveOptions.e_remove_unused
            );

            state = "signedFinal";
            instance.loadDocument(buf);

            const blob = new Blob([buf], { type: "application/pdf" });

            let srffile = new File(
              [blob],
              "Signed_" + fileName + "_" + new Date().toISOString() + ".pdf"
            );

            console.log(typeof buf);

            //SendformStatus

            // try {
            //   const formData = {
            //     "formstatus":"completed",
            //     "formRequestId":formId
            // }

            //   const res = await axios.post(
            //     `${process.env.REACT_APP_FORMSTATUS}`,
            //     formData
            //   );
            //   console.log("Form status",res);

            // } catch (ex) {
            //   console.log(ex);
            // }

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = "signed_doc.pdf";
            document.body.appendChild(a);
            a.click();
            

            // try {
            //   const formData = {
            //     "file":srffile,
            //     "formId":formId
            //   };

            //   const res = await axios.post(
            //     `${process.env.REACT_APP_FORMSAPI}`+"savetoblob",
            //     formData
            //   );
            //   console.log(res);
            // } catch (ex) {
            //   console.log(ex);
            // }
          }

          async function addSignDetails() {
            console.log(signatureAnnotaionCustom1);
            const signCanvas = document.createElement("canvas");
            // Reference the annotation from the Document
            const pageMatrix = documentViewer
              .getDocument()
              .getPageMatrix(signatureAnnotaionCustom1.PageNumber);
            signCanvas.height = signatureAnnotaionCustom1.Height;
            signCanvas.width = signatureAnnotaionCustom1.Width;
            const annotationList: any = [];
            const ctx: any = signCanvas.getContext("2d");
            // Translate the signatureAnnotaionCustom1 to the top Top Left Corner of the Canvas ie (0, 0)
            ctx.translate(
              -signatureAnnotaionCustom1.X,
              -signatureAnnotaionCustom1.Y
            );
            //signatureAnnotaionCustom1.Color = new Annotations.Color(1, 215, 88)
            signatureAnnotaionCustom1.setX(signatureAnnotaionCordinate.x);
            signatureAnnotaionCustom1.setY(signatureAnnotaionCordinate.y);
            //signatureAnnotaionCustom1.resize(new Math.rect(signatureAnnotaionCordinate.x, signatureAnnotaionCordinate.y, signatureAnnotaionCordinate.x + 40, signatureAnnotaionCordinate.y + 40))
            signatureAnnotaionCustom1.draw(ctx, pageMatrix);
            signCanvas.toBlob((blob: any) => {
              
              createCustomAnnotation(
                blob,
                [
                  "Signer Name:" + annotationManager.getCurrentUser(),
                  "Signing Time:" +
                    new Date().toISOString().substring(0, 10) +
                    " " +
                    new Date().toISOString().substring(11, 19) +
                    "Z(UTC)",
                  "Signer IP:" + ip,
                  "Signing Reason:" + reason,
                ],
                annotationList,
                signatureAnnotaionCustom1.Width,
                signatureAnnotaionCustom1.Height
              );
              flatSign(annotationList);
            });
            async function createCustomAnnotation(
              signImg: any,
              data: string[],
              annotationList: any,
              width: any,
              height: any
            ) {
              let annotManager = annotationManager;

              class DigitalSignatureAnnotation extends Annotations.CustomAnnotation {
                constructor() {
                  super("digitalSignature"); // provide the custom XFDF element name
                  this.Subject = "DigitalSignature";
                }
                draw(ctx: any, pageMatrix: any) {
                  // the setStyles function is a function on markup annotations that sets up
                  // certain properties for us on the canvas for the annotation's stroke thickness.
                  this.setStyles(ctx, pageMatrix);

                  const helper = new Annotations.CanvasHelper(ctx);
                  const textParams = {
                    fillColor: new Annotations.Color(255, 0, 0),
                    opacity: 1,
                    borderStyle: "solid",
                    fontSize: 4,
                  };
                  // first we need to translate to the annotation's x/y coordinates so that it's
                  // drawn in the correct location
                  ctx.translate(this.X, this.Y);
                  const img = new Image();
                  img.onload = (event) => {
                    ctx.drawImage(event.target, 0, 0, 50, 50);
                  };

                  img.src = URL.createObjectURL(signImg);

                  helper.drawText(
                    "Digitally Signed By",
                    width + 2,
                    0,
                    textParams
                  );
                  let y = helper.measureText("Digitally Signed By")[
                    "actualBoundingBoxAscent"
                  ];

                  let x = width + 2;
                  data.forEach((info) => {
                    helper.drawText(info, x, y, textParams);
                    //console.log(helper.measureText(info))
                    let textHeight =
                      helper.measureText(info)["actualBoundingBoxAscent"];
                    y = y + textHeight;
                  });
                }
              }

              DigitalSignatureAnnotation.prototype.elementName =
                "digitalSignature";
              const customSignAnnotation = new DigitalSignatureAnnotation();
              customSignAnnotation.Width = 300;
              customSignAnnotation.Height = 200;
              customSignAnnotation.setX(signatureAnnotaionCordinate.x);
              customSignAnnotation.setY(signatureAnnotaionCordinate.y);
              customSignAnnotation.Color = new Annotations.Color(255, 0, 0);

              // annotManager.deleteAnnotations(annotManager.getAnnotationsList(), { force: false, isUndoRedo: true });
              annotationList.push(customSignAnnotation);
              annotManager.addAnnotations(annotationList);
              annotManager.drawAnnotationsFromList(annotationList);
            }
          }
          document
            .getElementById("reasonSave")
            ?.addEventListener("click", () => {
              console.log("reasonSave15555");
              addSignDetails();
            });
          async function flatSign(annotationList: any) {
            console.log("inside flatsign");
            state = "signature";
            const doc1 = documentViewer.getDocument();
            console.log(await annotationManager.getAnnotationsList());
            const xfdfString = await annotationManager.exportAnnotations({
              widgets: false,

              annotList: await annotationManager.getAnnotationsList(),
            });

            const data = await doc1.getFileData({
              // saves the document with annotations in it
              xfdfString,
              flatten: true,
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: "application/pdf" });
            instance.UI.loadDocument(blob);
          }
          // document.getElementById("save")?.addEventListener("click", async () => {
          //               // setPdfstate("signature");

          //             });
          documentViewer.addEventListener("documentLoaded", async () => {
            const doc = await documentViewer.getDocument().getPDFDoc();
            dispatch(setDoc(doc));
            console.log(doc);
            if (state == "signature") {
              //document.getElementById("reason-popup").style.display = "block";
              save();
              state = "signed";
            }
            // if(state=='detailed')
            // flatSign();
            if (state == "signedFinal") VerifyAllAndPrint();
            annotationManager.addEventListener(
              "annotationSelected",
              async (annotationList: any) => {
                
                annotationList.forEach((annotation: any) => {
                  if (annotation.Subject === "Signature") {
                    

                    signatureAnnotaionCustom1 = annotation;
                    signatureAnnotaionCordinate = {
                      x: annotation.X,
                      y: annotation.Y,
                    };
                    extractAnnotationSignature(annotation, documentViewer);
                  }
                });
              }
            );

            signatureTool.addEventListener(
              "locationSelected",
              (pageCoordinates: any) => {
                //signatureAnnotaionCordinate = pageCoordinates;
                console.log(pageCoordinates);
              }
            );

            annotationManager.on("annotationChanged", async (e: any) => {
              // If annotation change is from import, return
              if (e.imported) {
                return;
              }
              e.forEach((annotation: any) => {
                if (annotation.Subject === "Signature") {
                  signatureAnnotaionCustom1 = annotation;
                  signatureAnnotaionCordinate = {
                    x: annotation.X,
                    y: annotation.Y,
                  };

                  extractAnnotationSignature(annotation, documentViewer);
                }
              });
              const xfdfString =
                await annotationManager.exportAnnotationCommand();
              // Parse xfdfString to separate multiple annotation changes to individual annotation change
              const parser = new DOMParser();
              const commandData = parser.parseFromString(
                xfdfString,
                "text/xml"
              );
              const addedAnnots = commandData.getElementsByTagName("add")[0];
              const modifiedAnnots =
                commandData.getElementsByTagName("modify")[0];
              const deletedAnnots =
                commandData.getElementsByTagName("delete")[0];

              // List of added annotations
              addedAnnots.childNodes.forEach((child) => {
                sendAnnotationChange(child, "add");
              });

              // // List of modified annotations
              modifiedAnnots.childNodes.forEach((child) => {
                sendAnnotationChange(child, "modify");
              });

              // // List of deleted annotations
              deletedAnnots.childNodes.forEach((child) => {
                sendAnnotationChange(child, "delete");
              });
            });
          });
        });
      }
    } else {
      setMobileInstance(null);
    }

    console.log(showdocviewer && fileUrl);
    getHeight();
  }, [showdocviewer, fileUrl]);

  const handleDocViewerMobile = (e) => {
    
    console.log("inside item 3");
    setFileUrl(e);

    setShowdocviewer(!showdocviewer);
  };

  const showCartHandler = (i) => {
    setHoveredCart(i);
  };

  const hideCartHandler = () => {
    setHoveredCart(-1);
  };

  const handleAllFilesClick = () => {
    setActiveClass("activedoctab");
    setActiveDocClass("");
    setActiveVideoClass("");
    setActiveFormTabClass("");
    setIsForm(false);
    setDocData(allDoc);
  };
  const handleDocsClick = () => {
    setIsForm(false);
    setActiveClass("");
    setActiveDocClass("activedoctab");
    setActiveFormClass("");
    setActiveFormTabClass("");
    //  let onlydocsData = allDoc.filter(i => i.filetype === 'doc');
    //  setDocData(onlydocsData);
  };
  const handleVideosClick = () => {
    setActiveClass("");
    setActiveDocClass("");
    setActiveVideoClass("activedoctab");

    let onlyVideosData = allDoc.filter((i) => i.filetype === "video");
    setDocData(onlyVideosData);
  };

  const handleFormClick = () => {
    setIsForm(true);
    getActiveForms();
    setActiveClass("");
    setActiveDocClass("");
    // setLocalData([])
    setActiveFormTabClass("activeformtab");
  };
  const getActiveForms = () => {
    getFormsForMedia((res: any) => {
      
      setLocalFormData(res.reverse());
      //   setHistData(res)
      console.log("forms data is here", res);
    });
  };
  const pdftronFileValidation = (fileUrl, type) => {
    if (type == "pdf") {
      return true;
    } else {
      setShowErrorMessage(false);
      return false;
    }
  };

  const fileSelect = (item, viewOrPresent) => {
    setOpen(false);
    // let validData = pdftronFileValidation(item?.item_url, item?.File_Typ);
    // if (!validData && !isAnnotedFileSelected && item) {
    //   setShowErrorMessage(true);
    //   setTimeout(function () {
    //     setShowErrorMessage(false);
    //   }, 1000);
    //   return;
    // }
    if (screenSize) {
      setBlockUI(true);
    }
    setSelectedFile(item);
    console.log("active", item);
    setFileSelectedName(item?.Item_Nm);
    setFileSelectedId(item?.Id);
    if (item) {
      if (isfolder && !isform) {
        getAnnotedFilesData(item);
      }
      // if(File_Typ)
      if (isfolder && !isform) {
        getPermissionAccess(item)
      }

      //setFileUrl("https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK")
      console.log("inside item");
      if (isMobile) {
        console.log("inside item 2");
        handleDocViewerMobile(item.item_url);
      } else {
        setOpen(true);
        setFileUrl(item.item_url);
      }

      //instance.loadDocument(item)
      if (room && viewOrPresent == "present") {
        room.localParticipant.dataTracks.forEach((element) => {
          element.track.send(
            JSON.stringify({ type: "pdftron", value: item.item_url })
          );
          // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
        });
        sendChat(item.item_url);
      }
    } else {
      if (isMobile) {
        handleDocViewerMobile(null);
      } else {
        setOpen(false);
        setFileUrl("");
      }

      if (room && viewOrPresent == "present") {
        room.localParticipant.dataTracks.forEach((element) => {
          element.track.send(JSON.stringify({ type: "pdftron", value: null }));
          // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
        });
        sendChat("");
      }
    }
    if (viewOrPresent == "view") {
      if (room) {
        room.localParticipant.dataTracks.forEach((element) => {
          element.track.send(JSON.stringify({ type: "pdftron", value: null }));
          // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
        });
        sendChat("");
      }
    }
  };

  const sendChat=(fileurl)=>{
   
    let payload=  {
     "UserName": room.localParticipant.identity,
     "RoomId": room.name,
     "Message": fileurl,
     "CreatedOn":new Date().toISOString(),
     "MessageType":"present"
     }
     
   
    //  room.localParticipant.dataTracks.forEach(element => {
     
    //    element.track.send(JSON.stringify({"type":"message","value":payload}))
    //    });
 
    
 
    axios.post(config.REACT_APP_VIDEO_API_BASE+'/meeting/InsertChat',payload).
    then((res)=>{
     console.log("Meassge sent successfully!")
    }).catch((err)=>
    {
     console.log(err);
    })
 
   }

  const searchData = (e) => {
    console.log(e);
    if (e.length == 0) {
      if (activeClass == "activedoctab") setDocData(allDoc);
      else if (activeDocClass == "activedoctab") {
        let onlydocsData = allDoc.filter((i) => i.filetype === "doc");
        setDocData(onlydocsData);
      } else {
        let onlyVideosData = allDoc.filter((i) => i.filetype === "video");
        setDocData(onlyVideosData);
      }
    } else {
      let temp = docData.filter(
        (x: any) => x.filename.toLowerCase().indexOf(e.toLowerCase()) >= 0
      );
      setDocData(temp);
    }
  };
  const handleShare = (value) => {
    // console.log(value)
    // let id:any = value.Id;
    // let fileType:any = value.type;
    // let name:any = value.name;
    // let id=1001;
    // let fileType='FOLDER';

    //path for the file
    // let tempPath = '..';
    // trackPath.map(item => {
    //   tempPath = tempPath + '/' + item.name;
    // })
    // tempPath = tempPath + '/' + name;
    // let temp = { "Id": id, "contentType": fileType, "filePath": tempPath };
    // console.log("handle share...", temp);
    // setSharePopupDetails(temp);
    dispatch(
      showModal({
        // title: "title",
        path: "modalBody/share",
        props: {
          id: value.ID,
          contentType: value.type,
          filePath: value.name,
        },
      })
    );

    // setShowSharePopup(true);
  };
  const handleNewFile = () => {
    let doc: any = document;
    doc.getElementById("attachedfiles").click();
  };

  const handleNewFolder = (e) => {
    console.log("event_lis", e);
    setNewFolder(true);
  };
  
  const getloaderFileFolderAfterDelete=(rename, parentId, type)=>{
    let tempselectedFolderData:any = parentFolderData;    
 
      if (!isAnnotedFileSelected && type != 'File' && !screenSize) tempselectedFolderData.pop();
      let currentParentData:any = tempselectedFolderData[tempselectedFolderData.length - 1]
  
    let temp:any;
    if (parentFolderData.length > 0 ) {

      getFolderFiles(parentId, (res) => {
        if (res) {
          temp = res.data.data;
          if (temp) {
            console.log("folder in data tree child", dataTree);
            getPermissionAccess(currentParentData);
            setSelectedFile(currentParentData)
            setSubMenuFolderName(currentParentData.Item_Nm);
            setDataTree(dataTree);
            setLocalData(temp);
            if (rename) {
              setRenameContentId(-1);
            }
          }
        }
      });
    } else {
      getFolders((response) => {
        if (response) {
          console.log("rename folders data", response.data.data);
          let temp = response.data.data;
          setLocalData(temp);
          setDataTree(temp);
          getPermissionAccess(currentParentData)
          // setSubMenuFolderName(currentParentData.Item_Nm);
          if (rename) {
            setRenameContentId(-1);
          }
        }
      });
    }
    
  }

  const handleFolderFileDelete = (value) => {
    let id: any = value?.ID;
    let contentType: any = value.type;
    let parentfolderId :any =value.ParentFolder_Id
    let fileFolderNm :any =value.Item_Nm
    //call api to delete folder
    // const deleteData={"id":1007,"fileType":'FILE'};
    console.log("deleted " + contentType + "with id " + id);

    if (contentType == "File") {
      //call folder API

      deleteFile({ ItemId: id }, (res) => {
        // setDeleteFileOrFolder(false)
        
        if (res) {
          console.log("file deleted sucessfully...", res);
          if(res.status == 200){
            getloaderFileFolderAfterDelete(false,parentfolderId, 'File')
            // loadFolderFiles(false);
            setIsAnnotedFileSelected(false)
            
            dispatch(setShowSuccessSnackBar(true)); 
            setOpen(false);
              fileSelect(null, "present");
          } else{
            dispatch(setShowErrorSnackBar(true));
          }
        }
      });
    }
    if (contentType == "Folder") {
      
      // setDeleteFileOrFolder(true)
      //call rename file api
      deleteFolder({ ID: id,
                  Item_Nm:fileFolderNm,
                  ParentFolder_Id:parentfolderId  }, (res) => {
        if (res) {
          console.log("folder deleted sucessfully...", res);
          if(res.status == 200){
            // loadFolderFiles(false);
            getloaderFileFolderAfterDelete(false,parentfolderId, 'Folder')

            dispatch(setShowSuccessSnackBar(true));            
          } else{
            dispatch(setShowErrorSnackBar(true));
          }
          // loadFolderFiles(false);
        }
      });
    }
  };
  const enableContentRename = (item) => {
    
    console.log(item.ID, item.type);
    let id = item.ID;
    let type = item.type;
    setRenameContentId(id);
    const temp = { id: id, fileType: type, newName: "" };
    setRenameData(temp);
    console.log("enable renamed details saved ...", temp);
  };
  const handleContentRename = (e) => {
    const temp = renameData;
    const newName = e.target.value;
    // setRenameData(temp);
    console.log("renamed details saved ...", temp, newName);
    if (newName.length > 0) {
      // if (renameData.fileType == "file") {
        //call folder API
        let payload = { ID: renameData.id, Item_Nm: newName, Item_Type:renameData.fileType};
        console.log("rename payload", payload);
        renameFile(payload, (res) => {
          if (res) {
            console.log("file renamed sucessfully...", res);
            loadFolderFiles(true);
            setSubMenuFolderName(newName)
          }
        });
      // }
      // if (renameData.fileType == "dir" || renameData.fileType == "Folder") {
      //   //call rename file api
      //   let payload = { Id: renameData.id, FolderName: newName };
      //   console.log("rename payload", payload);
      //   renameFolder(payload, (res) => {
      //     if (res) {
      //       console.log("folder renamed sucessfully...", res);
      //       loadFolderFiles(true);
      //     }
      //   });
      // }
    } else {
      console.log("please enter the name");
    }
  };

  const handleFolderFileRename = () => {
    //call api to delete folder
    //const renameData={"id":1004,"fileType":'FOLDER',"newName":'newname3'};
    console.log(
      "renamed " +
        renameData.fileType +
        " with id " +
        renameData.id +
        " to " +
        renameData.newName
    );

    if (renameData.fileType == "FILE") {
      //call folder API
      let payload = { Id: renameData.id, FileDisplayName: renameData.newName };
      renameFile(payload, (res) => {
        if (res) {
          console.log("file renamed sucessfully...", res);
        }
      });
    }
    if (renameData.fileType == "FOLDER") {
      //call rename file api
      let payload = { Id: id, FolderName: renameData.newName };
      renameFolder(payload, (res) => {
        if (res) {
          console.log("folder renamed sucessfully...", res);
        }
      });
    }
  };

  const handleFolderStructure = () => {};
  const updateDataTree = (tempDataTree, childData, id) => {
    let tempData = tempDataTree;
    console.log("folder data tree", dataTree);
    tempData.map((item, i) => {
      if (item.id == id) {
        tempData[i].child = childData;
        console.log("folder update ", item.id, i);
      } else {
        tempData[i] = updateDataTree(tempData, childData, id);
      }
    });
    console.log("folder temp data", tempData);
    //  setDataTree(tempData);
    return tempData;
  };

  // get permissions
  const getPermissionAccess =(item)=>{
      setReadPermisson(false);
      setWritePermisson(false);
      setPresentFilePermission(false);
      setDeleteFilePermission(false);
      setshareFilePermission(false);
      setRenameFilePermission(false);
    let permissionsList = item.Permissions?.split(",");
    if (permissionsList != null){
      permissionsList.map((x) => {
        if (x == "r") {
          setReadPermisson(true);
        }
        if (x == "w") {
          setWritePermisson(true);
        }
        if (x == "p") {
          setPresentFilePermission(true);
        }
        if (x == "d") {
          setDeleteFilePermission(true);
        }
        if (x == "sh") {
          setshareFilePermission(true);
        }
        if (x == "e") setRenameFilePermission(true);
      });
    } 
  }

  const getChild = (x) => {
    
    
    setSelectedFile(x)
    setAttachedFiles([])
    console.log("payload of folder", x.ID);
    let temp;
    setparent(localData);
    getPermissionAccess(x)

      getFolderFiles(x.ID, (res) => {
        
        console.log(x, res);
        if (res) {
          setSubMenuFolderName(x.Item_Nm);
  
          // set Parent folder Data
          let tempParentData: any = parentFolderData;
          tempParentData.push(x);
          
          console.log( Array.from(new Set(tempParentData)));
          let tempParentSelectedData:any =Array.from(new Set(tempParentData))
          setparentFolderData(tempParentSelectedData);

          temp = res.data.data;
          console.log(temp);
          if (temp) {
            x.child = temp;
            console.log("folder in data tree child", dataTree);
            setDataTree(dataTree);
  
            setLocalData(x.child);
            // let tempData=updateDataTree(dataTree,temp,x.id)
            //set data here
            // setDataTree(tempData);
            // console.log("folder main data",tempData);
  
            //adding path
            let tempPath = trackPath;
            tempPath.push(x);
            setTrackPath(tempPath);
            console.log("path till now . ", tempPath);
          }
        }
      });
    
    
    
  };


  const copyCoupon = (e, data) => {
    var coupon = data.copy;
    navigator.clipboard.writeText(coupon);
    alert(`Coupon code ${coupon} copied to your clipboard`);
  };

  const backFolder1 = (item) => {
    setRrenameFolderOnly(false)
    setAttachedFiles([])
    let tempselectedFolderData:any = parentFolderData;
    console.log(tempselectedFolderData)
    // get parent folder id
    // let tempIdArray: any = parentId;
    if (!isAnnotedFileSelected) tempselectedFolderData.pop();


    
    // get pargent folder name
    // let tempFolderNameArray: any = getPartentFolderNameList;
    // if (!isAnnotedFileSelected) tempFolderNameArray.pop();

    if (tempselectedFolderData.length > 0) {
      //get file folder
      let temp;
      // get active folder id
      let currentParentData = tempselectedFolderData[tempselectedFolderData.length-1];
      if (!isAnnotedFileSelected) setSelectedFile(currentParentData)
      // get active folder name
      // let currentFolderPermission =   tempselectedFolderData.pop();
      getFolderFiles(currentParentData.ID, (res) => {
        if (res) {
          temp = res.data.data;
          // set remaining folder and id array list
          
          console.log(currentParentData.Permissions)
          setParentId(tempselectedFolderData);
          setPartentFolderNameList(tempselectedFolderData);
          getPermissionAccess(currentParentData);         

          // display current folder name
          setSubMenuFolderName(currentParentData.Item_Nm);
          if (temp) {
            
            console.log("folder in data tree child", dataTree);
            setDataTree(dataTree);
            setLocalData(temp);
          }
        }
      });
      
    } else {
      //get 0 level folder
      getFolders((response) => {
        // let currentParentData = tempselectedFolderData[tempselectedFolderData.length-1];
        if (response) {
          console.log("folders data", response.data.data);
          let temp = response.data.data;
          getPermissionAccess({});
          setLocalData(temp);
          setDataTree(temp);
        }
      });
    }
    setIsAnnotedFileSelected(false);
    setannotedAllFile([]);
  };

  const getAnnotedFilesData = (x) => {
    // console.log("annoted file")
    getAnnotedFiles(x.ID, (res) => {
      let temp = res.data.data;
      console.log(temp);
      if (temp.length > 0 && isfolder && !isform) {
        setannotedAllFile(temp);
        // setIsAnnotedFileSelected(true)
      }
    });
  };
  const displayAnnotedFileData = (data) => {
    setIsAnnotedFileSelected(true);
    setLocalData(data);
  };

  const saveDocument = () => {
    console.log("e");
    // var saveDocumentBlob = async function() {
    //   // return new Promise(function(resolve) {
    //     annotationManager.exportAnnotations().then(function(xfdfString) {
    //       documentViewer.getDocument().getFileData({ xfdfString }).then(async function(data) {
    //         var arr = new Uint8Array(data);
    //         var blob = new Blob([ arr ], { type: 'application/pdf' });
    //         // FormData is used to send blob data through fetch
    //         var formData = new FormData();
    //         formData.append('blob', blob);

  // saveDocumentBlob()
  setShowPopup(true)
 }

 const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setSearchText:any, setShowDropdown:any) => {
  console.log("text", '----')
 
  
  const text = event.target.value;
  // searchData(text);
  setSearchText(text) 
  setShowDropdown(text.length > 0);
  
  let tempParentId = trackPath.length > 0 ? trackPath[trackPath.length - 1].id : 0;
    getFolderFiles(tempParentId, (res) => {
      if (res) {
        // console.log("payload of folder",x.id,res.data.data)
        let temp = res.data.data;
        if (temp) {
          // setDataTree(dataTree);
          // setLocalData(temp);
          setOptions(temp)
        }
      }
    })
};

  return (
    <>
      <div className="media-main-div" style={{ height: mediaHeight }}>
        {isform ? (
          <AocModal
            formId={formId}
            show={showAocModal}
            modalFormType={formType}
            handleCloseParent={(e: any) => {
              console.log(e);
              setFormId(0);
              if (e) {
                console.log("inside e");
                setOptionsdiv(!optionsdiv);
                if (room && videoCallStarted) {
                  room.localParticipant.dataTracks.forEach((element) => {
                    element.track.send(
                      JSON.stringify({
                        type: "formId",
                        value: formType === "SRF" ? 1062 : 1063,
                      })
                    );
                    // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
                  });
                }
                fileSelect(
                  {
                    fileurl:
                      "https://p360zingforms.blob.core.windows.net/srfpdf/" +
                      e.split("/")[e.split("/").length - 1],
                  },
                  videoCallStarted ? "present" : "view"
                );
              }
              setShowAocModal(false);
            }}
          />
        ) : null}
        <ReasonPopup
          show={showPopup}
          handleClose={(e: any) => {
            console.log(e, "-------");
            setShowPopup(false);
            if (e) {
              // setReasondata(e);
              // reason=e;
              document.getElementById("reasonSave")?.click();
            }
          }}
        />
        <button style={{ display: "none" }} id="reasonSave"></button>
        <div className="media-sidebar-div">
          <div className="media-sidebar-innerDiv">
            {/* SearchBar  */}
            <div className='searchbar-div only_desktop'>

              <div className='searchbar-input-div-media'>
                <SearchInput
                    folderTabRef={folderTabRef}
                    value={searchValue} 
                    onChange={(t)=>setSearchValue(t)}
                    getChild={getChild}
                    fileSelect={fileSelect}
                  // apiUrl={config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/file-folder-search?text="}
                  // options={options} 
                  // handleInputChange={handleInputChange}
                  // handleOptionClick={"handleOptionClick"}
                />
                {/* <input className='docs-search-input' onChange={(e) => { searchData(e.target.value) }} type='search' placeholder={t('Search file')} /> */}
              </div>
            </div>

            {/* Tabs  */}
            <div className="tabs-main-div">
              <div className="tabs-heading-div">
                <div className="tabs-inner-div">
                  <div
                    className={`doctab ${activeClass}`}
                    onClick={() => {
                      handleAllFilesClick();
                      setIsFolder(false);
                    }}
                  >
                    {t("All files")}
                  </div>
                  <div
                    className={`doctab ${activeDocClass}`}
                    ref={folderTabRef}
                    onClick={() => {
                      setIsFolder(true);
                      handleDocsClick();
                    }}
                  >
                    {t("Folder")}
                  </div>
                  <div
                    className={`doctab ${activeFormTabClass}`}
                    onClick={() => {
                      setIsFolder(false);
                      handleFormClick();
                    }}
                  >
                    {t("Forms")}
                  </div>
                  {/* <div className={`doctab ${activeDocClass}`} onClick={handleDocsClick}>Docs</div>
                            <div className={`doctab ${activeVideoClass}`} onClick={handleVideosClick}>Videos</div> */}
                </div>
              </div>
              {/* Get All File view 1st tab start */}
              {!isfolder && !isform ? (
                <div className="doc-list-div">
                  <ul className="docs-ul">
                    {docData?.map((item, i) => {
                      return (
                        <>
                          {screenSize ? (
                            <li className="docs-li">
                              <div
                                className={`listitem-div ${
                                  hoveredCart === i ? "withBg" : ""
                                }`}
                              >
                                <span
                                  onClick={() => {
                                    fileSelect(item, "view");
                                  }}
                                >
                                  <img
                                    src={AddFileIcon}
                                    style={{ paddingRight: "1rem" }}
                                    alt="docIcon"
                                  />
                                  {truncate(item?.Item_Nm, 15)}
                                </span>

                                <span className="moreoptions_icons only_mobile">
                                  {/* <Popover
                                        content={getMoreOptionContent(item,"mobile")}
                                        placement="bottomRight"
                                        //   title="Title"
                                        trigger="click"
                                      
                                        >
                                          <MoreVertIcon className='disabled_icon' />
                                      </Popover> */}
                                  {item?.Permissions.split(",").map((x) => {
                                    return (
                                      <>
                                        {x == "p" && videoCallStarted ? (
                                          <span
                                            className="presentButton"
                                            onClick={() => {
                                              setOptionsdiv(!optionsdiv);
                                              fileSelect(item, "present");
                                            }}
                                          >
                                            {t("Present")}
                                          </span>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </span>
                              </div>
                            </li>
                          ) : (
                            <li className="docs-li">
                              <div
                                className={`listitem-div ${
                                  hoveredCart === i ? "withBg" : ""
                                }`}
                                onMouseLeave={hideCartHandler}
                                onMouseEnter={() => showCartHandler(i)}
                              >
                                <span
                                  onClick={() => {
                                    fileSelect(item, "view");
                                  }}
                                >
                                  <img
                                    src={AddFileIcon}
                                    style={{ paddingRight: "1rem" }}
                                    alt="docIcon"
                                  />
                                  {truncate(item?.Item_Nm, 15)}
                                </span>

                                {item?.Permissions.split(",").map((x) => {
                                  return (
                                    <>
                                      {x == "p" && videoCallStarted ? (
                                        <span
                                          className="presentButton"
                                          onClick={() => {
                                            setOptionsdiv(!optionsdiv);
                                            fileSelect(item, "present");
                                          }}
                                        >
                                          {t("Present")}
                                        </span>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                    {
                      loading ? <div className="loading-div">
                        <Spinner />
                      </div>: docData.length == 0 ? (
                      <h6 style={{ textAlign: "center" }}>{t("No Data")}</h6>
                    ) : null
                    }
                    {showErrorMessage && !isfolder && !isform ? (
                      <div className="error_file_msg">
                        {" "}
                        {t("The file is not a valid PDF document")}
                      </div>
                    ) : null}
                   
                  </ul>
                </div>
              ) : null}
              {/* Get All File view 1st tab end */}

              {/*  Get All File and folder view 2st tab start*/}
              {isfolder && !isform ? (
                <div id="right-menu">
                  {/* desktop view sub menu header */}
                  {!screenSize ? (
                    <div className="media-icons-wrapper">
                      <div>
                        {parentFolderData.length > 0 ? (
                          <div>
                            <ArrowBackIosIcon onClick={backFolder1} />
                            {renameFolderOnly == true && renameContentId == selectedFile.ID?                            
                            <span className="folder_title">
                              {/* <input type="text" name="reanme_text" value={subMenuFolderName} /> */}
                              <input
                                type="text"
                                defaultValue={subMenuFolderName}
                                autoFocus
                                className="desktop_folder_rename"
                                onBlur={handleContentRename}
                              />
                            </span>:
                            <span className="folder_title">
                            {truncate(subMenuFolderName, 15)}
                          </span>}
                          </div>
                        ) : null}
                      </div>

                      <div className="only_desktop">
                        <Tooltip title={t("Annotated Files")} arrow>
                          <span
                            className={
                              annotedAllFile?.length > 0 ||
                              isAnnotedFileSelected
                                ? "annoted_file_btn"
                                : "annoted_file_btn disabled_icon"
                            }
                          >
                            <EditNoteIcon
                              onClick={() => {
                                if (annotedAllFile?.length > 0)
                                  displayAnnotedFileData(annotedAllFile);
                              }}
                            />{" "}
                          </span>
                        </Tooltip>
                        <Popover
                          content={getUploadContent}
                          placement="bottomLeft"
                          trigger="click"
                        >
                          <AddIcon />
                        </Popover>
                        <ShareIcon
                          className={shareFilePermission ? "" : "disabled_icon"}
                          onClick={() => {
                            if (shareFilePermission) handleShare(selectedFile);
                          }}
                        />
                        <DeleteIcon
                          className={
                            deleteFilePermission ? "" : "disabled_icon"
                          }
                          onClick={() => {
                            if(deleteFilePermission)
                            setShowConfirmationModal(true)                            
                            // handleFolderFileDelete(selectedFile);
                          }}
                        />

                        <Popover
                          content={getMoreOptionContent(
                            selectedFile,
                            "desktop"
                          )}
                          placement="bottomLeft"
                          trigger="click"
                        >
                          <MoreVertIcon />
                        </Popover>
                      </div>
                    </div>
                  ) : null}

                  {/* Mobile view sub menu header */}
                  {parentFolderData.length>0 && screenSize ? (
                    <div className="media-icons-wrapper">
                      <div>
                        <div>
                          <ArrowBackIosIcon onClick={backFolder1} />
                          <span className="folder_title">
                            {truncate(subMenuFolderName, 15)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Folder and files section looping start */}

                  <div id="folderContainer" className={attachedFiles.length > 0? (screenSize && parentFolderData.length>0?"folderContainer mobile_heigt_container":"folderContainer heigt_container"): "folderContainer"} >
                    {localData.map((x) => (
                      <div className="content-block">
                        {screenSize ? (
                          <div id="folderitem" className="folderitem">
                            <div id="item">
                            
                              <>
                                {x.type == "Folder" || x.type == "dir" ? (
                                  <FolderIcon
                                    style={{ fill: "var(--primary)" }}
                                    fontSize="large"
                                    onClick={() => {getChild(x);}}
                                  />
                                ) : (
                                  <InsertDriveFileIcon
                                    onClick={(e: any) => {
                                      fileSelect(x, "view");                                      
                                    }}
                                    color="info"
                                    fontSize="large"
                                  />
                                )}
                              </>
                              {/* {x.type != "Folder" ? ( */}
                                <div className="mobile_more_option">
                                  {/* <div aria-describedby={popoverId} onClick={(e)=>{handleClick(e); getMoreMobileOptionContent(x)}}>
                            <MoreVertIcon  />
                            </div> */}

                                  <Popover
                                    content={getMoreMobileOptionContent(x)}
                                    placement="bottomLeft"
                                    // aria-describedby={popoverId}
                                    //   title="Title"
                                    trigger="click"
                                    //   open={open}
                                    // onOpenChange={(e) => {
                                    //   if (e) getMoreMobileOptionContent(x);
                                    // }}
                                  >
                                    <MoreVertIcon />
                                  </Popover>
                                </div>
                              {/* ) : null} */}
                            </div>

                            {renameContentId == x.ID ? (
                              <input
                                type="text"
                                autoFocus
                                className="folder-rename"
                                onBlur={handleContentRename}
                              />
                            ) : (
                              <div className="folderName">
                                {truncate(x.Item_Nm, 15)}
                              </div>
                            )}
                          </div>
                        ) : (
                          // </Tooltip>
                          <Tooltip title={x.Item_Nm} arrow>
                            <div id="folderitem" className="folderitem">
                              <div id="item">
                                <>
                                  {x.type == "Folder" || x.type == "dir" ? (
                                    <FolderIcon
                                      style={{ fill: "var(--primary)" }}
                                      fontSize="large"
                                      onClick={() => {getChild(x); setDeleteFileOrFolderType('Folder')}}
                                    />
                                  ) : (
                                    <InsertDriveFileIcon
                                      onClick={(e: any) => {
                                        fileSelect(x, "view");
                                        setDeleteFileOrFolderType('File')
                                      }}
                                      color="info"
                                      fontSize="large"
                                    />
                                    
                                  )}
                                </>
                              </div>
                              {renameContentId == x.ID && renameFolderOnly == false ? (
                                <input
                                  type="text"
                                  autoFocus
                                  className="folder-rename"
                                  onBlur={handleContentRename}
                                />
                              ) : (
                                <div className="folderName">
                                  {truncate(x?.Item_Nm, 15)}
                                 
                                </div>
                              )}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    ))}

                    {/* upload attchment */}
                    {attachedFiles.length > 0 
                      ? attachedFiles.map((file: any, index) => (
                          <Tooltip
                            title={t(
                              "# File name : " +
                                file.name +
                                "  # file size : " +
                                (file.size / 1e6).toFixed(2)
                            )}
                            arrow
                          >
                            <div
                              className="add-files"
                              key={
                                file.name +
                                "-" +
                                file.type +
                                "-" +
                                index +
                                "-" +
                                file.testTime
                              }
                              id={
                                file.name +
                                "-" +
                                file.type +
                                "-" +
                                index +
                                "-" +
                                file.testTime
                              }
                            >
                              <div className="file-icon icon_scale icon_mobile_view">
                                <i className="fas fa-solid fa-file-pdf"></i>
                              </div>
                              <div className="file-details">
                                <div className="fileName">{file.name}</div>
                              </div>
                              <div className="close-btn">
                                <i
                                  className="fas fa-times"
                                  onClick={() => removeFile(index)}
                                />
                              </div>
                            </div>
                          </Tooltip>
                        ))
                      : null}
                  
                    {newFolder ? (
                      <div className="add-files">
                        <div className="file-icon add_new_item icon_scale">
                          <i className="fas fa-solid fa-folder"></i>
                        </div>
                        <input
                          type="text"
                          autoFocus
                          className="folder-rename"
                          onBlur={handleCreateFolder}
                        />
                      </div>
                    ) : null}

                    <label>
                      <input
                        type="file"
                        id="attachedfiles"
                        name="attachedfiles"
                        className="custom-file-input"
                        multiple
                        // accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
                        style={{ display: "none" }}
                        onChange={getFiles}
                      />
                    </label>

                     


                   
                  </div>

                  
                  {/* {
                        folderName.length > 0 ?
                            <div className='upload-media-btn' onClick={onFileUpload}>
                                create
                            </div>
                            :null} */}
                              {/* {
                    attachedFiles.length > 0 ? */}
                  
                      
                      {/* : null} */}
                      {
                    attachedFiles.length > 0 ?
                    <div className="upload-btn-wrapper">
                    <div className='upload-media-btn' onClick={handleUpload}>
                        {t('Upload')}
                      </div>
                    </div>
                      
                      : null}

                  <>
                    {showErrorMessage && isfolder && !isform ? (
                      <div className="error_file_msg">
                        {t("The file is not a valid PDF document")}
                      </div>
                    ) : null}
                  </>
                  
                </div>
              ) : null}
              {/*  Get All File and folder view 2st tab end*/}

              {/* <div>  */}
              {isform && localFormData.length > 0 ? (
                <div className="formlist">
                  {localFormData?.map((item: any, i: any) => {
                    return (
                      <>
                        <div
                          className={`listitem-div listitem-width ${
                            hoveredCart === i ? "withBg" : ""
                          }`}
                          onMouseLeave={hideCartHandler}
                          onMouseEnter={() => showCartHandler(i)}
                        >
                          <li
                            className="docs-li"
                            onClick={() => {
                              console.log(item);
                              setFormId(item.Id);
                              setFormType(item);
                              setShowAocModal(true);
                              // {isform?setShowAocModal(true):setShowAocModal(false)}
                            }}
                          >
                            <span>
                              <img
                                src={AddFileIcon}
                                style={{ paddingRight: "1rem" }}
                                alt="docIcon"
                              />
                              {item.Form_Nm}
                            </span>
                          </li>
                        </div>
                      </>
                    );
                  })}

                  {docData.length == 0 ? (
                    <h6 style={{ textAlign: "center" }}>No Data</h6>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="media-docviewer-div">
          {open ? <div className="webviewer" ref={viewer}></div> : null}
        </div>
      </div>
      {/* {showSharePopup ?
        <SharePopup contentDetails={sharePopupDetails} closeSharePopup={closeSharePopup} />
        // <SharePopup2/>
        : null
      } */}

      {/* Mobile Doc Viwer */}

      {showdocviewer && (
        <div
          className="docviwer-mob-div"
          style={{
            height: room ? "100%" : "100%",
            top: paddingValTop,
            bottom: paddingValBottom,
            left: paddingValLeft,
            right: paddingValRight,
          }}
        >
          <div className="docviwer-flex-div">
            {/* <button className='docviwer-mob-backBtn' onClick={() => { handleDocViewerMobile(null); fileSelect(null, 'view') }}>Back</button> */}
            <i
              className="fas fa-chevron-left i-2 docviwer-mob-backBtn"
              onClick={() => {
                handleDocViewerMobile(null);
                fileSelect(null, "view");
              }}
            ></i>
            {blockUI ? <BlockUI show={blockUI} /> : null}
            <div className="webviewerMobile" ref={viewerMobile}></div>
          </div>
        </div>
      )}

      {isfolder && !isform ? (
        <div
          className="create-dynamic-floating onlyMobile"
          // style={{
          //   paddingBottom: this.state.paddingValBottom,
          //   paddingRight: this.state.paddingValRight,
          // }}
        >
          <Popover
            content={getUploadContent}
            placement="bottomLeft"
            //   title="Title"
            trigger="click"
            //   open={open}
            //   onOpenChange={handleOpenChange}
          >
            <div
              id="createContactId"
              className="floating-button create_contact"
              // onClick={() => {
              //   this.setState({ show: true });
              // }}
            >
              <i className="fas fa-plus"></i>
            </div>
          </Popover>
        </div>
      ) : null}
      <ConfirmationModal
      show={showConfirmationModal}
      yorn={handleYOrNPopup}
      modalText={t(`Are you sure you want to Delete ${deleteFileOrFolderType} ?`)}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowConfirmationModal(false)
      }} />
    </>
  );
};

export default Media;
