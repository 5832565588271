import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CommentIcon from "@mui/icons-material/Comment";
import { blue } from "@mui/material/colors";
import { Divider, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Settings } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper/Paper";
import { useDispatch, useSelector } from 'react-redux';
import { setComposeEmailView, setEmailContentView } from '../../app/slice/emailSubMenuSlice';
import { getMailsByName } from '../../apis/Email/Email'
import Spinner from "../../layouts/spinner/Spinner";
import { useEffect } from "react";
import Avatar from '../../components/Avatar/Avatar'
import parse from 'html-react-parser';

export default function DraftList(props) {
  const dispatch = useDispatch();
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  const [data, setData] = React.useState([]);
  const [inboxMails, setinboxMails] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const getIndoxView = useSelector((state: any) => state.email.showInbox)
  const [apicalled, setapicalled] = React.useState(false);

  const openEmail = () => {
    setOpen(true);
  };

  const fetchData = () => {
    getMailsByName('SENT', (resp) => {
      setData(resp);
      //console.log("Emailds", resp);
      setapicalled(!apicalled)
  
      //console.log("data::::::::::", data)
    });
  }


  const convertHtml=(str)=>{
    if ((str===null) || (str===''))
    return false;
else
    str = str.toString();
      
// Regular expression to identify HTML tags in
// the input string. Replacing the identified
// HTML tag with a null string.
// let string =
let parser = new DOMParser();
    let dom_document = parser.parseFromString(str, "text/html");
    let body_element = dom_document.getElementsByTagName("body")[0];
    //console.log(body_element.innerHTML);
let newString = body_element.innerHTML
let strippedHtml = newString.replace(/<[^>]+>+/g, "");
//console.log(strippedHtml)
let strippedHtml1 = strippedHtml.replace(/\&nbsp;/g, '');
let finalEmailText= strippedHtml.includes('From')?strippedHtml1.split('From')[0]: strippedHtml
return finalEmailText
  }

  function truncate(source, size) {
    //console.log(source)
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  useEffect(() => {
    //console.log(props)
    if (!apicalled) {
      fetchData();
    }
  }, []);
  return (
    <div className="containter-div">

      <div className="chatlistbackground" id="scrollableDiv"
        style={{
          height: '100vh',
          width: '100%',
          overflow: 'auto',
          display: 'flex',
        }}>
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            margin: "20",
          }}
        >
          {/* <ListSubheader>{"Sent"}</ListSubheader> */}
          <InfiniteScroll
            dataLength={data?.length}
            next={()=>{}}
            hasMore={false}
            scrollableTarget="scrollableDiv"
            loader={<Spinner />}
          >
            {data?.map((info: any) => (
              <div key={info?.EmailConversation_Id}>
                <ListItem alignItems="flex-start" onClick={() => {
                  props.handleEmailSelect(info);
                  dispatch(setComposeEmailView(false));
                  dispatch(setEmailContentView(true))
                }}>
                  <ListItemAvatar>
                    {/* <Avatar
                      imageType={info.Is_Prescriber == "N" ? "prescriberassociates" : "prescriber"}
                      avatarHeight="3rem"
                      avatarWidth="3rem" /> */}
                  </ListItemAvatar>
                  <ListItemText
                    primary={truncate(parse(info.EmailTo_Email), 30)}
                    secondary={
                      <><React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {parse(info.EmailConversation_Subject.substring(0,15).substring(0,15))}
                        </Typography>
                        {/* {" — " + parse(info.EmailCC_Email)} */}
                    </React.Fragment><p>{truncate(convertHtml(info.Email_Bdy), 20)}</p></>
                    }
                  />

                  {/* <p style={{ fontSize: "8px" }}>{date}</p> */}
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </InfiniteScroll>
        </List>
      </div>
    </div>
  );
}
