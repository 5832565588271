import axios from "axios";
import config  from '../../env.json';
function getSharedContent(callback){
  // let {lang}=params;
//   try {
//     axios.get(config.REACT_APP_VIDEO_API_BASE+'/meeting',{
//       //cancelToken: cancelToken
//     })
//     .then(res=>{ 
//       callback(res.data.data);
//     }).catch((error)=>{
//       if (axios.isCancel(error)) {
//         callback({error:true})
//       }
//       else{
//         callback([])
//       }
      
//     })
// } catch (error) {
//     callback([])
// }
}

function uploadSharedContent(payload,callback){
  
  try {
    axios.post(config.REACT_APP_CONTENT_SHARE_API_BASE+"/contentsharing",payload).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
  // let resData="file uploaded..."
  // return resData
}

function getToken(callback){
  try {
    axios.get(config.REACT_APP_CONTENT_SHARE_API_BASE+"/sas-token",{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}


function getFolders(callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/get-all-child-items?itemId=0"
      ,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function getFolderFiles(payload,callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/get-all-child-items?itemId="+payload,
      {headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function getFolderFilesByName(payload,callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/file-folder-search?text="+payload,
      {headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

// Get annoated files
function getAnnotedFiles(payload,callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/getAnnotedFiles/?fileId="+payload,
      // "https://cbc4-122-162-148-234.ngrok-free.app/content/get-all-child-items?itemId="+payload,
      {headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}
function getAllFiles(callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/get-all-files/?type=file",{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}



// config.REACT_APP_CONTENT_SHARE_API_BASE+"/api/employee?Page="+page+"&PageSize="+pageSize+"&Search="+search
function getEmployeeList(params,callback){
  let {page,pageSize,search}=params;
  try {
    axios.get(config.REACT_APP_EMPLOYEE_API_BASE+"/employee?Page="+page+"&PageSize="+pageSize+"&Search="+search,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function getSharedWith(params,callback){
  let {ContentId,ContentType}=params;
  try {
    axios.get(config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/GetPermissionList?ContentId=1001&ContentType=FOLDER",{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function getSharedWithUserList(params,callback){
  let {ContentId}=params;
  try {
    axios.get(config.REACT_APP_CONTENT_SHARE_API_BASE+`/content/getSharedUsers?fileId=${ContentId}&type=FILE`,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}


function renameFile(payload,callback){
  
  try {
    axios.post(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/renamefile",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}
function createFile(payload,callback){
  
  try {
    axios.post(config.REACT_APP_CONTENT_SHARE_API_BASE+"/file-upload/",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}
function renameFolder(payload,callback){
  
  try {
    axios.post(config.REACT_APP_CONTENT_SHARE_API_BASE+"/folder",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function deleteFolder(payload,callback){
  try {
    axios.post(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/deleteFolder",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
} 

function deleteFile(payload,callback){
  console.log(payload)
  try {
    axios.post(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/deleteFile",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function createFolder(payload,callback){
  
  try {
    axios.post(config.REACT_APP_CONTENT_SHARE_API_BASE+"/folders",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function addRemoveSharedUser(payload,callback){
  
  try {
    axios.post(config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/SetPermission",payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
  // let resData="file uploaded..."
  // return resData
}


function shareFile(payload,callback){
  
  try {
    axios.post(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/share",
    payload,{
      headers:{
        "Authorization":"Bearer "+sessionStorage.getItem('login')
      }}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
  // let resData="file uploaded..."
  // return resData
}

// Get Channel type list
function getAllChannelType(callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/channel-type/"
      ,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res.data.data);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

// Get file according to channel type

function getAllFileChannelType(payload,callback){
  try {
    axios.get(
      config.REACT_APP_CONTENT_SHARE_API_BASE+"/content/getFilesByChannel?channelName="+payload
      ,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res.data.data);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}





export {
        createFile
        ,getAllFiles
        ,renameFile
        ,deleteFolder
        ,deleteFile
        ,createFolder
        ,renameFolder
        ,addRemoveSharedUser
        ,getSharedWith
        ,getEmployeeList
        ,getFolders
        ,getFolderFiles
        ,getSharedContent
        ,uploadSharedContent
        ,getToken
        ,shareFile
        ,getSharedWithUserList
        ,getAnnotedFiles
        ,getFolderFilesByName
        ,getAllChannelType
        ,getAllFileChannelType
      }