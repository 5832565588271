import axios from "axios";
import config  from '../../env.json';
function getCallLogsAPi(payload,callback){
  
    try {
        axios.get(config.REACT_APP_CALL_API_BASE+"/calllogs?Role="+payload)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
   
}
 
function getUnknownCallLogsAPi(callback){
  
  try {
      axios.get(config.REACT_APP_CALL_API_BASE+"/unknowCallLog")
      .then(res=>{ 
        callback(res.data);
      }).catch(()=>{
        callback([])
      })
  } catch (error) {
      callback([])
  }
 
}




export {getCallLogsAPi, getUnknownCallLogsAPi}