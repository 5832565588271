import React, { useEffect, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import SchoolIcon from '@mui/icons-material/School';
import Message from "./Message";
// import Avatar from '@mui/material/Avatar';
import axios from "axios";
import config from "../../env.json";
import ContactDetialsModal from "../contact/ContactDetialsModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { setSkipHeader } from '../../app/slice/loginSlice';
import CircularProgress from "@mui/material/CircularProgress";
import PreApprovedTemplates from "../preApprovedTemplates/preApprovedTemplate";
import { contactDetails } from "../../apis/contacts/contactsApi";
import { archiveUnarchiveChatApi, deleteChatApi, getConversationDetailsApi } from "../../apis/chat/chatApi";
import { useSelector, useDispatch } from "react-redux";
import { sendMessageApi, checkWhatsappValidation } from "../../apis/chat/messageApi";
import { getMsgReadAt } from "../../apis/chat/messageReadAt";
import { Keyboard } from '@capacitor/keyboard';
import { Link } from 'react-router-dom';
import {
  setChatDetails,
  setChatShowChat,
  setChatType,
  setRefreshChatComponent,
  setRefreshChatList,
  setNumberAssigned
} from "../../app/slice/chatSlice";
import {
  setCallModalDetails,
  setCallModalView,
} from "../../app/slice/callModalSlice";
import "./MobileChat.css";
import Avatar from "../Avatar/Avatar";
import { Tooltip } from "@mui/material";
import moment from "moment";
import CallModal from "../call/CallModal";
import { useTranslation } from "react-i18next";
import Pusher from "pusher-js";
import SaveIcon from "@mui/icons-material/Save";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";
import SaveContactsForChat from "./SaveContactForChat";
import SaveContactsForChatGAM from "./SaveContactForChatGAM";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import TestMessage from "./MobileMessage";
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import {getPhoneNumberType} from '../../apis/checkPhoneNumberType/landlineLookupApi';
import CallIcon from '@mui/icons-material/Call';
import sidebarHeaderBg from '../../assets/images/mobilesidebar_headerbg.jpg'
import ContactDetialsModalGAM from "../../components/contact/ContactDetialsModalGAM";
import ContactAddPopup from "../contact/ContactAddPopup";
import {  Popover } from 'antd';
import MediaChatChannelModal from '../../components/MediaChannel/MediaChatChannelModal';
import AttachmentIcon from '../../assets/icons/Attachicon.svg'
import MediaAttachmentIcon from  '../../assets/icons/templateicon.svg'
import { isAndroid } from "react-device-detect";
import getOptinBadgeStatus from "../../utils/OptinBadge";
import SpamButton from "../SpamButton/SpamButton";
import { parsePhoneNumber } from "react-phone-number-input";
import validateAttachment, { MAX_SIZE_LIMIT, supportedFileTypes } from "./validateAttachment";
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
//const Background:any = sidebarHeaderBg;
const Background = config.REACT_APP_PROFILE_BG_IMG;
let BestTimeFlag = config.REACT_APP_BESTTIME_FLAG;

// import '../chat/MsgComponent.css'

function NewMobileChatComponent(props) {
  const { t } = useTranslation();
  const { chatId, chatName, chatIsPrescriber, showChat, refreshChatComponent, pageSelected, numberAssigned, isArchive, blockedContact } = useSelector((state: any) => state.chat);
  const messageChannelType = useSelector((state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType)
  const type = useSelector((state: any) => state.chat.type);
  const token = useSelector((state: any) => state.login.token)
  const dispatch = useDispatch()
  const userTerrId=useSelector((state: any) => state.personalisation.EmployeeTerritoryId);

  const [changeTerritoryId,setChangeTerritoryId]=useState(false);
  const [messageData, setMessageData] = useState<any>([]);
  const [contactData, setContactData] = useState<any>({})
  const [showDetails, setShowDetails] = useState(false);
  const [name, setName] = useState('')
  const [showPreApproveTemplates, setPreApproveTemplates] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState<String[]>([])
  const [chatInput, setChatInput] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [messageId, setMessageId] = useState<any>();
  const [pusherChannel, setPusherChannel] = useState<any>(null);
  const [pusherMsgProps, setPusherMsgProps] = useState<any>(null);

  const [showTextArea, setShowTextArea] = useState(true);
  const [chatWidth, setChatwidth] = useState(0);
  const [chatHeight, setChatHeight] = useState(0);
  const [paddingValTop, setPaddingValTop] = useState(0);
  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [paddingValRight, setPaddingValRight] = useState(0);
  const [paddingValLeft, setPaddingValLeft] = useState(0);
  
  const [uploadAttachmentPopover, setUploadAttachmentPopover] = useState(false)

  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(0);
  // const [preApprovedTemplateData,setPreApprovedTemplateData]=useState({})
  const [showImgPopup, setShowImgPopup] = useState("");
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [unknown, setUnknown] = useState(false);
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [openSaveContacts, setOpenSaveContacts] = useState(false);
  const [mobileFooterBottom, setMobileHeaderTop] = useState<any>(0);
  const [mobileHeaderTop, setMobileFooterBottom] = useState<any>(0);
  const [mainChatTop, setMainChatTop] = useState<any>(0);
  const [mainChatBottom, setMainChatBottom] = useState<any>(0);
  const [finished, setFinished] = useState(false);
  const [deletedOthers,setDeletedOthers]=useState(false)
  const { optOutKeywords, optInKeywords } = useSelector((state: any) => state.personalisation);
  const [tempTemplateDataIfNoNumber,setTempTemplateDataIfNoNumber]=useState<any>(null);
  const [noNumber,setNoNumber]=useState(false);
  const [showAttachLoader, setShowAttachLoader] = useState<any>(false);
  const [isHospitalContact,setIsHospitalContact]=useState(false);
  const [addNumberPopup,setAddNumberPopup]=useState(false)
  const [chatPusher,setChatPusher]=useState<any>(null);
  const camp=useSelector((state: any) => state.personalisation.campaignName);
  const [mediaChannelPopup, setMediaChannelPopup] = useState(false)
  const [isSpam, setIsSpam] = useState(false);
  const [isWhatsappTimeExpired, setIsWhatsappTimeExpired] = useState(false);
  const [optinStatusFlag,setOptinStatusFlag]=useState("");
  const [location, setLocation] = useState('');
  const [speciality, setSpeciality] = useState('');
    const [showNewConversationWindowBanner, setShowNewConversationWindowBanner] = useState(false);
    const [showConversationBanner, setShowConversationBanner] = useState(false);
    const [loadingConversionWindowStatus, setLoadingConversionWindowStatus] = useState(true)
    const [disableCallButton, setDisableCallButton] = useState(false);
  let docElement: any = document;
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [isArchiveConfirmLoading ,setIsArchiveConfirmLoading] = useState<boolean>(false);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState<boolean>(false);
  const [isShowDeleteConfirmLoading, setIsShowDeleteConfirmLoading] = useState<boolean>(false);
  // const fileSupportedExt = [
  //   "png",
  //   "mpeg",
  //   "mpe",
  //   "mpa",
  //   "mp3",
  //   "mp2v",
  //   "mod",
  //   "mp4v",
  //   "3gpp",
  //   "3gp2",
  //   "3g2",
  //   "3gp",
  //   "mov",
  //   "mp4",
  //   "m1v",
  //   "au",
  //   "mp3",
  //   "ac3",
  //   "amr",
  //   "vcf",
  //   "csv",
  //   "rtx",
  //   "pdf",
  //   "bmp",
  //   "tif",
  //   "tiff",
  //   "jpe",
  //   "jpeg",
  //   "jpg",
  //   "gif",
  //   "m2v",
  //   "mpv2",
  //   "vbk",
  //   "web",
  //   "ogg",
  //   "ics",
  //   "webm",
  // ];
  // const fileSupported = {
  //   image: "image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png",
  //   video:
  //     "video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg",
  //   audio:
  //     "audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr",
  //   application: "application/pdf, application/vcard",
  //   text: "text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
  // };
  const [isSMS, setIsSms] = useState(true); // if true then whatsapp or else message
  // const fileType =
  //   fileSupported.application +
  //   ", " +
  //   fileSupported.text +
  //   ", " +
  //   fileSupported.image +
  //   ", " +
  //   fileSupported.video +
  //   ", " +
  //   fileSupported.audio;


  const fileSupportedExt= config.REACT_APP_FILESUPPORTED_EXT.split(',');
  const fileType = config.REACT_APP_FILESUPPORTED_TYPE;
  const default_wave_id = config.REACT_APP_DEFAULT_WAVE_ID;
  const default_campaign_id = config.REACT_APP_DEFAULT_CAMPAIGN_ID;
  const default_product_id = config.REACT_APP_DEFAULT_PRODUCT_ID;


  useEffect(() => {
    if(messageData[0]?.blockedContact) {
      setIsSpam(messageData[0]?.BlockedContact === "Y");
    } else {
      setIsSpam(blockedContact === "Y");
    }
  }, [messageData]);


  const handleSpam = () => {
    setIsSpam(!isSpam);
    dispatch(setChatDetails({
      chatId, chatName, chatIsPrescriber, showChat, refreshChatComponent, pageSelected, numberAssigned, isArchive, blockedContact: "Y"
    }))
  };

  const getUserInfo = () => {
    setLoadingConversionWindowStatus(true)
    let id = chatId,
      presOrNot = chatIsPrescriber;
    // if(contact['Recipient Sender Typ']=="EMP"){
    //     id=contact['Recipient Id'];
    //     presOrNot=contact['Recipient Type']== "PRESCR";
    // }
    // else{
    //     presOrNot=contact['Recipient Sender Typ']=="PRESCR";
    //     id=contact['Recipient Sender Id'];
    // }
    // debugger
    if(id && type === 'UNKN') {
      setUnknown(true);
      setLoadingConversionWindowStatus(false)
    }
    else if (id && type !== 'UNKN') {
      contactDetails({ Id: id, Is_Prescriber: presOrNot }, (res) => {
        setLoadingConversionWindowStatus(false)
        if (res) {
          if (res.message == "No data found") {
            setUnknown(true);
          } else {
            if(res.Terr_Id){
              setLocation(res.Acct_Adr)
              setSpeciality(res.Acct_Splty)
              if(userTerrId && userTerrId!=='' && config.REACT_APP_ABS_APPROVED === 'Y'){
                for(let i = 0; i < userTerrId.length; i++) {
                  if(userTerrId[i]?.TerritoryAlgnTerr_Id === res.Terr_Id){
                    setChangeTerritoryId(false);
                    break;
                  } else{
                    setChangeTerritoryId(true);
                  }
                }
             }
          }
          setLoadingConversionWindowStatus(true)
          checkWhatsappValidation({recipient_id: id, recipient_phone: res.AcctPh_Nbr, role_id: chatIsPrescriber ? 0 : 1}, (res, msgData) => {
            setLoadingConversionWindowStatus(false)
            if (res && msgData && !msgData.success) {
                setIsWhatsappTimeExpired(true)
                setDisableCallButton(true);
            }
            else {
              setDisableCallButton(false);
              setIsWhatsappTimeExpired(false)
              setDisableSendButton(false)
            }
          });

          if(res.AcctPh_Nbr.length>0){
            dispatch(setNumberAssigned(true))
          }
            setUnknown(false);

            chatSubscriber(1);
            setContactData(res);
            checkNumber(res)
            if(!res.AcctPh_Nbr){
              setNoNumber(true)
              dispatch(setNumberAssigned(false))
            }
            else{
              setNoNumber(false)
            }
            dispatch(
              setCallModalDetails({
                AcctFull_Nm: res.AcctFull_Nm,
                AcctFull_Nbr: getNumberFromItem(res),
              })
            );

            setOptinStatusFlag(res.All_Ph_Cont_Flags)
            if (res.All_Ph_Cont_Flags === "N, N, N"){
              setOptedOut(true);
            console.log("optout",res.All_Ph_Cont_Flags)

            } 
            else{
              setOptedOut(false);
            console.log("optout",res.All_Ph_Cont_Flags)

            }
          }
        }
        setFinished(true)
      });
    }

    // axios.get(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+id,{
    //     headers:{
    //       'Authorization':'Bearer '+sessionStorage.getItem('login')
    //     },
    //     params:{
    //       "IsPrescriber":presOrNot,
    //     }
    //   }).then((res:any)=>{

    //     setContactData(res.data.data)

    //   })
  };
  const handleFileSelected = (e) => {
    const files: any = Array.from(e.target.files);
    console.log("files:", files);
    setAttachedFiles(files);
    console.log("attachedFiles : ", attachedFiles);
  };

  const handleImgPopup = (imgdisurl) => {
    console.log("handling image enlarge parent", imgdisurl);
    setShowImgPopup(imgdisurl);
  };

  const forceFileDownload = (response: any, filename: any) => {
    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    document.getElementsByClassName("mobile-footer")[0].addEventListener('touchmove', ev => {
        ev.preventDefault();
        ev.stopPropagation();
    }, { passive: false });
    
    let chatdiv:any = document?.getElementsByClassName("shadowin")[0]


  var lastScrollTop = 0;

  chatdiv.addEventListener("touchmove", ev=>{
    if( chatdiv.scrollTop >= (chatdiv.scrollHeight - chatdiv.offsetHeight - 1)){
      chatdiv.scrollTop = chatdiv.scrollHeight - chatdiv.offsetHeight - 3;
    }
    var st = window.pageYOffset || chatdiv.scrollTop;
    if (st > lastScrollTop){
        if( chatdiv.scrollTop >= (chatdiv.scrollHeight - chatdiv.offsetHeight - 1)){
          ev.preventDefault();
          ev.stopPropagation();
        }else{
          return true
        }
    } else {
        return true
        

    }
  }, { passive: false });
   
  }, 
  [])


  
  const downloadWithAxios =async (url) => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    } else {
      console.log("handle click downloadWithAxios called", url);
      let urlSplit = url.split("/");
      let filename = urlSplit[urlSplit.length - 1];
      delete axios.defaults.headers.common["Authorization"];
      const instance = axios.create();
      const res = await instance.get(url, {responseType:"blob"})
      forceFileDownload(res.data, filename);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token
    }
};

const getUploadContent = () => {
  return (
    <div id={"popup1"}>
      <div className="videopopup-row mx-2 flex-column">
        <div
          className="msgoption_icon_wrapper"
          style={{
            fontSize: 20,
            color: "var(--primary-color)",
          }}
          onClick={pickPhoto}
        >
          <i className="fas fa-image "></i>
        </div>
        <div
          className="msgoption_icon_wrapper mt-2"
          style={{
            fontSize: 20,
            color: "var(--primary-color)",
          }}
          onClick={pickFiles}
        >
          <i className="fas  fa-folder "></i>
        </div>
      </div>

      {/* <div className="videopopup-row">
        <div className="msgoption_icon_wrapper">
          <img
            src={MediaAttachmentIcon}
            alt="folderIcon"
          />
        </div>
        <div className="videopopup-details" 
        onClick={()=>{setMediaChannelPopup(true)}}
        >
          Attach file from Media
        </div>
      </div> */}
    </div>
  );
};

  const removeFile = (i) => {
    console.log("functioin called for ", i);
    let attfile = attachedFiles.filter((x, index) => i != index);
    setAttachedFiles(attfile);
   validateGetfiles(attfile);
    // this.fileSize = 0;
    // for (let i = 0; i < this.files.length; i++) {
    //     this.fileSize += parseInt(this.files[i].size);
    // }
    // if (this.files.length <= 10) {
    //     this.footerErrorMsg = [];
    // }
  };
  // const pusherSubscribe = (e)=>{
  //     console.log(e, "porA")
  //     // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
  //     //     cluster: config.REACT_APP_PUSHER_CLUSTER,
  //     //   //   encrypted: true,
  //     //   });

  //     const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
  //       cluster: config.REACT_APP_PUSHER_CLUSTER,
  //     //   encrypted: true,
  //     });
  //     const channelName= getNumberFromItem(e)+"Dev";
  //     console.log(channelName)
  //     const channel = pusher.subscribe('chatdetailstatus');
  //   channel.bind(messageId, (data) => {
  //         console.log(data.Body)
  //         let pusherMsgData:any = messageData;
  //         pusherMsgData.push(data)
  //         setMessageData(data);
  //         optInOut(data.isOptIn)

  //     })
  //     // return () => {
  //     //   pusher.unsubscribe('chatdetailstatus');
  //     // };
  //   }
  useEffect(() => {
    if(!optedOut && !deletedOthers && !unknown && !isNumberLandline && !noNumber){
      let doc:any=document;
      setMainChatBottom(doc.getElementsByClassName('mobile-footer')[0].clientHeight)
    } else {
      setMainChatBottom("0px")
    }
}, [optedOut, deletedOthers, unknown, isNumberLandline, noNumber, attachedFiles])

  useEffect(() => {
    
    let doc: any = document;
    console.log(getNumberFromItem(contactData));
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind(getNumberFromItem(contactData));

      pusherChannel.bind(getNumberFromItem(contactData), (data) => {
        if (data) {
          console.log(messageData);
          // setShowTextArea(false);
          // document.querySelectorAll('textarea').forEach((element) => {

          //   element.blur();

          //   setTimeout(()=>{
          //     setShowTextArea(true);
          //   },1000)

          // });

          let jsonData = JSON.parse(data.Body);
          console.log(JSON.parse(data.Body));
          optInOut(jsonData.isOptIn);
        }
        //   if(data){
        //     console.log(messageData)
        //     let jsonData= JSON.parse(data.Body);
        //     console.log(JSON.parse(data.Body))
        //     let x = {
        //         'Recipient Channel Type':jsonData.channelType,
        //         'Recipient Msg End':jsonData.createdDateTime,
        //         'Recipient Msg Start':jsonData.createdDateTime,
        //         'Recipient Msg Body':jsonData.msgBody,
        //         'Recipient Msg Attachment':'',
        //         'Recipient Msg Status': 'sent'
        //     }
        //     let msgPusherData: any= [];
        //     messageData.map(e=>
        //       {msgPusherData.push(e);
        //           return e;
        //       }
        //       )
        //   //   messageData.map(e)
        //     console.log(jsonData.isOptIn)
        //     optInOut(jsonData.isOptIn)
        //     msgPusherData.unshift(x)
        //     console.log(msgPusherData)
        //     let t = msgPusherData;
        //     for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
        //       setMessageId(t[chatIndex]['Message Id'])
        //       if (chatIndex==t.length-1)
        //       {
        //         t[chatIndex].showDateBanner = true;
        //       }

        //       else if (chatIndex != 0) {
        //         if (
        //           moment(t[chatIndex]['Recipient Msg Start']).format(
        //             "MM/DD/YYYY"
        //           ) !=
        //           moment(t[chatIndex + 1]['Recipient Msg Start']).format(
        //             "MM/DD/YYYY"
        //           )
        //         ) {
        //           t[chatIndex].showDateBanner = true;
        //         } else {
        //           t[chatIndex].showDateBanner = false;
        //         }
        //       }
        //       else if (chatIndex == 0 &&  t.length>1) {

        //         if (
        //           moment(t[chatIndex]['Recipient Msg Start']).format("MM/DD/YYYY") == moment(t[chatIndex + 1]['Recipient Msg Start']).format( "MM/DD/YYYY")
        //         ) {
        //           t[chatIndex].showDateBanner = false;
        //         } else {
        //           t[chatIndex].showDateBanner = true;
        //         }
        //       }

        //     }
        //     setMessageData(t);
        //     // dispatch(setRefreshChatList(true))
        // }

        // setChats(data);
      });
    }
    let div: any = document.querySelector("#main");
    if (!div) return
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setPaddingValTop(divpaddingTop);
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingleft);
    setPaddingValLeft(divpaddingright);

    // console.log("Padding"+ divpadding);
    console.log("Innerwidth" + window.innerHeight);

    if (
      document.getElementsByClassName("mobile-footer") &&
      document.getElementsByClassName("mobile-footer").length > 0
    )
      setChatHeight(
        window.innerHeight -
        (divpaddingTop +
          divpaddingbottom)
    );
    else setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    scrollTOBottom();
    console.log("changes");
  }, [messageData, contactData]);

  /* useEffect(()=>{
    setChatHeight(window.innerHeight);
  }, []) */
  useEffect(()=>{
    scrollTOBottom()
    chatSubscriber()
},[messageData])

  const chatSubscriber = (p=0) => {
    console.log(messageData);
    
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    
    // console.log(contactNumber)
    const channel = pusher.subscribe("chatdetail");
    setPusherChannel(channel);
    setPusherMsgProps(pusher.subscribe("chatdetailstatus"));

    let payload:any={
      prescOrAssocId:chatId, 
      prescOrAssocType:chatIsPrescriber === true? "PRESCR": config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp ? "ASSHSP" : "ASSPRS"
    }
    if (p == 0) {
      getMsgReadAt(payload,(res)=>{
        console.log(res.data.updated )
        if(res.data.updated === true){
            dispatch(setRefreshChatList(true))
        } 
        
      })
    }
    let chatP:any= pusher.subscribe('chat')    
    setChatPusher(chatP);

        return () => {
            pusher.unsubscribe("chatdetail");
            pusher.unsubscribe("chat")
        };
  };
  const messageSubscriber = () => {
    // debugger
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    // debugger
    // console.log(contactNumber)
    const channel = pusher.subscribe("chatdetailstatus");
    channel.bind(messageId, (data) => {
      //   if(data)
      // debugger
      let x = data;
      // console.log(JSON.parse(data.Body))
      console.log(data);
      // setChats(data);
    });

    return () => {
      //   pusher.unsubscribe("chatdetailstatus");
    };
  };

  //   const optInOut= (e)=>{
  //     if(e === 'Y'){
  //         setOptedOut(false)
  //     }else if( e=== 'N'){
  //         setOptedOut(true)
  //     }

  // }
  const getFiles = (e) => {
    setUploadAttachmentPopover(false)
    console.log('inside')
    const files: any = Array.from(e.target.files)
    
    let temp: any = [];
    let totalsize:any=0;
    // attachedFiles.map((x:any) => {
    //     temp.push(x)
    //     totalsize+=x.size
    //     console.log(temp)
    // })
    files.map((e: any) => {
        e.testTime = new Date();
        temp.push(e)
        totalsize+=e.size
        console.log(temp)
    })
    // temp=temp.concat(files);
    
   
    console.log(temp)
    let { errorMsg } = validateGetfiles(temp);
    
  //   if(totalsize>100000000){
  //     let t:any = [];
  //     errorMessages.map((x:any)=>{
  //         t.push(x);
  //         return x;
  //     })
      
  //     t.push('File size exceeds 100MB');
  //     setErrorMessages(t)
  //     setTimeout(
  //         function () {
  //             setErrorMessages([])
  //         }
  //         ,
  //         2000
  //     );
  // }
  //  else 
   if (temp.length > 10) {
        // footerErrorMsg.push('Max 10 files are allowed');
        let errmsg = "Max 10 files are allowed";
        // errorMessages.push("Max 10 files are allowed");
        // console.log('Max 10 files are allowed');
        let t:any = [];
        errorMessages.map((x:any)=>{
            t.push(x);
            return x;
        })
        
        t.push('Max 10 files are allowed');
        setErrorMessages(t)
        setTimeout(
            function () {
                setErrorMessages([])
                setAttachedFiles([])
                setDisableSendButton(false);
            }
            ,
            2000
        );
        
    }
    else {
        setAttachedFiles(temp)

        console.log("attachedFiles : ", attachedFiles)
        console.log("hello world : ", attachedFiles.length);
    }
    if (errorMsg) {
      // temp.pop(e.target.files)
      //setDisableSendButton(true)
        // this.footerErrorMsg.push(errorMsg);
        let t:any = [];
        errorMessages.map((x:any)=>{
            t.push(x);
            return x;
        })
        t.push(errorMsg);
        setErrorMessages(t)
        setTimeout(
            function () {
                setErrorMessages([])
                setAttachedFiles([])
                setDisableSendButton(false);
            }
            ,
            2000
        );
    }
    let doc:any=document
    doc.getElementById('attachedfiles').value = "";
    // setTimeout(() => {
    //     this.footerErrorMsg = [];
    // }, 5000);
    // e.target.value = null;
}
  const validateGetfiles = (files) => {
    setDisableSendButton(false);
      let errorMsg = validateAttachment(files);
      if (errorMsg) {
        if(errorMsg.includes("is not supported")){
          errorMsg = errorMsg.replace("is not supported", t("is not supported"));
        }
        setDisableSendButton(true);
      }
    return { errorMsg };
  };
  // supportedFileType() {

  //   return this.fileType;
  // }
  const selectedTemplate = (tempData,contactData) => {
    // if(data['Recipient Sender Typ']=="EMP"){
    //     formData.append("recipient_id", data['Recipient Id']);
    //     formData.append("recipient_name", data['Recipient Name']);
    //     formData.append("recipient_phone", data['Phone #']);
    //     formData.append("template",templateData.templateVal);
    //     formData.append("Campaign_ID",templateData.campaginId.toString());
    //     formData.append("template_id",templateData.id.toString());
    //     formData.append('wave_id',templateData.waveId.toString())
    //    formData.append('product_id',templateData.productId.toString())

    //     formData.append("physcian_id",data['Recipient Id'] );
    //    formData.append("role_id", '0');//for prescriber
    //    if (!isSMS) {
    //     formData.append("channel", "SMS");
    //    } else {
    //     formData.append("channel", "whatsa");
    //    }
    //    sendMessageApi(formData,(res)=>{
    //     setPreApproveTemplates(false)
    //    })
    //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{
    //     setPreApproveTemplates(false)
    // }).catch(res=>{
    //     setPreApproveTemplates(false)
    //     console.log(res)
    // })
    // }
  
    let templateData: any = tempData.templateData
    console.log(contactData);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
  //   if(!phone){
  //     setNoNumber(true)
  //     setTempTemplateDataIfNoNumber(tempData);
  //     setShowDetails(true)
  //     dispatch(setShowErrorSnackBar(true));
  //     return; 
  // }
  // setNoNumber(false)
  // setTempTemplateDataIfNoNumber(null);
    let formData = new FormData();
    
    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }
    if(tempData.dynTempObj && Object.keys(tempData.dynTempObj)?.length>0){
      formData.append("dynTempObj", JSON.stringify(tempData.dynTempObj));
    }
    
    formData.append("Campaign_ID", default_campaign_id); //templateData.campaginId.toString());
    formData.append("wave_id", templateData.mainWaveId.toString()); //templateData.waveId.toString())
    formData.append("product_id", templateData.productId.toString());

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    if (attachedFiles.length > 0) {
      for (var i = 0; i < attachedFiles.length; i++) {
        let file = attachedFiles[i];
        formData.append("file", file);
      }
    }

    if (templateData.FormId) {
      let formPay = {
          "formId": templateData.FormId,
          "fieldDetails": tempData.formData,
          "recipientId": chatId,
          "recipientType": chatIsPrescriber ? "PRESCR" : 'ASSPRS'
      }
      axios.post(config.REACT_APP_FORMS_API_BASE + '/formRequest', formPay).then((res: any) => {
          console.log(res.data)
          let temptest = templateData.TemplateBody.toString();
          
          if (temptest.indexOf('shorturl') >= 0)
              formData.append("template", temptest.toString().replace('[shorturl_1]', res.data.FormUrl));
          else
              formData.append("template", temptest + " " + res.data.FormUrl);
          formData.append('urls', res.data.FormUrl)
          let qtPayload={
              quantityUsed:tempData.formData[0].valueOfFieldToAskFromEmp,
              prescriberId:chatId,
              productId:templateData.productId.toString()
            }
            axios.post(config.REACT_APP_CAMPAIGN_API_BASE+'/srf/updateQauntity',qtPayload).then((res:any)=>{
              
            })
          //   sendMessageForPresOrOthers(formData);
          sendMessageApi(formData, (res, msgData) => {
            console.log(msgData);
            // setAttachedFiles([]);
            // setChatInput("");
      
            // updateElSize({ target: { value: "" } });
            setPreApproveTemplates(false);
            if (res) {
              getMessageData(props.messageData, false, 1);
              dispatch(setRefreshChatList(true));
              dispatch(setShowSuccessSnackBar(true));
            }
            else{
              dispatch(setShowErrorSnackBar(true));
            }
          });
      })
          .catch(err => {
              dispatch(setShowErrorSnackBar(true));
          })
  }
  else {
      formData.append("template_id", templateData.TemplateId.toString());
      formData.append("template", templateData.TemplateBody);
      sendMessageApi(formData, (res, msgData) => {
        console.log(msgData);
        // setAttachedFiles([]);
        // setChatInput("");
  
        // updateElSize({ target: { value: "" } });
        setPreApproveTemplates(false);
        if (res) {
          getMessageData(props.messageData, false, 1);
          dispatch(setRefreshChatList(true));
          dispatch(setShowSuccessSnackBar(true));
        }
        else{
          dispatch(setShowErrorSnackBar(true));
        }
      });
  }
   
  };
  const saveUnknown = (data: any) => {
    
    if (data.Id) {
      dispatch(setChatType(data.Is_Prescriber == "Y" ? "PRESCR" : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
        config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp ? "ASSHSP" : "ASSPRS"));
      dispatch(
        setChatDetails({
          chatId: data.Id,
          chatName: data.AcctFull_Nm,
          chatIsPrescriber: data.Is_Prescriber == "Y",
          showChat: true,
        })
      );
      dispatch(setRefreshChatList(true));
      dispatch(setRefreshChatComponent(true));
    }
  };

  const getMessageData = (data, old, p) => {
    
    let id = chatId;
    // if(data['Recipient Sender Typ']=="EMP"){
    //     id=data['Recipient Id'];
    // }
    // else{
    //     id=data['Recipient Sender Id'];
    // }
    // if(id && id!=0)

    getConversationDetailsApi({ page: p, limit, id, type: type }, (res) => {
      window.localStorage.setItem('sentFromWindow',"false");
      if(!res) return; 
    
      if (res.length>0&&res[0]['Error']) {
        setHasMore(false);
      }
      if(res.length==0)
      {
          setHasMore(false)
      }
      let t = res.reverse();
      // let t = res;
      if (old) {
        t = messageData.concat(t);
      }
      
      for (let chatIndex = 0; chatIndex < t.length; chatIndex++) {
        if (chatIndex == 0) {
          t[chatIndex].showDateBanner = true;
        } else if (chatIndex != t.length - 1) {
          if (
            moment(t[chatIndex]["Recipient Msg Start"]).format("MM/DD/YYYY") !=
            moment(t[chatIndex - 1]["Recipient Msg Start"]).format("MM/DD/YYYY")
          ) {
            t[chatIndex].showDateBanner = true;
          } else {
            t[chatIndex].showDateBanner = false;
          }
        } else if (chatIndex == t.length - 1) {
          if (
            moment(t[chatIndex]["Recipient Msg Start"]).format("MM/DD/YYYY") ==
            moment(t[chatIndex - 1]["Recipient Msg Start"]).format("MM/DD/YYYY")
          ) {
            t[chatIndex].showDateBanner = false;
          } else {
            t[chatIndex].showDateBanner = true;
          }
        }
      }
      // t=t.sort((b:any,a:any)=>new Date(b['Recipient Msg Start']).getDate()-new Date(a['Recipient Msg Start']).getDate())
      //  for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
      //      if (chatIndex==t.length-1)
      //      {
      //        t[chatIndex].showDateBanner = true;
      //      }

      //      else if (chatIndex != 0) {
      //        if (
      //          moment(t[chatIndex]['Recipient Msg Start']).format(
      //            "MM/DD/YYYY"
      //          ) !=
      //          moment(t[chatIndex + 1]['Recipient Msg Start']).format(
      //            "MM/DD/YYYY"
      //          )
      //        ) {
      //          t[chatIndex].showDateBanner = true;
      //        } else {
      //          t[chatIndex].showDateBanner = false;
      //        }
      //      }
      //      else if (chatIndex == 0 &&  t.length>1) {

      //        if (
      //          moment(t[chatIndex]['Recipient Msg Start']).format("MM/DD/YYYY") == moment(t[chatIndex + 1]['Recipient Msg Start']).format( "MM/DD/YYYY")
      //        ) {
      //          t[chatIndex].showDateBanner = false;
      //        } else {
      //          t[chatIndex].showDateBanner = true;
      //        }
      //      }

      //    }

      console.log(t);
      //  getHeight()
      getUserInfo();
      if (res.length>0&&res[0]['Error']==undefined) {
        setMessageData(t);
      } else {
        setMessageData([])
      }
      
      let tt = t.filter(x => x[ 'Direction' ] == "incoming")
      let optOutIndex;
      let optOutIndexArray = new Array();
      let optInIndexArray = new Array();
      let optInIndex;
      let tr = tt.reverse();
      if (tt.length>0){             
          for(var i = 0; i < optOutKeywords.length; i++){
              
              optOutIndex = tr.findIndex(x => x[ 'Recipient Msg Body' ].toLowerCase() == optOutKeywords[i].toLowerCase());

              
              if (optOutIndex >= 0) {
                  optOutIndexArray.push(optOutIndex)
                  
              }
              else{
                  optOutIndex = 100000000;
                  optOutIndexArray.push(optOutIndex);
              }
          }
          for(var j = 0; j < optInKeywords.length; j++){
              optInIndex = tr.findIndex(x => x[ 'Recipient Msg Body' ].toLowerCase() == optInKeywords[j].toLowerCase());                  
              if (optInIndex >= 0) {
                  optInIndexArray.push(optInIndex)


              }
              else{
                  optInIndex = 100000000;
                  optInIndexArray.push(optInIndex);
              }
          }
          var smallestOptOutIndex = optOutIndexArray.sort((a, b) => a - b);
          var smallestOptInIndex = optInIndexArray.sort((a, b) => a - b);
          if(smallestOptOutIndex[0] < smallestOptInIndex[0]){
              setOptedOut(true);  
              console.log("lllllooooo", optedOut)
              // if(!optedOut)  {
              //   console.log("lllllooooo")
              //   setOptedOut(false); 
              // }         
          }
          else{
              setOptedOut(false);
          }

      } 
      //  scrollIntoView(docElement.querySelector('.msg-row'), { behavior: "smooth" });
      //  scrollTOBottom()
    });
    // axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/details?&page="+p+"&limit="+limit+"&recipientId="+id,{
    //     headers:{
    //       'Authorization':'Bearer '+sessionStorage.getItem('login')
    //     }
    //   }).then((res:any)=>{
    //     debugger
    //     if(res.data.length==0){
    //         setHasMore(false)
    //     }
    //     // if(data['Recipient Sender Typ']=="EMP"){
    //     //     setName(data['Recipient Name']);
    //     // }
    //     // else
    //     // {
    //     //     setName(data['Sender Name']);
    //     // }
    //     //debugger

    //     let t=res.data.reverse();
    //     if(old){
    //         t=t.concat(messageData)
    //     }
    //     for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
    //         if (chatIndex == 0) {
    //           t[chatIndex].showDateBanner = true;
    //         } else if (chatIndex != t.length - 1) {
    //           if (
    //             moment(t[chatIndex]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             ) !=
    //             moment(t[chatIndex - 1]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             )
    //           ) {
    //             t[chatIndex].showDateBanner = true;
    //           } else {
    //             t[chatIndex].showDateBanner = false;
    //           }
    //         } else if (chatIndex == t.length - 1) {
    //           if (
    //             moment(t[chatIndex]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             ) ==
    //             moment(t[chatIndex - 1]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             )
    //           ) {
    //             t[chatIndex].showDateBanner = false;
    //           } else {
    //             t[chatIndex].showDateBanner = true;
    //           }
    //         }
    //       }

    //     setMessageData(t);
    //   })
  };
  const scrollTOBottom = () => {
    if (
      docElement.getElementsByClassName("mainchat") &&
      docElement.getElementsByClassName("mainchat").length > 0
    ) {
      // let docElement:any=docElementument
      // console.log('hi');
      //document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length-1].scrollTop=document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length-1].scrollHeight
      //  setTimeout({docElement.getElementsByClassName('infinite-scroll-component')[docElement.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0}, 0)
      // docElementument.getElementsByClassName('infinite-scroll-component')[docElementument.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0
      setTimeout(function () {
        if(document.getElementsByClassName("mainchat")){
          if(document.getElementsByClassName("mainchat").length>0)
        document.getElementsByClassName("mainchat")[
          document.getElementsByClassName("mainchat").length - 1
        ].scrollTop =
          document.getElementsByClassName("mainchat")[
            document.getElementsByClassName("mainchat").length - 1
          ].scrollHeight;}

          if(document.getElementsByClassName("msg-attachment")){
            let scroll:any = document.querySelector(".infinite-scroll-component")
            scroll.scrollIntoView(false);
          }
          setTimeout(() => {
            const allMatchedElements = document.getElementsByClassName('mainchat');
            const moduleContent = allMatchedElements[0];
            moduleContent.scrollTop = moduleContent.scrollHeight;
          }, 500);
        //  docElement.getElementsByClassName('infinite-scroll-component')[docElement.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0;
        //   docElement.querySelector('.msg-row').scrollIntoView({   behavior: 'smooth' });
      }
        ,
        500
      );
    }

  };
  // const updateElSize = (e) => {
  //   // getHeight()
  //   console.log(e)
  //   let doc: any = document;
  //   // getHeight()
  //   doc.getElementById("footerinput").style.height = "auto"
  //   if (e.target?.value === "" || !e) {
  //     doc.getElementById("footerinput").style.height = "0px"
  //   }
  //   else {
  //     doc.getElementById('footerinput').style.height = doc.getElementById('footerinput').scrollHeight + "px"
  //     document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length - 1].scrollTop = document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length - 1].scrollHeight
  //     // doc.getElementById('scrollableDiv')
  //   }
  //   scrollTOBottom()
  //   // doc.getElementById('footerinput').style.height = "0px"
  // };

  const resizeInputBox = ()=>{

    return '\t';
    
  }

  useEffect(()=>{
    console.log("opppppp", optedOut)

   if(optedOut){
    setMainChatBottom("0px")
   }
   
  }, [optedOut]);
  const checkNumber = (contactData: any) => {
    let phoneNumber = getNumberFromItem(contactData);
    if(Object.keys(contactData).length > 0){
      getPhoneNumberType({phone:phoneNumber}, (res)=>{
        if(res.type == "landline") {
          setIsNumberLandline(true)
        } else {
          setIsNumberLandline(false)
        }
      })
    }
  }
  useEffect(()=>{
    
    if(unknown && type=='ASSPRS'){
        setDeletedOthers(true)
    }else{
        setDeletedOthers(false)
    }
  },[unknown])
  useEffect(() => {
    //   // scrollTOBottom()
    //   getHeight()

    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardWillShow', info => {
        // console.log('keyboard will show with height:', info.keyboardHeight);
         document.getElementById('main')?.setAttribute('style', 'padding-bottom: 0 !important');
        
      });
      
      Keyboard.addListener('keyboardDidShow', info => {
        calculateHeight();
      });
      
      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide');
        document.getElementById('main')?.removeAttribute('style');
      });
      
      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide');
        calculateHeight();
      });
    }
    let doc:any=document; 
    let div: any = document.querySelector("#main");
    if (!div) return
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    setMobileFooterBottom(divpaddingbottom);
    setMobileHeaderTop(divpaddingTop)
    if(doc.getElementsByClassName('header-wrapper')[0]){
      setMainChatTop(doc.getElementsByClassName('header-wrapper')[0]?.clientHeight)
   
    }    
    if(doc.getElementsByClassName('mobile-footer')[0]){
      console.log("Flag",optedOut)
     setMainChatBottom(doc.getElementsByClassName('mobile-footer')[0].clientHeight)
    } else{
      setMainChatBottom("0px")
    }
    // for ios
    if (!isAndroid) {
      window.visualViewport?.addEventListener("resize", (e) => {
        let div: any = document.querySelector("#main");
        if (!div) return
        let divpaddingleft: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-left")
        );
        let divpaddingright: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-right")
        );
        setChatHeight(window.visualViewport?.height ?? window.innerHeight);
        setChatwidth((window.visualViewport?.width ?? window.innerWidth) - (divpaddingright + divpaddingleft));
        getHeight();
        scrollTOBottom();
        console.log("resize")
      });
    } else {
      // for android
      window?.addEventListener("resize", (e) => {
        let div: any = document.querySelector("#main");
        if (!div) return
        let divpaddingleft: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-left")
        );
        let divpaddingright: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-right")
        );
        setChatHeight(window.innerHeight);
        setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
        getHeight();
        scrollTOBottom();
      });
    }
  }, [optedOut]);

  useEffect(() => {
    if(getOptinBadgeStatus(optinStatusFlag) === "G") {
      setShowConversationBanner(true);
    }
  }, [optinStatusFlag])

  useEffect(() => {
    
    window?.addEventListener('orientationchange', orientationChange)
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    ) {
      setIsHospitalContact(true)
    } else {
      setIsHospitalContact(false)
    }
    return () => {
      window.removeEventListener('orientationchange', orientationChange)
    }
  }, [])

  useEffect(()=>{

    if (!messageData || !messageData.length || loadingConversionWindowStatus) return;

    const lastIncoming = messageData.slice().reverse().find((m:any)=>m.Direction  === 'incoming' )
    console.log("lastIncoming",lastIncoming)
    let conversionWindowClose  
    if (lastIncoming){
      conversionWindowClose = new Date(lastIncoming["Recipient Msg Start"]).getTime() + Number(config.REACT_APP_CONVERSATION_WINDOW_TIMEOUT) * 36e5
    }
    console.log("conversionWindowClose", conversionWindowClose)
    
    for(let i = messageData.length - 1; i >= 0; i--) {
      const currentMsg = messageData[i]
      if(currentMsg["Direction"] === 'outgoing' && currentMsg["IsApproveTemplate"] === 'Y' && currentMsg["IsNotIntro"] === 'Y') {
        if (conversionWindowClose && conversionWindowClose  < new Date(currentMsg["Recipient Msg Start"]).getTime()) {
          setShowNewConversationWindowBanner(true);
            break;
        } 
      } else if (currentMsg["Direction"] === 'incoming') {
        setShowNewConversationWindowBanner(false);
        break
      }
    }
  },[messageData, loadingConversionWindowStatus])

  function orientationChange() {
    let doc:any=document; 
    if(doc.getElementsByClassName('header-wrapper')[0]){
      setMainChatTop(doc.getElementsByClassName('header-wrapper')[0]?.clientHeight)
   
    }    
    if(doc.getElementsByClassName('mobile-footer')[0]){
      console.log("Flag 2",optedOut)

      setMainChatBottom(doc.getElementsByClassName('mobile-footer')[0].clientHeight)
    } else{
      setMainChatBottom("0px")
    }
    let div: any = document.querySelector("#main");
    if (!div) return
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    setChatHeight(
      window.innerHeight -
      (divpaddingTop +
        divpaddingbottom)
    );
    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    getHeight();
    scrollTOBottom();
  }

  const calculateHeight = () => {
    let div: any = document.querySelector("#main");
    if (!div) return
        let divpaddingTop: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-top")
        );
        let divpaddingbottom: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
        );
        let divpaddingleft: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-left")
        );
        let divpaddingright: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-right")
        );
        setPaddingValTop(divpaddingTop);
        setPaddingValBottom(divpaddingbottom);
        setPaddingValRight(divpaddingleft);
        setPaddingValLeft(divpaddingright);

        // console.log("Padding"+ divpadding);
        console.log("Innerwidth" + window.innerHeight);

        if (
          document.getElementsByClassName("mobile-footer") &&
          document.getElementsByClassName("mobile-footer").length > 0
        )
          setChatHeight(
            window.innerHeight -
            (divpaddingTop +
              divpaddingbottom)
          );
        else setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
        setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
        scrollTOBottom();
  }
  const getHeight = () => {
    
    let doc: any = document;
    let div: any = document.querySelector("#main");
    if (!div) return
    let divpaddingbottom: any = parseInt(	
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")	
    );	
    let divpaddingTop: any = parseInt(	
      window.getComputedStyle(div, "").getPropertyValue("padding-top")	
    );	
    setMobileFooterBottom(divpaddingbottom);	
    setMobileHeaderTop(divpaddingTop)

    if (doc.getElementsByClassName("mainchat")[0]) {
      let ht = doc.getElementsByClassName("mainchat")[0].clientHeight;
      let footertop;

      let headerbottom = document.getElementsByClassName('header-wrapper')[0]?.clientHeight;
      if (doc.getElementsByClassName("mobile-footer")[0]) {
        footertop=doc.getElementsByClassName('mobile-footer')[0].offsetTop;
        if(config.REACT_APP_IS_CROSS_PLATFORM) {
          doc.getElementsByClassName('mainchat')[0].style.height= "100%"
        } else {
          doc.getElementsByClassName('mainchat')[0].style.height=(footertop+headerbottom)+"px"
        }
      }
      
      else{
        if(config.REACT_APP_IS_CROSS_PLATFORM) {
          doc.getElementsByClassName('mainchat')[0].style.height= "100%"
        } else {
          doc.getElementsByClassName('mainchat')[0].style.height=(headerbottom)+"px"
        } 
      }
     

      //   setInfiniteScrollHeight((footertop-headerbottom));
    }
  };
  useEffect(() => {
    setIsWhatsappTimeExpired(false)
    console.log(chatId);
    console.log("inchatId");
    if (showChat && chatId) {
      setHasMore(true);
      setAttachedFiles([])
      getMessageData(props.messageData, false, 1);
      getUserInfo();
    }
    let doc: any = document;
    // checkFirstMessages();

    // console.log(ht);

    //  if(doc.getElementsByClassName('mainchat')[0])
    //   {

    //     let headerbottom=doc.getElementsByClassName('header')[0].offsetTop+document.getElementsByClassName('header')[0].clientHeight;
    //     let footertop=doc.getElementsByClassName('footer')[0].offsetTop;
    //     // doc.getElementsByClassName('mainchat')[0].style.height=(footertop-headerbottom)+"px"

    //     doc.getElementsByClassName('mainchat')[0].style.height=(footertop-headerbottom)+"px"
    //     // console.log(calValue)
    //     // setMaichatscrollHeight( calValue)
    //     // let ht=doc.getElementsByClassName('mainchat')[0].clientHeight
    //   // console.log(ht);
    //   setInfiniteScrollHeight(footertop-headerbottom);
    //   }

    // let doc:any=document;
    // doc.getElementById('headerVal').style.display="none !important";
    // doc.getElementById('MobHeader').style.display="none !important";
    // doc.getElementsByClassName('chatListDiv')[0].style.display="none !important";
    return () => {
      setMessageData([]);
    };
  }, [chatId, showChat]);
//   useEffect(()=>{
//     console.log(chatId.toString(),chatPusher)
//     if(chatPusher && chatPusher.bind){
        
//         chatPusher.unbind(chatId.toString());
//         //debugger

//         chatPusher.bind(chatId.toString(), (data) => {
            
//             let t=window.localStorage.getItem('sentFromWindow')
//             if(t!="true")
//             {
//                 dispatch(setRefreshChatList(true))
//                 dispatch(setRefreshChatComponent(true))
//             }
//         }) 
//     }
// },[chatId,chatPusher])
  // useEffect(()=>{
  //   console.log('i am chaned')
  //   let doc:any=document

  //   if( doc.getElementsByClassName('mainchat') &&doc.getElementsByClassName('mainchat').length>0)
  //   {
  //     console.log('hi')
  //     doc.getElementsByClassName('mainchat')[doc.getElementsByClassName('mainchat').length-1].scrollTop=0
  //   }
  // },[messageData])

  const ChatPageHeight = () => {
    // let div:any=document.querySelector('#main')
    // let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    // let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    // let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    // let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    // setPaddingValTop(divpaddingTop);
    // setPaddingValBottom(divpaddingbottom);
    // setPaddingValRight(divpaddingleft);
    // setPaddingValLeft(divpaddingright);

    // console.log("Padding"+ divpadding);
    console.log("Innerwidth" + window.innerHeight);
    //   if(doc.getElementsByClassName('besttime-banner')[0]!=undefined&&doc.getElementsByClassName('footer')[0]!=undefined)
    //   { debugger;
    //   console.log("Banner"+doc.getElementsByClassName('besttime-banner')[0].offsetHeight);
    //   console.log("Header"+doc.getElementsByClassName('header')[0].offsetHeight);
    //   console.log("search"+doc.getElementsByClassName('footer')[0].offsetHeight);

    //  setChatHeight(window.innerHeight-(divpadding+divpadding))
    //   }
    // setChatHeight(window.innerHeight-(divpaddingTop+divpaddingbottom))
    //  setChatwidth(window.innerWidth-(divpaddingright+divpaddingleft))
  };
  const getNumberFromItem = (item) => {
    if(item){
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    } else return "";
  }
  else return "";
  };

  const optInOut = (e) => {
    if (e == "Y") {
      setOptedOut(false);
      console.log("optout",e)

    } else if (e == "N") {
      setOptedOut(true);
      console.log("optout",e)

    }
  };


  useEffect(() => {
    
    if (refreshChatComponent) {
      // setAttachedFiles([])
      getMessageData(props.messageData, false, 1);
      dispatch(setRefreshChatComponent(false));
    }
  }, [refreshChatComponent]);
  const sendMessage = (contactData) => {
    if (attachedFiles.length) {
      let { errorMsg } = validateGetfiles(attachedFiles);
      if (errorMsg) {
        setErrorMessages([...errorMessages, errorMsg]);
        setTimeout(function () {
          setErrorMessages([]);
          setAttachedFiles([]);
          setDisableSendButton(false);
        }, 2000);
        return;
      }
    }
    window.localStorage.setItem('sentFromWindow',"true");
    // document.querySelectorAll('textarea').forEach((element) => {
    //     element.blur();
    // });
    setDisableSendButton(true);
    console.log(contactData);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
    // setTempTemplateDataIfNoNumber(null);
    // if(!phone){
    //     setNoNumber(true)
    //     setShowDetails(true)
    //     dispatch(setShowErrorSnackBar(true));
    //     return; 
    // }
    // setNoNumber(false)
    let formData = new FormData();

    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }

    formData.append("template", chatInput);
    formData.append("Campaign_ID", default_campaign_id);
    formData.append("wave_id", default_wave_id);
    formData.append("product_id", default_product_id);

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    if (attachedFiles.length > 0) {
      setShowAttachLoader(true);
      for (var i = 0; i < attachedFiles.length; i++) {
        let file = attachedFiles[i];
        formData.append("file", file);
      }
    }

    // sendMessageApi(formData,(res, msgData)=>{
    //     console.log(msgData)
    //     setAttachedFiles([]);
    //     setChatInput('')
    //    })
    checkWhatsappValidation({recipient_id: chatId, recipient_phone: phone, role_id: chatIsPrescriber ? 0 : 1}, (res, msgData) => {
      if (res && msgData && !msgData.success) {
        setDisableCallButton(true);  
        setIsWhatsappTimeExpired(true)
        setShowAttachLoader(false);
      }else{
        setDisableCallButton(false);
        setIsWhatsappTimeExpired(false)
        sendMessageApi(formData, (res, msgData) => {
          console.log(res);
          
          if (msgData) {
            if (msgData.message === "Inappropriate message body") {
              setDisableSendButton(false);
              setShowAttachLoader(false);
              console.log(msgData.message);
              let t: any = [];
              t.push(msgData.message);
              errorMessages.map((x) => {
                t.push(x);
                return x;
              });
              setErrorMessages(t);
              setTimeout(function () {
                setErrorMessages([]);
              }, 2000);
              
            } else if (msgData.message === "Conversation window closed, please send approved template first") {
              setDisableSendButton(false);
              setShowAttachLoader(false);
              console.log(msgData.message);
              let t: any = [];
              t.push(msgData.message);
              errorMessages.map((x) => {
                t.push(x);
                return x;
              });
              setErrorMessages(t);
              setTimeout(function () {
                setErrorMessages([]);
              }, 2000);
              
            }
          } else if (res) {
            // console.log(msgData.message)
            setShowAttachLoader(false);
            // updateElSize('');
            setDisableSendButton(false);
            setAttachedFiles([]);
            setChatInput("");
            getMessageData(props.messageData, false, 1);
            dispatch(setRefreshChatList(true));
            dispatch(setShowSuccessSnackBar(true));
            let doc:any=document;
            doc.getElementById('footerinput').style.height="0px"
            scrollTOBottom();
          } else {
            setDisableSendButton(false);
            setShowAttachLoader(false);
            setAttachedFiles([]);
            setChatInput("");
            if (msgData !== undefined) dispatch(setShowErrorSnackBar(true));
          }
        });
      }
    })

   

    //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{

    //     setAttachedFiles([]);
    //     setChatInput('')
    // }).catch(res=>{
    //     setAttachedFiles([]);
    //     setChatInput('')
    // })

    //     else{
    //         debugger
    //         formData.append("recipient_id", data['Recipient Sender Id']);
    //         formData.append("recipient_name", data['Sender Name']);
    //         formData.append("recipient_phone", contactData['AcctPh_Nbr']);
    //         formData.append("template",chatInput);
    //         formData.append("Campaign_ID",'1000');
    //         formData.append('wave_id','1000')
    //        formData.append('product_id','1000');
    //         formData.append("physcian_id",contactData['Prescriber_Id'] );
    //        formData.append("role_id", '0');//for prescriber
    //        if (!isSMS) {
    //         formData.append("channel", "SMS");
    //        } else {
    //         formData.append("channel", "whatsa");
    //        }

    //    if(attachedFiles.length>0){
    //     for (var i = 0; i < attachedFiles.length; i++) {
    //         let file = attachedFiles[i];
    //         formData.append("file", file);
    //       }
    //    }

    //    sendMessageApi(formData,(res)=>{
    //     setAttachedFiles([]);
    //     setChatInput('')
    //     doc.getElementById("footerinput").style.height = "auto"
    //    })
    //     //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //     //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    //     // }).then((res)=>{

    //     //     setAttachedFiles([]);
    //     //     setChatInput('')
    //     //     doc.getElementById("footerinput").style.height = "auto"
    //     // }).catch(res=>{
    //     //     setAttachedFiles([]);
    //     //     setChatInput('')
    //     // })
    //     }

    //  formData.append("physcian_id", data.physcian_id);
    //  formData.append("role_id", data.type);
    //  formData.append("template", data.template);
    //  formData.append("Campaign_ID", 1000);
    //  formData.append("template_id");
    //  formData.append('wave_id',data.)

    //  if (this.switchChecked) {
    //    formData.append("communicationType", "whats app");
    //  } else {
    //    formData.append("communicationType", "sms");
    //  }
    //  // formData.append("file", this.files);
    //  for (var i = 0; i < this.files.length; i++) {
    //    let file = this.files[i];
    //    formData.append("file", file);
    //  }
  };


  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  // send selected file based on channel type 

const selectedFileSendMsg = (fileData)=>{
  // console.log('selectedFiles', fileData)
  let Urlstring:any;
   let tempChat:any;
  window.localStorage.setItem('sentFromWindow',"true");
  setDisableSendButton(true)
  // console.log(contactData);
  //setTempTemplateDataIfNoNumber(null);
  let cData = contactData ? contactData : {}
  let phone = getNumberFromItem(contactData);
  // if(!phone){
  //     setNoNumber(true)
  //     setShowDetails(true)
  //     dispatch(setShowErrorSnackBar(true));
  //     return; 
  // }
  // setNoNumber(false)
  let formData = new FormData();
  
  formData.append("recipient_id", chatId);
  formData.append("recipient_name", chatName);
  formData.append("recipient_phone", phone);
  if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", '0');//for prescriber
  }
  else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", '1');//for prescriber
      formData.append('association_id', cData.Prescribers[0].Association_Id)
  }

 Urlstring= fileData.map((x:any)=>{return (x.Item_url.split(',').join(' '))})
 tempChat = chatInput+" "+Urlstring.join(' ')
  formData.append("template", tempChat);
  formData.append("Campaign_ID", default_campaign_id);
  formData.append('wave_id', default_wave_id)
  formData.append('product_id', default_product_id);
  formData.append('urls',Urlstring)        

  if (!isSMS) {
      formData.append("channel", "SMS");
  } else {
      formData.append("channel", "whatsa");
  }
  // if (fileData.length > 0) {
  //      setShowAttachLoader(true);
  //     for (var i = 0; i < attachedFiles.length; i++) {
  //         let file = fileData;
  //         formData.append("file", file);
  //     }
  // }

  sendMessageApi(formData, (res, msgData) => {
      // console.log(res)
      //debugger
      // setDisableSendButton(true)
      if (msgData) {
          if (msgData.message === "Inappropriate message body") {
              setDisableSendButton(false)
              setShowAttachLoader(false);
              let t: any = [];
              t.push(msgData.message)
              errorMessages.map(x => {
                  t.push(x)
                  return x;
              })
              setErrorMessages(t)
              setTimeout(
                  function () {
                      setErrorMessages([])
                  }
                  ,
                  2000
              );
              
          }
      }
      else if (res) {
          // console.log(msgData.message)
          
          setDisableSendButton(false);
          setShowAttachLoader(false);
          setAttachedFiles([]);
          setChatInput('')
          getMessageData(props.messageData, false, 1);
          dispatch(setRefreshChatList(true))
          let doc:any=document;
          doc.getElementById('footerinput').style.height="0px"
          dispatch(setShowSuccessSnackBar(true));
          
      }
      else {
          setDisableSendButton(false);
          setShowAttachLoader(false);
          setAttachedFiles([]);
          setChatInput('')
          dispatch(setShowErrorSnackBar(true));
      }
  })
}

const pickFiles = async () => {
  const result = await FilePicker.pickFiles({
    types: supportedFileTypes,
    limit: 1,
    readData: false
  });
  const file:any = result.files[0];
  console.log("file size>>>>>"+file.size);

  let errorMsg;
  if (file.size > MAX_SIZE_LIMIT) {
    errorMsg = `File size exceeds ${MAX_SIZE_LIMIT / 1024 / 1024}MB`;
  }
  if (errorMsg) {
    setErrorMessages([...errorMessages, errorMsg]);
    setUploadAttachmentPopover(false);
    setTimeout(function () {
      setErrorMessages([]);
      setAttachedFiles([]);
      setDisableSendButton(false);
    }, 2000);
    return;
  }

  const { data } = await Filesystem.readFile({path: file.path});
  console.log("file>>>>>"+JSON.stringify(data));
  const blob = base64ToBlob(data, file.mimeType)
 
  const fileObj = new File([blob], file.name, { type: file.mimeType });
  getFiles({ target: { files: [fileObj] } });
};

const pickPhoto = async () => {
  const images = await Camera.pickImages({
    quality: 90,
    limit: 1,
  });
 
  const image = images.photos[0];
  const response = await fetch(image.webPath);
  const blob = await response.blob();
 
    const fileName = `photo.${blob.type.split("/")[1]}`;
    const file = new File([blob], fileName, { type: blob.type });
    getFiles({ target: { files: [file] } });

  };

  const base64ToBlob = (base64: any, contentType: any) => {
    const byteCharacters = atob(base64);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  if (props.showActiveChatPage) {
    return (
      <div
        className="mobileChatPage msg-component-div"
        style={{
          width: chatWidth + "px",
          height: chatHeight + "px",
          //top: !isAndroid ? window.visualViewport?.offsetTop : undefined,
        }}
      >
        {showDetails && !unknown ? 
        isHospitalContact?
        (
          <ContactDetialsModalGAM
            showChatIcon={false}
            showDetails={showDetails}
            showAddContactModal={(e, trueorFalse) => {setContactData(e);setAddNumberPopup(true)}}
            contact={contactData}
            handleCloseParent={(post: any, showDelete: any) => {
              setContactData(post);
              getUserInfo();
              setShowDetails(false);
              setDisableSendButton(false);
              setFinished(false);
              // if (noNumber && post.AcctPh_Nbr) {
              //   tempTemplateDataIfNoNumber
              //     ? selectedTemplate(tempTemplateDataIfNoNumber, post)
              //     : sendMessage(post);
              // }
            }}
            fetchDetails={true}
            showCallMakerModal={(e) => {
              console.log(e);
              setShowDetails(false);
              dispatch(setCallModalView(true));
            }}
          />
        ) 
        :
        (
          <ContactDetialsModal
          showChatIcon={false}
          showDetails={showDetails}
          showAddContactModal={(e, trueorFalse) => {setContactData(e);setAddNumberPopup(true)}}
          contact={contactData}
          handleCloseParent={(post: any, showDelete: any) => {
            setContactData(post);
            getUserInfo();
            setShowDetails(false);
            setDisableSendButton(false);
            setFinished(false);
            // if (noNumber && post.AcctPh_Nbr) {
            //   tempTemplateDataIfNoNumber
            //     ? selectedTemplate(tempTemplateDataIfNoNumber, post)
            //     : sendMessage(post);
            // }
          }}
          fetchDetails={true}
          showCallMakerModal={(e) => {
            console.log(e);
            setShowDetails(false);
            dispatch(setCallModalView(true));
          }}
          editted={()=>{getMessageData(props.messageData, false, 1)}}
            />
        )
        
        : null}

        <CallModal
          // show={showCallModal}
          data={contactData}
          handleCloseParent={(e) => {
            console.log(e);
            setShowDetails(false);
            // setShowCallModal(false)
            dispatch(setCallModalView(false));
          }}
        />
        <MediaChatChannelModal show={mediaChannelPopup} handleClose={()=>{setMediaChannelPopup(false)}} selectedFileSendMsg={selectedFileSendMsg}/>
        {openSaveContacts ? (
          isHospitalContact ?
          <SaveContactsForChatGAM
            unknownid={chatId}
            number={chatName}
            deletedOthers={deletedOthers}
            show={openSaveContacts}
            handleCloseParent={(torf, data: any) => {
              
              console.log(data);
              saveUnknown(data);
              setOpenSaveContacts(false);
            }}
          />:
          <SaveContactsForChat
            unknownid={chatId}
            number={chatName}
            deletedOthers={deletedOthers}
            show={openSaveContacts}
            handleCloseParent={(torf, data: any) => {
              
              console.log(data);
              if(config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER == "N"){saveUnknown(data)}
              // saveUnknown(data);
              setOpenSaveContacts(false);
            }}
          />
        ) : null}
        <PreApprovedTemplates
          show={showPreApproveTemplates}
          selectedTemplate={(e) => {
            selectedTemplate(e, contactData);
            // updateElSize('');
          }}
          handleCloseParent={(e) => {
            
            setPreApproveTemplates(false);
          }}
          contact={contactData}
          isOptedIn={getOptinBadgeStatus(optinStatusFlag)}
        />
        <div className="header-wrapper">
          {/* Add Key vault  */}
          <div
            className="besttime-banner"
            style={{ display: BestTimeFlag === "Y" ? "block" : "none" }}
          >
            <h5 className="bannertext m-0">
              <b> {t('Best time to connect: 10:00 AM - 03:30 PM')}</b>
            </h5>
          </div>

          <div className="header d-flex">
            <Link 
                
                
                onClick={()=>{
                  dispatch(setChatShowChat(false)); 
                  // dispatch(setRefreshChatList(true));
                }}
                to={window.location.href=="/Home"?"/Chat":pageSelected}
              >
              <i
              className="fas fa-chevron-left i-2">
              
              {" "}
            </i>
            </Link>
            <div className=" ms-3 m-2">
              {/* <Avatar {...stringAvatar(chatName)} /> */}
              <Avatar
                // imageType={
                //   chatIsPrescriber ? "prescriber" : "prescriberassociates"
                // }
                imageType={type}
                avatarHeight="2.3rem"
                avatarWidth="2.3rem"
                presOrAssoc={chatName}
                className="bg-white"
                isOptedIn={getOptinBadgeStatus(optinStatusFlag)}
              />
            </div>
            <div className="contact-details m-2"  onClick={(config.REACT_APP_SHOW_INFO_BTN == "N" && (!deletedOthers && finished || !deletedOthers && unknown))?() => { setShowDetails(true) }:()=>{}}>
              <div className="d-flex align-items-center">
              <h5 className="contacttext mb-0">
                {chatName ? truncate(getFormatedPhoneNumber(chatName), 15) : "Unknown"}
              </h5>
              
              
              
              </div>
              {/* confirm popup for archive and unarchive */}
              <ConfirmPopup
                show={showConfirmPopup}
                confirmButtonText={t("Yes")}
                cancelButtonText={t('No')}
                clickConfirmButtonHandler={() => {
                  setIsArchiveConfirmLoading(true);
                  archiveUnarchiveChatApi({
                    id: chatId, isArchive: isArchive === "Y" ?  "N" : "Y"
                  },(res) => {
                    if(!res) {
                      dispatch(setShowErrorSnackBar(true));
                      return;
                    }
                    setShowConfirmPopup(false);
                    dispatch(setShowSuccessSnackBar("Chat Archived Successfully"));
                    const payload = {
                      chatId,
                      chatName,
                      chatIsPrescriber,
                      showChat,
                      isArchive: isArchive === "Y" ? "N" : "Y"
                    }
                    dispatch(setChatDetails(payload))
                    setIsArchiveConfirmLoading(false);
                  })
                }}
                clickCancelButtonHandler={() => {
                  setShowConfirmPopup(false);
                }}
                text={isArchive === "Y" ? t('Are you sure you want to unarchive the chat?') : t('Are you sure you want to archive the chat?')}
                disabled={isArchiveConfirmLoading}
              />
              {/* confirm popup for delete */}
              <ConfirmPopup
                show={showDeleteConfirmPopup}
                confirmButtonText={t("Yes")}
                cancelButtonText={t('No')}
                clickConfirmButtonHandler={() => {
                  setIsShowDeleteConfirmLoading(true);
                  deleteChatApi({
                    id: chatId
                  },(res) => {
                    if(!res) {
                      return;
                    }
                    setIsShowDeleteConfirmLoading(false);
                    setShowDeleteConfirmPopup(false);
                    dispatch(setChatShowChat(false)); 
                  })
                }}
                clickCancelButtonHandler={() => {
                  setShowDeleteConfirmPopup(false);
                }}
                text={t('Are you sure you want to delete the chat?')}
                disabled={isShowDeleteConfirmLoading}
              />
              <div className="d-flex justify-content-between mt-0">
                              {
                                location && 
                                <div
                                >
                                  <PlaceIcon style={{
                                    fontSize:"0.9rem"
                                  }}/>
                                  <span style={{
                                    fontSize:"0.9rem"
                                  }}>
                                    {truncate(location, 8)}
                                  </span>
                                  </div>
                              }
                              {
                                speciality && 
                                <div>
                                  <SchoolIcon style={{
                                    fontSize:"0.9rem",
                                    marginRight: '3px'
                                  }}/>
                                  <span style={{
                                    fontSize:"0.9rem"
                                  }}>
                                    {speciality}
                                  </span>
                                  </div>
                              }  
                            </div>
            </div>
            <div className="headerright">
            <section className=" d-flex justify-content-between align-items-center">
                {!deletedOthers && !noNumber && unknown && chatName && (
                <SpamButton
                  isSpam={isSpam}
                  onClick={handleSpam}
                  data={parsePhoneNumber(chatName || "")}
                />
                )}
                {
                unknown && (isArchive === "Y" ? (
                <>
                  <svg
                    height="25"
                    width="25"
                    className="ms-2 me-2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      setShowConfirmPopup(true);
                      }
                    }
                  >
                    <g>
                      <path fill="#fff" d="M0 0h24v24H0z" />
                      <path
                        d="M20 3l2 4v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.004L4 3h16zm-8 7l-4 4h3v4h2v-4h3l-4-4zm6.764-5H5.236l-.999 2h15.527l-1-2z"
                        fill-rule="nonzero"
                        fill="#734BD1"
                      />
                    </g>
                  </svg>
                  <svg
                    style={{
                      color: "#734BD1",
                    }}
                    width="25"
                    height="20"
                    className="ms-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    onClick={() => {
                      setShowDeleteConfirmPopup(true)
                    }}
                  >
                    <title>trash</title>
                    <g fill="none" className="nc-icon-wrapper">
                      <path
                        d="M5.29 1.733h9.864c1.264 0 2.29.96 2.29 2.143 0 .093-.08.168-.18.168H3.18c-.1 0-.18-.075-.18-.168 0-1.183 1.026-2.143 2.29-2.143z"
                        fill="#734BD1"
                      ></path>
                      <path
                        d="M9.413 0h2.196c.989 0 1.79.776 1.79 1.733H7.623C7.622.776 8.424 0 9.413 0z"
                        fill="#734BD1"
                      ></path>
                      <path
                        d="M16.177 5H4.267a.157.157 0 0 0-.155.17l.987 12.643C5.195 19.047 6.212 20 7.434 20h5.577c1.222 0 2.239-.953 2.335-2.187l.987-12.642A.158.158 0 0 0 16.178 5h-.001zM7.16 17.59a.61.61 0 0 1-.645-.573L5.88 7.16a.612.612 0 0 1 .566-.654.61.61 0 0 1 .645.573l.635 9.857a.612.612 0 0 1-.565.654zm3.667-.7a.61.61 0 0 1-.606.615.611.611 0 0 1-.606-.615V7.03a.61.61 0 0 1 .606-.615.61.61 0 0 1 .606.615v9.86zm3.404-9.731l-.636 9.857a.61.61 0 0 1-.605.575c-.013 0-.027 0-.04-.002a.612.612 0 0 1-.565-.653l.635-9.857a.611.611 0 0 1 .645-.574.612.612 0 0 1 .566.654z"
                        fill="#734BD1"
                      ></path>
                    </g>
                  </svg>
                </>
                ) : ( 
                <svg
                  style={{
                    color: "#734BD1",
                  }}
                  height="25"
                  className="ms-2 me-2"
                  viewBox="0 0 48 48"
                  width="25"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setShowConfirmPopup(true);
                  }}
                >
                  <path
                    fill="#734BD1"
                    d="M41.09 10.45l-2.77-3.36c-.56-.66-1.39-1.09-2.32-1.09h-24c-.93 0-1.76.43-2.31 1.09l-2.77 3.36c-.58.7-.92 1.58-.92 2.55v25c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-25c0-.97-.34-1.85-.91-2.55zm-17.09 24.55l-11-11h7v-4h8v4h7l-11 11zm-13.75-25l1.63-2h24l1.87 2h-27.5z"
                  />
                  <path d="M0 0h48v48h-48z" fill="none" />
                </svg>
                ))
              }
              {
                unknown &&  <Tooltip title="" arrow>
                  <SaveIcon
                    onClick={() => {
                      setOpenSaveContacts(true);
                    }}
                    style={{ fill: "var(--primary)", fontSize: "25px",marginLeft:'0.9rem' }}
                  />
                </Tooltip>
              }
              </section>
              
            {messageChannelType === true && config.REACT_APP_SHOW_CHANNEL_TYPE_TOGGLE_BTN ==="Y" ? (
                <label className="autopilot-switch font-sm me-3">
                  <input
                    type="checkbox"
                    className="font-sm"
                    id="msg-wtsappChechedId"
                    checked={isSMS}
                    onChange={(e) => {
                      setIsSms(e.target.checked);
                    }}
                  />
                  <span
                    id="msg-wtsapptoggle"
                    className="b-slider b-round"
                  ></span>
                </label>
              ) : null}

            {noNumber &&  !changeTerritoryId && !deletedOthers && finished 
        && !numberAssigned ?null:
                        (config.REACT_APP_CALL_BUTTON_ENABLED==='Y' && !deletedOthers && finished && !unknown && !(changeTerritoryId && config.REACT_APP_ABS_CONFIG_ALLOWCALL =='N')) ?(
                          <Tooltip title={t('Call')} arrow>
                          <button className="header-callbtn" onClick={()=>{
                                   !noNumber && dispatch(setCallModalView(true))}
                                   } 
                                   disabled={getOptinBadgeStatus(optinStatusFlag) !== "G" || disableCallButton}
                                   ><CallIcon style={{width:"20px", height:"20px"}}/></button>
                             </Tooltip>
                    ):null
                    }
            
             
              {
               !unknown && config.REACT_APP_SHOW_INFO_BTN == "Y" ? 
               (<div>
              {((changeTerritoryId == true && type=='ASSPRS') || changeTerritoryId == false) && finished ? <Tooltip title={t('')} arrow>
              <i
                  id="infoBtnId"
                  data-v-4d521fc4=""
                  data-name="info"
                  data-tags=""
                  data-type="info"
                  className="feather feather--info i-2p5 p-0"
                  onClick={() => { setShowDetails(true) }}

              ><svg
                  style={{ color: '#734bd1',verticalAlign:'sup',display:'flex'  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"

                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-info feather__content"
              >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="16" x2="12" y2="12"></line>
                      <line x1="12" y1="8" x2="12.01" y2="8"></line>
                  </svg>
              </i>
          </Tooltip>
              :null}
              </div>):
            null
        }
            </div>
          </div>
        </div>
        {noNumber &&  !changeTerritoryId && !deletedOthers && finished 
        && !numberAssigned
        ? 
        (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Assign number to reply")}.</li>
            </ul>
          </div>
        ) : null}
        {
                changeTerritoryId && !deletedOthers && type!='UNKN' && config.REACT_APP_ABS_CONFIG_SHOWBANNER=='Y'? (
                    <div className="limit-msg error-footer optOut">
                        <ul>
                            {type!='ASSPRS'?<li>
                                {t('Chat unavailable, territory/HCP mismatch')}.
                            </li>:<li>
                                {t('Territory/HCP mismatch')}.
                            </li>}
                        </ul>

                    </div> ): null
            }
        {(unknown || type=="UNKN") && !deletedOthers ? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
               <li>{isSpam ? t('This number is blocked - contact Admin with questions') : t("Save contact to reply.")}</li>
            </ul>
          </div>
        ) : null}
        {deletedOthers 
        //  && !noNumber 
         && finished
         ?

         (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Contact has been deleted")}.</li>
            </ul>
          </div>
        ) : null}
         { optedOut && !noNumber && finished && !deletedOthers? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
              <li>{t("Recipient Opted Out")}.</li>
            </ul>
          </div>
        ) : null}
        {isNumberLandline ? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
              <li>{t("This is a landline number")}</li>
            </ul>
          </div>
        ) : null}
        {!unknown && !deletedOthers && isWhatsappTimeExpired && !optedOut && !(noNumber &&  !changeTerritoryId && !deletedOthers && finished && !numberAssigned)?(
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
            {
              showConversationBanner ?
              <li>
                 {
                 showNewConversationWindowBanner ?
                  "Por favor espera hasta que el profesional responda para poder continuar la conversación. Si no lo hace, puedes volver a enviar una plantilla aprobada"
                    :
                  t("Conversation window closed, please send approved template first")
                  }
              </li>
              :
              null
            }
            </ul>
          </div>
        ) : null}
        {/* main - all the msg conv*/}
        {showAttachLoader && <span className="loader"></span>}

        <div
          className="shadowin  mainchat"
          id="scrollableDiv"
          style={{
            overflowY: "auto",
            marginBottom: mainChatBottom
          }}
        >
          <InfiniteScroll
            dataLength={messageData.length}
            next={() => {
              console.log("next");
              setHasMore(false);
              //    getMessageData(props.messageData,true,page+1)
              //    setPage(page+1)
            }}
            refreshFunction={() => {
              console.log("reverse");
            }}
            inverse={true} //
            style={{
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              // flexDirection: "column-reverse",
            }}
            // scrollableTarget="contacts-body"
            hasMore={hasMore}
            loader={
              <div className="spinnerCircle">
                <CircularProgress color="inherit" size={"2rem"} />
              </div>
            }
            // height={infiniteScrollHeight}

            scrollableTarget="scrollableDiv"
            //  endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     {messageData.length == 0 ? <span>No data</span> : <span></span>}
            //   </p>
            // }
          >
            {/* <div className='innerChat'> */}
            {messageData.map((x: any) => (
              <TestMessage
                message={x}
                key={x["Recipient Msg Start"]}
                msgPusherSubscriber={pusherMsgProps}
                handleImgPopup={(imgdisurl) => handleImgPopup(imgdisurl)}
                avatarchatName ={chatName}
                contactData={contactData}
              />
            ))}
            {/* </div> */}
          </InfiniteScroll>
          {/* {
                               messageData.map((x:any)=>(<Message message={x}/>))
                              } */}
        </div>
        {errorMessages.length > 0 ? (
          <div className="limit-msg error-footer error-footer-mobile" style={{top:config.REACT_APP_IS_CROSS_PLATFORM?disableSendButton?'-7.68rem':'-4.5rem':'0'}}>
            <ul>
              {errorMessages.map((error: String) =>
                error === "" ? null : (
                  <li>
                    {t(`${error}`,{error})} <br />
                  </li>
                )
              )}
            </ul>
          </div>
        ) : null}

        {!unknown && !noNumber && !isNumberLandline && !(changeTerritoryId && config.REACT_APP_ABS_CONFIG_ALLOWCHAT=='N')
        ?
          (
          <div
            className="footer mobile-footer"
            style={{
              backgroundImage: `url(${Background})`,
              //bottom: "0px"
              // bottom: mobileFooterBottom,
            }}
          >
            <div className="insidefooter d-flex" style={{ color: "var(--primary-color)" }}>
              <div style={{ display: "none" }}> {errorMessages.length}</div>
              <div
                onClick={() => {
                  console.log("hi");
                  setPreApproveTemplates(true);
                  setAttachedFiles([])
                }}
              >
                <i className="btn far fa-newspaper i-2 get-template-btn"></i>
              </div>
              {config.REACT_APP_ATTACHMENT_ENABLED == "Y" &&  (!isWhatsappTimeExpired && getOptinBadgeStatus(optinStatusFlag) === "G") && (
                <button
                  className="btn"
                  type="button"
                  // onClick={() => {
                  //   let doc: any = document;
                  //   doc.getElementById("attachedfiles").click();
                  // }}
                > 
                 <Popover
                                content={getUploadContent}
                                placement="bottomLeft"
                                //   title="Title"
                                trigger="click"
                                  open={uploadAttachmentPopover}
                                  onOpenChange={()=> setUploadAttachmentPopover(!uploadAttachmentPopover)}
                            >
                                                <i className="fas fa-paperclip i-2"></i>

                            </Popover>
                </button>
              )}
              <label>
                <input
                  type="file"
                  id="attachedfiles"
                  name="attachedfiles"
                  className="custom-file-input"
                  // multiple
                  accept={supportedFileTypes.join(", ")}
                  style={{ display: "none" }}
                  onChange={getFiles}
                  // onClick={getFiles}
                />
              </label>
             
              {/* <input id='footerinput' type="text" placeholder="Type your message..." value={chatInput} onChange={(e)=>setChatInput(e.target.value)} /> */}
              <textarea
                id="footerinput"
                placeholder={t(`Type your message`)+"..."}
                //  onBlur={getHeight}
                onPaste={resizeInputBox}
                value={chatInput}
                onChange={(e) => {
                  const emojiRegex = /\uD83C\uDFF4(\uDB40[\uDC61-\uDC7A])+\uDB40\uDC7F|(\ud83c[\udde6-\uddff]){2}|([\#\*0-9]\ufe0f?\u20e3)|(\u00a9|\u00ae|[\u203c-\u3300]|[\ud83c-\ud83e][\ud000-\udfff])((\ud83c[\udffb-\udfff])?(\ud83e[\uddb0-\uddb3])?(\ufe0f?\u200d([\u2000-\u3300]|[\ud83c-\ud83e][\ud000-\udfff])\ufe0f?)?)*/g;
                  let inputValue=e.target.value;
                  let sanitizedValue:string;
                  if (emojiRegex.test(inputValue)) {
                      sanitizedValue = inputValue?.replace(emojiRegex, "");
                      setErrorMessages(["Emojis are not allowed"]);
                      setTimeout(
                          function () {
                              setErrorMessages([])
                          }
                          ,
                          1000
                      );
                  }else{
                      sanitizedValue=inputValue;
                  }
                  setChatInput(sanitizedValue);           
                }}
                rows={1}
                maxLength={1500}
                //   ref="textareaChat"
                //  onFocus={getHeight}
                // onKeyUp={updateElSize}
                // onFocus={(e)=>{
                //   console.log(e)
                //   e.target.scrollTo({top:0})
                // }}
                // max-length="1500"
                disabled={optedOut}
              ></textarea>
              <div className="mobileFooterSend">
                <button
                  className="btn btn-light sendbutton"
                  disabled={
                    ((chatInput.length <= 0
                      ? true
                      : !chatInput.replace(/\s/g, "").length
                      ? true
                      : false) &&
                      attachedFiles.length <= 0) ||
                    attachedFiles.length > 10 ||
                    disableSendButton || isWhatsappTimeExpired || getOptinBadgeStatus(optinStatusFlag) !== "G"
                  }
                  onClick={() => {
                    sendMessage(contactData);
                  }}
                >
                  <i
                    data-v-4d521fc4=""
                    data-name="send"
                    data-tags="message,mail,email,paper airplane,paper aeroplane"
                    data-type="send"
                    className="feather feather--send i-2"
                  >
                    <svg
                      style={{ color: 'var(--primary-color)' }}
                      data-v-4d521fc4=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send feather__content"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  </i>
                </button>
              </div>
            </div>

            {attachedFiles.length > 0 ? (
              <div className="attached-media grid-container">
                {attachedFiles.map((file: any, index) => (
                  <div key={file.name + "-" + file.type + "-" + index}>
                    <div className="media-tb">
                      <div className="media-left d-flex">
                        <i className="far fa-file-alt"></i>
                      </div>

                      <div>{file.name}</div>

                      <div>
                        <i
                          className="fas fa-times"
                          onClick={() => removeFile(index)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}

        {/* footer */}

        {/* footer ends here */}

        {/* popup start here */}
        {showImgPopup ? (
          <div className="mobileimagePopup">
            <div className="mobileouterdiv">
              <div className="mobileimageBox">
                <a
                  id="mobilecloseBtn"
                  className="mobilepopupBtn"
                  onClick={(e) => setShowImgPopup("")}
                >
                  <i className="fa fa-times"></i>
                </a>
                <img src={showImgPopup} alt="" />
                <a
                  id="mobiledownloadBtn"
                  className="mobilepopupBtn"
                  onClick={(e) => downloadWithAxios(showImgPopup)}
                >
                  <i className="fa fa-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {/* popup ends here */}
        <ContactAddPopup 
        showAddContactModal={addNumberPopup}
        data={contactData}
        redirectPage={false}
        handleCloseParent={(e)=>{
          // if(e){
          //   if(e.AcctPh_Nbr && e.IntlCallPfx_Nbr){
          //     let Temp=this.state.contactList;
          //     Temp[this.state.selectedContactIndex].AcctPh_Nbr=e.AcctPh_Nbr;
          //     Temp[this.state.selectedContactIndex].IntlCallPfx_Nbr=e.IntlCallPfx_Nbr;
          //     this.setState({showDetails:false,contactList:Temp})
          //   }  
          // }
          //setShowCallModalPopup(true);
          setShowDetails(false)
          setAddNumberPopup(false);
          getUserInfo()
          // this.setState({showAddContactModal:false, showDetails:false})}}
        }}
        />
      </div>
    );
  } else return null;
}

export default NewMobileChatComponent;
