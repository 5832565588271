import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ContactAlreadyAssign from '../../components/logoutModal/LogoutModal'
import { Keyboard } from '@capacitor/keyboard';
import { makeStyles } from "@mui/styles";


import Chip from "@mui/material/Chip";

import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import { parsePhoneNumber } from "react-phone-number-input";
// import ReactAutocomplete from "react-autocomplete";
import { addNewContact, checkNumberExist } from "../../apis/contacts/contactsApi";
import { useDebounce } from 'usehooks-ts'
import { useTranslation } from "react-i18next";
import Paper from '@mui/material/Paper';
import axios from "axios";
import config from '../../env.json'
import './SaveContactForChat.css'
import Button from "../../layouts/button/Button";
import { editContact } from "../../apis/contacts/contactsApi";
import { setChatType,setChatDetails,setRefreshChatList,setRefreshChatComponent } from "../../app/slice/chatSlice";
import { useDispatch,useSelector } from 'react-redux';
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
import { isValid } from "../../utils/CheckValidString";
import getErrorMessage from "../.././utils/getErrorCodeFromJsonFile";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: 'var(--primary-color)' ,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'var(--primary-color)' ,
  },
});
// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {

      "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
          // content: '"mandatory field"',
          color:'red'
        
      }
    }
  }
}));
export default function SaveContactsForChat(props) {
  const {unknownid,number, show, handleCloseParent,deletedOthers }=props;
  const { t } = useTranslation();
  const sRepRole=useSelector((state: any) => state.chat.repRole);
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState<any>({});
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [temp, setTemp] = useState("");
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [formType, setFormType] = useState(true);
  const [othersData,setOthersData]=useState([])
  const [othersDatahidden,setOthersDataHidden]=useState(false)
  const [selectedOthers,setSelecteedOthers]=useState<any>({})
  const [isAdd,setIsAdd]=useState(false);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
    const [showModal,setShowModal]=useState(false);
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [multiAssociatePrescriberCheck, setMutliAssociatePrescriberCheck]= useState<any>(true);
  const { chatName } = useSelector((state: any) => state.chat);
  const dispatch = useDispatch();
  let classes = useStyles();
  const [overrideMessage, setOverrideMessage] = useState('');
  const [clearDataOnDelete, setClearDataOnDelete] = useState(false)
  const [isValidString,setIsValidString]=useState(false);
  const [address, setAddress] = useState("")
  const [speciality, setSpeciality] = useState("")

  const [sRepName,setSRepName]=useState<any>('');
  const debouncedValueSRep = useDebounce<string>(sRepName, 350);
  const [sRepData,setSRepData]=useState<any>([]);
  const [srepDatahidden,setSRepDataHidden]=useState(false)
  const [selectedSRep,setSelecteedSRep]=useState<any>({})
  const [selectedPrescriberId,setSelecteedPrescriberId]=useState<any>()
  const [selectedAssocaitePrescriberId,setSelecteedAssocaitePrescriberId]=useState<any>()
  const [errorData, setErrorData] = useState('');
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);

  // const [srepDatahidden,setSRepDataHidden]=useState(false)
  useEffect(() => {
    setErrorData("*"+t(getErrorMessage('NameError')))
    setCountryList()

  },[]);
  const setCountryList = () => {
let userList: Array<any> = JSON.parse(config.REACT_APP_TEST_USER_LIST);
    let countryTestUserCountryList:Array<any>= config.REACT_APP_TEST_USER_COUNTRY_LIST?.split(",")?.map((code: string) =>
    code?.toLowerCase()
  )
    userList?.forEach((userId) => {
      if (userId === repId) {
        setCountries(countryTestUserCountryList);
      }
    });
  };
  useEffect(() => {
    console.log()
    setOthersPhone(number);
    if(config.REACT_APP_MULTI_PRES_ASSOCIATION){      
      setMutliAssociatePrescriberCheck(config.REACT_APP_MULTI_PRES_ASSOCIATION === "N"? false: true)
     }
     
     if(deletedOthers){
      setFormType(false)
      setOthersPhone('')
     }
  }, [number]);

  useEffect(() => {
    getModalDimensions();
  },[mobileStyle, modalHeight, modalWidth])

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
       Keyboard.addListener('keyboardDidShow', info => {
        getModalDimensions();
       });
 
       Keyboard.addListener('keyboardDidHide', () => {
        getModalDimensions();
       });
     }
  },[])
  
  function validatePhoneNumber(input_str) {
    var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  
    return re.test(input_str);
  }

  useEffect(()=>{
    if(validatePhoneNumber(chatName)==false && !unknownid)
    {
    setOthersName(chatName);
    }
  }, [])

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.setAccessoryBarVisible({ isVisible: true })
    }
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    getContactsData(debouncedValue);
   

    getModalDimensions();
    window.addEventListener('resize',(e) => {
      getModalDimensions()
    })
  }, [debouncedValue])
  useEffect(()=>{
    getSRepAdmin(debouncedValueSRep);
  },[debouncedValueSRep])

  useEffect(() => {
    window.addEventListener('orientationchange', getModalDimensions);
    return () => {
      window.removeEventListener('orientationchange', getModalDimensions)
    }
  }, [])

  const getModalDimensions=()=>{
    let div:any=document.querySelector('#main') 
    if (!div) return
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom);
      setModalWidth(window.innerWidth-divpaddingleft-divpaddingright); 
    }
  }
  const getContactsData = (value) => {
    ;
    if (value.length >= 2 && isAdd===false) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?ContactType=A&Page=" +
            0 +
            "&PageSize="+10+"&Search=" +
          value,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
            
          setOthersData(res.data.data.filter((x:any)=>x.Is_Prescriber=="N"))
          //setHiddenPresList(false);
          setOthersDataHidden(false);

          
        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };

  // get sRep data 
  const getSRepAdmin=(value)=>{
    
    let presriberOrAssociatePerscriberId = formType? selectedPrescriberId:(secPrescribers?secPrescribers[0]?.Id:selectedPrescriberId)
    console.log(selectedPrescriberId)
    console.log(value, value.length)
    if(value.length >= 2){
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact/bySalesRepId/?Id="+presriberOrAssociatePerscriberId,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          

          console.log(res.data)
          setSRepData(res.data.data)
          setSRepDataHidden(false)
          // setOthersData(res.data.data.filter((x:any)=>x.Is_Prescriber=="N"))
          // //setHiddenPresList(false);
          // setOthersDataHidden(false);

          
        });
    }
    
  }
  const setPrescriber = (x) => {
    setSelecteedPrescriberId(x.Id)
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setHiddenPresList(true);
    setDisabled(false);
    setAddress(x.Acct_Adr)
    setSpeciality(x.Acct_Splty)
  };

  const handleYOrNPopup =(e:any) =>{
    if(othersPhone){
      let contact:any=assoPresc
      let phn:any=parsePhoneNumber(othersPhone);
        let payload:any={
          AcctPh_Nbr:phn.nationalNumber,
          IntlCallPfx_Nbr:phn.countryCallingCode,
          Id:contact.Id,
          UnknownId:unknownid
      }
      
      if(e=== true){
        if(deletedOthers && !formType){
          if(contact.Id){
            payload={
              AcctPh_Nbr:phn.nationalNumber,
              AcctFull_Nm:othersName,
              Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
              Id:contact.Id,
              IntlCallPfx_Nbr:phn.countryCallingCode,
              UnknownId:unknownid,
              Acct_Splty: speciality,
              Acct_Adr: address
            } 
          }
          else{
            payload={
              AcctPh_Nbr:phn.nationalNumber,
              AcctFull_Nm:othersName,
              Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
              IntlCallPfx_Nbr:phn.countryCallingCode,
              UnknownId:unknownid,
              Acct_Splty: speciality,
              Acct_Adr: address
            } 
          }
          addNewContact(payload,async (cont)=>{
            if(cont){
              
                        payload.Prescriber=secPrescribers;
                        payload.Is_Prescriber="N";
                        
                        let done=false;
                        let original=contact.Prescribers?contact.Prescribers:[];
                        let modified=secPrescribers;
                        let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                        let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                        let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                        let a,d;
                        if(forAdd.length>0){
                          let assoPayload={
                            PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                            PresciberAccociatesPrescrber_Id: forAdd,
                          }
                          await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                            a=res
                          }).catch(()=>{
                            
                          });
                        }
                        if(forDelete.length>0){
                          let assoPayload={
                            Ids:forDelete
                          }
                          await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                            d= res
                          }).catch(()=>{
                            
                          })
                        }
                        let final=payload;
                        dispatch(setChatDetails(
                          {
                            chatId:cont.Id,
                            chatIsPrescriber:cont.Is_Prescriber=="Y",
                            showChat:true,
                            chatName:cont.AcctFull_Nm
                          }))
                          dispatch(setChatType(cont.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
                          dispatch(setRefreshChatList(true))
                          dispatch(setRefreshChatComponent(true))
                         clearAll(true,final);
                         dispatch(setShowSuccessSnackBar(true));
            }
            else{
               clearAll(false,{})
               dispatch(setShowErrorSnackBar(true));
            }
          })
        }
        
      } else{
        setShowModal(false) 
      }
    }
   
  }
  const closePopup = (torf) => {
    setOthersName("");
    setOthersPhone("");
    setAssoPrescName("");
    setDisabled(true);
    setAssoPresc({});
    setSecPrescribers([]);
    handleCloseParent(torf);
  };
  async function addContact() {
    setDisabled(true);
    let phn: any = parsePhoneNumber(othersPhone);
    console.log(phn);
    let payload = {
      AcctPh_Nbr: phn.nationalNumber,
      Prescriber_Id: secPrescribers.map((x: any) => x.Id),
      AcctFull_Nm: othersName,
      IntlCallPfx_Nbr: phn.countryCallingCode,
      Acct_Splty: speciality,
      Acct_Adr: address
    };
    console.log(payload);
    addNewContact(payload, (res: any) => {
      if (res) {
        closePopup(true);
      } else {
        setDisabled(false);
        closePopup(false);
      }
    });
    // axios
    //     .post(
    //       config.REACT_APP_CONTACTS_API_BASE+"/contact",
    //       {
    //         AcctPh_Nbr:othersPhone,
    //         Prescriber_Id:assoPresc.Id as any,
    //         AcctFull_Nm:othersName,
    //         IsPrescriber:false
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + sessionStorage.getItem("login"),
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       closePopup(true);
    //       //handleCloseParent(true)
    //     })
    //     .catch((err)=>{
    //       closePopup(false);
    //      // handleCloseParent(false)
    //     })
  }
  
  // useEffect(() => {
  //   setSecPrescribers(secPrescribers);
  // }, [secPrescribers])

  const checkDisabled = (name, phone, presList) => {
        if (name && phone && presList) {
      setDisabled(false);
    } else setDisabled(true);
  };
  const reSetFormInputs = (e) => {
    // setSelecteedOthers({});
    // console.log(e)
    setFormType(e);
    // if(e === true){
    // setOthersPhone("");
    // setTemplateText("")
    // setAssoPrescName("");
    // }else{
    //   setTemplateId(0)
      setAssoPrescName("");
      setSecPrescribers([])
      setAssoPresc({Id: ''});
    //   setHiddenPresList(true);
      setDisabled(true);
    //   setOthersPhone("");
    //   setDisableBrandInput(true)
    //   // setTemplate([]);
    //   setTemplateText("");
      setOthersName("");
     setAssoPrescName("");
     setAddress("")
     setSpeciality("")
    //  setAssets([]);
    //  setAssociated({})

    // }
  };

  const handleOverrideMsg = (accType,accName) => {
    if(config.REACT_APP_PH_OVERRIDE_MSG){
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
        let type = accType === "N" ? "Others" : "Prescriber";
        let name = accName;
        if(type){
          msg = msg.replace("TYPE", type);
        }else{
          msg = msg.replace("TYPE -", "");
        }
        if(name){
          msg = msg.replace("NAME", name);
        }
        setOverrideMessage(msg);
    }
  } 

  const clearAll=(passOrNot,data)=>{

    setOthersName('');
    setSecPrescribers([]);
    setDisabled(true)
    if(passOrNot){
      handleCloseParent(true,data);
    }else
    handleCloseParent(false,{})

  }

//   const isValid = (inputString:string)=>{

//     let pattern = /^(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9\s]{1,97}[a-zA-Z0-9]?\s*$/;
//     let result = pattern.test(inputString);    

//     return result;
// }


  async  function updateContact(){
        
    setDisabled(true)
  let srepContact:any = selectedSRep
  console.log(srepContact.Id)
    let contact:any=assoPresc
    let phn:any=parsePhoneNumber(othersPhone);
    console.log(phn)
    let payload:any={
      AcctPh_Nbr:phn.nationalNumber,
      IntlCallPfx_Nbr:phn.countryCallingCode,
      Id:contact.Id,
      UnknownId:unknownid,
      salesRepId: srepContact.Id,
      role:sRepRole,
      Acct_Splty: speciality,
      Acct_Adr: address
    }
     
    //for others
    if(!formType){

      let isValidStringEntered = isValid(othersName);
      let ContactName = othersName.replace(/\s+/g,' ').trim();

      if(ContactName && isValidStringEntered)
      {

        contact=selectedOthers;
        srepContact =selectedSRep
        if(contact.Id){
          payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:ContactName,
            Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
            Id:contact.Id,
            IntlCallPfx_Nbr:phn.countryCallingCode,
            UnknownId:unknownid,
            salesRepId: srepContact.Id,
            role:sRepRole,
            Acct_Splty: speciality,
            Acct_Adr: address
          } 
        }
        else{
          payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:ContactName,
            Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
            IntlCallPfx_Nbr:phn.countryCallingCode,
            UnknownId:unknownid,
            salesRepId: srepContact.Id,
            role:sRepRole,
            Acct_Splty: speciality,
            Acct_Adr: address
          } 
        }
          console.log(secPrescribers)
          
          if(deletedOthers){
            checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
              console.log(res)  
              if(res)  {
                handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
                setShowModal(true)
                    } else{
                      addNewContact(payload,async (cont)=>{
                        if(cont){
                          
                                    payload.Prescriber=secPrescribers;
                                    payload.Is_Prescriber="N";
                                    
                                    let done=false;
                                    let original=contact.Prescribers?contact.Prescribers:[];
                                    let modified=secPrescribers;
                                    let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                                    let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                                    let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                                    let a,d;
                                    if(forAdd.length>0){
                                      let assoPayload={
                                        PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                                        PresciberAccociatesPrescrber_Id: forAdd,
                                      }
                                      await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                                        a=res
                                      }).catch(()=>{
                                        
                                      });
                                    }
                                    if(forDelete.length>0){
                                      let assoPayload={
                                        Ids:forDelete
                                      }
                                      await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                                        d= res
                                      }).catch(()=>{
                                        
                                      })
                                    }
                                    let final=payload;
                                    if(config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER== "Y"){
                                      dispatch(setChatDetails({
                                        chatId:'',
                                        chatName:'',
                                        chatIsPrescriber:'',
                                        showChat:false,
                                    }))
                                    } else {
                                      dispatch(setChatDetails(
                                        {
                                          chatId:cont.Id,
                                          chatIsPrescriber:cont.Is_Prescriber=="Y",
                                          showChat:true,
                                          chatName:cont.AcctFull_Nm
                                        }))
                                    }
                                   
                                      dispatch(setChatType(cont.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
                                      dispatch(setRefreshChatList(true))
                                      dispatch(setRefreshChatComponent(true))
                                     clearAll(true,final);
                                     dispatch(setShowSuccessSnackBar(true));
                        }
                        else{
                           clearAll(false,{})
                           dispatch(setShowErrorSnackBar(true));
                        }
                      })
                    }})
          } else{
            addNewContact(payload,async (cont)=>{
              if(cont){
                
                          payload.Prescriber=secPrescribers;
                          payload.Is_Prescriber="N";
                          
                          let done=false;
                          let original=contact.Prescribers?contact.Prescribers:[];
                          let modified=secPrescribers;
                          let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                          let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                          let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                          let a,d;
                          if(forAdd.length>0){
                            let assoPayload={
                              PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                              PresciberAccociatesPrescrber_Id: forAdd,
                            }
                            await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                              a=res
                            }).catch(()=>{
                              
                            });
                          }
                          if(forDelete.length>0){
                            let assoPayload={
                              Ids:forDelete
                            }
                            await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                              d= res
                            }).catch(()=>{
                              
                            })
                          }
                          let final=payload;
                          if(config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER== "Y"){
                            dispatch(setChatDetails({
                              chatId:'',
                              chatName:'',
                              chatIsPrescriber:'',
                              showChat:false,
                          }))
                          } else{
                            dispatch(setChatDetails(
                              {
                                chatId:cont.Id,
                                chatIsPrescriber:cont.Is_Prescriber=="Y",
                                showChat:true,
                                chatName:cont.AcctFull_Nm
                              }))
                          }
                          
                            dispatch(setChatType(cont.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
                            dispatch(setRefreshChatList(true))
                            dispatch(setRefreshChatComponent(true))
                           clearAll(true,final);
                           dispatch(setShowSuccessSnackBar(true));
              }
              else{
                 clearAll(false,{})
                 dispatch(setShowErrorSnackBar(true));
              }
            })
          }
          
      }
      else{
        setIsValidString(!isValidStringEntered);
      }

    }
    else{
      
      editContact(payload,(res)=>{
        
        if(res){
          if(config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER== "Y"){
            
            dispatch(setChatDetails({
              chatId:'',
              chatName:'',
              chatIsPrescriber:'',
              showChat:false,
          }))
          } else{
            
            dispatch(setChatDetails(
              {
                chatId:res.Id,
                chatIsPrescriber:res.Is_Prescriber=="Y",
                showChat:true,
                chatName:res.AcctFull_Nm
              }))
          }
          
            dispatch(setChatType(res.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
            dispatch(setRefreshChatList(true))
            dispatch(setRefreshChatComponent(true))
            clearAll(true,res);
            dispatch(setShowSuccessSnackBar(true));
        }
        else{
            clearAll(false,{})
            dispatch(setShowErrorSnackBar(true));
        }
      })
     
    }
   
    
    
   
  }
  const setOthersFromList=(x)=>{
    console.log("YYYYYYY", x.Id)
      setIsAdd(true);
      setSelecteedOthers(x);
      setSelecteedPrescriberId(x.Id)
      setOthersName(x.AcctFull_Nm);
      if(x.Prescribers){
        setSecPrescribers(x.Prescribers);
      }
      checkDisabled(
       true,true,true
      )
}

const setSrepFromList=(x)=>{
  console.log("xxxxxxxxxxxxxxx", x.Id)
  setIsAdd(true);
  setSelecteedSRep(x);  
  setSRepName(x.EmployeeFull_Nm);
  // if(x.Prescribers){
  //   setSecPrescribers(x.Prescribers);
  // }
  checkDisabled(
   true,true,true
  )
}

const clearData = (e) => {
    console.log("gc values changes e set", secPrescribers.length);
    setClearDataOnDelete(false)
    if (!multiAssociatePrescriberCheck && e.length == 0) {
      setSecPrescribers([]);
      console.log("gc values changes e cleared!");
      checkDisabled(othersName.length > 0, false, e.length > 0);
    }
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        //onClose={()=>{closePopup(false)}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div id='ipad-top-margin' className="modal-box saveContactChat" style={mobileStyle?{height:modalHeight,width:modalWidth}:{borderRadius:'1rem',overflow:'auto'}}>
              <div className="modal-header d-flex ">
                <h1 className="font-createcontact">{t("Save Contact")}</h1>
                <span
                  onClick={() => {
                    clearAll(false,{});
                  }}
                  className="close"
                >
                  ×
                </span>
              </div>

              <div className="modal-body addContacts">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateContact();
                  }}
                  className={classes.root}
                >
                  <div className="selecttype-radio-btns">
                    <FormControl>
                      <FormLabel id="demo-customized-radios">
                        {t("Select Type")}
                        {/* <span className="important">*</span> */}
                      </FormLabel>
                      <RadioGroup
                        row
                        defaultValue={deletedOthers?'others':'prescriber'}
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        onClick={(e: any) => {
                          e.target.value === "prescriber"
                            ? reSetFormInputs(true)
                            : reSetFormInputs(false);
                        }}
                      >
                        <FormControlLabel
                          value="prescriber"
                          control={<BpRadio />}
                          label={t('Prescriber')}
                          disabled={deletedOthers?true:false}
                        />
                        {config.REACT_APP_OTHER_STAFF_DISABLED !=="N" && <FormControlLabel
                          value="others"
                          control={<BpRadio />}
                          label={t('Others')}
                        />}
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div>
                    {formType ? (
                      <div className="form-fields-div">
                        {/* <h1 className="font-md">{t("Prescriber Name")}<span className="important">*</span></h1> */}
                        
                        <div>
                          <AssociatedPresList
                            placeholder={t("Search for a Prescriber")}
                            label={t("Prescriber Name") + '*'}
                            styleClass=""
                            clearDataOnSelect={false}
                            onChangeInputValue={(e:any)=>{
                              setDisabled(true)
                              console.log(e)}}
                            setAssoPresc={(e: any) => {
                                setPrescriber(e)
                                setDisabled(false)
                            }}
                          />
                        </div>
                        <TextField
                          className="roundborder disableNumber"
                          value={getFormatedPhoneNumber(othersPhone)}
                          label={t("Phone Number")+'*'}
                          color="secondary"
                          focused
                        />
                         
                         <TextField
                    label={t("Address")}
                    className="roundborder inputDisable"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    focused
                    disabled
                    value={address || " "}
                    placeholder={t("Please enter address")}
                    color="secondary"
                    inputProps={{ maxLength: 200 }}
                  />

                  <TextField
                    label={t("Speciality")}
                    className="roundborder inputDisable"
                    onChange={(e) => {
                      setSpeciality(e.target.value);
                    }}
                    focused
                    disabled
                    value={speciality || " "}
                    placeholder={t("Please enter speciality")}
                    color="secondary"
                    inputProps={{ maxLength: 50 }}
                  />
                      </div>
                    ) : (
                      <div className="form-fields-div">
                        {/* <h1 className="font-md">{t("Recipient Name")}<span className="important">*</span></h1> */}

                        <div className="NewChatModalOthersName">
                          <TextField
                            className="roundborder inputEnable"
                            onChange={(e) => {
                                                            setIsValidString(false)
                              setIsAdd(false)
                              const result = e.target.value.replace(/[^A-Za-z0-9 _]/gi, '');
                              // setSelecteedOthers({});
                              if (result.length == 0) {
                                setSelecteedOthers({});
                              }
                                                            if(formType === false) {
                                checkDisabled(
                                  result.length >= 3,
                                  true,
                                  secPrescribers.length > 0
                                );
                              }
                              if(othersData.length > 0) {
                                setOthersDataHidden(false);
                              }
                              setOthersName(result);
                            }}
                            value={othersName}
                            label={t("Recipient Name") + '*'}
                            placeholder={t("Enter Name")}
                            color="secondary"  
                            focused
                            inputProps = {{maxLength:config.REACT_APP_CONTACT_NAME_MAXLENGTH}}                       
                          />

                          {
                            isValidString && <p style={{color:'red',fontSize:'12px',margin:'0',wordWrap: 'break-word'}}>{errorData}</p>
                          }

                          {/* {
                            deletedOthers?null:
                           <>
                            {othersData.length>0?
                            <Paper hidden={othersDatahidden}>
                              
                            <div id="presList">
                              {othersData.length > 0 && (
                                othersData.map((x: any) => 
                                  <div
                                    key={x.Id}
                                    onClick={() => {
                                    setOthersDataHidden(true)
                                    setOthersFromList(x);
                                    }}
                                    >
                                    {x.AcctFull_Nm}
                                  </div>
                                )
                              ) 
                              }
                            </div>
                          </Paper>
                          :null}
                           </>
                          } */}
                          
                       
                        </div>
                        {
                          deletedOthers?
                          // <CountryDropdown errorFunc={(e)=>{
                          //   checkDisabled(
                          //     othersName.length > 0,
                          //     true,
                          //     secPrescribers.length > 0
                          //   );
                          // }} disableColored="" valueNumber={othersPhone} onChangeNumber={(e)=>{setOthersPhone(e);}} />
                          <PhoneNumberDropdown 
                          initiall={(e)=>{}}
                          countryList={countries}
                          errorFunc={(e)=>{
                            checkDisabled(
                              othersName.length > 0,
                              true,
                              secPrescribers.length > 0
                            );
                          }} disableColored={false} valueNumber={othersPhone} onChangeNumber={(e)=>{setOthersPhone(e);}} />
                          :
                          <TextField
                          className={"roundborder disableNumber"}
                          value={getFormatedPhoneNumber(othersPhone)}
                          label={t("Phone Number"+'*')}
                          color="secondary"
                          focused
                          
                        />
                        }
<TextField
                    label={t("Address")}
                    className="roundborder inputDisable"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    focused
                    disabled
                    value={address || " "}
                    placeholder={t("Please enter address")}
                    color="secondary"
                    inputProps={{ maxLength: 200 }}
                  />

                  <TextField
                    label={t("Speciality")}
                    className="roundborder inputDisable"
                    onChange={(e) => {
                      setSpeciality(e.target.value);
                    }}
                    focused
                    disabled
                    value={speciality || " "}
                    placeholder={t("Please enter speciality")}
                    color="secondary"
                    inputProps={{ maxLength: 50 }}
                  />
                         <h1 className="font-md title-remove-margin"> {t("Associated Prescribers")+"*"}</h1>


                         {multiAssociatePrescriberCheck && secPrescribers.length>0 ?
                // <div>
               

                  <div className="secPresDiv">
                {
                   secPrescribers.map((x:any)=>(
                    
                   <Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm} 
                   onDelete={() => {
                    let temp = secPrescribers.filter((p: any) => {
                      if (p.Id !== x.Id) return p;
                    });
                    console.log(temp);
                    setSecPrescribers(temp);
                    checkDisabled(
                      othersName.length > 0,
                      true,
                      temp.length > 0
                    );
                  }}
                    />))
                }
                {/* </div> */}
                
                </div>:
                null}
                


                         {/* {
                            secPrescribers.length>0?
                            <div className="secPresDiv">
                          {secPrescribers.map((x: any) => (
                            <Chip
                              key={x.Id}
                              id={x.Id}
                              label={x.AcctFull_Nm}
                              onDelete={() => {
                                let temp = secPrescribers.filter((p: any) => {
                                  if (p.Id !== x.Id) return p;
                                });
                                console.log(temp);
                                setSecPrescribers(temp);
                              }}
                            />
                          ))}
                        </div>:null
                         } */}
                        
                       
                        <AssociatedPresList
                          placeholder={t("Search for an Associated Prescriber")}
                          label=""
                          styleClass=""
                          clearDataOnDelete={clearDataOnDelete}
                          clearDataOnSelect={multiAssociatePrescriberCheck?true:false}
                          onChangeInputValue={(e:any)=>{console.log(e); clearData(e);}}
                          multisel={multiAssociatePrescriberCheck}
                          setAssoPresc={(e: any) => {
                            let t: any = [e];
                            if(multiAssociatePrescriberCheck){
                      
                              t=t.concat(secPrescribers);
                          }
                            const result: any = [];
                            const map = new Map();
                            for (const item of t) {
                              if (!map.has(item.Id)) {
                                map.set(item.Id, true); // set any value to Map
                                result.push(item);
                              }
                            }
                            checkDisabled(
                              othersName.length > 0,
                              othersPhone.length > 0,
                              result.length > 0
                            );
                            console.log("result", result)
                            setSecPrescribers(result);
                            setClearDataOnDelete(true)
                          }}
                         
                        />
                      </div>
                    )}
                    {config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER== "Y" && config.REACT_APP_SHOW_REP_LIST_CONTACT_SCREEN== "Y" ?
                    <div className="srep_text_input">
                    <TextField
                            className="roundborder inputEnable"
                            onChange={(e) => {
                              setIsValidString(false)
                              setDisabled(false)
                              setIsAdd(false)
                              const result = e.target.value.replace(/[^A-Za-z0-9 _]/gi, '');
                              // setSelecteedOthers({});
                              if (result.length == 0) {
                                setSelecteedSRep({});
                              }

                              if(sRepData.length > 0) {
                                // setOthersDataHidden(false);
                                 setSRepDataHidden(false)
                              }
                              setSRepName(result);
                            }}
                            value={sRepName}
                            label={t("Srep Name") + '*'}
                            placeholder={t("Enter Name")}
                            color="secondary"  
                            focused
                            inputProps = {{maxLength:config.REACT_APP_CONTACT_NAME_MAXLENGTH}}                       
                          />

{
                            isValidString && <p style={{color:'red',fontSize:'12px',margin:'0',wordWrap: 'break-word'}}>{errorData}</p>
                          }

                          {
                            deletedOthers?null:
                           <>
                            {sRepData.length>0?
                            <Paper hidden={srepDatahidden}>
                              
                            <div id="presList">
                              {sRepData.length > 0 && (
                                sRepData.map((x: any) => 
                                  <div
                                    key={x.Id+x.EmployeeFull_Nm}
                                    onClick={() => {
                                    setSRepDataHidden(true)
                                    setSrepFromList(x);
                                    }}
                                    >
                                    {x.EmployeeFull_Nm}
                                  </div>
                                )
                              ) 
                              }
                            </div>
                          </Paper>
                          :null}
                           </>
                          }
                    </div>:null}

                        <br />
                        <div className="SaveButtonForchat">
                        <Button type="submit" label={t("Save")} disable={disabled} />
                        </div>
                   
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
     
      <ContactAlreadyAssign
      show={showModal}
      yorn={handleYOrNPopup}
      modalText={overrideMessage}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowModal(false)
      }} />
    </div>
  );
}
