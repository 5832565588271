import React, { useState, useEffect, useRef } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {  convertToHTML} from 'draft-convert';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './MobileCompose.css';
import { createMail } from '../../apis/Email/Email'
import { setComposeEmailView, setEmailContentView, setSentEmailView } from '../../app/slice/emailSubMenuSlice';
import Button from '../../layouts/button/Button';
export default function ComposeMail() {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(null);

  const [cc, setCC] = useState(false)
  const [bcc, setBCC] = useState(false)
  const config = (
    {
      readonly: false,
    }
  );
  const [state, setState] = useState({
    To: "",
    CC: "",
    BCC: "",
    Subject: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);



  const saveasDraft = () => {

    console.log(convertedContent);
    let data = {
      "ConversationId": 0,
      "ContactId": 1000,
      "ContactType": "PRES",
      "EmailStatus": "DRAFT",
      "EmailBody": convertedContent,
      "EmailSubject": state.Subject,
      "ContentType": "HTML",
      "ToEmail": state.To,
      "CCEmail": state.CC
    }
    console.log(data)
    createMail(data, (resp) => {
      console.log(resp);
    });
  }

  const sendMail = () => {

    console.log(convertedContent);
    let data = {
      "ConversationId": 0,
      "ContactId": 1000,
      "ContactType": "PRES",
      "EmailStatus": "SENT",
      "EmailBody": convertedContent,
      "EmailSubject": state.Subject,
      "ContentType": "HTML",
      "ToEmail": state.To,
      "CCEmail": state.CC
    }
    console.log(data)
    createMail(data, (resp) => {
      console.log(resp);
      if(resp.code){
        // dispac
        dispatch(setComposeEmailView(false));
        dispatch(setEmailContentView(true));
        dispatch(setSentEmailView(true));
      }
    });
  }
  return (
    <div className="jodit" style={{
      height: '100vh',
      width: '100%',

    }}>

      {/* <div className="form-field" >
        <label>From:</label>
        <input type="email" />
      </div> */}
      <div className="form-field ">
        <label>To:</label>
        <input
          type="email"
          name="To"
          value={state.To}
          onChange={handleInputChange}
        />
        <p style={{ margin: '0px' }} onClick={() => {
          setCC(!cc);
        }}>CC</p>
        <p onClick={() => {
          setBCC(!bcc);
        }}>BCC</p>
      </div>
      {cc ? <div className="form-field" style={{ display: 'flex' }} >
        <label>CC:</label>
        <input
          type="email"
          name='CC'
          value={state.CC}
          onChange={handleInputChange}
        />
      </div> : null}

      {bcc ? <div className="form-field" >
        <label>BCC:</label>
        <input
          type="text"
          name="BCC"
          value={state.BCC}
          onChange={handleInputChange}
        />
      </div> : null}
      <div className="form-field">
        <label>Subject:</label>
        <input
          type="text"
          name="Subject"
          value={state.Subject}
          onChange={handleInputChange}
        />
      </div>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
            options: ['inline', 'blockType']}}
      />
      <div className='btn'>
      <Button  type="button" onClick={()=>{saveasDraft()}} label="Save as Draft"/>
      <Button  type="button" onClick={()=>{sendMail()}} label="Send"/>
      {/* <button onClick={saveasDraft}   role="button" >Save as Draft</button>
      <button onClick={()=>{sendMail()}}  role="button"> Send</button> */}
      </div>
    
    </div>
  )
}

function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

