import { useEffect, useState } from "react";
import VideoChatDetails from "./VideoChatDetails";
import VideoFiles from "./VideoFiles";
import VideoParticipants from "./VideoParticipants";
import "./VideoMobileLogs.css"
import { useNavigate} from 'react-router-dom';
import { useSelector } from "react-redux";


export default function VideoMobileLogs() {
  const [activeTab, setActiveTab] = useState("tab1");
  const navigate = useNavigate();
  const [navigateBack,setNavigateBack]= useState(false)
  const videoCalllogId: any = useSelector((state: any) => state.floaterVideoCall.Id)
  const videoMeetingName: any = useSelector((state: any) => state.floaterVideoCall.meetingName)
  const videoEmployeeName: any = useSelector((state: any) => state.floaterVideoCall.EmployeeName)
  const videoStartDate: any = useSelector((state: any) => state.floaterVideoCall.StartDateTime)
  const trncallId: any = useSelector((state: any) => state.floaterVideoCall.TrnCallId)
  const recordName: any = useSelector((state: any) => state.floaterVideoCall.RecordName)
  
  const handleChat = (e) => {
    
    // update the state to tab1
    setActiveTab("tab1");
    e.stopPropagation();
  };

  const handleFiles = (e) => {
    // update the state to tab2
    setActiveTab("tab2");
    e.stopPropagation();
  };

  const handleParticipants = (e) => {
    // update the state to tab2
    setActiveTab("tab3");
    e.stopPropagation();
  };

  const handleBackClick = () => {
      let doc:any=document;
      let headerVal = doc.getElementById('headerVal');
      while (headerVal.classList.contains("hide-header")) {
        headerVal.classList.remove("hide-header");
      }
      navigate('/Video')
  }

  useEffect(()=> {
    let doc:any=document;
    let headerVal = doc.getElementById('headerVal');
    headerVal.classList.add("hide-header");
    console.log(videoCalllogId)
    console.log(trncallId)
    console.log(videoMeetingName)
  })

    return (
        <div className="meeting-container">
            <div className="meeting-header">
                <i className="back-icon material-icons i-2p5" onClick={e => handleBackClick()}>{('arrow_back_ios')}</i>
                <div className="meeting-header-details">
                    <p className="meeting-title">{videoEmployeeName}</p>
                    <p className="meeting-subtitle">{videoMeetingName}</p>
                 </div>
             </div>
        <div className="tabs-container">
                  <ul className="tab-main nav">
                    <li
                      className={activeTab === "tab1" ? "tab-active" : ""}
                      onClick={handleChat}
                    >
                      <div className="tab-title" id='chatheader'>Chat</div>
                       
                    </li>

                    <li
                      className={activeTab === "tab3" ? "tab-active" : ""}
                      onClick={handleParticipants}
                    >
                         <div className="tab-title"  id='chatheader'>Participants</div>
                    </li>

                    {/* <li
                      className={activeTab === "tab2" ? "tab-active" : ""}
                      onClick={handleFiles}
                    >
                      <div className="tab-title"  id='chatheader'>Files</div>
                    </li> */}
                    
                  </ul>
                   
                  <div className="outlet">
                    {activeTab === "tab1" ? <VideoChatDetails Roomid={videoCalllogId} meeting={videoMeetingName} Employeename={videoEmployeeName} startDateTime={videoStartDate}/> : activeTab === "tab2"?<VideoFiles files={trncallId} meeting={videoMeetingName} Employeename={videoEmployeeName} trncallId={trncallId} />: <VideoParticipants files={trncallId} trncallId={trncallId}/>}
                  </div>
            </div>

        </div>

    )
}