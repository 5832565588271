import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./SaveContactForChatGAM.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import ContactAlreadyAssign from "../logoutModal/LogoutModal";
import { Keyboard } from "@capacitor/keyboard";
import { FormHelperText } from "@mui/material";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import { parsePhoneNumber } from "react-phone-number-input";
import {
  addNewContact,
  checkNumberExist,
  getAccounts,
} from "../../apis/contacts/contactsApi";
import { useTranslation } from "react-i18next";
import config from "../../env.json";
import "./SaveContactForChat.css";
import Button from "../../layouts/button/Button";
import { editContact } from "../../apis/contacts/contactsApi";
import {
  setChatType,
  setChatDetails,
  setRefreshChatList,
  setRefreshChatComponent,
} from "../../app/slice/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,
} from "../../app/slice/personalisationSlice";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor =
  document.documentElement.style.getPropertyValue("--primary-color");
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: primcolor
    ? document.documentElement.style.getPropertyValue("--primary-color")
    : "#734bd1",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor
      ? document.documentElement.style.getPropertyValue("--primary-color")
      : "#734bd1",
  },
});

export default function SaveContactsForChat(props) {
  const { unknownid, number, show, handleCloseParent, deletedOthers } = props;
  const { t } = useTranslation();
  const [assoPresc, setAssoPresc] = useState<any>({});
  const [disabled, setDisabled] = useState(true);
  const [formType, setFormType] = useState(false);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [showModal, setShowModal] = useState(false);
  useState<any>(true);
  const [phoneErr, setPhoneErr] = useState<any>(false);

  const { chatName } = useSelector((state: any) => state.chat);
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const dispatch = useDispatch();
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [emailError, setEmailError] = useState(false);
  const [addContactFormData, setAddContactFormData] = useState({});
  const [overrideMessage, setOverrideMessage] = useState('');
  const camp = useSelector((state: any) => state.personalisation.campaignName);
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);
  const FORM_DATA_KEYS = {
    CONTACT_NAME: "contactName",
    TITLE: "Title",
    PHONE_NUMBER: "AcctPh_Nbr",
    EMAIL: "Email",
  };
  useEffect(() => {
    setAddContactFormData({
      [FORM_DATA_KEYS.PHONE_NUMBER]: number,
    });
    if (deletedOthers) {
      setFormType(false);
      setAddContactFormData({
        [FORM_DATA_KEYS.PHONE_NUMBER]: "",
      });
    }
  }, [number]);

  useEffect(() => {
    getModalDimensions();
  }, [mobileStyle, modalHeight, modalWidth]);

  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardDidShow", (info) => {
        getModalDimensions();
      });

      Keyboard.addListener("keyboardDidHide", () => {
        getModalDimensions();
      });
    }
    setCountryList();
  }, []);
  const setCountryList = () => {
let userList: Array<any> = JSON.parse(config.REACT_APP_TEST_USER_LIST);
    let countryTestUserCountryList:Array<any>= config.REACT_APP_TEST_USER_COUNTRY_LIST?.split(",")?.map((code: string) =>
    code?.toLowerCase()
  )
    userList?.forEach((userId) => {
      if (userId === repId) {
        setCountries(countryTestUserCountryList);
      }
    });
  };
  function validatePhoneNumber(input_str) {
    var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

    return re.test(input_str);
  }

  useEffect(() => {
    if (validatePhoneNumber(chatName) == false && !unknownid) {
      setAddContactFormData({
        ...addContactFormData,
        [FORM_DATA_KEYS.CONTACT_NAME]: chatName,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("orientationchange", getModalDimensions);
    return () => {
      window.removeEventListener("orientationchange", getModalDimensions);
    };
  }, []);

  const handleOverrideMsg = (accType,accName) => {
    if(config.REACT_APP_PH_OVERRIDE_MSG){
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
        let type = accType === "N" ? "Others" : "Prescriber";
        let name = accName;
        if(type){
          msg = msg.replace("TYPE", type);
        }else{
          msg = msg.replace("TYPE -", "");
        }
        if(name){
          msg = msg.replace("NAME", name);
        }
        setOverrideMessage(msg);
    }
  } 

  const getModalDimensions = () => {
    let div: any = document.querySelector("#main");
    if (!div) return;
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };

  const handleYOrNPopup = (e: any) => {
    if (addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]) {
      let contact: any = assoPresc;
      let phn: any = parsePhoneNumber(
        addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
      );
      let payload: any = {
        AcctPh_Nbr: phn.nationalNumber,
        IntlCallPfx_Nbr: phn.countryCallingCode,
        Id: contact.Id,
        UnknownId: unknownid,
        Prescriber_Id: [selectedAccount.Id],
        AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
        Title: addContactFormData[FORM_DATA_KEYS.TITLE],
        Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
      };

      if (e === true) {
        if (deletedOthers && !formType) {
          if (contact.Id) {
            payload = {
              AcctPh_Nbr: phn.nationalNumber,
              Prescriber_Id: [selectedAccount.Id],
              Id: contact.Id,
              IntlCallPfx_Nbr: phn.countryCallingCode,
              UnknownId: unknownid,
              AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
              Title: addContactFormData[FORM_DATA_KEYS.TITLE],
              Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
            };
          } else {
            payload = {
              AcctPh_Nbr: phn.nationalNumber,
              Prescriber_Id: [selectedAccount.Id],
              IntlCallPfx_Nbr: phn.countryCallingCode,
              UnknownId: unknownid,
              AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
              Title: addContactFormData[FORM_DATA_KEYS.TITLE],
              Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
            };
          }
          addNewContact(payload, async (cont) => {
            if (cont) {
              dispatch(
                setChatDetails({
                  chatId: cont.Id,
                  chatIsPrescriber: cont.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: cont.AcctFull_Nm,
                })
              );
              dispatch(
                setChatType(
                  cont.Is_Prescriber == "Y"
                    ? "PRESCR"
                    : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
                      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
                    ? "ASSHSP"
                    : "ASSPRS"
                )
              );
              dispatch(setRefreshChatList(true));
              dispatch(setRefreshChatComponent(true));
              clearAll(true, payload);
              dispatch(setShowSuccessSnackBar(true));
            } else {
              clearAll(false, {});
              dispatch(setShowErrorSnackBar(true));
            }
          });
        }
      } else {
        setShowModal(false);
      }
    }
  };
  const clearAll = (passOrNot, data) => {
    setAddContactFormData({});
    if (passOrNot) {
      handleCloseParent(true, data);
    } else handleCloseParent(false, {});
  };
  async function updateContact() {
    setDisabled(true);
    let contact: any = assoPresc;
    let phn: any = parsePhoneNumber(
      addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
    );
    let payload: any = {
      AcctPh_Nbr: phn?.nationalNumber,
      IntlCallPfx_Nbr: phn?.countryCallingCode,
      Id: contact.Id,
      UnknownId: unknownid,
      Prescriber_Id: [selectedAccount.Id],
      AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
      Title: addContactFormData[FORM_DATA_KEYS.TITLE],
      Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
    };

    if (!formType) {
      payload = {
        AcctPh_Nbr: phn.nationalNumber,
        IntlCallPfx_Nbr: phn.countryCallingCode,
        UnknownId: unknownid,
        Prescriber_Id: [selectedAccount.Id],
        AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
        Title: addContactFormData[FORM_DATA_KEYS.TITLE],
        Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
      };
      if (deletedOthers) {
        checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          if (res) {
            handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
            setShowModal(true);
          } else {
            addNewContact(payload, async (cont) => {
              setDisabled(false);

              if (cont) {
                dispatch(
                  setChatDetails({
                    chatId: cont.Id,
                    chatIsPrescriber: cont.Is_Prescriber == "Y",
                    showChat: true,
                    chatName: cont.AcctFull_Nm,
                  })
                );
                dispatch(
                  setChatType(
                    cont.Is_Prescriber == "Y"
                      ? "PRESCR"
                      : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
                        config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT ==
                          camp
                      ? "ASSHSP"
                      : "ASSPRS"
                  )
                );
                dispatch(setRefreshChatList(true));
                dispatch(setRefreshChatComponent(true));
                clearAll(true, payload);
                dispatch(setShowSuccessSnackBar(true));
              } else {
                clearAll(false, {});
                dispatch(setShowErrorSnackBar(true));
              }
            });
          }
        });
      } else {
        addNewContact(payload, async (cont) => {
          if (cont) {
            setDisabled(false);

            dispatch(
              setChatDetails({
                chatId: cont.Id,
                chatIsPrescriber: cont.Is_Prescriber == "Y",
                showChat: true,
                chatName: cont.AcctFull_Nm,
              })
            );
            dispatch(
              setChatType(
                cont.Is_Prescriber == "Y"
                  ? "PRESCR"
                  : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
                    config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
                  ? "ASSHSP"
                  : "ASSPRS"
              )
            );
            dispatch(setRefreshChatList(true));
            dispatch(setRefreshChatComponent(true));
            clearAll(true, payload);
            dispatch(setShowSuccessSnackBar(true));
          } else {
            clearAll(false, {});
            dispatch(setShowErrorSnackBar(true));
          }
        });
      }
    } else {
      editContact(payload, (res) => {
        setDisabled(false);
        if (res) {
          dispatch(
            setChatDetails({
              chatId: res.Id,
              chatIsPrescriber: res.Is_Prescriber == "Y",
              showChat: true,
              chatName: res.AcctFull_Nm,
            })
          );
          dispatch(setChatType(res.Is_Prescriber == "Y" ? "PRESCR" : "ASSPRS"));
          dispatch(setRefreshChatList(true));
          dispatch(setRefreshChatComponent(true));
          clearAll(true, payload);
          dispatch(setShowSuccessSnackBar(true));
        } else {
          clearAll(false, {});
          dispatch(setShowErrorSnackBar(true));
        }
      });
    }
  }
  /**
   * Below use effect used to set the create button enable disable value
   */
  useEffect(() => {
    let formkeyArray = Object.keys(FORM_DATA_KEYS);
    let hasNullData = formkeyArray.some((data) => {
      if (
        !addContactFormData[FORM_DATA_KEYS[data]] ||
        addContactFormData[FORM_DATA_KEYS[data]] == ""
      ) {
        return data;
      }
    });

    if (hasNullData || phoneErr || !selectedAccount || emailError) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [addContactFormData, selectedAccount]);

  /**
   * This method used to fill the form data which will be used as  contact create request data
   * @param key It is key of the object for which we will be setting value
   * @param value It value to the key i.e the value of the user input
   */
  const fillForm = (key, value) => {
    setAddContactFormData({ ...addContactFormData, [key]: value });
  };
  /**
   * after selecting dropdown value this method will get called
   * @param value represent selected account
   */
  const onSelectionOfAccount = (value) => {
    setSelectedAccount(value);
  };
  /**
   * When account field input value get changed this method get called
   * @param value
   */
  const onChangeOfAccountInputValue = (value) => {
    setDisabled(true);
  };
  return (
    <div>
      <Modal
        open={show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div
              id="ipad-top-margin"
              className="modal-box saveContactChat"
              style={
                mobileStyle
                  ? { height: modalHeight, width: modalWidth }
                  : { borderRadius: "1rem", overflow: "auto" }
              }
            >
              <div className="modal-header d-flex ">
                <h1 className="font-createcontact">{t("Save Contact")}</h1>
                <span
                  onClick={() => {
                    clearAll(false, {});
                  }}
                  className="close"
                >
                  ×
                </span>
              </div>

              <div className="modal-body addContacts">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateContact();
                  }}
                >
                  <div style={{ paddingTop: " 1 px", marginBottom: "20px" }}>
                    <AssociatedPresList
                      falsifyShowOnce={() => {}}
                      clearDataOnSelect={true}
                      placeholder={t("Search for an Account")}
                      label={t("Account")}
                      styleClass="inputEnable"
                      setAssoPresc={onSelectionOfAccount}
                      onChangeInputValue={onChangeOfAccountInputValue}
                    />
                  </div>
                  <TextField
                    style={{ width: "100%" }}
                    className="roundborder marTop"
                    onChange={(e) => {
                      fillForm(FORM_DATA_KEYS.CONTACT_NAME, e.target.value);
                    }}
                    value={addContactFormData[FORM_DATA_KEYS.CONTACT_NAME]}
                    label={t("Contact Name")}
                    placeholder={t("Enter contact name")}
                    color="secondary"
                    focused
                    inputProps={{ maxLength: 170 }}
                  />
                  <TextField
                    style={{ width: "100%" }}
                    className="roundborder marTop"
                    onChange={(e) => {
                      fillForm(FORM_DATA_KEYS.TITLE, e.target.value);
                    }}
                    value={addContactFormData[FORM_DATA_KEYS.TITLE]}
                    label={t("Title")}
                    placeholder={t("Enter a title")}
                    color="secondary"
                    focused
                    inputProps={{ maxLength: 10 }}
                  />
                  {deletedOthers ? (
                    // <CountryDropdown
                    //   errorFunc={(e) => {
                    //     setPhoneErr(e);
                    //   }}
                    //   disableColored=""
                    //   valueNumber={
                    //     addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
                    //   }
                    //   class={"phoneNumberInputAddContactGam"}
                    //   onChangeNumber={(e) => {
                    //     fillForm(FORM_DATA_KEYS.PHONE_NUMBER, e);
                    //   }}
                    // />
                    <PhoneNumberDropdown
                    initiall={(e)=>{}}
                      errorFunc={(e) => {
                        setPhoneErr(e);
                      }}
                      countryList={countries}
                      disableColored={false}
                      valueNumber={
                        addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
                      }
                      class={"phoneNumberInputAddContactGam"}
                      onChangeNumber={(e) => {
                        fillForm(FORM_DATA_KEYS.PHONE_NUMBER, e);
                      }}
                    />
                  ) : (
                    <TextField
                      style={{ width: "100%" }}
                      className={"roundborder disableNumber marTop"}
                      value={addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]}
                      label={t("Phone Number")}
                      color="secondary"
                      focused
                    />
                  )}

                  <TextField
                    style={{ width: "100%" }}
                    className="roundborder marTop"
                    onChange={(event) => {
                      if (
                        event.target.value &&
                        !emailPattern.test(event?.target?.value)
                      ) {
                        setEmailError(true);
                      } else {
                        setEmailError(false);
                      }
                      fillForm(FORM_DATA_KEYS.EMAIL, event.target.value);
                    }}
                    value={addContactFormData[FORM_DATA_KEYS.EMAIL]}
                    label={t("Email Address")}
                    placeholder={t("Enter an email address")}
                    color="secondary"
                    focused
                    InputProps={{
                      inputProps: {
                        pattern: "/^[w-.]+@([w-]+.)+[w-]{2,4}$/g",
                        maxLength: 50,
                      },
                    }}
                    error={emailError}
                    helperText={
                      emailError && (
                        <FormHelperText>
                          <p className="erorrPhone form-error-message">
                            {t("Enter a valid email address")}
                          </p>{" "}
                        </FormHelperText>
                      )
                    }
                  />

                  <div className="SaveButtonForchat marTop">
                    <Button
                      type="submit"
                      label={t("Save")}
                      disable={disabled}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <ContactAlreadyAssign
        show={showModal}
        yorn={handleYOrNPopup}
        modalText={overrideMessage}
        handleCloseParent={(e) => {
          setShowModal(false);
        }}
      />
    </div>
  );
}
