import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface userInfoDetails {
    Id:String,
    EmployeeV_Nbr: String,
    EmployeeEmail_Adr: String,
    EmployeePh_Nbr: String,
    EmployeeVCard_Url:String,
    EmployeeQR_Cd:String,
    EmployeeFull_Nm:String,
    EmployeeMsgorWhatsAppChannelType:boolean,
  }
const initialState: userInfoDetails = {  
  Id:'',
  EmployeeV_Nbr: '',
  EmployeeEmail_Adr: '',
  EmployeePh_Nbr: '',
  EmployeeVCard_Url:'',
  EmployeeQR_Cd:'',
  EmployeeFull_Nm:'',
  EmployeeMsgorWhatsAppChannelType:false,
};
export const authApiSlice = createSlice({
  name: 'callModal',
  initialState: initialState,
  reducers: {
     
      setUserVirtualNumber: (state,action: PayloadAction<any>) => { 
        state.EmployeeV_Nbr=action.payload.EmployeeV_Nbr
        state.Id =action.payload.Id
      },
      setUserDetails: (state,action: PayloadAction<any>) => { 
        state.EmployeeEmail_Adr=action.payload.EmployeeEmail_Adr
        state.EmployeePh_Nbr=action.payload.EmployeePh_Nbr
        state.EmployeeQR_Cd=action.payload.EmployeeQR_Cd
        state.EmployeeFull_Nm=action.payload.EmployeeFull_Nm
      },
      
      setUserMsgChannelType: (state,action: PayloadAction<any>) => {
        state.EmployeeMsgorWhatsAppChannelType=action.payload
       }
      // setCallModalDetails: (state,action: PayloadAction<any>) => { 
      //   state.contact_name=action.payload.AcctFull_Nm
      //   state.contact_number=action.payload.AcctFull_Nbr
      // },
      // setVirtualNumber: (state,action: PayloadAction<any>)=>{
      //   state.virtual_number=action.payload.EmployeeV_Nbr
      // },

    }
});

// this is for dispatch
export const { setUserVirtualNumber,setUserDetails, setUserMsgChannelType} = authApiSlice.actions;

// this is for configureStore
export default authApiSlice.reducer;