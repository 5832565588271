import axios from "axios";
import config  from '../../env.json';
function updatePushToken(payload,callback){

    try {
        axios.post(config.REACT_APP_NOTIFICATION_API_BASE+'/notification/update_device', payload)
        .then(res=>{ 
            console.log("----Device Token Update Response-----");
            console.log(res.data);
          ;
          callback(res);
        }).catch(()=>{
          ;
          callback([])
        })
    } catch (error) {
      ;
        callback([])
        
    }    
}

export {updatePushToken}