import React, { useEffect, useState } from 'react';
import moment from 'moment';
import '../chat/Message.css'
import Tooltip from '@mui/material/Tooltip';
import tickicon from '../../assets/icons/tick-double.svg'
import { isImage, isVcard, isAudio, isOtherAudio, isOtherVideo, isPdf, isVideo, isTextFile, isDoc } from '../../utils/FileTypes';
import { retry } from '@reduxjs/toolkit/dist/query';
// import linkifyHtml from "linkifyjs/html";
import Linkify from 'linkify-react';
import axios from 'axios';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { formatDateIntoTodayYesterdayFormat } from '../../utils/Date';
import ErrorIcon from '@mui/icons-material/Error';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import config from '../../env.json';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from "react-i18next";
import './MobileMessage.css'
import Popover from '@mui/material/Popover';
import LogoutModal from '../logoutModal/LogoutModal';
import { flagMessageByRep } from '../../apis/chat/messageApi';
import { setRefreshChatComponent } from '../../app/slice/chatSlice';

import {ReactComponent as HCP} from "../../assets/images/prescriber_icon.svg";
import{ReactComponent as Others} from '../../assets/images/associated_prescriber_icon.svg'
import Pusher from "pusher-js";
import { setSkipHeader } from '../../app/slice/loginSlice';
import { useSelector, useDispatch } from "react-redux";
import { unshortenUrl } from '../../apis/chat/messageApi';
import PreviewComponent from './PreviewComponent';
import Avatar from '../Avatar/Avatar';

var decode = require("decode-html");


export default function ChatComponent(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.login.token);
  const Chattype = useSelector((state: any) => state.chat.type);
  const {chatIsPrescriber} = useSelector((state: any) => state.chat);
  const userName=useSelector((state: any) => state.authApi.EmployeeFull_Nm);
  const { chatId, chatName} = useSelector((state: any) => state.chat);

  const [messageData, setMessageData] = useState({});
  const [body, setBody] = useState("");
  const [incoming, setIncoming] = useState(true);
  const [attachment, setAttachment] = useState([]);
  const [sentmsg, setSendMsg] = useState(true);
  const [random, setRandom] = useState(
    (Math.random() + 1).toString(36).substring(9)
  );
  const [iswhatsapp, setIswhatapp] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [msgStatus, setMsgStatus] = useState<any>();
  const [msgStatusPusher, setMsgStatusPusher] = useState<any>();
  const personalizationDateFormat = useSelector((state: any) => state.personalisation.personalizationDateFormat);
  const RepNumber= useSelector((state: any) => state.authApi.EmployeeV_Nbr)
  const [tooltopOpen,setTooltipOpen]=useState(false)
  const [flagConfirmPopup,setFlagConfirmPopup]=useState(false)
  useEffect(() => {

    if (props.message) {

      // console.log(props.message['Recipient Msg Body'])
      // messageSubscriber()
      // setMsgStatus(props.message['Recipient Msg Status'])
      // messageSubscriber()



      // if(props.pusher){
      //   const channel = props.pusher.subscribe("chatdetailstatus");
      //       channel.bind(props.message['Message Id'], (data) => {
      //           console.log('pusher',data)
      //           //setMessageData(data);
      //       });
      // }

      if (props.message['Recipient Sender Typ'] == "EMP") {
        setIncoming(false);
      } else {
        setIncoming(true)
      }
      
      if (props.message['Recipient Channel Type'] === "SMS" || props.message['Recipient Channel Type'] === "sms") setIsSms(true); else setIsSms(false);
      if (props.message['Recipient Channel Type'] === "whatsa") setIswhatapp(true); else setIswhatapp(false);
      
      setBody(props.message['Recipient Msg Body']);
      if (props.message['Recipient Msg Attachment'])
        setAttachment(JSON.parse(props.message['Recipient Msg Attachment']))
      setMessageData(props.message);

      // console.log(props.message['Message Id'])
      
      // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      //   const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      //     // cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   cluster:config.REACT_APP_PUSHER_CLUSTER,
      // //   encrypted: true,
      // });

      // console.log(contactNumber)
      // const channel = pusher.subscribe("chatdetailstatus");
      if (props.message['Message Table Id'] && props.msgPusherSubscriber && props.msgPusherSubscriber.bind) {
        props.msgPusherSubscriber.unbind(props.message['Message Table Id']);
        props.msgPusherSubscriber.bind(props.message['Message Table Id'], (data) => {
          //   if(data)
          
          // let x = data;
          console.log('inside')
          let x = JSON.parse(data.Body);
          console.log(x);
          // console.log(x.status, props.message['Message Id'] )
          if(x)
          setMsgStatus("")
          setMsgStatus(x.status)
          // console.log(data)
          // console.log(JSON.parse(data.Body))
          // console.log(data)
          // getStatus()
          // setChats(data);
        });
      }
      // return () => {
      //   pusher.unsubscribe("chatdetailstatus");
      // };
    }

  }, [props])

  useEffect(() => {
    if(props.message && props.message['Recipient Msg Status']!=msgStatus)
    setMsgStatus(props.message['Recipient Msg Status'])
  },[props.message['Recipient Msg Status']])



  const forceFileDownload = (response: any, filename: any) => {
    console.log("I am here forceFileDownload 11")
    // const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = response;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  // const downloadWithAxios = (url) => {
  //   console.log("I am here 22")
  //   let urlSplit = url.split("/");
  //   let filename = urlSplit[urlSplit.length - 1];
  //   delete axios.defaults.headers.common["Authorization"];
  //   dispatch(setSkipHeader({ 'skipHeader': true }));
  //   axios({
  //     method: "get",
  //     url: url,
  //     responseType: "arraybuffer",
  //     headers: { 'skipHeader': true }
  //   })
  //     .then((response) => {
  //       console.log("ddddddddddddd")
  //       forceFileDownload(response, filename);

  //       dispatch(setSkipHeader({ 'skipHeader': false }));
  //       axios.defaults.headers.common["Authorization"] = "Bearer " + token
  //     })
  //     .catch((err) => {
  //       axios.defaults.headers.common[
  //         "Authorization"
  //       ] = "Bearer " + token as string
  //     });
  // }

  const downloadWithAxios = async (url) => {

    let vcfurl = url.split("?")[0];

    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      const link = document.createElement("a");
      link.href = vcfurl;
      document.body.appendChild(link);
      link.click();
    } else {
      console.log("handle click downloadWithAxios called", vcfurl);
      let urlSplit = vcfurl.split("/");
      let filename = urlSplit[urlSplit.length - 1];
      // delete axios.defaults.headers.common["Authorization"]
      // const instance = axios.create();
      // const res = await instance.get(url, {responseType:"blob"})
      forceFileDownload(url, filename);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token
    }
  }
  const linkProps = {

    onClick: (event) => {
        event.preventDefault()
      }   
 };

   const getTabtoPreviewData = (value, type)=> {


    if(config.REACT_APP_VIDEO_PREVIEW_FLAG === "Y"){
      
      return (<div>
             <PreviewComponent value={value.trimStart()}/>
         
        <div >

        <span onClick={()=>{
          window.open((value.indexOf('https://')>=0 || value.indexOf('http://')>=0)?value:"https://"+value,"_blank")
          }} > {value}</span>
        
        </div>
  
        </div>
  
        )

    } else{
      return value;
    }

    }

  const messageSubscriber = () => {
    // console.log(props.message['Message Id'])
    
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    
    // console.log(contactNumber)
    const channel = pusher.subscribe("chatdetailstatus");
    channel.bind(props.message['Message Id'], (data) => {
      //   if(data)
      
      let x = data;
      // console.log(data)
      // console.log(JSON.parse(data.Body))
      // messageStatusInfo()
      // console.log(data)
      // getStatus()
      // setChats(data);
    });

    // return () => {
    //   pusher.unsubscribe("chatdetailstatus");
    // };
  }
  const checkIfURL = (textToCheck) => {
    // return linkifyHtml(textToCheck, {
    //   defaultProtocol: "https",
    //   className: "linkified",
    //   download:textToCheck,
    //   nl2br: false,
    //   validate: true,
    //   formatHref: function(href, type) {
    //     console.log(href, type);
    //     return href;
    //   },
    //   format: function(value, type) {
    //     console.log(value, type);
    //     return value;
    //   },
    // });
  }
  // const messageStatusInfo = (e) => {
  //   // if(e=== 'delivered'){
  //   //   setMsgStatus('delivered')
  //   // } else if(e==='failed'){
  //   setMsgStatus(e)
  //   // }
  // }
  const createMarkup = () => {

    // return {
    //   __html: checkIfURL(body)    };
  }
  const handleEnlargeable = (imgdisurl) => {
    // console.log('handling image enlarge child',imgdisurl);
    props.handleImgPopup(imgdisurl)

  }
  const getStatus = () => {

    // if(e=== 'delivered' || e==='failed'|| e==='In Progress'){
    //   setMsgStatus(e)
    // }

    let inc = false;
    if (props.message['Recipient Sender Typ'] === "EMP") {
      // inc=true
      return props.message['Recipient Msg Status']
    }
    else {

    }
    // if(props.message['Recipient Msg Status']=="In Progress")
    // return false
    // else return true
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: any) => {
    // setTooltipOpen(!tooltopOpen)
    // setAnchorEl(event.currentTarget);
    setFlagConfirmPopup(true)
  };

  const handleClose = () => {
    setTooltipOpen(!tooltopOpen)
    setAnchorEl(null);
  };

  
 const handleFlagMessage=(e:boolean)=>{
  

  
  const default_wave_id = config.REACT_APP_DEFAULT_WAVE_ID;
  const default_product_id = config.REACT_APP_DEFAULT_PRODUCT_ID;
  setTooltipOpen(false)
    setFlagConfirmPopup(false);
  if(props && props.contactData && props.message && e){
    const attachment = JSON.parse(props.message['Recipient Msg Attachment'])
      let mediaType = ""
      console.log("attachment", attachment)
      if (attachment?.length) {
        const attachmentType = attachment[0].attachmentType
        console.log("attachmentType", attachmentType)
        if (attachmentType.includes("application") || attachmentType.includes("text") || attachmentType.includes("image") || attachmentType.includes("video") || attachmentType.includes("audio") || attachmentType.includes("others")) {
          mediaType = "Archivo"
        }
      }
    
    let payload:any={
      id: props.message['Message Table Id'],
      recipient_type:props.message['Recipient Sender Typ'],
      recipient_id:props.message['Recipient Sender Id'],
      from:RepNumber,
      to:'+'+props.contactData['IntlCallPfx_Nbr']+props.contactData['AcctPh_Nbr'],
      template:props.message['Recipient Msg Body'],
      channel:props.message["Recipient Channel Type"].toLowerCase(),
      recipient_name:chatName,
      wave_id: default_wave_id,
      product_id: default_product_id,
      mediaType,
      dateTime: moment(props.message['Recipient Msg Start']).format("DD/MM/YYYY h:mm A"),
      attachment: attachment
    }
    if(props.contactData.Prescribers)
    {
      payload.association_id=props.contactData.Prescribers[0].Association_Id
    }
    flagMessageByRep(payload,(res)=>{
      if(res){
        dispatch(setRefreshChatComponent(true))
      }
    })
  }
 
 }

  return (
    <>
    <LogoutModal
              show={flagConfirmPopup}
              yorn={(e)=>{handleFlagMessage(e)}}
              modalText={t("This cant be undone, message will be removed from chat. Are you sure you want to flag this message ?")}
              handleCloseParent={(e) => {
                setFlagConfirmPopup(false);
              }}
            />
            {/* {tooltopOpen &&<Popover
          style={{left:'5%',top:'1rem'}}
          open={tooltopOpen}
          onClose={()=>{setTooltipOpen(!tooltopOpen)}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
        >
          <p className='msg msg-content' onClick={()=>{setFlagConfirmPopup(true)}}>{t('Flag Message')}</p>
        </Popover>} */}
      {
        messageData['showDateBanner'] ?
          <div className="dateBanner">
            <h4 className="font-md h4-date">{t(formatDateIntoTodayYesterdayFormat(messageData['Recipient Msg Start'],personalizationDateFormat))}</h4>
            <hr />
            <h4 className="font-md vis-hidden">{t('content')}</h4>
          </div> : null
      }

      <div className={incoming ? 'msg-row received' : 'msg-row sender'}>
        {/* <div className='msg-row received'> */}


        {/* if received msg */}
        {!(messageData['Recipient Sender Typ'] == "EMP") && !incoming && config.REACT_APP_SHOW_MESSAGE_AVATAR == "Y" ?
          (<div className='avatar-area' >
             <Avatar
             imageType={messageData['Recipient Sender Typ']?messageData['Recipient Sender Typ']:"Unknown"}
             avatarHeight="2.5rem" avatarWidth="2.5rem"  presOrAssoc={userName} />
          </div>)
          : null}
          {incoming && chatIsPrescriber && config.REACT_APP_SHOW_MESSAGE_AVATAR == "Y" ?(<div className='avatar-area' >
          <Avatar
             imageType={messageData['Recipient Sender Typ']?messageData['Recipient Sender Typ']:"Unknown"}
             avatarHeight="2.5rem" avatarWidth="2.5rem"  presOrAssoc={props?.avatarchatName} />
        </div>):null}
        {incoming && !chatIsPrescriber && (messageData['Recipient Sender Typ'] == "ASSPRS") && config.REACT_APP_SHOW_MESSAGE_AVATAR == "Y" ?(<div className='avatar-area' >

          <Avatar
             imageType={messageData['Recipient Sender Typ']?messageData['Recipient Sender Typ']:"Unknown"}
             avatarHeight="2.5rem" avatarWidth="2.5rem"  presOrAssoc={props?.avatarchatName} />
        </div>):null}
        {(messageData['Recipient Sender Typ'] == "UNKN") && incoming && !chatIsPrescriber && config.REACT_APP_SHOW_MESSAGE_AVATAR == "Y" ?(<div className='avatar-area' >
          <Avatar
             imageType={messageData['Recipient Sender Typ']?messageData['Recipient Sender Typ']:"Unknown"}
             avatarHeight="2.5rem" avatarWidth="2.5rem"  presOrAssoc={props?.avatarchatName} />
        </div>):null}

        <div className='msgtext' >

          <div className='msg' >
            {
              attachment.length > 0 ?
                <div className='msg-attachment'>
                  {
                    attachment.map((x: any) => {

                      if (isOtherAudio(x.attachmentType))
                        return (<div className="media-files card">
                          <a
                            className="card-a"
                            href="javascript:void(0)"
                          // @click="downloadWithAxios(attachment.attachmentUrl)"
                          >
                            <i className="fas fa-file-audio i-2p5"></i>
                            <h5 className="ml-2 font-md">
                              {x.attachmentName}
                            </h5>
                          </a>
                        </div>)
                      // if (isOtherVideo(x.attachmentType)) {
                      //   return (
                      //     <div className="media-files card">
                      //       <a
                      //         className="card-a"
                      //         href="javascript:void(0)"
                      //       // @click="downloadWithAxios(x.attachmentUrl)"
                      //       >
                      //         <i className="fas fa-video i-2p5"></i>
                      //         <h5 className="ml-2 font-md">
                      //           {x.attachmentName}
                      //         </h5>
                      //       </a>
                      //     </div>
                      //   )
                      // }
                      
                      if (isVideo(x.attachmentType) ||  isOtherVideo(x.attachmentType)) {
                        
                        // console.log("inside isvideo : ",x.attachmentType)
                        return (<div className="media-files">
                          <a href="javascript:void(0)">
                            <video
                              style={{ width: '100%' }}
                              controls
                              controlsList="nodownload"
                              disablePictureInPicture
                              src={x.attachmentUrl}
                              className="med"
                            ></video>
                          </a>
                        </div>
                        )
                      }

                      if (isAudio(x.attachmentType)) {
                        return (
                          <div className="audiodiv media-files">
                            <audio
                               controls
                               controlsList="nodownload novolumeslider"
                            >
                              <source src={x.attachmentUrl} />
                              {t('Your browser does not support the audio tag.')}
                            </audio>
                          </div>
                        )
                      }

                      if (isImage(x.attachmentType)) {
                        return (
                          <div className="media-files">
                            <img
                              data-enlargeable=""
                              src={x.attachmentUrl}
                              className="med"
                              //@click="enlargeable(attachment.attachmentUrl)"
                              onClick={() => handleEnlargeable(x.attachmentUrl)}
                            />
                          </div>)
                      }
                      if (isVcard(x.attachmentType) || isTextFile(x.attachmentType)) {
                        return (
                          <div className="media-files attachcard">
                            <a
                              href="javascript:void(0)"
                              onClick={() => downloadWithAxios(x.attachmentUrl)}
                              className='d-flex'
                            >
                              <i
                                className="fas fa-user-tag"
                              // style={{paddingRight: "10px"}}
                              ></i>
                              <h5 className="font-md">{x.filename ? x.filename : x.attachmentName}</h5>
                            </a>
                          </div>
                        )
                      }
                      if (isDoc(x.attachmentType)) {
                        return (
                          <div className="media-files attachcard">
                            <a
                              className="card-a"
                              href="javascript:void(0)"
                              onClick={() => downloadWithAxios(x.attachmentUrl)}
                            >
                             {isPdf(x.attachmentType)? <i className="fas fa-file-pdf"></i>:<i className="fas fa-file"></i>}
                              <h5 className="ml-2 font-md">
                              {x.attachmentName}
                              </h5>
                            </a>
                          </div>)
                      }
                    })

                  }
                </div> : null
            }
            {
              body ?
                <h5 className='msg-content'
                  // dangerouslySetInnerHTML={createMarkup()}
                  id={messageData['Recipient Msg Start'] + '-msg-body-' + random}>
                  <Linkify tagName="p"
                    options={
                      {
                        attributes: config.REACT_APP_VIDEO_PREVIEW_FLAG === "Y"?linkProps: {target: "_blank"},
                        defaultProtocol: "https",
                        className: "linkified",
                        // download:decode(body),
                        nl2br: true,
                        validate: true,
                        formatHref: function (href, type) {


                          return href;
                        },

                        format:getTabtoPreviewData,
                      }}
                  >
                    {decode(body)}
                  </Linkify>
                </h5> : null
            }
            <div className='msg-details' style={{paddingTop:'1rem'}}>
            {incoming && <span>
              <span className='fw-bold' onClick={(e)=> incoming && handleClick(e)}>{t('Report')}</span>
              </span>}
              <span className="msg-time">
                {moment(messageData['Recipient Msg Start']).format("h:mm A")}

              </span>
              {config.REACT_APP_CHAT_ICON==="Y"?
                <>
                {
                config.REACT_APP_SMS_ICON === "Y" ?
                  <>
                  {
                    isSms ? <span className='ms-1'>
                      <SmsOutlinedIcon fontSize='small' />
                    </span> : null
                  }
                  </>:null
                }
                {
                  iswhatsapp ? <span className='ms-1'>
                    <WhatsAppIcon fontSize='small' />
                  </span> : null
                }
                </>:null
              }
              {incoming === false ?
                <span className='ms-1'>
                  {/* {
                  <DoneAllIcon color='success' fontSize='small' />:<ErrorIcon color='error' fontSize='small'/>
                } */}
                    {
                    iswhatsapp && (msgStatus === "sent" || msgStatus === 'In Progress') ? 
                    <>{config.REACT_APP_SHOW_MESSAGE_STATUS_TEXT == "Y" ? t('Sent'):null} {config.REACT_APP_SHOW_MESSAGE_STATUS_ICON == "Y"? <DoneIcon color='success' fontSize='small' />:null} </> 
                    // <DoneIcon color='success' fontSize='small' />
                     : null
                  } {
                    isSms && (msgStatus === "sent" || msgStatus === 'In Progress' ) ? 
                    // <DoneIcon color='success' fontSize='small' /> 
                    <>{config.REACT_APP_SHOW_MESSAGE_STATUS_TEXT == "Y" ? t('Sent'):null} {config.REACT_APP_SHOW_MESSAGE_STATUS_ICON == "Y"? <DoneIcon color='success' fontSize='small' />:null} </> 
                    : null
                  }
                  {
                    isSms && (msgStatus === 'delivered' || msgStatus === "read") ? 
                    // <DoneAllIcon color='success' fontSize='small' />
                    <>{config.REACT_APP_SHOW_MESSAGE_STATUS_TEXT == "Y" ? t('Delivered'):null} {config.REACT_APP_SHOW_MESSAGE_STATUS_ICON == "Y"? <DoneAllIcon color='success' fontSize='small' />:null} </>
                     : null
                  }
                  {
                    iswhatsapp && (msgStatus === "read" || msgStatus === 'delivered' ) ? 
                    <>{config.REACT_APP_SHOW_MESSAGE_STATUS_TEXT == "Y" ? t('Delivered'):null} {config.REACT_APP_SHOW_MESSAGE_STATUS_ICON == "Y"? <DoneAllIcon color='success' fontSize='small' />:null} </>
                    // <DoneAllIcon color='success' fontSize='small' /> 
                    : null
                  }
                  {
                    msgStatus === "undelivered" || msgStatus === "failed" ?
                    <> {config.REACT_APP_SHOW_MESSAGE_STATUS_TEXT == "Y" ? t('Failed'):null} {config.REACT_APP_SHOW_MESSAGE_STATUS_ICON == "Y"? <ErrorIcon color='error' fontSize='small' />:null}</>
                    //  <ErrorIcon color='error' fontSize='small' /> 
                     : null
                  }
                </span> : null}
            </div>



          </div>
        </div>
        {messageData['Recipient Sender Typ'] == "EMP" && config.REACT_APP_SHOW_MESSAGE_AVATAR == "Y" ?
          <div className='avatar-area' >
             <Avatar
             imageType={messageData['Recipient Sender Typ']?messageData['Recipient Sender Typ']:"Unknown"}
             avatarHeight="2.5rem" avatarWidth="2.5rem"  presOrAssoc={userName} />
          </div>
          : null}
      </div>


    </>
  )
}




