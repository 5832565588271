import axios from "axios";
import config from "../../env.json";

function getPersonalizationData(callback, token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios
    .get(config.REACT_APP_AUTH_API_BASE + "/employeepersonalisation")
    .then((res) => {
      callback(res?.data);
    })
    .catch((error) => {
      callback({}, error);
    });
}

function getEmailDetails(callback){
    try {
        axios.get(config.REACT_APP_CALENDAR_API_BASE+'/google/token',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
        
    }
}

function updatePersonalizationData(payload,callback){
  
  try {
      axios.post(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}



export {getPersonalizationData,updatePersonalizationData, getEmailDetails}