import { createSlice,PayloadAction } from '@reduxjs/toolkit';



export const initialState: any = {
    otpValue:'',
    number:'',
    pdfurl:'',
    id:'',
    doc:null
};
export const chat = createSlice({
  name: 'pdf',
  initialState: initialState,
  reducers: {
    setNumber:(state:any,action: PayloadAction<any>) => { 
        state.number=action.payload
      },
    setOtpValue:(state:any,action: PayloadAction<any>) => { 
        state.otpValue=action.payload
      },
    setPdfId:(state:any,action: PayloadAction<any>) => { 
      state.id=action.payload
    },
    setDoc:(state:any,action: PayloadAction<any>) => { 
      state.doc=action.payload
    },
    }
});

// this is for dispatch
export const { setNumber,setOtpValue,setPdfId,setDoc } = chat.actions;

// this is for configureStore
export default chat.reducer;