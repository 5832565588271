import { useState } from "react";
import { Button, Modal, Spinner, Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { spamContact } from "../../apis/contacts/contactsApi";
import { setShowSuccessSnackBar } from "../../app/slice/personalisationSlice";
import { ReactComponent as BlockIcon } from "../../assets/icons/BlockIcon.svg";
import { useTranslation } from "react-i18next";

const SpamButton = ({ isSpam, onClick, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSpam = async () => {
    setIsLoading(true);
    spamContact(
      {
        ContactIntlCallPfx_Nbr: "+" + data?.countryCallingCode,
        ContactPh_Nbr: data?.nationalNumber,
      },
      (res) => {
        handleClose();
        if (res.length && res[0]?.BlockedContacts_Id) {
          onClick();
          dispatch(setShowSuccessSnackBar(true));
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      {!isSpam ? (
        <span className="btn btn-sm py-0" onClick={handleShow}>
          <BlockIcon style={{ width: "25px" }} />
        </span>
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="py-4 px-4 px-md-5">
          <h6 className="lh-lg">
            {t('This number will be blocked for all ZING Users. Are you sure you want to proceed?')}
          </h6>
          <Stack className="mt-4 text-center d-flex flex-row-reverse" direction="horizontal" gap={3}>
            <Button
              variant="outline-dark"
              className="rounded-pill border border-dark px-3 primary-btn"
              onClick={handleClose}
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="danger"
              className="rounded-pill border border-danger px-3"
              onClick={handleSpam}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" className="mt-1 mx-3" size="sm" />
              ) : 
                t('Block')
              }
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SpamButton;