import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { Variant } from "../../components/common/buttons/Button";

export interface ModalActions {
  label: string;
  variant: string;
  action?: () => void;
}

interface ModalState {
  show: boolean;
  title?: string;
  path: string;
  actions?: ModalActions[];
  props?: any;
}

const initialState: ModalState = {
  show: false,
  title: "",
  path: "",
  props: {},
  actions: [],
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (
      state,
      action: PayloadAction<any>
    ) => {
      state.show = true;
      state.title = action.payload?.title;
      state.path = action.payload.path;
      state.props = action.payload?.props;
      state.actions = action.payload?.actions;
    },
    hideModal: (state) => {
      state.show = false;
      state.title = "";
      state.path = "";
      state.props = {};
      state.actions = [];
    },
    setModalAction:  (
        state,
        action: PayloadAction<any>
      ) => {
      state.actions = action.payload.actions;
      },
  },
});

export const { showModal, hideModal, setModalAction } = modalSlice.actions;
export default modalSlice.reducer;