import React, {useState,useEffect} from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {setRefreshChatComponent, setRefreshChatList, setChatShowChat} from '../../app/slice/chatSlice';
import Spinner from '../../layouts/spinner/Spinner';
import LoginBgImage from '../../assets/images/LoginBgImage.png'
import { msalInstance } from '../../index';
import "./Login.css"
import Home from '../home/Home';
import { useNavigate } from "react-router-dom";
import initLogo from "../../assets/images/Zinglogo.png";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


 

// import { setLoginDetails } from '../../app/loginReducer';
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setLoginDetails, setLoginInterval, setRefreshToken } from '../../app/slice/loginSlice';
import { getAuthApi, getAppVersion } from '../../apis/authApi/authApi';
import { PingAuthApi, PingAuthRefresh } from '../../apis/pingauthApi/pingauthApi';
import { updatePushToken } from '../../apis/notificationApi/notificationApi';
import { createDatabase, addUserDetails, getUserDetails } from '../../apis/dbApi/dbApi';
import { setUserVirtualNumber,setUserDetails } from '../../app/slice/authApiSlice';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {capacitormsal} from "capacitormsal";
import  config  from '../../env.json';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { useTranslation } from "react-i18next";
import { setCallModalView } from '../../app/slice/callModalSlice';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Plugins, PluginResultError } from '@capacitor/core';
import { isMobile, isTablet } from 'react-device-detect';
import { ForgerockAuthApi, ForgerockAuthRefresh, ForgerockLogout } from '../../apis/forgerockAuthApi/forgerockauthApi';
import { Dialog } from '@capacitor/dialog';
import { Dialog as MatDialog } from '@material-ui/core';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Badge } from '@capawesome/capacitor-badge';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
const { FaceId } = Plugins;



function Login() {
  let IsCrossPlatForm = config.REACT_APP_IS_CROSS_PLATFORM
  let IsPingLogIn: any;
  let IsForgeRockLogIn: any;
  let loginImage = config.REACT_APP_LOGIN_IMAGE
  const { t } = useTranslation();
  const [IsLoggingSilently, setIsLoggingSilently] = useState(false);
  const [IsLoggedIn, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showForceUpdate, setShowForceUpdate] = React.useState(false);
  const [loginType, setLoginType] = React.useState('pingfederate');
  const [isLoadingAuthType, setIsLoadingAuthType] = useState(true)
  const [appVersion, setAppVersion] = useState('');

  const getAuthType = async () => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      let version = "";
      if (config.REACT_APP_IS_CROSS_PLATFORM) {
        const res = await App.getInfo();
        version = res.version;
        setAppVersion(res.version);
      }

      const response = await uninterceptedAxiosInstance.get(
        config.REACT_APP_AUTH_API_BASE + "/auth/login-type/?version=" + version
      );
      sessionStorage.setItem("appAuthType", response.data.loginType);
      setLoginType(response.data.loginType);
      setIsLoadingAuthType(false)
    } catch (e) {
      setIsLoadingAuthType(false)
    }
  };
  
    IsPingLogIn = loginType == 'pingfederate';
  
    IsForgeRockLogIn = loginType =='forgerock';
  

  useEffect(() => {
    getAuthType();
  }, []);

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      // checkAppStatus();
      App.addListener('appStateChange', ({ isActive }) => {
        // checkAppStatus();
        console.log('App state changed. Is active?', isActive);
        if(isActive) {
          dispatch(setRefreshChatList(true))
          dispatch(setRefreshChatComponent(true))
        }
      });

      PushNotifications.addListener('registration', token => {
        sessionStorage.setItem("pushToken",token.value);
        updatePushToken({
          "DeviceToken_Val": token.value,
          "Device_Typ": "iOS",
          "is_active": 'Y'
        },(res)=>{     
          })
      });
  
      PushNotifications.addListener('pushNotificationReceived', async notification => {
        console.log('Push notification received: ', notification);
        // await Badge.increase();
        dispatch(setRefreshChatList(true))
        dispatch(setRefreshChatComponent(true))
      });
      
      PushNotifications.addListener('registrationError', err => {
        // alert("Token Error");
        console.error('Registration error: ', err.error);
      });
    
      // PushNotifications.addListener('pushNotificationReceived', notification => {
      //   console.log('Push notification received: ', notification);
      // });
    
      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
        dispatch(setRefreshChatList(true))
        dispatch(setRefreshChatComponent(true))
      });
      
      if(!sessionStorage.getItem("login") || sessionStorage.getItem("login") === "") {
        setIsLoggedIn(false);
        getUserDetails().then((users: Array<any>) => {
          setIsLoggingSilently(true);
          if(users.length>0) { 
              if(config.REACT_APP_IS_CROSS_PLATFORM) {
                if(config.REACT_APP_SHOULD_USE_FACE_LOCK == "Y") {
                  FaceId.isAvailable().then(checkResult => {
                    if(checkResult.value) {
                      FaceId.auth().then(() => {
                        console.log('authenticated');
                        silentLoginMobileApp(users);
                      }).catch((error: PluginResultError) => {
                        // handle rejection errors
                        console.error(error.message);
                        // alert(error.message);
                        if(error.message.includes("Authentication canceled") || error.message.includes("Authentication failure")) {
                          setIsLoggingSilently(false);
                          setIsLoggedIn(false);
                        } else {
                          silentLoginMobileApp(users);
                        }
                      });
                    } else {
                      silentLoginMobileApp(users);
                    }
                  });

              } else {
                silentLoginMobileApp(users);
              }
            }
        } else {
            //Go to login
            setIsLoggingSilently(false);
            setIsLoggedIn(false);
        }
        });
      } else {
        setIsLoggedIn(true);
      }
    }
    else
    {
      setIsLoggedIn(false);
    }
  }, [])
  
  //const dispatch = useAppDispatch();
  const dispatch = useDispatch();
  const loginDetails=useSelector((state:any)=>state.login.userName)
//   axios.interceptors.request.use(function (config) {
//     const token = store.getState().session.token;
//     config.headers.Authorization =  token;

//     return config;
// });
  let mobile = "Desktop"
  // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    // mobile = deviceType
    if (isTablet){
      mobile = "Tablet"
    }
    else{
      mobile = "Mobile"
    }
  }

// if(!loginSave && e.accessToken){
  useEffect(()=>{
  if(sessionStorage.getItem("appAuthType")=="AD")
  {
      msalInstance.handleRedirectPromise().then((e: any) => {
    console.log("mobile", mobile);
    console.log("Hiii", JSON.stringify(e));
    // var loginData : any 
    //  loginData = JSON.stringify(e)
    // console.log("loginData", e.account.username)
    console.log("REACT_APP_AUTH_API_BASE", config.REACT_APP_AUTH_API_BASE)
    try {
      axios.defaults.headers.common["Authorization"] = "Bearer "+e.accessToken;
      saveLoginDetails(e.account.username)
    } catch (error) {
    //  callback(false)
    }

    if(e){
      

      axios.defaults.headers.common["Authorization"] = "Bearer "+e.accessToken;
      //dispatch(setLoginDetails({'token':'Hi Hello','userName':e.account.username,'userAccount':e}));
      dispatch(setLoginDetails({'token':e.accessToken,'userName':e.account.username,'userAccount':e}));
      dispatch(setChatShowChat(false));
      dispatch(setCallModalView(false));
      
      getAuthApi({authToken:"Bearer "+e.accessToken,type:sessionStorage.getItem("appAuthType")},(res)=>{
        
        if(res)
        dispatch(setUserVirtualNumber(
          {
            EmployeeV_Nbr: res[0].EmployeeV_Nbr,
            Id: res[0].Id
          }
        ))
        dispatch(setUserDetails(
          {
            // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
            EmployeePh_Nbr:res[0].EmployeePh_Nbr,
            EmployeeQR_Cd:res[0].EmployeeQR_Cd,
            EmployeeFull_Nm:res[0].EmployeeFull_Nm
          }
        ))
        console.log(res)
      })

      
      // getAutoPilot({authToken:"Bearer "+e.accessToken},(res)=>{
      // })

      sessionStorage.setItem("login",e.accessToken);
      sessionStorage.setItem('loginName',e.account.username);
    }});
    }
  //   setLoginSave(true)
  // }
  },[])

  useEffect(() => {
    //regenerate access token before log out called
    const token = sessionStorage.getItem(`login`);
    const refresh_token = sessionStorage.getItem(`refreshtoken`);
    console.log(`refreshing the token`, token, refresh_token);
    if (sessionStorage.getItem("appAuthType") === "forgerock") {
      // on reload regenerate access token
      if (token && refresh_token)
        ForgerockAuthRefresh({ refresh_token: refresh_token }, async (res: any | null) => {
          sessionStorage.setItem("login", res.access_token)
          sessionStorage.setItem("refreshtoken", res.refresh_token)
          delete axios.defaults.headers.common["Authorization"];
          axios.defaults.headers.common["Authorization"] = "Bearer " + res.access_token;

          dispatch(setAccessToken({ token: res.access_token }))
          dispatch(setRefreshToken({ refresh_token: res.refresh_token }));

          getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
            console.log(res);
            setIsLoggingSilently(false);
            if (res) {
              dispatch(setUserVirtualNumber(
                {
                  EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                  Id: res[0].Id
                }
              ))
              dispatch(setUserDetails(
                {
                  // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                  EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                  EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                  EmployeeFull_Nm: res[0].EmployeeFull_Nm
                }
              ))
              setIsLoggedIn(true);
            }
          })
        })
      // setting the interval for the next call
      const interval = setInterval(async () => {
        await ForgerockAuthRefresh({ refresh_token: sessionStorage.getItem("refreshtoken") }, async (res: any | null) => {
          if (!res) {
            clearInterval(interval)
            return
          }
          if (res.error) {
            clearInterval(interval)
          }
          sessionStorage.setItem("login", res.access_token)
          sessionStorage.setItem("refreshtoken", res.refresh_token)
          delete axios.defaults.headers.common["Authorization"];
          axios.defaults.headers.common["Authorization"] = "Bearer " + res.access_token;

          dispatch(setAccessToken({ token: res.access_token }))
          dispatch(setRefreshToken({ refresh_token: res.refresh_token }));

          getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
            console.log(res);
            setIsLoggingSilently(false);
            if (res) {
              dispatch(setUserVirtualNumber(
                {
                  EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                  Id: res[0].Id
                }
              ))
              dispatch(setUserDetails(
                {
                  // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                  EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                  EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                  EmployeeFull_Nm: res[0].EmployeeFull_Nm
                }
              ))
              setIsLoggedIn(true);
            }
          })
        })
      }, 30 * 60 * 1000) //30 minutes
    }
  }, [])


  axios.interceptors.request.use((request: any) => {
    console.log(request.url);
    // Edit request config
    // alert("Token: " + sessionStorage.getItem('login'));
    if (!request.url.includes(config.REACT_APP_AUTH_API_BASE+"/auth/token") && !request.url.includes(config.REACT_APP_AUTH_API_BASE+ "/auth/app-version?version=") && !request.url.includes(config.REACT_APP_AUTH_API_BASE + "/auth/login-type/?version=") && (sessionStorage.getItem('login') == null || sessionStorage.getItem('login') == "")) {
      throw new axios.Cancel('');
    }
    request.headers.Authorization = "Bearer " + sessionStorage.getItem('login');
    console.log(request)
    return request;
  }, error => {
    console.log(error);
    return Promise.reject(error);
  });

  let isRefreshed = false;

  axios.interceptors.response.use(response => {
    return response;
  }, async error => {
    if (sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "" && IsLoggedIn && !IsLoggingSilently) {
      console.log("---before login----");
      console.log(error);
      const originalRequest = error.config;
      let newAt="";
      if (error.response) {
        if (config.REACT_APP_AUTH_SESSION_TIMEOUT_ENABLED && error.response.status === 440) {
          if(config.REACT_APP_IS_CROSS_PLATFORM) {
            await Dialog.alert({
              title: '',
              message: config.REACT_APP_SESSION_EXPIRED_MESSAGE,
              buttonTitle: "Return to Login"
            });
            setIsLoggingSilently(false);
            LogoutPing()            
          } else {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/"
          }
        }
        console.log(`before refreshed`,isRefreshed)
        if (error.response.status === 401 && !isRefreshed) {
          if (sessionStorage.getItem("appAuthType") == "pingfederate" || sessionStorage.getItem("appAuthType") == "forgerock") {
            if (sessionStorage.getItem("refreshtoken") && sessionStorage.getItem("refreshtoken") !== "") {
              let payload: any = {
                refresh_token: sessionStorage.getItem("refreshtoken")
              }

              axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("login");
              getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, async (res,status) => {
                if(status === 401 && sessionStorage.getItem("appAuthType") === "forgerock"){
                  isRefreshed = true;
                  ForgerockAuthRefresh(payload, async (res: any | null) => {
                    sessionStorage.setItem("login", res.access_token)
                    sessionStorage.setItem("refreshtoken", res.refresh_token)
                    delete axios.defaults.headers.common["Authorization"];
                    axios.defaults.headers.common["Authorization"] = "Bearer " + res.access_token;
            
                    dispatch(setAccessToken({ token: res.access_token }))
                    dispatch(setRefreshToken({ refresh_token: res.refresh_token }));
            
                    getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
                      if (res) {
                        dispatch(setUserVirtualNumber(
                          {
                            EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                            Id: res[0].Id
                          }
                        ))
                        dispatch(setUserDetails(
                          {
                            // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                            EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                            EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                            EmployeeFull_Nm: res[0].EmployeeFull_Nm
                          }
                        ))
                        setIsLoggedIn(true);
                        originalRequest.headers.Authorization = "Bearer " + res.access_token;
                        return axios(originalRequest);
                      }
                    })
                  })
                }
                if (res) {
                  //do nothing
                } else {
                  if (sessionStorage.getItem("appAuthType") === "forgerock") {
                    LogoutPing();
                  }
                  else {
                    PingAuthRefresh(payload, async (res) => {
                      if (res.access_token != null) {
                        console.log(res);
                        if (res.refresh_token != null) {
                          sessionStorage.setItem("refreshtoken", res.refresh_token);
                        }
                        // axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                        sessionStorage.setItem("login", res.access_token);

                        if (config.REACT_APP_IS_CROSS_PLATFORM) {
                          await createDatabase();
                          await addUserDetails(res.access_token, res.refresh_token);
                        }

                        dispatch(setLoginDetails({ 'token': res.access_token, 'userName': "", 'userAccount': null }));
                        dispatch(setChatShowChat(false));
                        dispatch(setCallModalView(false));
                        const newToken = res.access_token
                        axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
                        //if api call is success add in local storage and give access to the user 
                        getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
                          console.log(res);
                          if (res){
                            dispatch(setUserVirtualNumber(
                              {
                                EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                                Id: res[0].Id
                              }
                            ))
                          dispatch(setUserDetails(
                            {
                              // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                              EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                              EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                              EmployeeFull_Nm: res[0].EmployeeFull_Nm
                            }
                          ))
                          setIsLoggedIn(true);
                          originalRequest.headers.Authorization = "Bearer " + newToken;
                          return axios(originalRequest);
                        } else {
                          LogoutPing();
                        }
                        })
                      } else {
                        if (sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "") {
                          LogoutPing();
                        }
                      }

                    })
                  }
                }
              });
            }
            else {
              if (sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "") {
                axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("login");
                getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
                  if (res) {
                  } else {
                    LogoutPing();
                  }
                })
              }
            }
          } else if (sessionStorage.getItem("appAuthType") == "AD") {
            originalRequest._retry = true;
            let currentAccount: any = msalInstance.getAccountByUsername(loginDetails as string);
            let silentRequest: any = {
              scopes: loginRequest.scopes,
              account: currentAccount,
              forceRefresh: false
            };
            let request = {
              scopes: loginRequest.scopes,
              loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
            };
            const tokenResponse: any = await msalInstance.acquireTokenSilent(silentRequest).catch(error => {
              if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect(request as any)
              }
            });
            if (tokenResponse) {
              sessionStorage.setItem("login", tokenResponse.accessToken)
              dispatch(setLoginDetails({ 'token': tokenResponse.accessToken, 'userName': tokenResponse.account.username, 'userAccount': tokenResponse }));
              axios.defaults.headers.common["Authorization"] = "Bearer " + tokenResponse.accessToken;
              originalRequest.headers.Authorization = "Bearer " + tokenResponse.accessToken;
              return axios(originalRequest);
            }
          }
        }
        console.log(`after refreshed`,isRefreshed)
        return Promise.reject(error);
      }
    }
  });

  const saveLoginDetails = async (email) =>{
    try {
      await axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/login-details?email=" + email + "&device=" + mobile + "&version=" + (appVersion || ""))
    } catch (error) {
      console.log("Error in saving login details", error)
    }
  }


  function silentLoginMobileApp(users) {
    // alert("1");
    if(sessionStorage.getItem("appAuthType")=="AD") {
      capacitormsal.signinSilent({
        authority: config.REACT_APP_AD_AUTHORITY as string,
        clientId: config.REACT_APP_AD_CLIENTID as string,
        redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
        scope: config.REACT_APP_CROSS_SCOPE as string,
        })
        .then(async (response) => {
            if(response["accesstoken"] && response["accesstoken"] !== "") {
                console.log("---Silent login success----");
                console.log(response["accesstoken"]);
                dispatch(setChatShowChat(false));
                dispatch(setCallModalView(false));
                axios.defaults.headers.common["Authorization"] = "Bearer "+response["accesstoken"];
                saveLoginDetails(response["name"])
                sessionStorage.setItem("login",response["accesstoken"]);
                sessionStorage.setItem('loginName',response["name"]);
                dispatch(setLoginDetails({'token':response["accesstoken"],'userName':response["name"],'userAccount':null}));
                registerNotifications();
                checkBadgePermissions();
                getAuthApi({authToken:"Bearer "+response["accesstoken"],type:sessionStorage.getItem("appAuthType")},async (res)=>{
                    if(res) {
                        dispatch(setUserVirtualNumber({
                            EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                            Id: res[0].Id
                        }))

                        dispatch(setUserDetails({
                            EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                            EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                            EmployeeFull_Nm:res[0].EmployeeFull_Nm
                        }))
                        
                        await createDatabase();
                        await addUserDetails(response["accesstoken"], "");
                        console.log("---Silent Token Done----");
                        setIsLoggingSilently(false);
                        setIsLoggedIn(true);
                    }
                });
            } else {
                console.log("---Silent login fail----");
                setIsLoggingSilently(false);
                setIsLoggedIn(false);
            }
        })
        .catch(error => {
            console.log("Error");
            console.log(error);
            console.log("---Silent login fail----");
            setIsLoggingSilently(false);
            setIsLoggedIn(false);
        })

    } else {
      let previousAccessToken = users[0].authToken;
      let previousRefreshToken = users[0].refreshToken;
      // alert(previousAccessToken);

      dispatch(setChatShowChat(false));
      dispatch(setCallModalView(false));
      axios.defaults.headers.common["Authorization"] = "Bearer " + previousAccessToken;
      sessionStorage.setItem("login", previousAccessToken);
      sessionStorage.setItem("refreshtoken",previousRefreshToken);
      sessionStorage.setItem('loginName', "");
      dispatch(setLoginDetails({'token': previousAccessToken,'userName':"",'userAccount':null}));
      registerNotifications();
      checkBadgePermissions();
      getAuthApi({authToken:"",type:sessionStorage.getItem("appAuthType")},async (res)=>{
          // alert(JSON.stringify(res));
          if(res) {
              dispatch(setUserVirtualNumber({
                  EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                  Id: res[0].Id
              }))

              dispatch(setUserDetails({
                  EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                  EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                  EmployeeFull_Nm:res[0].EmployeeFull_Nm
              }))
              
              await createDatabase();
              await addUserDetails(previousAccessToken, previousRefreshToken);
              console.log("---Silent Token Done----");
              setIsLoggingSilently(false);
              setIsLoggedIn(true);
              // alert("11");

          } else {
            // alert("12");
            setIsLoggingSilently(false);
          }
        })
    }
  }
  function LogoutPing(){
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      updatePushToken({
        "DeviceToken_Val": sessionStorage.getItem("pushToken"),
        "Device_Typ": "iOS",
        "is_active": 'N'
      },(res)=>{       
      })
      if(sessionStorage.getItem("appAuthType")=="AD"){
        capacitormsal.signOut({
          authority: config.REACT_APP_AD_AUTHORITY as string,
          clientId: config.REACT_APP_AD_CLIENTID as string,
          redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
          scope: config.REACT_APP_CROSS_SCOPE as string,
          })
          .then(async (response) => {
            sessionStorage.setItem("login", "");
            sessionStorage.setItem("appAuthType", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });               
          })
          .catch(error => {
            sessionStorage.setItem("login", "");
            sessionStorage.setItem("appAuthType", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });
          })
      } else if(sessionStorage.getItem("appAuthType")=="forgerock"){
        ForgerockLogout((res)=>{
          if(res){
            sessionStorage.setItem("login", "");
            sessionStorage.setItem("appAuthType", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });
          }
        })
      } else {
        let browser = InAppBrowser.create(config.REACT_APP_PING_LOGOUT_URL, '_blank', 'location=no');
        browser.on('loadstop').subscribe(async event => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        sessionStorage.setItem("appAuthType", "");
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });
      }
    } else {
      if(sessionStorage.getItem("appAuthType")=="forgerock"){
        ForgerockLogout((res)=>{
          if(res){
            sessionStorage.clear();
            localStorage.clear();
            window.location.href =config.REACT_APP_FORGEROCK_REDIRECT_URL;

          }
        })
      } else {
      sessionStorage.clear();
      localStorage.clear();
      var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
      window.location.href = logoutUrl     
       window.location.href =config.REACT_APP_PING_REDIRECT_URL;
      }

      }            
  }
  useEffect(() => {
    //check if you have code query param
    const state = new URLSearchParams(window.location.search).get("state");
    const code = new URLSearchParams(window.location.search).get("code");
    // console.log(state,code) 
    //if yes call api and show the loader and not the loggin button
    if (!config.REACT_APP_IS_CROSS_PLATFORM) {
      if (state && code) {
        setLoading(true);
        let payload: any = {
          code: code,
        }
        console.log(payload);
        //  alert(payload);
        sessionStorage.clear();
        localStorage.clear();
        if (sessionStorage.getItem("appAuthType") === "forgerock") {
          ForgerockAuthApi(payload, (res) => {
            if (res.access_token != null) {
              //alert("Auth API call")
              console.log(res);
              if (res.refresh_token != null) {
                sessionStorage.setItem("refreshtoken", res.refresh_token);
              }
              axios.defaults.headers.common["Authorization"] = "Bearer " + res.access_token;
              sessionStorage.setItem("login", res.access_token);
              dispatch(setChatShowChat(false));
              dispatch(setCallModalView(false));
              //if api call is success add in local storage and give access to the user
              getAuthApi({ authToken: "Bearer " + res.access_token, type: sessionStorage.getItem("appAuthType") }, (res) => {
                console.log(res);
                if (res) {
                  saveLoginDetails(res[0].EmployeeEmail_Adr)
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm: res[0].EmployeeFull_Nm
                    }
                  ))
                  navigate('/Chat', { replace: true })
                  setIsLoggedIn(true);
                  setLoading(false);

                }
              })
            }
            else {
              setIsLoggingSilently(true);
              axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("login");
              getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
                console.log(res);
                setIsLoggingSilently(false);
                if (res) {
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm: res[0].EmployeeFull_Nm
                    }
                  ))
                  setIsLoggedIn(true);
                }
              })
            }
          })
        }
        else {
          PingAuthApi(payload, (res) => {
            ;
            if (res.access_token != null) {
              //alert("Auth API call")
              console.log(res);
              if (res.refresh_token != null) {
                sessionStorage.setItem("refreshtoken", res.refresh_token);
              }
              axios.defaults.headers.common["Authorization"] = "Bearer " + res.access_token;
              sessionStorage.setItem("login", res.access_token);

              dispatch(setLoginDetails({ 'token': res.access_token, 'userName': "", 'userAccount': null }));
              dispatch(setChatShowChat(false));
              dispatch(setCallModalView(false));
              //if api call is success add in local storage and give access to the user 
              getAuthApi({ authToken: "Bearer " + res.access_token, type: sessionStorage.getItem("appAuthType") }, (res) => {
                console.log(res);
                if (res) {
                  saveLoginDetails(res[0].EmployeeEmail_Adr)
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm: res[0].EmployeeFull_Nm
                    }
                  ))
                  setIsLoggedIn(true);
                  setLoading(false)
                } else {
                  setLoading(false)
                  setIsLoggedIn(false)
                }
              })

            }
            else {
              setIsLoggingSilently(true);
              axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("login");
              getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
                console.log(res);
                setIsLoggingSilently(false);
                if (res) {
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm: res[0].EmployeeFull_Nm
                    }
                  ))
                  setIsLoggedIn(true);
                } else {                 
                  setLoading(false)
                  setIsLoggedIn(false)
                }
              })
            }
          })
        }

      } else {
        if (sessionStorage.getItem("appAuthType") === "pingfederate") {
          if (sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "") {
            setIsLoggingSilently(true);
            axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("login");
            getAuthApi({ authToken: "", type: sessionStorage.getItem("appAuthType") }, (res) => {
              console.log(res);
              setIsLoggingSilently(false);
              if (res) {
                dispatch(setUserVirtualNumber(
                  {
                    EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                    Id: res[0].Id
                  }
                ))
                dispatch(setUserDetails(
                  {
                    // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                    EmployeePh_Nbr: res[0].EmployeePh_Nbr,
                    EmployeeQR_Cd: res[0].EmployeeQR_Cd,
                    EmployeeFull_Nm: res[0].EmployeeFull_Nm
                  }
                ))
                setIsLoggedIn(true);
              }
            })

          }
        } else if (sessionStorage.getItem("appAuthType") === "forgerock") {
          if (sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "") {
            setIsLoggedIn(true);
          }
        }
      }
    }
  }, [])

  function generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
      ""
    );
  }
  var nonce = generateRandomString();
  var state = generateRandomString();
  function encodeQueryData(data) {
    ;
    let ret:any = [];
    for (let d in data){    console.log(data[d]);
       ret.push(encodeURIComponent(d)+ "=" + encodeURIComponent(data[d]));
    }
    return ret.join("&");
  }
  function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
  }
  // function base64urlencode(str) {
  //   
  //   console.log(str);
  //   // return btoa(String.fromCharCode.apply(new Uint8Array(str)));
  //   console.log(typeof(new Uint8Array(str)));
  //  // console.log(String.fromCharCode.apply( new Uint8Array(str)));
  //  console.log(JSON.stringify(new Uint8Array(str)));
  //   let temp=  btoa(JSON.stringify( new Uint8Array(str)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''))
  //  // .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  //   console.log(temp)
  //   return temp;
  //   // return btoa(str)
  //   // .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  //   // // return str.toString('base64')
  //   // .replace(/\+/g, '-')
  //   // .replace(/\//g, '_')
  //   // .replace(/=/g, '');
  // }
  function base64urlencode(a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  async function pkceChallengeFromVerifier(v) {

    let hashed:any = await sha256(v);
    return base64urlencode(hashed);
  }
  
  async function handleLogin ()  {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      if(sessionStorage.getItem("appAuthType")=="AD"){
        capacitormsal.signin({
          authority: config.REACT_APP_AD_AUTHORITY as string,
          clientId: config.REACT_APP_AD_CLIENTID as string,
          redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
          scope: config.REACT_APP_CROSS_SCOPE as string,
        })
          .then(async (response) => {
              setIsLoggingSilently(true);
              registerNotifications();
              checkBadgePermissions();
              axios.defaults.headers.common["Authorization"] = "Bearer "+response["accesstoken"];
              sessionStorage.setItem("login",response["accesstoken"]);
              sessionStorage.setItem('loginName',response["name"]);
              saveLoginDetails(response["name"])
              dispatch(setLoginDetails({'token':response["accesstoken"],'userName':response["name"],'userAccount':null}));
              dispatch(setChatShowChat(false));
              dispatch(setCallModalView(false));
              
              
              getAuthApi({authToken:"Bearer "+response["accesstoken"],type:sessionStorage.getItem("appAuthType")},async (res)=>{ 
                if(res) {
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                    }
                  ))
                  
                  await createDatabase();
                  await addUserDetails(response["accesstoken"], "");
                  let user = await getUserDetails();
                  setIsLoggingSilently(false);
                  setIsLoggedIn(true);

                } else {
                  setIsLoggingSilently(false);
                  setIsLoggedIn(false);
                  LogoutPing();
                }
              });
          })
          .catch(error => {
              console.log("Error");
              console.log(error);
              setIsLoggingSilently(false);
              setIsLoggedIn(false);
          })
        } 
        else {
          var code_verifier = generateRandomString();
          var code_challenge = await pkceChallengeFromVerifier(code_verifier);
          const oAuthurl = config.REACT_APP_PING_OAUTH_URL;
          const params = {
            client_id: config.REACT_APP_PING_CLIENTID,
            redirect_uri: config.REACT_APP_PING_REDIRECT_URL,
            response_type: ["code"],
            scope: "openid profile email offline_access",
            state: state
          };
      
  
          sessionStorage.clear();
          localStorage.clear();
          // Build the authorization URL
          const url = oAuthurl + "?" + encodeQueryData(params);
          console.log(url);
          let browser = InAppBrowser.create(url, '_blank', 'location=no');
          browser.on('loadstart').subscribe(async event => {
              console.log("---event.url---");
              console.log(event.url);
              if(event.url.startsWith(config.REACT_APP_PING_REDIRECT_URL)) {
                  let code = getParameterByName('code', event.url);
                  let state = getParameterByName('state', event.url);
                  browser.close();
                  // alert("--code--" + code);
                  if(code && code !=="") {
                    let payload: any= {
                      code: code
                    }
                    setIsLoggingSilently(true);
                    PingAuthApi(payload,(res)=>{
                      //  alert(JSON.stringify(res));
                      // alert("Access token:" + res.access_token);
                      // alert(res.access_token);
                      if(res.access_token!=null) {
                        console.log(res); 
                              
                        axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                        sessionStorage.setItem("login",res.access_token);
                        sessionStorage.setItem("refreshtoken",res.refresh_token);
                        sessionStorage.setItem('loginName','');
                        dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
                        dispatch(setChatShowChat(false));
                        dispatch(setCallModalView(false));
                        registerNotifications();
                        checkBadgePermissions();
                        //if api call is success add in local storage and give access to the user 
                        getAuthApi({authToken:"", type:sessionStorage.getItem("appAuthType")},async (apires)=>{
                          //alert(JSON.stringify(apires));
                          console.log(apires);
                          if(apires) {
                            saveLoginDetails(apires[0].EmployeeEmail_Adr)
                            dispatch(setUserVirtualNumber(
                              {
                                EmployeeV_Nbr: apires[0].EmployeeV_Nbr,
                                Id: apires[0].Id
                              }
                            ))
                            dispatch(setUserDetails(
                              {
                                // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                                EmployeePh_Nbr:apires[0].EmployeePh_Nbr,
                                EmployeeQR_Cd:apires[0].EmployeeQR_Cd,
                                EmployeeFull_Nm:apires[0].EmployeeFull_Nm
                              }
                            ))
                            await createDatabase();
                            await addUserDetails(res.access_token, res.refresh_token);
                            let user = await getUserDetails();
                            setIsLoggedIn(true);
                            setIsLoggingSilently(false);
                          } else {
                            setIsLoggingSilently(false);
                          }
                        })
                      } else {
                        setIsLoggingSilently(false);
                      }
              
                    })
                  }
              }
          });
        }
    } 
    else {
      if(sessionStorage.getItem("appAuthType")=="AD"){
      msalInstance.loginRedirect(loginRequest).then(e=>{
        console.log(e)
      }).catch((e:any) => {
        console.log(e);
         
      });
      }
      else
      {
        var code_verifier = generateRandomString();
        var code_challenge = await pkceChallengeFromVerifier(code_verifier);
        const oAuthurl = config.REACT_APP_PING_OAUTH_URL;
        const params = {
          client_id: config.REACT_APP_PING_CLIENTID,
          redirect_uri: config.REACT_APP_PING_REDIRECT_URL,
          response_type: ["code"],
          scope: "openid profile email offline_access",
          state: state
        };
    
        
        sessionStorage.clear();
        localStorage.clear();
        // Build the authorization URL
        const url = oAuthurl + "?" + encodeQueryData(params); 
        console.log(url);
        // const authClient = new PingOneAuthClient({
        //   environmentId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        //   clientId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        //   redirectUri: 'http://localhost:8080',
        //   postLogoutRedirectUri: 'http://localhost:8080',
        //   scopes: ['openid','profile', 'email', 'address'],
        //   responseType: ['token', 'id_token'],
        //   pkce: false
        // });
        // authClient.signIn();
        console.log(url);
        window.location.href=url;

      }
    }

    saveDataToCache('showForceUpdatePopup', true)
  }

  async function handleForgerockLogin(){
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
            var code_verifier = generateRandomString();
            var code_challenge = await pkceChallengeFromVerifier(code_verifier);
            const oAuthurl = config.REACT_APP_FORGEROCK_OAUTH_URL+"/authorize"
            const params = {
              client_id: config.REACT_APP_FORGEROCK_CLIENTID,
              redirect_uri: config.REACT_APP_FORGEROCK_REDIRECT_URL,
              response_type: ["code"],
              scope: "openid profile email",
              state: state
            };
            sessionStorage.clear();
            localStorage.clear();
            // Build the authorization URL
            const url = oAuthurl + "?" + encodeQueryData(params);
            console.log(url);
            let browser = InAppBrowser.create(url, '_blank', 'location=no');
            browser.on('loadstart').subscribe(async event => {
                console.log("---event.url---");
                console.log(event.url);
                if(event.url.startsWith(config.REACT_APP_PING_REDIRECT_URL)) {
                    let code = getParameterByName('code', event.url);
                    let state = getParameterByName('state', event.url);
                    browser.close();
                    // alert("--code--" + code);
                    if(code && code !=="") {
                      let payload: any= {
                        code: code
                      }
                      setIsLoggingSilently(true);
                      ForgerockAuthApi(payload,(res)=>{
                        //  alert(JSON.stringify(res));
                        // alert("Access token:" + res.access_token);
                        // alert(res.access_token);
                        if(res.access_token!=null) {
                          console.log(res); 
                                
                          axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                          sessionStorage.setItem("login",res.access_token);
                          sessionStorage.setItem("refreshtoken",res.refresh_token);
                          sessionStorage.setItem('loginName','');
                          dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
                          dispatch(setChatShowChat(false));
                          dispatch(setCallModalView(false));
                          registerNotifications();
                          checkBadgePermissions();
                          //if api call is success add in local storage and give access to the user 
                          getAuthApi({authToken:"", type:sessionStorage.getItem("appAuthType")},async (apires)=>{
                            //alert(JSON.stringify(apires));
                            console.log(apires);
                            if(apires) {
                              saveLoginDetails(apires[0].EmployeeEmail_Adr)
                              dispatch(setUserVirtualNumber(
                                {
                                  EmployeeV_Nbr: apires[0].EmployeeV_Nbr,
                                  Id: apires[0].Id
                                }
                              ))
                              dispatch(setUserDetails(
                                {
                                  // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                                  EmployeePh_Nbr:apires[0].EmployeePh_Nbr,
                                  EmployeeQR_Cd:apires[0].EmployeeQR_Cd,
                                  EmployeeFull_Nm:apires[0].EmployeeFull_Nm
                                }
                              ))
                              await createDatabase();
                              await addUserDetails(res.access_token, res.refresh_token);
                              let user = await getUserDetails();
                              setIsLoggedIn(true);
                              setIsLoggingSilently(false);
                            } else {
                              setIsLoggingSilently(false);
                            }
                          })
                        } else {
                          setIsLoggingSilently(false);
                        }
                
                      })
                    }
                }
            });
          
      } else {
          var code_verifier = generateRandomString();
          var code_challenge = await pkceChallengeFromVerifier(code_verifier);
          const oAuthurl = config.REACT_APP_FORGEROCK_OAUTH_URL+"/authorize";
          const params = {
            client_id: config.REACT_APP_FORGEROCK_CLIENTID,
            redirect_uri: config.REACT_APP_FORGEROCK_REDIRECT_URL,
            response_type: ["code"],
            scope: "openid profile email",
            state: state
          }; 
          sessionStorage.clear();
          localStorage.clear();
          // Build the authorization URL
          const url = oAuthurl + "?" + encodeQueryData(params); 
          console.log(url);
          console.log(url);
          window.location.href=url;
        }
  }

  function getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
  
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
  
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await PushNotifications.register();

  }

  const checkBadgePermissions = async () => {
    const isSupport = await Badge.isSupported();

    if(isSupport.isSupported) {
      let permStatus = await Badge.checkPermissions();

      if(permStatus.display === 'prompt'){
        permStatus = await Badge.requestPermissions();
      }

      if (permStatus.display !== 'granted') {
        throw new Error('User denied permissions!');
      }
    }
  }
  
  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }



  const saveDataToCache = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error('Error saving data:', e);
    }
  };
  

 
  return (
    <>
    {(isLoading || isLoadingAuthType) &&
        <div className="intial-loader">
          {/* <div className="init-page-loader"></div> */}
          <img src={initLogo} alt=""></img>
          <div className="init-page-loader-div">
          <div className="init-page-loader" >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          </div>
         
        </div>
      
      }

    { IsCrossPlatForm && IsLoggedIn && !isLoading && (
          <Home />
    )}
    { IsCrossPlatForm && !IsLoggedIn && (
              <div className="login-layout-bg login-bg">
              
              <div className="login-left-div">
                <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
              </div>
              {(IsLoggingSilently) ? <Spinner /> :
              <div className="login-right-div">
                <h1 className="login-text font--xl">{t("let's sign you in")}</h1>
                <h4 className="login-text font--lg">
                  {t('Your organization uses the following login modes to access the application')}
                </h4>
              <button
                  className="rounded-primary-40 btn font--btn"
                  type="button"
                  onClick={handleLogin}
                >
                  {IsPingLogIn ? t('Login with PING') : sessionStorage.getItem("appAuthType")==="forgerock"?t('Iniciar sesión'):t('Login with AD')}
                </button>
                <div className="login-text font--lg version-code-text">{t('Versión: ')+ appVersion}</div>
              </div>
              }
            </div>
    )}

    { !IsCrossPlatForm && !IsPingLogIn && !IsForgeRockLogIn &&(
      <><AuthenticatedTemplate>
          <Home /> {/*HomePage*/}
        </AuthenticatedTemplate><UnauthenticatedTemplate>
            <div className="login-layout-bg login-bg">

              <div className="login-left-div">
                <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
              </div>

              <div className="login-right-div">
                <h1 className="login-text font--xl">{t('let\'s sign you in')}</h1>
                <h4 className="login-text font--lg">
                  {t('Your organization uses the following login modes to access the application')}
                </h4>
                <button
                  className="rounded-primary-40 btn font--btn"
                  type="button"
                  onClick={async () => {await handleLogin();} }
                >
                  {t('Login with AD')}
                </button>


              </div>
            </div>
          </UnauthenticatedTemplate></>
    )

    
    }
    {!IsCrossPlatForm && IsLoggedIn && (IsPingLogIn ||IsForgeRockLogIn) && (
          <Home />
    )}
    
    { !IsCrossPlatForm && !IsLoggedIn && IsPingLogIn && !IsForgeRockLogIn &&(
           

              <div className="login-layout-bg login-bg">
              
              <div className="login-left-div">
                <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
              </div>
              {(IsLoggingSilently) ? <Spinner /> :
              <div className="login-right-div">
                <h1 className="login-text font--xl">{t("let's sign you in")}</h1>
                <h4 className="login-text font--lg">
                  {t('Your organization uses the following login modes to access the application')}
                </h4>
              <button
                  className="rounded-primary-40 btn font--btn"
                  type="button"
                  onClick={handleLogin}
                >
                  {t('Login with PING')}
                </button>
              </div>
              }
            </div>
    )}

{ !IsCrossPlatForm && !IsLoggedIn && !IsPingLogIn && IsForgeRockLogIn &&(
           

           <div className="login-layout-bg login-bg">
           
           <div className="login-left-div">
             <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
           </div>
           {(IsLoggingSilently) ? <Spinner /> :
           <div className="login-right-div">
             <h1 className="login-text font--xl">{t("let's sign you in")}</h1>
             <h4 className="login-text font--lg">
               {t('Your organization uses the following login modes to access the application')}
             </h4>
           <button
               className="rounded-primary-40 btn font--btn"
               type="button"
               onClick={handleForgerockLogin}
             >
               {t('Iniciar sesión')}
             </button>
           </div>
           }
         </div>
 )}
  </>
  )
}

export default Login