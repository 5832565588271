import React, { useEffect, useState } from 'react';
import './SharePopup.css';
import { Tooltip } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { useTranslation } from "react-i18next";
import {addRemoveSharedUser,getEmployeeList,getSharedWith, shareFile} from "../../apis/contentSharingApi/contentsharing";

function stringAvatar(name: string,size:string) {
    if(!name){
      name='D P';
    }
    return {
      sx: { 
        cursor:"pointer",
        bgcolor: "#734BD14D",
        color:document.documentElement.style.getPropertyValue('--primary-color'),
        fontSize:"1rem",
        width:size,
        height:size
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function SharePopup(props) {
    const { t } = useTranslation();
    const [sharedWith,setSharedWith]=useState<any>([]); //list of users with whome file folder already shared with
    const [userList,setUserList]=useState<any>([]);     //list of the available users with whome we can share the file
    const [searchVal,setSearchVal]=useState();          //variable used for searching the users
    const [addUsers,setAddUsers]=useState<any>([]);     //list of newly added users before saving on popup
    const [delUsers,setDelUsers]=useState<any>([]);     //list of the deleted users before saving on popup
    
    const removeSharedUser = (i,item) => {
        // const temp = item;
        console.log("gc shared files user remove", item.Id);

        //removing user from the shared list
        let user =sharedWith[i];
        let updatedSharedList = sharedWith.filter((x, index) => item.Id != x.Id);       
        setSharedWith(updatedSharedList);
        
        //adding user back to the list
        let temp =userList;
        temp.push(user);
        setUserList(temp);

        //adding user to the delete list to be shared to api
        let tempDelUsers=delUsers;
        //as we need only id's to be sent
        // tempDelUsers.push(user.Id); 
        tempDelUsers.push({"Id":user.Id});
        setDelUsers(tempDelUsers);
        console.log("shred folders del list",tempDelUsers);

        //remove user from the add users list to be shared with api
        let updatedAddUserList = addUsers.filter((x, index) => item.Id != x.Id);
        setAddUsers(updatedAddUserList);
        console.log("shred folders Add list",updatedAddUserList);
    }

    const addSharedUser = (i,item) => {
        console.log("gc shared files user add ", item.Id);
        //getting user
        let user =userList[i];
        
        //adding new user to the list to be set to api
        let tempAddUser=addUsers;   //list of new user added before save
        //we just need id's to be sent to the api
        // tempAddUser.push(user.Id);
        tempAddUser.push({"Id":user.Id,"AccessType":"r"});
        setAddUsers(tempAddUser);
        console.log("shred folders Add list",tempAddUser);
        
        //adding new user for display on UI
        let temp=sharedWith;        //list of users with whome file or folder already shared
        temp.push(user);
        setSharedWith(temp);
        console.log("shred folders Add list 2",temp);

        //removing the user from the list of user available to share
        let updatedUserList = userList.filter((x, index) => item.Id != x.Id);
        setUserList(updatedUserList); 

        //remove from remove list
        let updatedDelUserList = delUsers.filter((x, index) => item.Id != x.Id);
        setDelUsers(updatedDelUserList);
        console.log("shred folders Del list",updatedDelUserList);        
    }
    
    const getEmployees=(e)=>{
        console.log("e value gc share folder",e.target.value)
        getEmployeeList({page:0,pageSize:10,search:e.target.value},(res)=>{
            if(res) {
                let temp=res.data.data;
                setUserList(temp);
            }
        })        
    }


    const handleSave=()=>{
        console.log("on save payload...");
        let payload={
            "ContentId":props.contentDetails.Id,      
            "ContentType":props.contentDetails.contentType,
            "EmployeesToAdd":addUsers,
            "EmployeesToDelete":delUsers
        
        };
        let newPayload={
            "ItemId": props.contentDetails.Id,      
            "ItemType": "file",
            "EmployeesToBeAdded": addUsers,
            "PermissionLevelTyp": 'r,sh'
        };
        // console.log("on save newPayload",newPayload);

        shareFile(newPayload,(res)=>{
            console.log("adding----------------+ user... ",payload);
    })
        // addRemoveSharedUser(payload,(res)=>{
        //         console.log("adding removing user... ",payload);
        // })
    }

    useEffect(()=>{
        // setSharedWith([{"EmployeeFull_Nm":"Gaurav Chibde"},{"EmployeeFull_Nm":"Parag K"},{"EmployeeFull_Nm": "Abhay Garg"}]);
        // setUserList([{"EmployeeFull_Nm":"Aaron Cordeiro"},{"EmployeeFull_Nm":"Rahul Tule"},{"EmployeeFull_Nm":"Rohan Fulzele"}]);
        
        // getting all the list of users
        //modification : users who has permission and not in the sharedWith list
        getEmployeeList({page:0,pageSize:10,search:''},(res)=>{
            if(res) {
                
                let temp=res.data.data;
                setUserList(temp);
            }
        })

        //getting list of user with whole it is shared
        getSharedWith({ContentId:1001,ContentType:'FOLDER'},(res)=>{
            if(res) {
                
                let temp=res.data.data;
                setSharedWith(temp);
                console.log("shared with ",temp)
            }
        })
    },[])
    
    return(
        <div className='sharepopup'>
            <div className='sharepoup-main'>
                <div className='sharepopup-header'>
                    <div className='sharepopup-title'>{t('share')}</div>
                    <div className='sharepopup-closebtn' onClick={props.closeSharePopup}>x</div>
                </div>
                <div className='sharepopup-filedetails'>{props.contentDetails?.filePath}</div>
                    <div className='sharepopup-shareduserdiv'>
                        
                            <div  className='sharepopup-shareduserTitle'>{t('shared users')}</div>                   
                            <div className='sharepopup-shareduser'>
                                {sharedWith?
                                    sharedWith.map((item,i)=>{
                                        return(
                                            <div>
                                                <Tooltip title={item.EmployeeFull_Nm} arrow>
                                                <div className='sharepopup-shareduseravatar' >
                                                    
                                                        <Avatar {...stringAvatar(item.EmployeeFull_Nm,"50px")} />
                                                    
                                                </div>
                                                </Tooltip>
                                                <div className='sharepopup-shareduseravatar-closebtn' onClick={(() => removeSharedUser(i,item))}>x</div>
                                            </div>
                                        )
                                    })
                                :null
                                } 
                                {/* <div className='sharepopup-addshareduserbtn'>
                                    <i className="fas fa-solid fa-plus"></i>
                                </div> */}
                            </div>            
                        
                            {/* <div className='sharepopup-addshareduserdiv'>
                                add new shared user
                            </div> */}
                            <div  className='sharepopup-shareduserTitle'>{t('share file/folder with')}</div>     
                            <div className='sharepopup-sharedusersearch'>
                                <input type ="text" value={searchVal} onChange={(e)=>getEmployees(e)}/>   
                            </div>              
                            <div className='sharepopup-sharewithuserlist'>
                                {sharedWith?
                                    userList.map((item,i)=>{
                                        return(
                                            <div className='sharepopup-shareuserlistitem' key={item.id} onClick={(() => addSharedUser(i,item))}>
                                                <Avatar {...stringAvatar(item.EmployeeFull_Nm,"50px")} />
                                                <div>{item.EmployeeFull_Nm}</div>
                                            </div>
                                        )
                                    })
                                :null
                                } 
                            </div>
                    </div>
                <div>
                    <div className='save-btn' onClick={(() => handleSave())}>{t('Save')}</div>
                </div>
            </div>
        </div>
    )
}