import React from 'react'
import LogoutBgImage from '../../assets/images/LogoutBgImage.png'
import "./Logout.css"
import { useTranslation } from "react-i18next";
function Logout() {
  const { t } = useTranslation();

  const handleLogout =()=>{
    alert("Login Success!")
  }

  return (
    <>
    <div className="login-layout-bg login-bg">
    
    <div className="login-left-div">
      <img src={LogoutBgImage} alt="LoginImage" />
    </div>
   
    <div className="login-right-div">
      <h1 className="login-text font--xl">{t('Goodbye')}</h1>
      <h4 className="login-text font--lg">
      {t('You are now logged out of the application')}.
      </h4>
    
      <button
        className="rounded-primary-40 btn font--btn"
        type="button"
        onClick={handleLogout}
      >
        {t('Go back to Login')}
      </button>
      
      
    </div>
  </div>
  </>
  )
}

export default Logout