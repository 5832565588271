import axios from "axios";
import config  from '../../env.json';
function createInfoBipCall(params, callback){
    try {
        axios.post(config.REACT_APP_CALL_API_BASE+"/infobip/start_web_call", params)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback(null)
        })
    } catch (error) {
        callback(null)
    }
}

function createInfoBipCallToken(callback){
    try {
        axios.post(config.REACT_APP_CALL_API_BASE+"/infobip/token")
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback(null)
        })
    } catch (error) {
        callback(null)
    }
}

export {createInfoBipCall, createInfoBipCallToken}