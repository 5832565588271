import axios from "axios";
import config from "../../env.json";
function getPhoneNumberType(params, callback) {
  let { phone } = params;

  try {
    axios
      .get(
        config.REACT_APP_MESSAGE_API_BASE +
          "/checkPhoneNumberTypeRouter?phone_number=" +
          phone
      )
      .then(res => {
        callback(res.data);
      })
      .catch(() => {
        callback([]);
      });
  } catch (error) {
    callback([]);
  }
}
export {getPhoneNumberType}
