import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface loginState {
  token: string;
  userName: string;
  userAccount: any,
  autoPilot: boolean,
  profileSidebarView: boolean,
  mobileProfileSidebar: boolean,
  skipHeader: boolean,
  refresh_token:string,
  loginInterval:number
}
const initialState: loginState = {
  token: '',
  userName: '',
  userAccount: {},
  autoPilot: false,
  profileSidebarView: false,
  mobileProfileSidebar: false,
  skipHeader: false,
  refresh_token:"",
  loginInterval:0
};
export const login = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setLoginDetails: (state, action: PayloadAction<any>) => {
      state.token = action.payload.token;
      state.userName = action.payload.userName;
      state.userAccount = action.payload.userAccount;
    },
    setAutoPilot: (state, action: PayloadAction<any>) => {
      state.autoPilot = action.payload;
    },
    setProfileSidebarView: (state, action: PayloadAction<any>) => {
      state.profileSidebarView = action.payload;
    },
    setMobileProfileSidebarView: (state, action: PayloadAction<any>) => {
      state.mobileProfileSidebar = action.payload;
    },
    setSkipHeader: (state, action: PayloadAction<any>) => {
      console.log("pay", action.payload)
      state.skipHeader = action.payload.skipHeader;
    },
    setRefreshToken:(state,action:PayloadAction<any>) => {
      console.log(state)
      state.refresh_token = action.payload.refreshToken
    },
    setAccessToken:(state,action:PayloadAction<any>)=>{
      console.log(state)
      state.token = action.payload.token
    },
    setLoginInterval:(state,action)=>{
      console.log(state)
      state.loginInterval = action.payload.loginInterval;
    }
  }
});

// this is for dispatch
export const { setLoginDetails, setAutoPilot, setProfileSidebarView, setMobileProfileSidebarView, setSkipHeader, setAccessToken, setRefreshToken, setLoginInterval } = login.actions;

// this is for configureStore
export default login.reducer;