import { AttachmentOutlined, EmailOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../layouts/searchInput/SearchInput';
import "./Email.css";

import DraftEmail from '../../components/DraftEmail/DraftEmail';

import { useDispatch, useSelector } from 'react-redux';
import { setComposeEmailView, setEmailContentView, setEmailDraftView, setEmailInboxView, setEmailSubTitle, setSentEmailView } from '../../app/slice/emailSubMenuSlice';
import Draft from '../../components/Draft/Draft';
import EmailText from '../../components/EmailText/EmailText';
import Inbox from '../../components/Inbox/Inbox';
import config from "../../env.json";
import Sent from '../../components/Sent/Sent';
import ComposeMail from '../../components/composeEmail/composeEmail';
import MobileEmailText from '../../components/MobileEmailText/MobileEmailText';
import Button from '../../layouts/button/Button';
import MobileCompose from '../../components/MobileCompose/MobileCompose';
import MobileDraftMail from '../../components/MobileDraftMail/MobileDraftMail';

export default function Email(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getIndoxView = useSelector((state: any) => state.email.showInbox)
  const getDraftView = useSelector((state: any) => state.email.showDraft)
  const getComposeView = useSelector((state: any) => state.email.showComposeEmail)
  const getEmailContentView = useSelector((state: any) => state.email.showMailContent)
  const getSentEmailList = useSelector((state: any) => state.email.showSentEmail)
  const getSubTitle = useSelector((state: any) => state.email.emailSubTitle)
  // const [newChatComponent,setNewChatComponent] = useState(false);
  // const [search, setSearch] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const { open } = props;
  const [emailData, setEmailData] = useState<any>();
  const [isEmailSelected, setisEmailSelected] = useState(false);
  const handleEmailSelect = (data) => {
    console.log("===================\n", data);
    setEmailData(data);
    setisEmailSelected(true);
    // console.log("===================\n", JSON.stringify(emailData, null, 2));
  };

  useEffect(() => {
    // getCallLogData();
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t("Email"));
  }, []);

  function getComposeMail() {
    dispatch(setComposeEmailView(true));
    dispatch(setEmailContentView(false));
    dispatch(setEmailDraftView(false)); 
    dispatch(setSentEmailView(false));
     dispatch(setEmailInboxView(false)); 
     dispatch(setEmailSubTitle('Compose')); 
     dispatch(setEmailInboxView(false))
   // throw new Error('Function not implemented.');
  }



  // function Dropdown() {
  //   document.getElementById("HeaderLeft").classList.toggle("show");
  // }

  // // Close the dropdown if the user clicks outside of it
  // window.onclick = function(event) {
  //   if (!event.target.matches('.dropbtn')) {
  //     var dropdowns = document.getElementsByClassName("dropdown-content");
  //     var i;
  //     for (i = 0; i < dropdowns.length; i++) {
  //       var openDropdown = dropdowns[i];
  //       if (openDropdown.classList.contains('show')) {
  //         openDropdown.classList.remove('show');
  //       }
  //     }
  //   }
  // }

  // const SearchBar = () => {
  //   const [searchTerm, setSearchTerm] = useState('');

  //   const handleChange = event => {
  //     setSearchTerm(event.target.value);
  //   };

  //   const handleSubmit = event => {
  //     event.preventDefault();
  //     // Perform the email search here, using the searchTerm value
  //   };
  // }

  return (
    <>

      <div className="EmailContainer">

        <div className="Email_Header">
          <div className="HeaderLeft" onClick={() => { setShowSidebar(true) }}>
            <h1>{getSubTitle}</h1>


          </div>


          <div className="HeaderMiddle">
            {/* <SearchInput
              id={'allContactSearch'}
              classes={'form-control font-md search-contact'}
              placeholder={t('Search Mail')}
              onChangeValue={
                (e) => {
                  console.log(e)
                }
              }
            ></SearchInput> */}
          </div>

          <div className="HeaderRight">
            {getComposeView? <button onClick={() => { dispatch(setEmailInboxView(true));dispatch(setComposeEmailView(false)); }} className="button-86" role="button">Inbox </button>:
            <button onClick={() => { getComposeMail() }} className="button-86" role="button">Compose + </button>}
          </div>

        </div>


        {/*    Inbox Container Web*/}
        {getIndoxView && !config.REACT_APP_IS_CROSS_PLATFORM ?

          <Grid container spacing={0} className="Inbox-Container">

            <Grid item lg={3} xs={12} md={12}>
              <Inbox handleEmailSelect={handleEmailSelect} />
            </Grid>
            <Grid className="mailContent" lg={9} xs={12} md={12}>
              {isEmailSelected && !config.REACT_APP_IS_CROSS_PLATFORM && getEmailContentView ? (
                <EmailText email={emailData} />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    justifyContent: "center",
                    marginTop: 50,
                  }}
                >
                  Select any email
                </div>
              )}
            </Grid>
          </Grid> : null}



        {/*  Inbox Container Mobile*/}
        {getIndoxView && config.REACT_APP_IS_CROSS_PLATFORM ?

          <Grid container spacing={0} className="Inbox-Container">

            {!isEmailSelected ? <Grid item lg={3} xs={12} md={12}>
              <Inbox handleEmailSelect={handleEmailSelect} />
            </Grid> : null}
            <Grid className="mailContent" lg={9} xs={12} md={12}>
              {isEmailSelected && getIndoxView && config.REACT_APP_IS_CROSS_PLATFORM ? (
                <MobileEmailText email={emailData} handleClose={()=>{
                  setisEmailSelected(false);
                }} />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    justifyContent: "center",
                    marginTop: 50,
                  }}
                >
                  Select any email.
                </div>
              )}
            </Grid>
          </Grid> : null}




        {/* Sent Container Web */}

            {getSentEmailList && !config.REACT_APP_IS_CROSS_PLATFORM ?

    <Grid container spacing={0} className="Inbox-Container">

      <Grid item lg={3} xs={12} md={12}>
        <Sent handleEmailSelect={handleEmailSelect} />
      </Grid>
      <Grid className="mailContent" lg={9} xs={12} md={12}>
        {isEmailSelected && !config.REACT_APP_IS_CROSS_PLATFORM && getEmailContentView ? (
          <EmailText email={emailData} />
        ) : (
          <div
            style={{
              textAlign: "center",
              verticalAlign: "center",
              justifyContent: "center",
              marginTop: 50,
            }}
          >
            Select any email
          </div>
        )}
      </Grid>
    </Grid> : null}

        {/* {!config.REACT_APP_IS_CROSS_PLATFORM && getSentEmailList ?
          <Grid container spacing={0} className="Sent-Container">

            {!isEmailSelected ? <Grid item lg={3} xs={12} md={12}>
              <Sent handleEmailSelect={handleEmailSelect} />
            </Grid> : null}
            <Grid className="mailContent" lg={9} xs={12} md={12}>
              {isEmailSelected && !config.REACT_APP_IS_CROSS_PLATFORM ? (
                <div>
                  <EmailText email={emailData} />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    justifyContent: "center",
                    marginTop: 50,
                  }}
                >
                  Select any email
                </div>
              )}
            </Grid>

          </Grid> : null} */}



        {/*Sent Container Mobile*/}
        {getSentEmailList && config.REACT_APP_IS_CROSS_PLATFORM ?

          <Grid container spacing={0} className="Inbox-Container">

            {!isEmailSelected ? <Grid item lg={3} xs={12} md={12}>
              <Sent handleEmailSelect={handleEmailSelect} />
            </Grid> : null}
            <Grid className="mailContent" lg={9} xs={12} md={12}>
              {isEmailSelected && config.REACT_APP_IS_CROSS_PLATFORM ? (
                <MobileEmailText email={emailData} handleClose={()=>{
                  setisEmailSelected(false);
                }} />
              ) : null}
            </Grid>
          </Grid> : null}

        {/* Draft Container Web */}
        {!config.REACT_APP_IS_CROSS_PLATFORM && getDraftView ?
          <Grid container spacing={0} className="Sent-Container">
            <Grid item lg={3} xs={12} md={12}>
              <Draft handleEmailSelect={handleEmailSelect} />
            </Grid>

            <Grid className="mailContent" lg={9} xs={12} md={12}>
              {isEmailSelected && getDraftView? (
                <DraftEmail email={emailData} />
              ) : (
                <div className="select-mail">
                  <EmailOutlined
                    style={{ fontSize: "38px", margin: "auto" }}
                  />
                  <p style={{ margin: 0 }}>Select an item to read</p>
                </div>
              )}
            </Grid>
          </Grid> : null}

        {/* Draft Container Mobile */}
        {config.REACT_APP_IS_CROSS_PLATFORM && getDraftView ?
          <Grid container spacing={0} className="Sent-Container">
          {!isEmailSelected?  <Grid item lg={3} xs={12} md={12}>
              <Draft handleEmailSelect={handleEmailSelect} />
            </Grid>:null}

            <Grid className="mailContent" lg={9} xs={12} md={12}>
              {isEmailSelected ? (
                <MobileDraftMail email={emailData} handleClose={()=>{
                  setisEmailSelected(false);
                }} />
              ) : null}
            </Grid>
              </Grid> : null}
 
              {/* {getComposeView ?
                <div>
                  <button onClick={()=>{getComposeMail()}}>Back</button>
                </div>:null} */}

        {getComposeView && !config.REACT_APP_IS_CROSS_PLATFORM ? (
          <Grid container spacing={0} className="Sent-Container">
            <Grid item lg={12} xs={12} md={12}>
              <ComposeMail />
            </Grid></Grid>)
          : null}

        {getComposeView && config.REACT_APP_IS_CROSS_PLATFORM ? (
          <Grid container spacing={0} className="Sent-Container">
            <Grid item lg={12} xs={12} md={12}>
              <MobileCompose />
            </Grid></Grid>)
          : null}


      </div>
    </>
  )
}
