import React, { useState, useEffect } from 'react';
import config  from '../../env.json';
import axios from 'axios';
import AddFileIcon from '../../assets/icons/Add_files.svg'
import AddFolder from '../../assets/icons/Add_folder.svg'
import Spinner from "../../layouts/spinner/Spinner";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  getChild:any;
  fileSelect: any;
  folderTabRef: any;
}

const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, getChild, fileSelect, folderTabRef }) => {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (value && value.length > 0) {
      const apiUrl = `${config.REACT_APP_CONTENT_SHARE_API_BASE}/content/file-folder-search?text=${value}`;
      setIsSearching(true);

    axios.get(apiUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('login')}`
        }
    }).then(res => {
        console.log("data",res.data.data)
        setSearchResults(res.data.data); // Assuming the API response returns an array of search results
      setIsSearching(false);
      setShowDropdown(true)
    })
    .catch(error => {
      console.error('Error fetching search results:', error);
      setIsSearching(false);
    });
    } else {
      setSearchResults([]);
    }
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  return (
    <div style={{"zIndex":1040}}>
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        className="form-control search-input position-relative"
        placeholder="Search..."
        onClick={() => setShowDropdown(!showDropdown)}
        // onBlur={() => setShowDropdown(false)}
      />
        { value && showDropdown && (
        <ul className="pt-5  list-group shadow-lg p-3 bg-white overflow-auto search-input-dropdown search-input-border">
          {
            isSearching ? <div className="d-flex justify-content-center search-input-border bg-white p-2"><Spinner /></div>
            :
            searchResults.length ? searchResults.map(result => (
              <div key={result.Id} className="" onClick={()=>{result.type == 'File'? fileSelect({
                item_url: result.Item_Url,
              }, 'view') : getChild({
                ID: result.Id,
                Item_Nm: result.Item_Nm,
              })
              folderTabRef.current.click()
              setShowDropdown(false)
            }
              }>
              <div className='row m-2'>
                <div className="col-2 p-0">
                  <img 
                  className='border-0 mt-2 search-input-dropdown-icon mx-auto'
                    src={result.type == 'File'? AddFileIcon : AddFolder }
                    alt="folderIcon"
                  />
                </div>
                <div className='col-9 p-0'>
                  <div className='p-0 search-input-dropdown-title text-truncate'>{result.Item_Nm}</div>
                  {/* <br/> */}
                  <div className='p-0 search-input-dropdown-path text-truncate'>{"./path_of_file_folder"}</div>
                </div>
              </div>
              </div>
            ))
            :<div className='mx-auto'>No File Folder found.</div>
          }
        </ul>
        )
      }
    </div>
  );
};

export default SearchInput;
