import React, { ChangeEvent, Component,useEffect,useState } from 'react'




import './SearchInput.css'
import { useDebounce } from 'usehooks-ts'

export default function SearchInput({onChangeValue,placeholder,classes,id}) {
  const [value, setValue] = useState<string>('')
  const debouncedValue = useDebounce<string>(value, 450)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  // Fetch API (optional)
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    onChangeValue(debouncedValue)
  }, [debouncedValue])

  return (
  <div className='genericSearchDiv'>
    <div className="input-group-prepend">
    <span id="basic-addon3" className="input-group-text generic-search-button ">
      <button className="btn contact-search-btn" onClick={() => {}}>
        <i className="fas fa-search i-1p5"></i>
      </button>
    </span>
  </div>
    <input  type="text" className={"generic-search-input "+classes} id={id} placeholder={placeholder} value={value} onChange={handleChange} />
    </div>
  )
}