import { Spinner } from "react-bootstrap";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

interface ConfirmPopupProps {
  show: boolean;
  confirmButtonText: string;
  cancelButtonText: string;
  text: string;
  clickConfirmButtonHandler: () => void;
  clickCancelButtonHandler: () => void;
  disabled: boolean;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  show,
  confirmButtonText,
  cancelButtonText,
  text,
  clickConfirmButtonHandler,
  clickCancelButtonHandler,
  disabled,
}) => {
  return (
    <Modal
      open={show}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={show}>
        <Box>
          <div className="modal-box logout--modal">
            <div className="modal--body">
              <div className="modal--wrapper">
                <p className="modal--content">{text}</p>
                <div className="modal--btn logout-modal-btn">
                  <div className="modal--btn--left">
                    <button
                      disabled={disabled}
                      onClick={() => {
                        clickConfirmButtonHandler();
                      }}
                    >
                      {disabled ? (
                        <Spinner
                          animation="border"
                          className="mt-1 mx-3"
                          size="sm"
                        />
                      ) : (
                        <>
                          <DoneIcon className="logout_page_icons" />
                          {confirmButtonText}
                        </>
                      )}
                    </button>
                  </div>
                  <div className="modal--btn--right">
                    <button
                      disabled={disabled}
                      onClick={() => {
                        clickCancelButtonHandler();
                      }}
                    >
                      <CloseIcon className="logout_page_icons" />
                      {cancelButtonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ConfirmPopup;
