import React, { useEffect, useState } from 'react'
import Avatar from "../../components/Avatar/Avatar";
import './VideoParticipants.css'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CallIcon from '@mui/icons-material/Call';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VideocamIcon from '@mui/icons-material/Videocam';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import { getVideoLogParticipants } from '../../apis/video/video';
import moment from 'moment';
import Spinner from '../../layouts/spinner/Spinner';
import { useTranslation } from "react-i18next";


export default function VideoParticipants(props) {
    const [videoPopup,setVideoPopup]=useState(false);
    const [value,setValue]=useState('');
    const [videoParticipantResult,setVideoParticipantResult]=useState([]) as any;
    const [spin, setSpin] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setSpin(true)
        let doc:any=document;
        let accvideo2= doc.getElementsByClassName("vchat-main");
            // console.log(accvideo2)
            for (var i = 0; i < accvideo2.length; i++) {
             console.log('fakeImage: ', accvideo2[i]);
             accvideo2[i].style.display="block";
        
           }
           console.log(props)
           console.log(props.files);
           let fileid=props.files;
           getVideoLogParticipants({Id:fileid},(res)=>{
            if(res) {
              setSpin(false)
              console.log(res.data.data);
              setVideoParticipantResult(res.data.data);
            }
            // setVideoLogResult(res.data.data);
          })
      }, [])
      const openpopup=(el)=>{
        setValue(el);
        setVideoPopup(true);
      }
    //   const getItemType = (item, outOrnot) => {
    //     if (outOrnot) {
    //       if (item.RequestedBy) {
    //         const val= item.RequestedBy === "" ? "" : item.RequestedBy.split(",")[item.RequestedBy.split(",").length - 1];
    //         console.log(val)
    //         return val;
    //       }
    //     } 
    //     return "";
    //   };
    //   const getUserName =(item:any, outOrnot:boolean)=>{
    //     console.log(item, outOrnot)
    //     if (outOrnot) {
    //       if (item.RequestedBy) {
    //         return (item.RequestedBy);        
    //       } 
    //     return ''
    //   }
    // }
  return (
    <div className='main-chat-details'>
       <div className="vfiles-main notMobile" >
        {/* participants history */}
          <div className='video-files-headers'>
          <div className="video-participant-list-item">{t('Participant Name')}</div>
          <div className="video-participant-list-item">{t('Start time')}</div>
          <div className="video-participant-list-item">{t('End time')}</div>
          <div className="video-participant-list-item">{t('Duration')}</div>
          </div>
        </div>
        <ul className='notMobile'>
        {/* {videoParticipantResult.length==0?:null} */}
        {spin && videoParticipantResult.length==0?<Spinner />: videoParticipantResult.length==0?<div className='validation'>No Participants found</div>:null}
        { videoParticipantResult.map((videoResult:any,key:any) => (
            <>
            <li>
                <div className="vchat-details">
                    <div className='participant-logo'>
                    <Avatar
                     imageType={videoResult.Participant_typ}
                     avatarHeight="2.5rem"
                     avatarWidth="2.5rem"
                     presOrAssoc={videoResult.Participant_Nm}  
                     showAvatarType="initialsOnly"  
                    /> 

                    </div>
                    
                    <div className="participantListItem-content-name">
                            {videoResult.Participant_Nm}
                    </div>   
                    <div
                    className="participantListItem-content-name"
                    >
                    {moment(videoResult.ParticipantStart_DtTm).format('hh:mm A')}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {moment(videoResult.ParticipantEnd_DtTm).format('hh:mm A')}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {/* {(moment.utc(moment(videoResult.ParticipantEnd_DtTm,"hh:mm:ss")).diff(moment(moment(videoResult.ParticipantStart_DtTm,"hh:mm:ss")),"minutes"))==0?"0":(moment(moment(videoResult.ParticipantEnd_DtTm,"hh:mm:ss").utc()).utc().diff(moment(moment(videoResult.ParticipantStart_DtTm,"hh:mm:ss").utc()).utc(),"minutes"))
                } m */}
                    {moment.duration(moment(videoResult.ParticipantEnd_DtTm).diff(moment(videoResult.ParticipantStart_DtTm))).minutes()} m
                 {/* { (moment(moment(videoResult.ParticipantEnd_DtTm,"hh:mm:ss")).diff(moment(moment(videoResult.ParticipantStart_DtTm,"hh:mm:ss")),"seconds")) } secs */}
                    </div >
                </div>
             </li>
             </>
        ))}
             {/* <li>
                <div className="vchat-details">
                    <div className='participant-logo'>
                    <Avatar
                        imageType={"PRESCR"}
                        avatarHeight="2.5rem"
                        avatarWidth="2.5rem"
                        presOrAssoc={"prescriber"}     
                    /> 
                    </div>
                    
                    <div className="participantListItem-content-name">
                            {"Others"}
                    </div>   
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 am'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 pm'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'30 min'}
                    </div >
                </div>
             </li>
             <li>
                <div className="vchat-details">
                    <div className='participant-logo'>
                    <Avatar
                        imageType={"PRESCR"}
                        avatarHeight="2.5rem"
                        avatarWidth="2.5rem"
                        presOrAssoc={"prescriber"}     
                    /> 
                    </div>
                    
                    <div className="participantListItem-content-name">
                            {"Aaron"}
                    </div>   
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 am'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 pm'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'30 min'}
                    </div >
                </div>
             </li>
             <li>
                <div className="vchat-details">
                    <div className='participant-logo'>
                    <Avatar
                        imageType={"PRESCR"}
                        avatarHeight="2.5rem"
                        avatarWidth="2.5rem"
                        presOrAssoc={"prescriber"}     
                    /> 
                    </div>
                    
                    <div className="participantListItem-content-name">
                            {"Prescriber"}
                    </div>   
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 am'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 pm'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'30 min'}
                    </div >
                </div>
             </li>
             <li>
                <div className="vchat-details">
                    <div className='participant-logo'>
                    <Avatar
                        imageType={"PRESCR"}
                        avatarHeight="2.5rem"
                        avatarWidth="2.5rem"
                        presOrAssoc={"prescriber"}     
                    /> 
                    </div>
                    
                    <div className="participantListItem-content-name">
                            {"Prescriber"}
                    </div>   
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 am'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'07:05 pm'}
                    </div >
                    <div
                    className="participantListItem-content-name"
                    >
                    {'30 min'}
                    </div >
                </div>
             </li> */}
        </ul>
        <div className='participants onlyMobile'>
        <ul className='items-view-main'>
        {spin && videoParticipantResult.length==0?<Spinner />: videoParticipantResult.length==0?<div className='mob-validation'>No Participants found</div>:
            <p className='pchat-length'>{videoParticipantResult.length} {videoParticipantResult.length==1?"Participant":"Participants"} </p>}
            {
                videoParticipantResult.map((videoResult:any,key:any)=> (
                    <li>
                <div className="pchat-details">
                    <div className='participant-logo'>
                    <Avatar
                      imageType={videoResult.Participant_typ}
                      avatarHeight="3.5rem"
                      avatarWidth="3.5rem"
                      presOrAssoc={videoResult.Participant_Nm}
                       showAvatarType="initialsOnly" 
                    /> 
                    </div>

                    <div className='pchat-detail-items'>
                    <div className="pchat-detail-item">
                        <p className='pchat-name'>
                            {videoResult.Participant_Nm}
                        </p>
                        <p className='pchat-date'>
                           {moment(videoResult.ParticipantStart_DtTm).format("dddd, Do MMM, hh:mma")}
                        </p>
                    </div>
                    <div className="pchat-detail-item">
                        <p className='pchat-duration'>
                        {(moment.duration(moment(videoResult.ParticipantEnd_DtTm).diff(moment(videoResult.ParticipantStart_DtTm))).minutes())==0?"0":(moment.duration(moment(videoResult.ParticipantEnd_DtTm).diff(moment(videoResult.ParticipantStart_DtTm))).minutes())
                            } mins
                        </p>
                        <p className='pchat-time'>
                           {moment(videoResult.ParticipantEnd_DtTm).format("hh:mma")}
                        </p>
                    </div>
                    </div>

                </div>
             </li>
                ))
             }
        
        </ul>
        </div>
        {/* <div className='file-content'><DescriptionIcon  className='videofileicons'                      
            /> <div className="videofile-details" >Zing work flow builder</div>
            <MoreHorizIcon onClick={()=>openpopup("popup1")}/>
            {videoPopup&&value=='popup1'? <div className='file-popup popup1'>
                    <div className='videopopup-row'>
                            <VideocamIcon    className='videofileicons'                   
                            /> <div className="videopopup-details" >Open</div>
                    </div>
                   
                    <div className='videopopup-row'>
                            <ShareIcon    className='videofileicons'                   
                            /> <div className="videopopup-details" >Share</div>
                    </div>

                      <div className='videopopup-row'>
                            <LinkIcon    className='videofileicons'                   
                            /> <div className="videopopup-details" >Get link</div>
                    </div>
            </div>:null}
        </div>
        
        <div className='file-content'>
            <VideocamIcon    className='videofileicons'                   
            /> <div className="videofile-details" >Zing work flow builder</div>
            <MoreHorizIcon onClick={()=>openpopup("popup2")}/>
            {videoPopup&&value=='popup2'? <div className='file-popup popup1'>
                    <div className='videopopup-row'>
                            <VideocamIcon    className='videofileicons'                   
                            /> <div className="videopopup-details" >Open</div>
                    </div>
                   
                    <div className='videopopup-row'>
                            <ShareIcon    className='videofileicons'                   
                            /> <div className="videopopup-details" >Share</div>
                    </div>

                      <div className='videopopup-row'>
                            <LinkIcon    className='videofileicons'                   
                            /> <div className="videopopup-details" >Get link</div>
                    </div>
            </div>:null}
           
        </div> */}
      
      
    </div>
  )
}
