import { Box, ListItemIcon, ListSubheader } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { getMailById } from "../../apis/Email/Email";
import parse from 'html-react-parser';
import './EmailText.css'
import { data } from "jquery";
import { isImage, isVcard, isAudio, isOtherAudio, isOtherVideo, isPdf, isVideo } from '../../utils/FileTypes';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { setSkipHeader } from '../../app/slice/loginSlice';
export default function AlignItemsList({ email }) {

  const [apicalled, setapicalled] = React.useState(false);
  const [emailListData, setEmailListData] = useState<any>([]);
  const [showprev, setshowprev] = useState(false);
  const [attachments, setAttachments] = useState<Array<any>>([])
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.login.token)
  const fetchData = (id) => {
    getMailById(id, (resp: any) => {
      console.log(resp.data)
      if (resp.data) {
        if(resp.data[0].Attachments.length>0){  setAttachments(resp.data[0].Attachments)}
      }
    });

  }


  const getDate = (Update_DtTm) => {
    const date = new Date(Update_DtTm)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
  }

  const forceFileDownload = (response: any, filename: any) => {

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  const downloadWithAxios = async (url, filename) => {
    delete axios.defaults.headers.common["Authorization"];
    const instance = axios.create();
    const response = await instance.get(url,{responseType:"blob"});
    const blob = response.data
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link); // This line is needed for Firefox
    link.click();
    document.body.removeChild(link); // Clean up after ourselves
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(`login`)}`
  }

  useEffect(() => {

  console.log("id:::::::::::::::", email)
    setshowprev(false)
    if (email) {
      setAttachments([]);
      fetchData(email.EmailConversation_Id);
       setEmailListData([email])
      // let Attachments: Array<any> = [{
      //   "ContentType": "image/png",
      //   "Display_Nm": "app latest.png",
      //   "EmailAttach_Url": "https://p360zingforms.blob.core.windows.net/shared-content/2023-05-04-6:41applatest.png",
      //   "File_Nm": "2023-05-04-6:41applatest.png",
      //   "AttachmentId": 0
      // },
      // {
      //   "ContentType": "application/pdf",
      //   "Display_Nm": "29298-P360-Employee-Handbook-V1.1-India.pdf",
      //   "EmailAttach_Url": "https://p360zingforms.blob.core.windows.net/shared-content/2023-05-15-3:3929298-P360-Employee-Handbook-V1.1-India.pdf",
      //   "File_Nm": "2023-05-15-3:3929298-P360-Employee-Handbook-V1.1-India.pdf",
      //   "Id": 0
      // }]

      // Attachments.map((file)=>{
      //   let blobtoFile = new File([file.EmailAttach_Url],file.DisplayName, { lastModified: new Date().getTime(), type:file.ContentType })
      //   //console.log("::::::::::::::::::::::::::::",blobtoFile);
      //   attachments.push(blobtoFile)
      // })
      // setAttachments(Attachments);
    }
  }, [email.EmailConversation_Id]);


  const convertHtml = (str) => {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    // let string =
    let parser = new DOMParser();
    let dom_document = parser.parseFromString(str, "text/html");
    let body_element = dom_document.getElementsByTagName("body")[0];
    // ////console.log(body_element.innerHTML);
    // let newString = body_element.innerHTML
    // let strippedHtml = newString.replace(/<[^>]+>+/g, "");
    // ////console.log(strippedHtml)
    // let strippedHtml1 = strippedHtml.replace(/\&nbsp;/g, '');
    // let finalEmailText = strippedHtml.includes('From') ? strippedHtml1.split('From')[0] : strippedHtml
    // return finalEmailText
    var docInnerHtML: any = body_element.innerHTML;
    ////console.log(docInnerHtML.getElementsByTagName("div"))

    // tempDiv.innerHTML = htmlString
    return body_element.innerHTML;
  }
  function truncate(source, size) {
    //console.log(source)
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  return (
    <div className="EmailText" style={{ textAlign: "start" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          height: "100vh",
          width: "100%",
          borderBlockEndStyle: 25,
          borderBlockStartStyle: 25,
        }}
      >
        {emailListData?.map((item, index) => {

          return (
            <div key={item.EmailConversation_Id + '-' + item.Id}>

              <List sx={{ width: "100%" }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.EmailConversation_Subject
                    }
                    secondary={<React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        To: {item.EmailTo_Email} <br />
                        cc: {item.EmailCC_Email}
                      </Typography>

                    </React.Fragment>} />
                  {/* {
                    item.Update_DtTm ? <ListItemIcon>
                      <p style={{ fontSize: 12 }}>{getDate(item.Update_DtTm)}</p>
                    </ListItemIcon> : null
                  } */}

                </ListItem>
              </List><div className="mailContent" style={{ padding: "20px", textAlign: "start" }}>

                {attachments.length > 0 ?
                  <div className='attached-media'>

                    {attachments?.map((file: any, index) => {
                      return (
                        <div key={file.Display_Nm + "-" + file.ContentType + "-" + index + '-' + file.lastModifiedDate} id={file.Display_Nm + "-" + file.ContentType + "-" + index + '-' + file.lastModifiedDate}>
                          <div className='file-wrapper'>
                            <div className='media-tb'>
                              <div className="media-left d-flex">
                                <i className="far fa-file-alt"></i>

                              </div>
                              <div>{truncate(file.Display_Nm, 15)}</div>

                            </div>

                          </div>
                          <p onClick={() => {
                            downloadWithAxios(file.EmailAttach_Url, file.Display_Nm)
                          }} className="Download">Download</p>

                        </div>)
                    })}
                  </div>
                  : null}
                <p>
                  {email && email.Email_Bdy && parse(email.Email_Bdy)}
                </p>
              </div>

            </div>
          )
        })}


        {/* {previous?.map((item,index)=>{
 
    
          return( 
            <div key={item.EmailConversation_Id+'-'+item.Id}>
              {index >0?       
              <List sx={{ width: "100%" }}>        
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={email.EmailConversation_Subject}
                    secondary={<React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        To: {item.EmailTo_Email} <br />
                        cc: {item.EmailCC_Email}
                      </Typography>

                    </React.Fragment>} />

                  <ListItemIcon>
                    <p style={{ fontSize: 12 }}>{getDate(item.Update_DtTm)}</p>
                  </ListItemIcon>
                </ListItem>
              </List>:null}
        
      <div className="mailContent" style={{ padding: "20px",textAlign:"start" }}>
        <p>
          {item && item.Email_Bdy && parse(item.Email_Bdy)}
        </p>
      </div>
            </div>
          )
        })} */}

      </Box>
    </div>


  );
}
