
import React,{useState,useEffect, useRef} from 'react'
import { Link } from 'react-router-dom';
import ZingLogoHome from "../../assets/images/ZingLogoHomePage.png"
import "./Sidebar.css"
import axios from 'axios'
import  config  from '../../env.json';
import { useDispatch,useSelector } from 'react-redux';
import {setActiveTab, setChatPermId, setTitle, setAllPermissions} from '../../app/slice/sideBarSlice';
import { setProfileSidebarView, } from '../../app/slice/loginSlice';
import { useTranslation, initReactI18next } from "react-i18next";
import {setUserMsgChannelType} from '../../app/slice/authApiSlice';
import {setUserCallType} from '../../app/slice/personalisationSlice';
import { useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import InboxIcon from '@mui/icons-material/Inbox';
import StarIcon from '@mui/icons-material/Star';
// import SidebarOption from "./SidebarOption"
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import DraftsIcon from '@mui/icons-material/Drafts';
import {setEmailInboxView, setEmailDraftView, setComposeEmailView, setEmailSubTitle, setSentEmailView} from '../../app/slice/emailSubMenuSlice'
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SendIcon from '@mui/icons-material/Send';
import SidebarOption from '../../pages/email/SidebarOption';
import { setUserRole } from '../../app/slice/chatSlice';
function Sidebar() {
  const messageChannelType=useSelector((state:any)=>state.authApi.EmployeeMsgorWhatsAppChannelType)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector((state: any) =>state.sideBar.active)
  const [menuItems,setMenuItems] = useState<any[]>([]);
  const [activeClass,setActiveClass] = useState('');
  const [showEmailSubMenu, setShowEmailSubMenu] = useState('false');
  const [activeLink, setActiveLink] = useState('false');
  const token = useSelector((state: any) => state.login.token)


const getMenuItems = async()=>{

  // axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  axios.defaults.headers.common["Authorization"] = "Bearer " + token
  const menuItems =  axios.get(`${config.REACT_APP_AUTH_API_BASE}/auth/getuserpermissions`,{ headers: {"type":sessionStorage.getItem("appAuthType") as any} })
    .then(response => {
      
      if(response){
        
      if(response.data.message === 'No Menu Options to display'){
        console.log('No Data')
      navigate('/error')
      
      }}
      console.log(response.data)
      response.data.sort((a, b) => parseFloat(a.Display_Or) - parseFloat(b.Display_Or));
      // whatsapp start
      dispatch(setAllPermissions(response.data))
      let userRole = response.data.filter(d => d.Display_Nm === "sAdm")
          if(userRole.length> 0){
            
            console.log(userRole[0].Display_Nm)
            dispatch(setUserRole(userRole[0].Display_Nm))
          } else{
            dispatch(setUserRole('sRep'))
          }

      let x = response.data.filter(d => d.Display_Nm === "WHATSAPP")
        if(x.length>0){
          console.log(x);
          dispatch(setUserMsgChannelType(true))
       } else{
        dispatch(setUserMsgChannelType(false))
       }
       // whatsapp End


      //  Bridge Call start
      let bridgecall= response.data.filter(d => d.Display_Nm === "BridgeCall");
      console.log(bridgecall.length)
      {bridgecall.length>0?dispatch(setUserCallType(true)):dispatch(setUserCallType(false))}
      let y=  response.data.filter(d => d.Display_Or >= 0);
     
   
      setMenuItems(y);
      
      if(!activeTab){
        handleMenuItemClick(y[0]);
      }else
      {
        setActiveClass(activeTab);
      }
      
    //  setDefaultMenuItemSelected(response.data[0]);
    })
    .catch(error => {
        console.error('There was an error!', error);
    });

    
}

const setDefaultMenuItemSelected =(menuItem)=>{
      setActiveClass(menuItem.PermissionsId);
}
useEffect(() => {
  console.log(activeTab)
}, [activeTab])

useEffect(() => {
  
  console.log(activeTab)
//  dispatch(setActiveTab('Chat'))
  getMenuItems();
  // console.log("gc logo URL",config.REACT_APP_logo)
}, [])
  
  const handleMenuItemClick =(item) =>{
    setActiveClass(item.PermissionsId);
    
    dispatch(setTitle(item.Display_Nm == 'Templates'?t('Templates'):item.Display_Nm))
    dispatch(setActiveTab(item.PermissionsId));
    // dispatch(setProfileSidebarView(false))
  }

  const GoToHome =()=>{
    navigate('/Chat')
    let chatPermissionId;
    dispatch(setActiveTab(chatPermissionId ? chatPermissionId : 1004));
  }

  const { t } = useTranslation();
  return (
    <div className="sidebar-container notMobile">

        <div className="zing-logo-div" onClick={()=>GoToHome()}>
          <div className='logo-img'>

          <img src={config.REACT_APP_LOGO} alt="sRep Logo" />

          </div>
        </div>

        <div className="menu-btn-div">
            <ul>
              
              {
                  menuItems?.map(items => {
                    if(items.Display_Nm == "Chat"){
                      dispatch(setChatPermId(items.PermissionsId));
                    }
                    return (<>
                   
                        <Link key={ items.PermissionsId }
                            className={activeTab === items.PermissionsId ? 'active':'' }
                            onClick={(e)=>{ handleMenuItemClick(items) 
                              if(items.Permissions_Nm == "email"){
                                setShowEmailSubMenu('true');
                                // dispatch(setEmailInboxView(true));
                                dispatch(setEmailInboxView(true))
                                dispatch(setComposeEmailView(false))
                                dispatch(setEmailSubTitle('Inbox'))
                              }                           
                             }} 
                            to={"/"+ items.Route_Nm.replace(/ /g,"")}>

                        {/* <i className={items.ClassNameForUI}></i> */}
                        <img className="" style={{ fill: "#ffffff", width: "35px",height:"35px"}} src={items.IconStoredUrl?items.IconStoredUrl:items.ClassNameForUI} alt="menu-icon" /> 
                              <h3>
                                {/* {t(items.Display_Nm == 'Templates'?"Pre-Approved Templates":items.Display_Nm)} */}
                                {t(items.Display_Nm)}
                              </h3>
                              
                              
                      </Link> 
                      {showEmailSubMenu== "true" && items.Permissions_Nm == "email"? 
                              <div className="Email_SubMenu">
                                <div className='list-wrapper'>
                                  <div onClick={()=>{setShowEmailSubMenu('false'); dispatch(setEmailInboxView(true)); dispatch(setComposeEmailView(false)); dispatch(setSentEmailView(false)); dispatch(setEmailDraftView(false));dispatch(setEmailSubTitle('Inbox'))}}>
                                    <Link to={"/"+items.Display_Nm+"/Inbox"} className={(activeLink === 'Inbox' || activeLink === 'false') ? 'active' : ''} onClick={() => { setActiveLink('Inbox')}}>
                                      <InboxIcon /> &nbsp;Inbox
                                    </Link>
                                  </div>

                                  <div onClick={()=>{setShowEmailSubMenu('false'); dispatch(setSentEmailView(true)); dispatch(setComposeEmailView(false)); dispatch(setEmailInboxView(false));  dispatch(setEmailDraftView(false));dispatch(setEmailSubTitle('Sent'))}}>
                                    <Link to={"/"+items.Display_Nm+"/Sent"} className={activeLink === 'Sent' ? 'active' : ''} onClick={() => setActiveLink('Sent')}>
                                      <SendIcon />&nbsp; Sent
                                    </Link>
                                  </div>

                                  {/* <li onClick={()=>{setShowEmailSubMenu('false'); dispatch(setEmailSubTitle('All Mail'))}}><Link to={"/"+items.Display_Nm+"/AllMail"}><AllInboxIcon/> &nbsp;All Mail</Link></li> */}
                                  <div onClick={()=>{setShowEmailSubMenu('false'); dispatch(setEmailDraftView(true)); dispatch(setComposeEmailView(false)); dispatch(setSentEmailView(false));dispatch(setEmailSubTitle('Draft')); dispatch(setEmailInboxView(false))}}><Link to={"/"+items.Display_Nm+"/Draft"} className={activeLink === 'Draft' ? 'active' : ''} onClick={() => setActiveLink('Draft')}><DraftsIcon/>&nbsp; Drafts</Link></div>
                                </div>
                            
                          </div>:null}
                            
                     </>
                    )
                  })
              }
              
                
            </ul>
        </div>

  </div>
  )
}

export default Sidebar