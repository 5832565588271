import { createSlice,PayloadAction } from '@reduxjs/toolkit';



export const initialState: any = {
    localParticipant:null,
    participants:[],
    inactiveParticipants:[],
    dominantSid:0,
    toggleVideo:false,
    toggleVoice:false,
    fullScreenMode:true,
};

export const videoCallCentral = createSlice({
  name: 'videoCall',
  initialState: initialState,
  reducers: {
        setLocalParticipant:(state,action: PayloadAction<any>) => { 
            state.localParticipant=action.payload
        },
        setParticipants:(state,action: PayloadAction<any>) => { 
            state.participants=action.payload
        },
        setInactiveParticipants:(state,action: PayloadAction<any>) => { 
            state.inactiveParticipants=action.payload
        },
        setDominantSid:(state,action: PayloadAction<any>) => { 
            state.dominantSid=action.payload
        },
        setToggleVideo:(state,action: PayloadAction<any>) => { 
            state.toggleVideo=action.payload
        },
        setToggleVoice:(state,action: PayloadAction<any>) => { 
            state.toggleVoice=action.payload
        },
        setFullScreenMode:(state,action: PayloadAction<any>) => { 
            state.fullScreenMode=action.payload
        }
    }
});

// this is for dispatch
export const {
    setLocalParticipant,
    setParticipants ,
    setInactiveParticipants,
    setDominantSid,
    setToggleVideo,
    setToggleVoice,
    setFullScreenMode
} = videoCallCentral.actions;

// this is for configureStore
export default videoCallCentral.reducer;