import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';
import { sendMessageApi } from '../../apis/chat/messageApi';
import { getAOCStatus } from '../../apis/srfApi/srfApi';
import config from '../../env.json';
import Button from '../../layouts/button/Button';

import './Aoc.css'

export default function Aoc() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [aocList,setAocList]=useState<any>([])
    const pageTitle=useSelector((state:any)=>state.sideBar.title)


    useEffect(() => {
      // getCallLogData();
      document.documentElement.style.setProperty("--visibility", "hidden");
      document
        .getElementById("page-name")
        ?.setAttribute("data-content", t(pageTitle));
    }, []);
    useEffect(()=>{
      document.documentElement.style.setProperty('--visibility', 'initial');
      document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));
    },[pageTitle])
    useEffect(()=>{
      getAocData()
        // getAssetsFromCampign(userId)
        // getAocData()
        // console.log(modalFormType)
    },[])
    const getAocData = () =>{
      getAOCStatus((res)=>{
        console.log("", res);
        setAocList(res)
      })
    }
    function sendRemainderForAOC(contact:any){
//const {contact,url}=payload;
//let phone=contact.PrescriberAcctPh_Nbr//getNumberFromItem(contact);
let formData = new FormData();

formData.append("recipient_id",contact.prescriber_id);
formData.append("recipient_name", contact.PrescriberAcctFull_Nm);
formData.append("recipient_phone", contact.PrescriberAcctPh_Nbr);
// if(contact.Is_Prescriber=="Y"){
    formData.append("physcian_id",contact.prescriber_id);
    formData.append("role_id", '0');//for prescriber
// }
// else{
//     formData.append("physcian_id",contact.Prescribers[0].Id);
//     formData.append("role_id", '1');//for other
//     formData.append('association_id',contact.Prescribers[0].Association_Id)
// }

let frmId=1063
let idOfField=1077
let formPayLoad ={"formId":frmId,"fieldDetails":[{"idOfFieldToAskFromEmp":idOfField,"valueOfFieldToAskFromEmp":10}],"recipientId":contact.prescriber_id,"recipientType":"PRESCR"}

axios.post(config.REACT_APP_FORMS_API_BASE + '/formRequest', formPayLoad).then((res: any) => {
  let temptest ="Your previous AOC is pending. please sign it by using this url.".toString();
  if (temptest.indexOf('shorturl') >= 0)
      formData.append("template", temptest.toString().replace('[shorturl_1]', res.data.FormUrl));
  else
      formData.append("template", temptest + " " + res.data.FormUrl);
  formData.append('urls', res.data.FormUrl)
 // formData.append("template","Your previous AOC is pending. please sign it by using this url "+url);
  formData.append("Campaign_ID",'1000');
  formData.append('wave_id','1000')
  formData.append('product_id',contact.product_id);
  formData.append("channel", "SMS");
  //   sendMessageForPresOrOthers(formData);
  sendMessageApi(formData, (res) => {
      alert('reminder sent!')
  })
})



}
  return (
    <div className='aoc-container aoc-list-wrapper'>
        <div className="aoc-header notMobile"></div>
        <div className="list-title call-log-sub-header notMobile">
          <div className=" pres-name">{t("Prescriber Name")}</div>
          {/* <div className="">{t("Product Name")}</div> */}
          <div className=" status">{t("Status")}</div>
          {/* <div className="">{t("Quantity")}</div> */}
          <div className=" quantity"></div>
        </div>
        <ul className='aoc-scroll-list notMobile'>
          {aocList.map((item: any) => {
                return(
                  <li>
                <div className='aoc-item-div'>
                    <div className="pres-name">
                    <div
                          style={{
                            fontSize: "medium",
                            fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        >
                          {item.PrescriberAcctFull_Nm}
                        </div>
                    </div>
                    {/* <div className="product-name">
                    <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        >
                          {item.Product_Nm}
                    </div>  
                    </div> */}
                    <div className="status">
                    <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        >
                          {item.aoc_status}  
                    </div>  
                    </div>
                    <div className="quantity">
                    <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                            
                          }}
                        >
                          {/* {item.quantity} */}
                          <button 
                            onClick={()=>{sendRemainderForAOC(item)}}
                            style={{
                                  borderRadius: "1rem",
                                  color: "white",
                                  border: "none",
                                  background: "var(--primary-color)",
                                  padding:"0 0.3rem"
                            }} >{t("Send Reminder")}</button>
                    </div> 
                    
                    </div>
                    
                </div>
            </li> 
           
                )})}
            
        </ul>
        <ul className="onlyMobile">
        {/* <li className=""> */}
        {aocList.map((item: any) => {
                return(
                  <li className=''>
                <div className='aoc-list-container'>
                    <div className="pres-name-mobile">
                    <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        > 
                        <label>{t("Name")}: &nbsp;</label>
                          {item.PrescriberAcctFull_Nm}
                        </div>
                        {/* <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        >
                          <label>Product Name:&nbsp; </label>
                          {item.Product_Nm}
                    </div> */}
                    {/* <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        >
                          <label>Status:&nbsp; </label>
                          {item.aoc_status}  
                    </div> */}
                    <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                            display:'flex'
                          }}
                        >
                         <label>{t("Status")}:&nbsp; </label>
                          <div style={{
                            display:'flex',
              
                            width:"100%",
                            marginRight:'1rem',
                            justifyContent:"space-between"
                            }}>
                            {item.aoc_status}  
                            <button 
                            onClick={()=>{sendRemainderForAOC(item)}}
                            style={{
                                  borderRadius: "1rem",
                                  color: "white",
                                  border: "none",
                                  background: "var(--primary-color)",
                                  padding:"0 1rem"
                            }} >{t("Send Reminder")}</button>
                          </div>
                    </div>
                    <div
                          style={{
                            fontSize: "medium",
                            // fontWeight: "bold",
                            // paddingLeft: "1rem",
                          }}
                        >
                          
                          
                                              </div>  
                    </div>
                  
                </div>
            </li> 
            
           
                )})}
                

  

        {/* </li> */}
        </ul>
    </div>
  )
}
