import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    
    videoFileDetails:any,
    showSharedFilePopup: Boolean
  }
const initialState: loginState = {  
    videoFileDetails:{},
    showSharedFilePopup: false
};
export const sharedFile = createSlice({
  name: 'sharedFile',
  initialState: initialState,
  reducers: {
     
    setVideoCallFileSharedData:(state,action: PayloadAction<any>) => { 
        state.videoFileDetails=action.payload;
      },
      setSharedFilePermissionModal:(state,action: PayloadAction<any>) => { 
        state.showSharedFilePopup=action.payload;
      },

    }
});

// this is for dispatch
export const { setVideoCallFileSharedData, setSharedFilePermissionModal} = sharedFile.actions;

// this is for configureStore
export default sharedFile.reducer;