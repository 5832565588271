import React, { useState,useEffect } from 'react'
import './Button.css'
import CircularProgress from '@mui/material/CircularProgress';
function Button(props) {
  const {type,disable,onClick,label,loading}=props;
  const [disableHere,setDisableHere]=useState(false);
  useEffect(() => {
    setDisableHere(loading);
  }, [loading])
  useEffect(()=>{
    setDisableHere(disable);
  },[disable])
  return (
    <button
                  type={type?type:'button'}
                  className="genericButton"
                  disabled={disableHere}
                  style={{ opacity:disableHere ? 0.3 : 1 }}
                  onClick={() => {
                    if(onClick)onClick()
                  }}
                >
                  {label}
                  {loading?<span><CircularProgress color="inherit" size={'1rem'} /></span>:<span></span>}
                </button>
  )
}

export default Button