import { registerPlugin } from '@capacitor/core';

import type { capacitormsalPlugin } from './definitions';

const capacitormsal = registerPlugin<capacitormsalPlugin>('capacitormsal', {
  web: () => import('./web').then(m => new m.capacitormsalWeb()),
});

export * from './definitions';
export { capacitormsal };
