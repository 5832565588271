import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default class Spinner extends Component {
  render() {
    return (
        <div className="spinnerCircle"><CircularProgress color="inherit" size={'2rem'}  /></div>
    )
  }
}
