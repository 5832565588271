import React, { useState, useEffect } from "react";
// import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

import Cancel from '@mui/icons-material/Cancel';
import './MediaChatChannel.css'
import { getAllFileChannelType } from "../../apis/contentSharingApi/contentsharing";
import {getDeviceType} from '../../utils/deviceTypeUtil';
import ListFiles from "./ListFiles";
import Tickmark from "../../assets/icons/Tickmark.svg";
import close from "../../assets/icons/close.svg";
import moment from 'moment';


export default function MediaChatChannelModal({show, handleClose, selectedFileSendMsg}) {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [allFileList, setAllFileList] = useState<any>([]);
    const [selectedFileData, setSelectedFileData]= useState<any>([])
    const [deviceType, setDeviceType] = useState<any>('');
    const [disabled, setDisabled]= useState<any>(true)
    const getMediaPermission = useSelector((state: any) => state.chat.channelMedia)
    const getChannelType = useSelector((state: any) => state.chat.channelType)

  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    useEffect(()=>{
      // getMediaPermission{}
      if( show && getMediaPermission){
        getAllFileChannelType(getChannelType, (res)=> {
          setAllFileList(res)
        })
        setDeviceType(getDeviceType())
      }
     
    },[show])

  const getSelectedFile=(e, file, i)=>{    
    let edata:any= file
    let selectefile:any = selectedFileData
    
    if(e.target.checked){
          selectefile.push(edata)      
    } else{
      selectefile.splice(i, 1)
    }
    setSelectedFileData(selectefile)
    console.log(selectedFileData.length)
  }
  const sendFileMessage= ()=>{
    selectedFileSendMsg(selectedFileData)
    setSelectedFileData([])
    handleClose() 
  }
  
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={show}
        // onClose={handleClose} 
        className="file_modal"
      >
        <DialogTitle
          className="modal_title_wrapper d-flex" 
        >
          <div className="d-flex">
          <h2 className="">My Files</h2>
          </div>
          <div className="ms-auto onlyMobile">
          <img 
            className='border-0 my-svg mx-2'
            src={close}
            alt="close"
            onClick={()=> setSelectedFileData([])}
          />
          <img 
            className='border-0 my-svg mx-2'
            src={Tickmark}
            alt="tickmark"
            onClick={()=>{sendFileMessage()}}
          />
          </div>
        </DialogTitle>
        <DialogContent className="notMobile">
          <DialogContentText>
             <div className="file-container notMobile">
              <div className="header-submenu">
                <div className="header-item">
                  Name                  
                </div>
                <div className="header-item">Modified</div>
                  <div className="header-item">Modified by</div>
                  <div className="header-item">File Size</div>
                {/* <div className="header-item">File Url</div> */}
              </div>
              <div className="file-container-body">
                {allFileList.map((file, i)=>{
                  // console.log(file)
                  return(
                    <div className="body-item"  onClick={(e)=>{getSelectedFile(e,file,i)}}>
                <div>
                  <input className="input_checkbox" type="checkbox" />
                 <span>{file.Item_Nm} </span></div>
                 <div>
                  {/* {file.UpdatedBy_DtTm} */}
                  {/* {moment(messageData['Recipient Msg Start']).format("h:mm A")} */}
                  {moment(file['UpdatedBy_DtTm']).format("h:mm A")}
                   </div>
                <div>{file.EmployeeFull_nm}</div>
                {/* <div>8.78 KB</div> */}
                </div>
                  )

                })}  
              </div>
            </div>
          </DialogContentText>
            <div className="file_footer">
              <button className="cancel_btn" onClick={handleClose}>Cancel</button>
              <button className="send_btn"  onClick={()=>{sendFileMessage()}}> Send</button>
            </div>
        </DialogContent>
        <DialogContent>
          <div className="onlyMobile">
          <ListFiles
            data={allFileList}
            setSelectedFileData={setSelectedFileData}
            allFileList={allFileList}
            selectedFileData={selectedFileData}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
    </>
  )
}
