import axios from "axios";
import config from '../../env.json';

function getMailsByName(payload, callback) {
    try {
        axios.get(config.REACT_APP_EMAIL_API_BASE + '/email/conversations?Status=' + payload).then(res => {
            console.log(res, res.data.data)
            callback(res.data.data);
        }).catch(() => {
            callback(false)
        })
    } catch (error) {
        callback(false)
    }
}
function getEmailIds(callback) {
    try {
        axios.get(config.REACT_APP_EMAIL_API_BASE + '/email/emailIds').then(res => {
            console.log(res, res.data)
            callback(res.data);
        }).catch(() => {
            callback(false)
        })
    } catch (error) {
        callback(false)
    }
}

function deleteDraftMail(payload, callback) {
    try {
        axios.delete(config.REACT_APP_EMAIL_API_BASE + '/email?Id=' + payload).then(res => {
            console.log(res, res.data.data)
            callback(res);
        }).catch(() => {
            callback(false)
        })
    } catch (error) {
        callback(false)
    }
}

async function getMailById(param, callback) {
    try {
        const response = await axios.get(config.REACT_APP_EMAIL_API_BASE + '/email/emails?ConversationId=' + param);
        callback(response.data);
    } catch (error) {
        callback(null);
    }
}

function createMail(param, callback) {
    try {
        axios.post(config.REACT_APP_EMAIL_API_BASE + "/email", param).then(res => {
            console.log(res.status)
            callback(res.status)
        }).catch(() => {
            callback(null)
        })
    } catch (error) {
        callback(false)
    }
}


function updateMail(param, callback) {
    try {
        axios.put(config.REACT_APP_EMAIL_API_BASE + "/email", param).then(res => {
            console.log(res.data.code)
            callback(res.data.code)
        }).catch(() => {
            callback(null)
        })
    } catch (error) {
        callback(false)
    }
}

function getEmailIdContact(payload, callback) {
    try {
        axios.post(config.REACT_APP_CONTACTS_API_BASE + '/emailContacts/', payload).then(res => {
            callback(res.data.data);
        }).catch(() => {
            callback(false)
        })
    } catch (error) {
        callback(false)
    }
}

export { getMailsByName, getMailById, createMail, updateMail, getEmailIdContact, deleteDraftMail,getEmailIds };
