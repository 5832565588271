import axios from "axios";
import config  from '../../env.json';
import { App } from "@capacitor/app";

function ForgerockAuthApi(payload,callback){
   // let {authToken}=params;
    try {
      const uninterceptedAxiosInstance = axios.create();
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        App.getInfo().then(({ version }) => {
        uninterceptedAxiosInstance.post(config.REACT_APP_AUTH_API_BASE+"/auth/token?version="+version,payload)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback(null)
        })
      })
      } else {
        uninterceptedAxiosInstance.post(config.REACT_APP_AUTH_API_BASE+"/auth/token",payload)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback(null)
        })
      }        
    } catch (error) {
        callback(null)
    }
   
}
async function ForgerockAuthRefresh(payload, callback:(data:any)=>Promise<void>) {
  try {
    const uninterceptedAxiosInstance = axios.create();
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      const res = await uninterceptedAxiosInstance.post(config.REACT_APP_AUTH_API_BASE + "/auth/refreshtoken", payload);
      await callback(res.data)
    } else {
      const res = await axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/refreshtoken", payload);
      await callback(res.data)
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(`cancelled`)
    } else {
      await callback(null)
    }
  }

}


function ForgerockLogout(callback){
  try {
    axios.post(config.REACT_APP_AUTH_API_BASE+"/auth/logout")
    .then(res=>{ 
      callback(res.data);
    }).catch(()=>{
      callback(null)
    })
} catch (error) {
    callback(null)
}
}




export {ForgerockAuthApi,ForgerockAuthRefresh,ForgerockLogout}