import axios from "axios";
import config  from '../../env.json';
function getAllConversationsApi(params,callback){
  let {page,limit,search,Role,UnknFlwIsSameRepNbr}=params;

    try {
        axios.get( 
          config.REACT_APP_MESSAGE_API_BASE +
          "/conversations/list?page="+page+"&limit="+limit+"&Role="+Role+"&UnknFlwIsSameRepNbr="+UnknFlwIsSameRepNbr+"&searchquery=" + search)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }   
}

async function getConversationDetailsApi(params,callback){
    let {page,limit,id,type}=params;
    try {
        //axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/details?&page="+page+"&limit="+limit+"&recipientId="+id)
       const res = await axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/detailsNew?recepientId="+id+"&recepientType="+type)
       callback(res.data)
    } catch (error) {
        callback()
    }
} 

async function archiveUnarchiveChatApi(params, callback) {
  const {
    id,
    isArchive
  } = params;
  try {
    const res = await axios.post(config.REACT_APP_MESSAGE_API_BASE+`/conversations/${isArchive === "Y" ? 'archive' : 'updatearchive'}`, {
      "Unknown_Id": id,
      "IsArchive": isArchive,
      "Active_Flg": "Y"
    });
    callback(res.data);
  } catch(error) {
    callback();
  }
}

async function deleteChatApi(params, callback) {
  const {
    id,
  } = params;
  try {
    const res = await axios.post(config.REACT_APP_MESSAGE_API_BASE+"/conversations/deletearchive", {
      "Unknown_Id": id,
    });
    callback(res.data);
  } catch(error) {
    callback();
  }
}

export {getAllConversationsApi,getConversationDetailsApi,archiveUnarchiveChatApi, deleteChatApi}