import axios from "axios";
import config  from '../../env.json';
import { App } from '@capacitor/app';

function getAuthApi(params,callback){
    let {authToken,type}=params;
    console.log("AuthToken"+authToken)
    console.log("type"+type)
    try {
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        App.getInfo().then(({ version }) => {
        const uninterceptedAxiosInstance = axios.create();
        uninterceptedAxiosInstance.get(config.REACT_APP_AUTH_API_BASE+"/auth/?EndpointType=SMS&version="+version)
        .then(res=>{ 
          callback(res.data);
        }).catch((error)=>{
          // alert(JSON.stringify(error));
          callback(null)
        })
      })
      } else {
        axios.get(config.REACT_APP_AUTH_API_BASE+"/auth/?EndpointType=SMS")
        .then(res=>{ 
          callback(res.data,res.status);
        }).catch(()=>{
          callback(null)
        })
      }
    } catch (error) {
        callback(null)
    }
   
}
 
function getAppVersion(params,callback){
  let {appVersion}=params;
  const instance = axios.create();
  console.log("appVersion"+appVersion);
  try {
    instance.get(config.REACT_APP_AUTH_API_BASE+ "/auth/app-version?version=" + appVersion).then(res=>{ 
    //axios.get(config.REACT_APP_AUTH_API_BASE+"/auth/app-version",{params: {version: appVersion}}).then(res=>{ 
      callback(res);
    }).catch((error)=>{
      callback(false)
    })
  } catch (error) {
    callback(false)
  }
}

export {getAuthApi,getAppVersion}

