import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useEffect } from 'react';

function MultiContactSelect(props) {
    const { onClose, selectedValue, open ,multiContact} = props;
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value: string) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{multiContact.name.display}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {multiContact.phones.map((phone) => (
            <ListItem disableGutters>
              <ListItemButton onClick={() => handleListItemClick(phone.number)} key={phone.number}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={phone.number} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }
  export default MultiContactSelect;