import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface emailDetails {
    emailSubTitle: String,
    showInbox:boolean,
    showDraft: boolean,
    showComposeEmail:boolean,
    showSentEmail:boolean,
    showMailContent:boolean
    // contact_number: String,
    // virtual_number: String,
  }
const initialState: emailDetails = { 
    emailSubTitle:"Inbox", 
    showInbox:false,
    showDraft: false,
    showComposeEmail:false,
    showMailContent:false,
    showSentEmail:false
    // virtual_number: '',
};
export const emailView = createSlice({
  name: 'emailView',
  initialState: initialState,
  reducers: {
     
      setEmailInboxView: (state,action: PayloadAction<any>) => { 
        state.showInbox=action.payload
      },
      setEmailDraftView: (state,action: PayloadAction<any>) => { 
        state.showDraft=action.payload        
      },
      setComposeEmailView: (state,action: PayloadAction<any>)=>{
        state.showComposeEmail=action.payload
      },
      setEmailContentView: (state,action: PayloadAction<any>)=>{
        state.showMailContent=action.payload
      },
      setSentEmailView: (state,action: PayloadAction<any>)=>{
        state.showSentEmail=action.payload
      },
      setEmailSubTitle: (state,action: PayloadAction<any>)=>{
        state.emailSubTitle=action.payload
      },
      

    }
});

// this is for dispatch
export const { setEmailInboxView, setEmailDraftView, setComposeEmailView, setEmailContentView, setSentEmailView,setEmailSubTitle} = emailView.actions;

// this is for configureStore
export default emailView.reducer;