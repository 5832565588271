import React, { useEffect, useState } from 'react';
import MainRoutes from './Routes'
import MyModal from './components/modal/Modal';

import { updatePushToken } from "./apis/notificationApi/notificationApi";
import { capacitormsal } from "capacitormsal";
import { deleteUserDetails } from "./apis/dbApi/dbApi";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { msalInstance } from "./index";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import './App.css';
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import config from './env.json';
import { useSQLite } from 'react-sqlite-hook';
import { useIdleTimer } from 'react-idle-timer'
import { App as AppInfo } from '@capacitor/app';
// import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
// import Email from './pages/email/Email';
// import SidebarOption from './pages/email/SidebarOption';
// import Mail from './pages/email/Mail';
// import EmailList from './pages/email/EmailList';
import PermScanWifiIcon from '@mui/icons-material/PermScanWifi';
import {Detector} from "react-detect-offline";
import { useTranslation } from 'react-i18next';

export let sqlite: any;
export let existingConn: any;

type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  const dispatch=useDispatch();
  
  const { t } = useTranslation();
  const loginDetails=useSelector((state:any)=>state.login)
  document.documentElement.style.setProperty('--primary-color', `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_COLOR}`);
  document.documentElement.style.setProperty('--secondary-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_COLOR}`);
  document.documentElement.style.setProperty('--tertiary-color', `${config.REACT_APP_MAIN_APP_CSS_TERTIARY_COLOR}`);
  document.documentElement.style.setProperty('--primary-text-color', `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_TEXT_COLOR}`);
  document.documentElement.style.setProperty('--secondary-text-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_TEXT_COLOR}`);
  document.documentElement.style.setProperty('--placeholder-text-color', `${config.REACT_APP_MAIN_APP_CSS_PLACEHOLDER_TEXT_COLOR}`);
  document.documentElement.style.setProperty('--date-banner-bg-color', `${config.REACT_APP_DATE_BANNER_BG_COLOR}`);
  // document.documentElement.style.setProperty('--secondary-bg-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_BG_COLOR}`);
 // document.documentElement.style.setProperty('--AUTH-TYPE', `${config.REACT_APP_AUTH_TYPE}`);
  const token = useSelector((state: any) => state.login.token);
  const skipHeader = useSelector((state: any) => state.login.skipHeader);
  axios.interceptors.request.use((request: any) => {
    console.log(token);
    
    console.log("request", request)
    // Edit request config
    console.log("skipHeader", skipHeader)
    if (token && !skipHeader) {
      if (token.length > 0)
        request.headers.common.Authorization = "Bearer " + token;
    }

    return request;
  }, error => {
    console.log(error);
    return Promise.reject(error);
  });
  const onIdle = () => {
    if (config.REACT_APP_INACTIVITY_ENABLED) {
      logoutUser();
    }
  };

  const getAuthType = async () => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      let version = "";
      if (config.REACT_APP_IS_CROSS_PLATFORM) {
        const res = await AppInfo.getInfo();
        version = res.version;
      }

      const response = await uninterceptedAxiosInstance.get(
        config.REACT_APP_AUTH_API_BASE + "/auth/login-type/?version=" + version
      );
      console.log("response ", response.data.loginType);
      sessionStorage.setItem("appAuthType", response.data.loginType);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  useEffect(() => {
    getAuthType();
  }, [loginDetails]);

  const { getRemainingTime,reset } = useIdleTimer({
    onIdle,
    timeout: parseInt(config.REACT_APP_INACTIVITY_ENABLED_TIME),
  });
  
  function logoutUser() {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      updatePushToken(
        {
          DeviceToken_Val: sessionStorage.getItem("pushToken"),
          Device_Typ: "iOS",
          is_active: "N",
        },
        (res) => {}
      );
      if (sessionStorage.getItem("appAuthType") == "AD") {
        capacitormsal
          .signOut({
            authority: config.REACT_APP_AD_AUTHORITY as string,
            clientId: config.REACT_APP_AD_CLIENTID as string,
            redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
            scope: config.REACT_APP_CROSS_SCOPE as string,
          })
          .then(async (response) => {
            sessionStorage.setItem("login", "");
            sessionStorage.setItem("appAuthType", "");
            deleteUserDetails().then(() => {
              window.location.href = "/";
            });
          })
          .catch((error) => {
            sessionStorage.setItem("login", "");
            sessionStorage.setItem("appAuthType", "");
            deleteUserDetails().then(() => {
              window.location.href = "/";
            });
          });
      } else {
        let browser = InAppBrowser.create(
          config.REACT_APP_PING_LOGOUT_URL,
          "_blank",
          "location=no"
        );
        browser.on("loadstop").subscribe(async (event) => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        sessionStorage.setItem("appAuthType", "");
        deleteUserDetails().then(() => {
          window.location.href = "/";
        });
      }
    } else {
      if (sessionStorage.getItem("appAuthType") == "AD") {
        let account = sessionStorage.getItem("loginName") as string;
        dispatch({ type: "RESET_APP" });
        msalInstance
          .logoutRedirect({
            account: msalInstance.getAccountByUsername(account),
          })
          .catch((e: any) => {
            console.log(e);
          });
      } else {
        sessionStorage.clear();
        localStorage.clear();
        var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
        window.location.href = logoutUrl;
        window.location.href = config.REACT_APP_PING_REDIRECT_URL;
        //window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
      }
    }
  }
  if(config.REACT_APP_IS_CROSS_PLATFORM) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [existConn, setExistConn] = useState(false);
    existingConn = {existConn: existConn, setExistConn: setExistConn};
    const {echo, isConnection, getPlatform, createConnection, closeConnection,
          retrieveConnection, retrieveAllConnections, closeAllConnections,
          addUpgradeStatement, importFromJson, isJsonValid, copyFromAssets,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          isAvailable} = useSQLite();
    sqlite = {echo: echo, isConnection: isConnection, getPlatform: getPlatform,
              createConnection: createConnection,
              closeConnection: closeConnection,
              retrieveConnection: retrieveConnection,
              retrieveAllConnections: retrieveAllConnections,
              closeAllConnections: closeAllConnections,
              addUpgradeStatement: addUpgradeStatement,
              importFromJson: importFromJson,
              isJsonValid: isJsonValid,
              copyFromAssets: copyFromAssets,
              isAvailable:isAvailable};
  }
  useEffect(() => {
    document.title = config.REACT_APP_TITLE;
    const favicon = document.getElementById("favicon");
    favicon?.setAttribute('href', config.REACT_APP_FAVICON);
  }, []);

  const logoutUserIdeally =()=>{
    let inactivityTimer;

    function resetTimer() {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logoutUser, parseInt(config.REACT_APP_INACTIVITY_ENABLED_TIME)); // Adjust the time as per your requirement (in milliseconds)
    }

    //  Below function is for user inactivity logout. When user not intractive with screen.
    //   We cannot move this function in separate file beacuse of dispatch used in this function

    function logoutUser() {
      if (config.REACT_APP_IS_CROSS_PLATFORM) {
        updatePushToken(
          {
            DeviceToken_Val: sessionStorage.getItem("pushToken"),
            Device_Typ: "iOS",
            is_active: "N",
          },
          (res) => {}
        );
        if (sessionStorage.getItem("appAuthType") == "AD") {
          capacitormsal
            .signOut({
              authority: config.REACT_APP_AD_AUTHORITY as string,
              clientId: config.REACT_APP_AD_CLIENTID as string,
              redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
              scope: config.REACT_APP_CROSS_SCOPE as string,
            })
            .then(async (response) => {
              sessionStorage.setItem("login", "");
              sessionStorage.setItem("appAuthType", "");
              deleteUserDetails().then(() => {
                window.location.href = "/";
              });
            })
            .catch((error) => {
              sessionStorage.setItem("login", "");
              sessionStorage.setItem("appAuthType", "");
              deleteUserDetails().then(() => {
                window.location.href = "/";
              });
            });
        } else {
          let browser = InAppBrowser.create(
            config.REACT_APP_PING_LOGOUT_URL,
            "_blank",
            "location=no"
          );
          browser.on("loadstop").subscribe(async (event) => {
            browser.close();
          });
          sessionStorage.setItem("login", "");
          sessionStorage.setItem("appAuthType", "");
          deleteUserDetails().then(() => {
            window.location.href = "/";
          });
        }
      } else {
        if (sessionStorage.getItem("appAuthType") == "AD") {
          let account = sessionStorage.getItem("loginName") as string;
          dispatch({ type: "RESET_APP" });
          msalInstance
            .logoutRedirect({
              account: msalInstance.getAccountByUsername(account),
            })
            .catch((e: any) => {
              console.log(e);
            });
        } else {
          sessionStorage.clear();
          localStorage.clear();
          var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
          window.location.href = logoutUrl;
          window.location.href = config.REACT_APP_PING_REDIRECT_URL;
          //window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
        }
      }
    }

    function handleActivity() {
      resetTimer();
    }

    function handleVisibilityChange() {
      if (!document.hidden) {
        resetTimer();
      }
    }

    //reset inactivity if user receive message
    axios.interceptors.response.use(res=>{
      if(res.status > 199 || res.status < 300){
        handleActivity();
      }
      return res
    },err=>{
      console.log(err);
      return err
    })

    //add listeners in case of ios
    window.visualViewport?.addEventListener("mousemove", handleActivity);
    window.visualViewport?.addEventListener("mousedown", handleActivity);
    window.visualViewport?.addEventListener("keydown", handleActivity);
    window.visualViewport?.addEventListener("touchstart", handleActivity);
    window.visualViewport?.addEventListener("visibilitychange", handleVisibilityChange);

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("mousedown", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("touchstart", handleActivity);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("mousedown", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }
  useEffect(() => {
    if(config.REACT_APP_INACTIVITY_ENABLED){
      // logoutUserIdeally()
        //reset inactivity if user receive message
    axios.interceptors.response.use(res=>{
      if(res.status > 199 || res.status < 300){
        reset();
      }
      return res
    },err=>{
      console.log(err);
      return err
    })
    }
  }, []);

  useEffect(()=>{
    console.log(`loginDetails`,loginDetails)
    if(loginDetails.token && loginDetails.refresh_token){
      sessionStorage.setItem("login", loginDetails.token)
      sessionStorage.setItem("refreshtoken", loginDetails.refresh_token)
      delete axios.defaults.headers.common["Authorization"];
      axios.defaults.headers.common["Authorization"] = "Bearer " + loginDetails.token;
    }
  },[loginDetails])
  return (
    <>
      <Detector render={({ online }) => !online && <div className='internet-unavailable'><PermScanWifiIcon/> {t("Please check your internet connection")}</div>} />
      <MsalProvider instance={pca}>
        <MyModal />
        <MainRoutes />
      </MsalProvider>
    </>
  );
}

export default App;
