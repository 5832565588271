export const supportedFileTypes = [
  // Images
  "image/jpg",
  "image/jpeg",
  "image/png",
  // "image/webp",

  // Audio
  // "audio/ogg",
  // "audio/amr",
  // "audio/3gpp",
  // "audio/aac",
  // "audio/mpeg",

  // Documents
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

  // Video
  // "video/mp4",
  // "video/quicktime",

  // Contacts
  "text/vcard",
  "text/x-vcard",
];

export const MAX_SIZE_LIMIT = 100 * 1024 * 1024; // 100 MB in bytes
const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB in bytes

const validateAttachment = (files) => {
  let errorMsg = "";
  files = Array.from(files);
  let file;
  for (var i = 0; i < files.length; i++) {
    file = files[i];

    const { type, size } = file;
    console.log(type, size)

    if (i < 10) {
      if (!supportedFileTypes.includes(type)) {
        errorMsg = file.name + " is not supported";
      }
      //  else if (type.startsWith("image/")) {
      //   if (size > IMAGE_SIZE_LIMIT) {
      //     errorMsg = `File size exceeds ${IMAGE_SIZE_LIMIT / 1024 / 1024}MB`;
      //   }
      // }
       else {
        if (size > MAX_SIZE_LIMIT) {
          errorMsg = `File size exceeds ${MAX_SIZE_LIMIT / 1024 / 1024}MB`;
        }
      }
    } else {
      errorMsg = "Max 10 files are allowed";
    }
  }
  return errorMsg;
};

export default validateAttachment;
