import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    active: String,
    chatPermId:any,
    title:any,
    allPermissions:any
   
  }

interface SetChatPermIdAction {
    type: string;
    payload: string;
  }

const initialState: loginState = {
    active:'',
    chatPermId:'',
    title:'',
    allPermissions:[]
};
export const sideBar = createSlice({
  name: 'sideBar',
  initialState: initialState,
  reducers: {
    setActiveTab: (state,action:any) => { 
        
        state.active=action.payload;

      },
    setChatPermId: (state,action:PayloadAction<string>) => { 
        
        state.chatPermId=action.payload;

      },
      setTitle: (state,action:any) => { 
        state.title=action.payload;
      },
      setAllPermissions: (state,action:any) => { 
        state.allPermissions=action.payload;
      },
    }
});

// this is for dispatch
export const { setActiveTab, setChatPermId,setTitle,setAllPermissions } = sideBar.actions;

// this is for configureStore
export default sideBar.reducer;