import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    
    showCallModal:boolean,
    contact_name: String,
    contact_number: String,
    // virtual_number: String,
  }
const initialState: loginState = {  
    showCallModal:false,
    contact_name: '',
    contact_number: '',
    // virtual_number: '',
};
export const callModal = createSlice({
  name: 'callModal',
  initialState: initialState,
  reducers: {
     
      setCallModalView: (state,action: PayloadAction<any>) => { 
        state.showCallModal=action.payload
      },
      setCallModalDetails: (state,action: PayloadAction<any>) => { 
        state.contact_name=action.payload.AcctFull_Nm
        state.contact_number=action.payload.AcctFull_Nbr
      },
      // setVirtualNumber: (state,action: PayloadAction<any>)=>{
      //   state.virtual_number=action.payload.EmployeeV_Nbr
      // },

    }
});

// this is for dispatch
export const { setCallModalView, setCallModalDetails} = callModal.actions;

// this is for configureStore
export default callModal.reducer;