import axios from "axios";
import config  from '../../env.json';
function sendMessageApi(payload,callback){
  

    try {
        axios.post(
          config.REACT_APP_MESSAGE_API_BASE +"/message",payload)
        .then(res=>{ 
          console.log(res)
          if(res.data.message === "Inappropriate message body" || res.data.message === "Conversation window closed, please send approved template first"){
            callback(true, res.data);
          } else{
            callback(true);
          }
          
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}

function checkWhatsappValidation(payload,callback){
  try {
      axios.post(config.REACT_APP_MESSAGE_API_BASE +"/message/check-whatsapp-validation",payload)
      .then(res=>{ 
        callback(true, res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
 
}


function unshortenUrl(payload,callback){

  try {

    axios.post(config.REACT_APP_MESSAGE_API_BASE +"/unshorturl",payload)

    .then(res=>{

        callback(res.data);

    }).catch(()=>{

      callback(false)

    })

} catch (error) {

    callback(false)

}

}


function flagMessageByRep(payload,callback){
  try {
      axios.post(config.REACT_APP_MESSAGE_API_BASE +"/conversations/flagMsgByRep",payload)
      .then(res=>{ 
        callback(true, res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
 
}
export {sendMessageApi, unshortenUrl, checkWhatsappValidation,flagMessageByRep}
