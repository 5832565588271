import React, { useEffect, useState } from "react";
import config from "../../env.json";
import AvatarLogo from '@mui/material/Avatar';

import HCP_ICON_NOT_CONTACTED from "../../assets/icons/avatar/HCP Icon for Pfizer - Not Contacted_4.svg" 
import HCP_ICON_OPTED_IN from "../../assets/icons/avatar/HCP Icon for Pfizer - Opted In_1.svg";
import HCP_ICON_OPTED_OUT from "../../assets/icons/avatar/HCP Icon for Pfizer - Opted Out_2.svg";
import HCP_ICON_PENDING from "../../assets/icons/avatar/HCP Icon for Pfizer - Pending_3.svg";

import STAFF_ICON_NOT_CONTACTED from "../../assets/icons/avatar/Staff Icon for Pfizer - Not Contacted_4.svg";
import STAFF_ICON_OPTED_IN from "../../assets/icons/avatar/Staff Icon for Pfizer - Opted In_1.svg"
import STAFF_ICON_OPTED_OUT from "../../assets/icons/avatar/Staff Icon for Pfizer - Opted Out_2.svg"
import STAFF_ICON_PENDING from "../../assets/icons/avatar/Staff Icon for Pfizer - Pending_3.svg";

import UNKNOWN_ICON_NOT_CONTACTED from "../../assets/icons/avatar/Unknown Icon for Pfizer - Not Contacted_4.svg";
import UNKNOWN_ICON_OPTED_IN from "../../assets/icons/avatar/Unknown Icon for Pfizer - Opted In_1.svg"
import UNKNOWN_ICON_OPTED_OUT from "../../assets/icons/avatar/Unknown Icon for Pfizer - Opted Out_2.svg"
import UNKNOWN_ICON_PENDING from "../../assets/icons/avatar/Unknown Icon for Pfizer - Pending_3.svg"

import './Avatar.css';



function stringAvatar(name: string, type, avatarwd, avatarht ) {
  let trimName:any;
  let primaryColor:any=document.documentElement.style.getPropertyValue('--primary-color');

  if(type){
    if(type.toLowerCase() === "unknown" || type==="UNKN" || type === "" || type === "undefined" || type == "UNKNOW" ){
      trimName="UN"
    }else{   
      
    name=name.trim().toUpperCase();
    let tempname:any=name.split(' ');
    trimName=tempname[0][0];
    if(tempname[1])
    trimName+=tempname[1][0];
    }
  }
    return {
    sx: {
      bgcolor: primaryColor+'05',
      border: "1px solid "+primaryColor+"4D",
      color: primaryColor,  
      fontSize: "1rem",
      fontWeight: "bold",
      width: avatarwd,
      height: avatarht,      
    },    
    children: trimName
  };
}

export default function Avatar(props) {
  const { imageType, avatarHeight, avatarWidth,  presOrAssoc, showAvatarType, isOptedIn } =props





  const badgeColor = {
    R: 'optBadge optedOutBadge',
    G: 'optBadge optedInBadge',
    Y:'optBadge optedIntroBadge',
    M: 'optBadge optedDefaultBadge'
  }

  const staffIcon = {
    R: STAFF_ICON_OPTED_OUT,
    G: STAFF_ICON_OPTED_IN,
    Y: STAFF_ICON_PENDING,
    M: STAFF_ICON_NOT_CONTACTED
  }

  const HCPIcon = {
    R: HCP_ICON_OPTED_OUT,
    G: HCP_ICON_OPTED_IN,
    Y: HCP_ICON_PENDING,
    M: HCP_ICON_NOT_CONTACTED
  }

  const unknownIcon = {
    R: UNKNOWN_ICON_OPTED_OUT,
    G: UNKNOWN_ICON_OPTED_IN,
    Y: UNKNOWN_ICON_PENDING,
    M: UNKNOWN_ICON_NOT_CONTACTED
  }

  const optinColor =isOptedIn ? badgeColor[isOptedIn] : null

  const getIcons =() =>{
    return(
      <div className="avatarBadge">
      {imageType === "prescriberassociates" || imageType === "ASSPRS" ? 
        (
          <div className="avatar-container other-staff" style={{ height:avatarHeight, width:avatarWidth}}>
             <img src={isOptedIn ? staffIcon[isOptedIn] : config.REACT_APP_ASSPRS_ICON} className={isOptedIn && 'wh-83'} alt="other-staff-icon"   />
          </div>
        )
      :null}
      {imageType === "prescriber" || imageType==="PRESCR"?(<div className="avatar-container" style={{ height:avatarHeight, width:avatarWidth}}>
            <img src={isOptedIn ? HCPIcon[isOptedIn] : config.REACT_APP_PRESCR_ICON} className={isOptedIn && 'wh-83'} alt="doctor-icon" />
        </div>
      ):null }
      {imageType.toLowerCase() === "unknown" || imageType==="UNKN"|| imageType === "UNKNOW" || imageType === "" || imageType === "undefined"?
      (<div className={`avatar-container ${!isOptedIn && 'avatar-border' }`} style={{ height:avatarHeight, width:avatarWidth}}>
           <img className={`unknown-icon-width ${isOptedIn && 'wh-83'}`} style={{ width: isOptedIn ? '80%' : '58%' }} src={isOptedIn ? unknownIcon[isOptedIn] : config.REACT_APP_UNKOWN_ICON} alt="unknown-icon" />
      </div>):null
      }
      {imageType === "Employee" || imageType === "EMP" ? 
        (
          <div className="avatar-container avatar-border emp-user" style={{ height:avatarHeight, width:avatarWidth}}>
            <img src={config.REACT_APP_EMPLOYEE_ICON} alt="other-staff-icon"   />
            {
        optinColor?<span className={optinColor}></span>:null
      }
          </div>
        )
      :null}
      
    </div>
    )
  }

  if(showAvatarType === "initialsOnly" ){
    return(
      <AvatarLogo  {...stringAvatar(presOrAssoc, "initialsOnly", avatarWidth, avatarHeight)} />
          )
  }

  if(showAvatarType === "initials" ) {
    return(
      <AvatarLogo  {...stringAvatar(presOrAssoc, imageType, avatarWidth, avatarHeight)} />
    )
  } 
   else if(showAvatarType === "icons"){
    return getIcons()

  } else{
    if(config.REACT_APP_AVATAR_WITH_ICON==="Y"){
    return  getIcons()
    } else{
      return(  <div className="avatarBadge" style={{ height:avatarHeight, width:avatarWidth}}><AvatarLogo {...stringAvatar(presOrAssoc, imageType, avatarWidth, avatarHeight)} className={props.className} />
      {
        optinColor?<span className={optinColor}></span>:null
      }</div>)
    }
  }
}

