import axios from "axios";
import config  from '../../env.json';
function autopilotApi(payload,callback){
  

  let url=config.REACT_APP_AUTH_API_BASE
    payload= {

        "customerId": payload.Id.toString(),
        "customerType": "PRES",
        "handOffType": "E",
        "handoffOn": payload.state
    }

    try {
        axios.post(url+"/bot/PostBotHandOff",payload)
        .then(res=>{ 
          callback(true);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
 
function setOutOfOffice(payload,callback){
  let url=config.REACT_APP_AUTH_API_BASE
    payload= {
      "BkpUser_Id":payload.newBackupId,
      "Custom_Msg":payload.newMsgText,
      "OutOfOfficeMsg_Typ":payload.newMsgType,
      "OutofOfficeStartTime":payload.newavailstrt,
      "OutofOfficeEndTime":payload.newavailend
    }

    try {
        axios.post(url+"/bot/PostEmployeeOutOfOffice",payload)
        .then(res=>{ 
          callback(true);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}

function editOutOfOffice(payload,callback){
  let url=config.REACT_APP_AUTH_API_BASE
    payload= {
      "BkpUser_Id":payload.newBackupId,
      "Custom_Msg":payload.newMsgText,
      "OutOfOfficeMsg_Typ":payload.newMsgType,
      "OutofOfficeStartTime":payload.newavailstrt,
      "OutofOfficeEndTime":payload.newavailend,
      "Id":payload.id,
      "Is_Active":payload.flag,
    }

    try {
        axios.put(url+"/bot/updateOutOfOffice",payload)
        .then(res=>{ 
          callback(true);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}

function getOutOfOffice(callback){
  let url=config.REACT_APP_AUTH_API_BASE
    try {
        axios.get(url+"/bot/getOutOfOffice")
        .then(res=>{ 
          callback(res);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
 
function getAutoPilot(callback){
  let url=config.REACT_APP_AUTH_API_BASE

  try {
      axios.get(url+"/bot/GetBotHandOff")
      .then(res=>{ 
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
 
}
 const setOOOStatus=(payload,callback)=>{
  let url=config.REACT_APP_AUTH_API_BASE
    try {
        axios.post(url+"/bot/setoutofoffice",payload)
        .then(res=>{ 
          callback(res);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}



export {autopilotApi,getAutoPilot,setOutOfOffice,getOutOfOffice,editOutOfOffice,setOOOStatus}