import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
// import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Button from "@mui/material/Button";
import FieldDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import MultiselectDropdown from "../../layouts/mutliselectDropdown/MultiselectDropdown";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import DateTimeField from 'react-bootstrap-datetimepicker'
import { DateTime } from 'react-datetime-bootstrap';
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import AddBoxIcon from "@material-ui/icons/AddBox";
// import DoneIcon from "@material-ui/icons/Done";
// import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DateTextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
// import Button from "../../layouts/button/Button";
import axios from "axios";
import './FormsModal.css';
// import "./AddContact.css";
import { data } from "jquery";
import { borderRadius } from "@mui/system";
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import { AddNewForm } from '../../apis/forms/formsapi';
import { AddNewFormFields } from '../../apis/forms/formsapi';
import { AddNewPlatform } from '../../apis/forms/formsapi';
import { AddNewDelivery } from '../../apis/forms/formsapi';
import { UpdateFormField } from '../../apis/forms/formsapi';
import { UpdateForm } from '../../apis/forms/formsapi';
import { GetFormDetails } from '../../apis/forms/formsapi';
import { UpdatePlatform } from '../../apis/forms/formsapi';
import { UpdateDelivery } from '../../apis/forms/formsapi';
import { UpdateWave } from '../../apis/forms/formsapi';
import moment from 'moment';
import { debug } from "console";
import { getWavesData } from "../../apis/waves/waves";
import { AddwaveAssociation } from "../../apis/forms/formsapi";
// import "../node_modules/bootstrap/dist/css/bootstrap.css";
import {
  Box, Button, Snackbar, Table,
  TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import { fabClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCampaignsData } from "../../apis/campaigns/campaigns";
const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  
  let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
  const BpCheckedIcon = styled(BpIcon)({
  
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'var(--primary-color)' ,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'var(--primary-color)' ,
    },
  });
const style = {
  position: "absolute" as "absolute",
  top: "49%",
  left: "54%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  borderRadius: "1rem",
  padding: "0 3rem 1.5rem 3rem",
  bgcolor: "white",
  border: "none",
};
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: '1rem',
  }),
}
function BpRadio(props: RadioProps) {

    return (
      <Radio
        sx={{
          "&:hover": {
            bgcolor: "transparent",
          },
          "marginLeft":"1rem"
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 650,
    }
    // snackbar: {
    //     bottom: "104px",
    // },
});

export default function FormsModal({ show, handleCloseParent ,title,Id,viewform}) {
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [PrescName, setPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc,setAssoPresc]=useState({Id:null})
  const [post, setPost] = useState([]);
  const [temp,setTemp]=useState('')
  const [age, setAge] = React.useState('');
  const [name, setName] = React.useState('');
  const [meetingType, setMeetingType] = useState(true);
  const [assoPrescName, setAssoPrescName] = useState("");
  const [disableBrandInput,setDisableBrandInput]=useState(true)
  const [phoneError,setPhoneError]=useState(false);
  const [meetingname,setMeetingName]=useState('');
  const [prescriberId,setPrescriberId]=useState('');
  const [disabled,setDisabled]=useState(true);
  const[signcapture,setSignCapture]=useState(false);
  const[prepform,setPrepform]=useState(false);
  const[activeformflag,setActiveform]=useState(false);
  const[prepformfield,setPrepformfield]=useState(false);
  const[salesforce,setsalesforceform]=useState(false);
  const[fieldformval,setNextTabfieldform]=useState('collapse');
  const[formval,setFormTab]=useState('show');
  const[waveval,setNextTabWave]=useState('collapse');
  const[platfrmtab,setNextTabplatform]=useState('collapse'); 
  const[deltab,setNextTabdelivery]=useState('collapse');
  const[FormName,setFormName]=useState('');
  const[FormType,setFormType]=useState('');
  const[FormBlobURL,setFormBlobURL]=useState('');
  const[Formloc,setFormstorageloc]=useState('');
  const[FormID,setFormID]=useState('');
  const [form, setForm]=useState([]);
  const classes = useStyles();
  const [platform,setPlatform]=useState('');
  const [deliveryval, setDelivery]=useState([]);
  const [value, setvalue] = useState([])
  const [deliverytype, setDeliveryType] = useState([])
  const [platformupdate, setUpdateplatform] = useState([])
  const[viewformval,setViewForm]=useState(false);
  const [formfieldupdate, setupdateformfield]=useState([]);
  const[activewaveflag,setActiveWave]=useState(false);
  const[activeplatformflag,setActivePlatform]=useState(false);
  const[activeDelflag,setActiveDelivery]=useState(false);
  const [activeWaves, setActiveWaves] = useState([]);
  const [activeDel, setActiveDel] = useState([]);
  const [delupdate, setdelupdate]=useState([]);
  const[FormTemplate,setFormTemplate]=useState('')
  const [formdeltype,setFormDelType]=useState('');
  const [maptoMedia,setMaptoMedia]=useState(false);
  const [confirmDeliveries,setConfirmDeliveries]=useState(false)
  const [selectedDelForDelete,setSelectedDelForDelete]=useState<any>({})
  const [secPrescribers,setSecPrescribers]=useState<any>([]);
  const [rows, setRows] = useState([	
    {id:1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:'Yes',Active:'Yes',EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY'),NewlyAdded:true},	
]);
const [payloadval, setpayload] = useState([
  {     
    FieldForm_Id: "",
    Field_Nm: "",
    Field_Typ: "",
    FieldQueryExec_Val : "",
    FieldPrepopulate_Flg: "",
    FieldValByEmp_Flg: "",
    EffectiveStart_DtTm: "",
    EffectiveEnd_DtTm	 : "",
    Active_Flg: ""

},
]);
const [payloadwave, setwavepayload] = useState([
  {     
      WaveAssociationForm_Id:1004,
      WaveAssociationWave_Id:1001,
      EffectiveStart_DtTm:"09-08-2022",
      EffectiveEnd_DtTm:"01-01-1800",
      Active_Flg:"Y"
  },
]);
const FormDelData = [
  { value: 'open', label: 'Open' },
  { value: 'submit', label: 'Submit' }
]
const [startdatevalue, setStartDateValue] = useState<Date | null>(new Date());
const [enddatevalue, setEndDateValue] = useState<Date | null>(new Date());
const [startwavedate, setStartWaveDate] = useState<Date | null>(new Date());
const [endwavedate, setEndWaveDate] = useState<Date | null>(new Date());
const [startplatformdate, setStartPlatformDate] = useState<Date | null>(new Date());
const [endplatformdate, setEndPlatformDate] = useState<Date | null>(new Date());
const [startdeldate, setStartDeliveryDate] = useState<Date | null>(new Date());
const [enddeldate, setEndDeliveryDate] = useState<Date | null>(new Date());
const [deliveryRows,setDeliveryRows]=useState<any>([{
        FormDelivery_Id : 1,
        FormDelivery_Typ: '',
        FormDeliveryEvent_Typ:'',
        EffectiveStart_DtTm: moment().format("YYYY-MM-DD"),
        EffectiveEnd_DtTm:moment().format("YYYY-MM-DD"),
        Active_Flg:"Y",
        ShowPost_Url:false,
        FormDeliveryAction_Url:"",
        NewAdded:true
}])
const  [options,setOptions]:any  = useState([]);
const  [deliveryactions,setdeliveryactions]:any   = useState([
  { label:  'SMS', Id:  'SMS'  },
  { label:  'Email', Id:  'Email'  },
  { label:  'Fax', Id:  'Fax'  },
  { label:  'Salesforce', Id: 'Salesforce'  },
  { label:  'Veeva', Id:  'Veeva'  },
  { label:  'Webhook', Id:  'Webhook'  },
])
const [previousWaveData,setPreviousWaveData]=useState<any>([])
const [rowsData, setRowsData] = useState([]);
 
  //   const addTableRows = ()=>{
  
  // //      const rowsInput={ Name: "", Type: "", Value: "", Query: "",Prepopulate:false }
  //       setRows([
  //         ...rows,
  //         {id: rows.length + 1, Name: "", Type: "", Value: "", Query: "",Prepopulate:false },
  //     ]);
      
      
  //   }
    const [open, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [disable, setDisable] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);
  
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
  
    // Function For adding new row object
    const handleAdd = () => {	
      let i=rows.length+1;	
      rows.map((x:any)=>{	
        i=i>x.id?i:x.id+1	
        return x;	
      })	
        setRows([	
            ...rows,	
            {id:i, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:"Yes" ,Active:"Yes",EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY'),NewlyAdded:true},	
        ]);	
        setEdit(true);	
    };
  
    //function to handle add deliveries actions

    const handleAddDeliveries = () => {
      let id=deliveryRows[deliveryRows.length-1].FormDelivery_Id+1
      
      setDeliveryRows([
        ...deliveryRows,{
        FormDelivery_Id : id,
        FormDelivery_Typ: '',
        FormDeliveryEvent_Typ:"", 
        EffectiveStart_DtTm: moment().format("YYYY-MM-DD"),
        EffectiveEnd_DtTm:moment().format("YYYY-MM-DD"),
        Active_Flg:"Y",
        ShowPost_Url:false,
        FormDeliveryAction_Url:"",
        NewAdded:true
      }
      ]);


    
      //     let forAdd: any = difference.filter((x: any) => { if (!x.Association_Id) return x.Id }).map(x => x.Id)
      setEdit(true);
  };

  const handleRemoveDeliveries=(y:any)=>{
      //let tempRows:any=deliveryRows.filter((y:any)=>y.FormDelivery_Id!=x.FormDelivery_Id);

      // const { name, value } = e.target;
      let x:any=selectedDelForDelete;
      
      let index=deliveryRows.findIndex((p:any)=>p.FormDelivery_Id==x.FormDelivery_Id);
      if(!x.NewAdded){
      const list = [...deliveryRows];
    
      list[index]['Active_Flg'] = "N" ;
      console.log(list);
      setDeliveryRows(list);
      // setDeliveryRows(tempRows);
      setShowConfirm(false)
      }
      else{
      const list = [...deliveryRows];
      list.splice(index,1);
      console.log(list);
      setDeliveryRows(list);
      }
      setConfirmDeliveries(false)
  }

    // Function to handle edit
    const handleEdit = (i) => {
        // If edit mode is true setEdit will 
        // set it to false and vice versa
        setEdit(!isEdit);
    };
  
    // Function to handle save
    const handleSave = () => {
        setEdit(!isEdit);
        setRows(rows);
        console.log("saved : ", rows);
        setDisable(true);
        setOpen(true);
    };
  
    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes 
    // to input elements and record their values in state
    const handleInputChange = (e, index) => {
      
        
        setDisable(false);
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        console.log(list);
        setRows(list);
    };
    const handlePlatform=(event)=>{
      setPlatform(event.target.value);
    }
    // Showing delete confirmation to users
    const handleConfirm = () => {
        setShowConfirm(true);
    };
  
    // Handle the case of delete confirmation where 
    // user click yes delete a specific row of id:i
    const handleRemoveClick = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
        setShowConfirm(false);
    };
  
    // Handle the case of delete confirmation 
    // where user click no 
    const handleNo = () => {
        setShowConfirm(false);
    };
    // console.log("test");
    // console.log(Id);
    // const [value, setvalue]:any = useState([]);


    const selectActiveWaves=(e)=>{

      //setActiveWaves(e);
     // let w=[];
      // e.map(x=>{
      //   w=w.concat(x.Waves);
      //   return x;
      // })
      //w=w.concat(e.map(x=>x.Waves));
      setActiveWaves(e);
      console.log(e);
      console.log(activeWaves);
    }
    
    const selectActiveDelivery=(e:any,y:any,index:any)=>{

      //setActiveWaves(e);
     // let w=[];
     let t:any=deliveryRows
      e.map(x=>{
        
        if(x.label.toLowerCase()=='webhook')
        {
          t[index].ShowPost_Url=true;
        }
        return x;
      })
      
      t[index].FormDelivery_Typ=e;
      setDeliveryRows(t)
      //w=w.concat(e.map(x=>x.Waves));
      
      //setActiveDel(e);
      console.log(e);
      console.log(activeDel);
    }
console.log(viewform);

    const  handleOnchange  =  (val)  => {
      
      console.log(val);
      const final=val.split(",");
      console.log(final);
      
      // console.log(typeof(val));
      // console.log(JSON.parse(val));
      // console.log(JSON.stringify(val));
      setForm(final);
      console.log(form);
    }
    const  handleOnchangeDelivery  =  (val)  => {
      
      console.log(val);
      const final=val.split(",");
      console.log(final);
      
      // console.log(typeof(val));
      // console.log(JSON.parse(val));
      // console.log(JSON.stringify(val));
      setDelivery(final);
      console.log(form);
    }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    
    if(Id!=undefined)
    {
      
      GetFormDetails({FormId:Id},(res:any)=>{
        
        if(res){
        let data=res;
        console.log(data);
        const signval=res.FormSignReq_Flg=='Y'?true:false
        
             console.log(res);
             setFormName(res.Form_Nm);
             setFormType(res.Form_Typ);
             setSignCapture(signval);
             setFormTemplate(res.Template_Val);
             setFormBlobURL(res.FormLocation_Url);
             setFormstorageloc(res.FormStorage_Loc);
             setPrepform(res.FormPrepopulateReq_Flg=='Y'?true:false);
             setsalesforceform(true);
             setActiveform(res.Active_Flg=='Y'?true:false);
             setMaptoMedia(res.FormToMedia_Flg=='Y'?true:false);
             let sdate:any=moment(res.EffectiveStart_DtTm).format("MM/DD/YYYY");
             let edate:any=moment(res.EffectiveEnd_DtTm).format("MM/DD/YYYY");
             console.log(sdate,edate);
             setStartDateValue(sdate);
             setEndDateValue(edate);
             console.log(res.formFields);
             let formfields=res.formFields;
             setupdateformfield(formfields);
             let formdata:any=[];
             console.log(formfields);
             formfields.map((x:any)=>{	
              formdata=[...formdata,{id:x.Id, Name: x.Field_Nm, Type: x.Field_Typ, Value: x.FieldValByEmp_Flg=='Y'?"Yes":"No", Query: x.FieldQueryExec_Val,Prepopulate:x.FieldPrepopulate_Flg=='Y'?"Yes":"No",Active:x.Active_Flg=='Y'?"Yes":"No",EffectiveStartDate:moment(x.EffectiveStart_DtTm).format("YYYY-MM-DD"), EffectiveEndDate:moment(x.EffectiveEnd_DtTm).format("YYYY-MM-DD"),NewlyAdded:false}]	
               return x;	
            })
            console.log(formdata);
            setRows(formdata);
            let wavedata=res.formWaveAssociations;
            let d1:any=[];
           
            wavedata=wavedata.map((x:any) => {
              
              d1=[...d1,{Id:x.Id,waveId: x.WaveAssociationWave_Id,label:x.Wave_Nm,value:x.WaveAssociationWave_Id}]
             
              let wavesdate:any=moment(x.EffectiveStart_DtTm).format("MM/DD/YYYY");
              let waveedate:any=moment(x.EffectiveEnd_DtTm).format("MM/DD/YYYY");
              
              setActiveWave(x.Active_Flg=="Y"?true:false);
              setStartWaveDate(wavesdate);
              setEndWaveDate(waveedate);
               return x;
            })
            setPreviousWaveData(wavedata);
            console.log(d1);
            setActiveWaves(d1);
            //setvalue(d1);
            setUpdateplatform(res.formPlatforms);
            res.formPlatforms.map((x:any)=>{
              setPlatform(x.Platform_Nm)
              let startplatform:any=moment(x.EffectiveStart_DtTm).format("MM/DD/YYYY");
              let endplatform:any=moment(x.EffectiveEnd_DtTm).format("MM/DD/YYYY");
              setActivePlatform(x.Active_Flg=="Y"?true:false)
              setStartPlatformDate(startplatform);
              setStartPlatformDate(endplatform);
            })

            let deltype=res.formDeliveries;
            setdelupdate(deltype);
            let d2:any='';
            // let gb=groupBy(deltype,v=>v.FormDeliveryEvent_Typ)
            // for(let key in gb){
            //   gb[key].map((x:any)=>{

            //   })
            // }

            // deltype.map((x:any)=>{
            //   d2=[...d2,{label: x.FormDelivery_Typ,Id:x.Id}]
            //   // let startdelivery:any=moment(x.EffectiveStart_DtTm).format("MM/DD/YYYY");
            //   // let enddelivery:any=moment(x.EffectiveEnd_DtTm).format("MM/DD/YYYY");
            //   // setActiveDelivery(x.Active_Flg=="Y"?true:false);
            //   // setStartDeliveryDate(startdelivery);
            //   // setEndDeliveryDate(enddelivery);
            //   return x;
            // })
            // console.log(d2);
            // setActiveDel(d2);

              let t:any=[];
              deltype.map((x:any)=>{
                let shw=false;
                x.FormDelivery_Typ.toLowerCase()=='webhook'?shw=true:shw=false;
                t.push({
                  FormDelivery_Id :x.Id ,
                  FormDelivery_Typ: x.FormDelivery_Typ,
                  FormDeliveryEvent_Typ:x.FormDeliveryEvent_Typ,
                  EffectiveStart_DtTm: moment(x.EffectiveStart_DtTm).format("YYYY-MM-DD"),
                  EffectiveEnd_DtTm:moment(x.EffectiveEnd_DtTm).format("YYYY-MM-DD"),
                  Active_Flg:x.Active_Flg,
                  ShowPost_Url:true,
                  FormDeliveryAction_Url:x.FormDeliveryAction_Url,
                  NewAdded:false
              })
              })
              setDeliveryRows(t)
         //   setvalue(d1);
            if(title=="View")
            {
              
              setViewForm(true);
            }
            else
            {
              
              setViewForm(false);
            }
         }
         
      })
     
    }
    else
    {
      console.log("view");
    }
  }, [Id,title,viewformval])
  const groupBy = (x:any,f:any)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});

  // useEffect(() => {
  //   console.log(viewform);
  // }, [viewform])
  

  useEffect(() => {
    
    ;
    getData();
    console.log("DATA:"+options);
  }, [])
  
  const handleFormClick=()=>{
    
    const s_date=moment(new Date()).toISOString();
  }
  const getData=()=>{
      
    getCampaignsData({page:0,pageSize:10},(res)=>{
      
      if (res.length>0) {
             
              let waves:any=[];
              let dataForTree = res;
              dataForTree= res.map((x:any) => {
      x.WaveDetails.map((y: any) => {
      waves.push({label: y.WaveName, value: y.WaveId})
    })
    return x;
  });

              setOptions(waves);
              
     }
    }
     )
    // getWavesData({page:0,pageSize:20},(res)=>{
    //   let data:any=res;
    //       //  setHistData(res)
    //       console.log(res);
    //       let d1:any=[];
    //       data=data.map((x:any) => {
            
    //         d1=[...d1,{label: x.Wave_Nm, value: x.Id}]
    //          return x;
    //       })
    //       console.log(d1);
    //       setOptions(d1);
    //       console.log(options);
          
    //    })
  }
  const handleFormFieldClick=(event)=>{
    
    console.log(rows);
    const s_date=moment(new Date()).toISOString();
  //   console.log(FormName,FormType,signcapture,FormBlobURL,Formloc,prepform,salesforce,s_date);
  //   const signval=signcapture==true?"Y":"N"
  //   const prepval=prepform==true?"Y":"N"
  //   let d1:any=[];
  //   rows.map((x:any) =>{
  //     d1=[...d1,{   FieldForm_Id: FormID,
  //     Field_Nm: x.Name,
  //     Field_Typ: x.Type,
  //     FieldQueryExec_Val : "",
  //     FieldPrepopulate_Flg: x.Prepopulate,
  //     FieldValByEmp_Flg: "Y",
  //     EffectiveStart_DtTm: s_date,
  //     EffectiveEnd_DtTm	 :"1800-01-01",
  //     Active_Flg: "Y"
  // }]
  //     return x;
       
  //   })
  //   console.log(d1);
  //    let payload=d1;
  //   //   {     
  //   //       FieldForm_Id: FormID,
  //   //       Field_Nm: "Question2",
  //   //       Field_Typ: "string",
  //   //       FieldQueryExec_Val : "",
  //   //       FieldPrepopulate_Flg: "N",
  //   //       FieldValByEmp_Flg: "Y",
  //   //       EffectiveStart_DtTm: "2022-08-08",
  //   //       EffectiveEnd_DtTm	 : "1800-01-01",
  //   //       Active_Flg: "Y"
      
  //   // }
  //   console.log(
  //     payload
  //   )
  
  //   AddNewFormFields(payload,(res:any)=>{
  //     if(res){
  //       console.log(res);
  //     }
  //     else{
  //       console.log(res);
  //     }
  //   })
  }

  const handleWaveAssociation=()=>{
    
    const s_date=moment(new Date()).toISOString();
    // console.log(FormName,FormType,signcapture,FormBlobURL,Formloc,prepform,salesforce,s_date);
    // const signval=signcapture==true?"Y":"N"
    // const prepval=prepform==true?"Y":"N"
    // console.log(form,FormID);
    // let d1:any=[];
    //  for(var i=0;i<form.length;i++)
    // {        
    //     d1=[...d1,{    WaveAssociationForm_Id:FormID,
    //       WaveAssociationWave_Id:form[i],
    //       EffectiveStart_DtTm:s_date,
    //       EffectiveEnd_DtTm:"01-01-1800",
    //       Active_Flg:"Y"
    // }]
    // }

    // console.log(d1);
    // let payload=d1;
    // // console.log(
    // //   payload
    // // )
    // //   }
    // AddwaveAssociation(payload,(res:any)=>{
    //   if(res){
    //     console.log(res);
    //    // setFormID(res)
    //   }
    //   else{
    //     console.log(res);
    //   }
    // })
  }
  const closePopup=(torf)=>{	
    	
    	
    setFormName('')	
    setFormType('')	
    setSignCapture(false)	
    setFormBlobURL('')	
    setFormstorageloc('')	
    setPrepform(false);	
    setsalesforceform(false)	
    setRows([	
      {id:1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:"Yes" ,Active:"Yes",EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY'),NewlyAdded:true }	
    ])	
    // setForm([]);	
    // setPlatform('');	
    // setDelivery([]);	
    // setAssoPrescName('');	
    setDisabled(true);	
    // setAssoPresc({})	
    // setSecPrescribers([]);	
    handleCloseParent(torf);	
    setViewForm(false);	
             setFormTemplate('');	
             setActiveform(false);	
             setMaptoMedia(false);	
             let sdate:any=moment().format("MM/DD/YYYY");	
             let edate:any=moment().format("MM/DD/YYYY");	
             setStartDateValue(sdate);	
             setEndDateValue(edate);	
             setupdateformfield([]);	
             	
             setActiveWave(false);	
             setPlatform('')	
              	
              setActivePlatform(false)	
              setStartWaveDate(sdate);	
              setEndWaveDate(edate);	
              setActiveWaves([]); 	
              setUpdateplatform([]);	
              setStartPlatformDate(sdate);	
              setStartPlatformDate(edate);	
              setdelupdate([]);	
            	
              setDeliveryRows([])	
    	
              setViewForm(false);	
            	
         	
  }	

 
  const handleSavePlatform=()=>{
    const s_date=moment(new Date()).toISOString();
  //   console.log(platform);
  //   let payload=
  //   {
  //     PlatformForm_Id : FormID,
  //     Platform_Nm: platform,
  //     EffectiveStart_DtTm: s_date,
  //     EffectiveEnd_DtTm:s_date,
  //     Active_Flg: "Y"
  // }
  // AddNewPlatform(payload,(res:any)=>{
  
  //   if(res){
  
  //     console.log(res);
  //   }
  //   else{
  
  //     console.log(res);
  //   }
  // })
  }
 const handleSaveDelivery=()=>{
  
  
  if(title=="Edit")
  {
    const s_date=moment(new Date()).toISOString();
    let d1:any=[];
    const signval=signcapture==true?"Y":"N"
    const prepval=prepform==true?"Y":"N"
       
    let formupdatepayload=
    {
      Form_Nm:FormName, 
      Active_Flg: activeformflag==true?"Y":"N",
      Template_Val:FormTemplate,
      Form_Typ:FormType, 
      FormSignReq_Flg:signval,
      FormSignProvider_Typ:"signpad", 
      FormLocation_Url:FormBlobURL,  
      FormStorage_Loc:"",  
      FormPrepopulateReq_Flg:prepval,   
      FormCallback_Url:"",  
      FormToMedia_Flg:maptoMedia==true?'Y':'N',
      EffectiveStart_DtTm: moment(startdatevalue).format("YYYY-MM-DD"),   
      EffectiveEnd_DtTm:moment(enddatevalue).format("YYYY-MM-DD")     
    }
  UpdateForm(formupdatepayload,{FormId:Id},(res:any)=>{
    
    if(res){
      
      console.log(res.formId);
      setFormID(res.formId)
    }
    else{
      
      console.log(res);
    }
  })

  
    rows.map((x:any) =>{

      d1=[...d1,{   Field_Id: x.id,
      FieldForm_Id: Id,
      Field_Nm: x.Name,
      Field_Typ: x.Type,
      FieldQueryExec_Val : x.Query,
      FieldPrepopulate_Flg: x.Prepopulate=="true"?"Y":"N",
      FieldValByEmp_Flg: x.Value,
      EffectiveStart_DtTm: moment(x.EffectiveStartDate).format("YYYY-MM-DD"),
      EffectiveEnd_DtTm	 :moment(x.EffectiveEndDate).format("YYYY-MM-DD"),
      Active_Flg: x.Active=="true"?"Y":"N"
  }]
      return x;
       
    })
    console.log(d1);
     let payload=d1;
  
        UpdateFormField(payload,(res:any)=>{
          
          if(res){
            
            console.log(res);
            closePopup(true)
            //handleCloseParent(true);
          }
          else{
            
            console.log(res);
          }
        })
        
        let platformpayload:any=[];
        platformupdate.map((x:any)=>{
          platformpayload =[...platformpayload,{
            FormPlatform_Id: x.Id,
            PlatformForm_Id : Id,
            Platform_Nm: platform,
            EffectiveStart_DtTm: moment(startplatformdate).format("YYYY-MM-DD"),
            EffectiveEnd_DtTm: moment(endplatformdate).format("YYYY-MM-DD"),
            Active_Flg: activeplatformflag==true?"Y":"N"
          }]
        })
      console.log(platformpayload);
        UpdatePlatform(platformpayload,{FormId:Id},(res:any)=>{
          
          if(res){
            
            console.log(res);
          }
          else{
            
            console.log(res);
          }
        })
        
      //wave association 
      let d3:any=[];
      let d3Old:any=[];
      
     activeWaves.map((x:any)=>{
      let index:any=previousWaveData.findIndex((y:any)=>y.WaveAssociationWave_Id==x.value);
      if(index<0)
      d3=[...d3,{   
        WaveAssociationForm_Id:Id,
        WaveAssociationWave_Id:x.value,
        EffectiveStart_DtTm:startwavedate,
        EffectiveEnd_DtTm:endwavedate,
        Active_Flg:activewaveflag==true?"Y":"N"
      }]
      else
      d3Old=[...d3Old,
        {
          Id:previousWaveData[index].Id,
          WaveAssociationForm_Id:Id,
          WaveAssociationWave_Id:x.value,
          EffectiveStart_DtTm:startwavedate,
          EffectiveEnd_DtTm:endwavedate,
          Active_Flg:activewaveflag==true?"Y":"N",
          CreatedBy_Id:previousWaveData[index].CreatedBy_Id,
          CreatedBy_DtTm:previousWaveData[index].CreatedBy_DtTm,
          UpdatedBy_DtTm:new Date().toISOString()
        }]

    })      
    let wavepayload=d3;
    if(wavepayload.length>0)
    AddwaveAssociation(wavepayload,(res:any)=>{
      if(res){
        console.log(res);
      // setFormID(res)
      }
      else{
        console.log(res);
      }
    })
    if(d3Old.length>0)
    UpdateWave(d3Old,(res:any)=>{
      if(res){
        console.log(res);
      // setFormID(res)
      }
      else{
        console.log(res);
      }
    })
  
    
    
  //     let original = contact.Prescribers;
  //     let modified = secPrescribers;
  //     let difference = original.filter(x => { if (modified.findIndex(p => p.Id == x.Id) < 0) return x; }).concat(modified.filter(x => { if (original.findIndex(p => p.Id == x.Id) < 0) return x; }))
  //     let forDelete: any = difference.filter((x: any) => { if (x.Association_Id) return x.Id }).map(x => x.Association_Id)
  //     let forAdd: any = difference.filter((x: any) => { if (!x.Association_Id) return x.Id }).map(x => x.Id)
  
  // console.log(deldata);
  let original =delupdate;
  let modified = deliveryRows;
  let forAdd:any = modified.filter((x:any)=>{
    if(x.NewAdded)
    return x;
  })//modified.filter((x:any) => { if (original.findIndex((p:any) => p.FormDelivery_Id == x.FormDelivery_Id) < 0) return x; })
  let forUpdate: any = modified.filter((x:any)=>{
    if(!x.NewAdded)
    return x;
  }) //modified.filter((x:any) => { if (original.findIndex((p:any) => p.FormDelivery_Id == x.FormDelivery_Id) >= 0) return x; })
  
  if(forUpdate.length>0){
    let deldata:any=[];
    forUpdate.map((x:any)=>{
      deldata=[...deldata,
        {
        FormDelivery_Id : x.FormDelivery_Id,
        FormDelivery_Typ: x.FormDelivery_Typ,
        FormDeliveryEvent_Typ:x.FormDeliveryEvent_Typ,
        FormDeliveryAction_Url:x.FormDeliveryAction_Url,
        EffectiveStart_DtTm: moment(startdeldate).format("YYYY-MM-DD"),
        EffectiveEnd_DtTm: moment(enddeldate).format("YYYY-MM-DD"),
        Active_Flg: x.Active_Flg
    }]
    return x;
    })
    let delpayload=deldata;
    UpdateDelivery(delpayload,(res:any)=>{
      
      if(res){
        
        console.log(res);
        closePopup(true)
        //handleCloseParent(true);
      }
      else{
        
        console.log(res);
      }
    })
  }
  if(forAdd.length>0){
    let deldata2:any=[];
    forAdd.map((x:any)=>{
      deldata2=[...deldata2,
        {
        FormDeliveryForm_Id : Id,
        FormDelivery_Typ: x.FormDelivery_Typ,
        FormDeliveryEvent_Typ:x.FormDeliveryEvent_Typ,
        EffectiveStart_DtTm: moment(startdeldate).format("YYYY-MM-DD"),
        EffectiveEnd_DtTm: moment(enddeldate).format("YYYY-MM-DD"),
        Active_Flg: x.Active_Flg,
        FormDeliveryAction_Url:x.FormDeliveryAction_Url
    }]
    return x;
    })
    AddNewDelivery(deldata2,(res:any)=>{
      
      if(res){
        
        console.log(res);
        closePopup(true)
        //handleCloseParent(true);
      }
      else{
        
        console.log(res);
      }
    })
  
  }
 
  setNextTabfieldform('collapse');
  setNextTabWave('collapse');
  setNextTabplatform('collapse');
  setNextTabdelivery('collapse');

        
  }
  else{
       
          const s_date=moment(new Date()).toISOString();
          //form click
          console.log(FormName,FormType,signcapture,FormBlobURL,Formloc,prepform,salesforce,s_date);
          const signval=signcapture==true?"Y":"N"
          const prepval=prepform==true?"Y":"N"
         
                    
           if(FormName!=''&&FormType!="")
          {
            let formpayload=
            {
              Form_Nm:FormName, 
              Active_Flg: activeformflag==true?"Y":"N",
              Template_Val:FormTemplate,
              Form_Typ:FormType, 
              FormSignReq_Flg:signval,
              FormSignProvider_Typ:"signpad", 
              FormLocation_Url:FormBlobURL,  
              FormStorage_Loc:"",  
              FormPrepopulateReq_Flg:prepval,   
              FormCallback_Url:"",  
              FormToMedia_Flg:maptoMedia==true?'Y':'N',
              EffectiveStart_DtTm:moment(startdatevalue).format("YYYY-MM-DD"),   
              EffectiveEnd_DtTm:moment(enddatevalue).format("YYYY-MM-DD")
              }
              console.log(
                formpayload
              )
              AddNewForm(formpayload,(res:any)=>{
                
                if(res){
                  
                  console.log(res.formId);
                  const FormID=res.formId
                  
                  
                  //form field
                    let d2:any=[];
                    rows.map((x:any) =>{
                      d2=[...d2,{   FieldForm_Id: FormID,
                      Field_Nm: x.Name,
                      Field_Typ: x.Type,
                      FieldQueryExec_Val : "",
                      FieldPrepopulate_Flg: x.Prepopulate=="Yes"?"Y":"N",
                      FieldValByEmp_Flg: x.Value=="Yes"?"Y":"N",
                      EffectiveStart_DtTm: x.EffectiveStartDate,
                      EffectiveEnd_DtTm	 :x.EffectiveEndDate,
                      Active_Flg: x.Active=="Yes"?"Y":"N"
                    }]
                        return x;
                        
                      })
                    console.log(d2);
                    let formfieldpayload=d2;
                    console.log(
                      formfieldpayload
                    )
                    
                    AddNewFormFields(formfieldpayload,(res:any)=>{
                      if(res){
                        console.log(res);
                      }
                      else{
                        console.log(res);
                      }
                    })
                  
                
                  
                  //wave association
                    console.log(form,FormID);
                    let d3:any=[];
                    activeWaves.map((x:any)=>{
                      d3=[...d3,{   
                        WaveAssociationForm_Id:FormID,
                        WaveAssociationWave_Id:x.value,
                        EffectiveStart_DtTm:startwavedate,
                        EffectiveEnd_DtTm:endwavedate,
                        Active_Flg:activewaveflag==true?"Y":"N"
                      }]
                    })      
                      
                    
          
                    console.log(d3);
                    let wavepayload=d3;
                    // console.log(
                    //   payload
                    // )
                    //   }
          
                    
                    AddwaveAssociation(wavepayload,(res:any)=>{
                      if(res){
                        console.log(res);
                      // setFormID(res)
                      }
                      else{
                        console.log(res);
                      }
                    })
                  
                
              
                  //form platform
                    console.log(platform);
                    let platformpayload=
                    {
                      PlatformForm_Id : FormID,
                      Platform_Nm: platform,
                      EffectiveStart_DtTm: moment(startplatformdate).format("YYYY-MM-DD"),
                      EffectiveEnd_DtTm:moment(endplatformdate).format("YYYY-MM-DD"),
                      Active_Flg: activeplatformflag==true?"Y":"N"
                      }
                      AddNewPlatform(platformpayload,(res:any)=>{
                        
                        if(res){
                          
                          console.log(res);
                        }
                        else{
                          
                          console.log(res);
                        }
                      })
                  
                  
                  //form delivery
                  let d1:any=[];

                  deliveryRows.map((x:any)=>{
                    d1=[...d1,
                      {
                      FormDeliveryForm_Id : FormID,
                      FormDelivery_Typ: x.FormDelivery_Typ,
                      FormDeliveryEvent_Typ:x.FormDeliveryEvent_Typ,
                      EffectiveStart_DtTm: moment().format("YYYY-MM-DD"),
                      EffectiveEnd_DtTm:moment().format("YYYY-MM-DD"),
                      Active_Flg: "Y",
                      FormDeliveryAction_Url:x.FormDeliveryAction_Url
                  }]
                   
                  return x;
                  })
                  //   activeDel.map((x:any)=>{
                  //     d1=[...d1,{FormDeliveryForm_Id : FormID,
                  //       FormDelivery_Typ: x.label,
                  //       EffectiveStart_DtTm: startdeldate,
                  //       EffectiveEnd_DtTm:enddeldate,
                  //       Active_Flg: activeDelflag==true?"Y":"N"
                  //   }]
                  //   return x;
                  // })    
                      
                
                  console.log(d1);
                  let payload=d1;
                  AddNewDelivery(payload,(res:any)=>{
                    
                    if(res){
                      
                      console.log(res);
                      closePopup(true)
                      //handleCloseParent(true);
                    }
                    else{
                      
                      console.log(res);
                    }
                  })
                
            
          
       
                }
                else{
                  
                  console.log(res);
                }
             })
          }
         
          setNextTabfieldform('collapse');
          setNextTabWave('collapse');
          setNextTabplatform('collapse');
          setNextTabdelivery('collapse');
          // //form field
          //   let d2:any=[];
          //   rows.map((x:any) =>{
          //     d2=[...d2,{   FieldForm_Id: FormID,
          //     Field_Nm: x.Name,
          //     Field_Typ: x.Type,
          //     FieldQueryExec_Val : "",
          //     FieldPrepopulate_Flg: x.Prepopulate=="Yes"?"Y":"N",
          //     FieldValByEmp_Flg: x.Value=="Yes"?"Y":"N",
          //     EffectiveStart_DtTm: x.EffectiveStartDate,
          //     EffectiveEnd_DtTm	 :x.EffectiveEndDate,
          //     Active_Flg: x.Active=="Yes"?"Y":"N"
          //   }]
          //       return x;
                
          //     })
          //   console.log(d2);
          //    let formfieldpayload=d2;
          //   console.log(
          //     formfieldpayload
          //   )
          
          //   AddNewFormFields(formfieldpayload,(res:any)=>{
          //     if(res){
          //       console.log(res);
          //     }
          //     else{
          //       console.log(res);
          //     }
          //   })
          
        
          
          // //wave association
          //   console.log(form,FormID);
          //   let d3:any=[];
          //   activeWaves.map((x:any)=>{
          //     d3=[...d3,{    WaveAssociationForm_Id:FormID,
          //       WaveAssociationWave_Id:x.value,
          //       EffectiveStart_DtTm:startwavedate,
          //       EffectiveEnd_DtTm:endwavedate,
          //       Active_Flg:activewaveflag==true?"Y":"N"
          //     }]
          //   })      
               
            
  
          //   console.log(d3);
          //   let wavepayload=d3;
          //   // console.log(
          //   //   payload
          //   // )
          //   //   }
  
            
          //   AddwaveAssociation(wavepayload,(res:any)=>{
          //     if(res){
          //       console.log(res);
          //     // setFormID(res)
          //     }
          //     else{
          //       console.log(res);
          //     }
          //   })
          
        
      
          // //form platform
          //   console.log(platform);
          //   let platformpayload=
          //   {
          //     PlatformForm_Id : FormID,
          //     Platform_Nm: platform,
          //     EffectiveStart_DtTm: startplatformdate,
          //     EffectiveEnd_DtTm:endplatformdate,
          //     Active_Flg: activeplatformflag==true?"Y":"N"
          //     }
          //     AddNewPlatform(platformpayload,(res:any)=>{
          
          //       if(res){
          
          //         console.log(res);
          //       }
          //       else{
          
          //         console.log(res);
          //       }
          //     })
          
          
          // //form delivery
          //   let d1:any=[];
          //   activeDel.map((x:any)=>{
          //     d1=[...d1,{FormDeliveryForm_Id : FormID,
          //       FormDelivery_Typ: x.label,
          //       EffectiveStart_DtTm: startdeldate,
          //       EffectiveEnd_DtTm:enddeldate,
          //       Active_Flg: activeDelflag==true?"Y":"N"
          //   }]
          // })    
              
         
          // console.log(d1);
          // let payload=d1;
          // AddNewDelivery(payload,(res:any)=>{
          
          //   if(res){
          
          //     console.log(res);
          //     handleCloseParent(true);
          //   }
          //   else{
          
          //     console.log(res);
          //   }
          // })
        
    
          
      } 
     

    }
    const handleNextTab=(event)=>{
    
    if(event.target.dataset.bsTarget=='#panelsStayOpen-collapseOne'){
      setNextTabfieldform('show');
      handleFormClick();
    }

     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseTwo")
     {
     setNextTabWave('show');
     handleFormFieldClick(event);
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseThree")
     {
     setNextTabplatform('show');
     handleWaveAssociation();
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFour")
     {
     setNextTabdelivery('show');
     handleSavePlatform();
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFive")
     {
      
     handleSaveDelivery();
     }
    //  document.getElementsByClassName('accformfield')[0].append('show');
  }
  const checkDisabled=(assoPrescName,othersPhone,meetingname)=>
  {
    
    if(assoPrescName && othersPhone && meetingname){
      
      setDisabled(false);
    }
    else setDisabled(true);
  }
  const handleTabClick=(event)=>{
    
    if(event.target.dataset.bsTarget=='#panelsStayOpen-collapseOne'){
      setNextTabWave('collapse');
      setFormTab('show')
      setNextTabfieldform('collapse');
      setNextTabplatform('collapse');
      setNextTabdelivery('collapse');
    }

     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseTwo")
     {
     setNextTabWave('collapse');
     setFormTab('collapse')
     setNextTabfieldform('show');
     setNextTabplatform('collapse');
     setNextTabdelivery('collapse');
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseThree")
     {
        setNextTabWave('show');
        setFormTab('collapse')
        setNextTabfieldform('collapse');
        setNextTabplatform('collapse');
        setNextTabdelivery('collapse');
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFour")
     {
      setNextTabWave('collapse');
      setFormTab('collapse')
      setNextTabfieldform('collapse');
      setNextTabplatform('show');
      setNextTabdelivery('collapse');
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFive")
     {
      setNextTabWave('collapse');
      setFormTab('collapse')
      setNextTabfieldform('collapse');
      setNextTabplatform('collapse');
      setNextTabdelivery('show');
     }
    //  document.getElementsByClassName('accformfield')[0].append('show');
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        onClose={closePopup}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{height:"50%"}}
      >
        <Fade in={show}>
          <Box sx={style}>
            <div className="modal-header d-flex ">
              <h1 className="font-createcontact">{title} {t("Forms")}</h1>
             
              <span onClick={() => {
                  closePopup(false);
                }}
                 className="close">
                ×
              </span>
            </div>
         <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" onClick={handleTabClick}>
                  {t("Information")}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" className={formval} aria-labelledby="panelsStayOpen-headingOne">
                <div className="accordion-body">
                    {/* <form> */}
                    <TextField
                         className="roundborder"
                         label={t("Name")}
                         placeholder={t("Please enter Form Name")}
                         value={FormName}
                         sx={{width:400}}
                         onChange={(e)=>setFormName(e.target.value)}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                        //  onChange={(e) => {if(e.target.value.length>0){
                        //   setMeetingName(e.target.value)
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        //  }
                        // else
                        // {
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        // }}}
                         
                         focused
                        />
                         <TextField
                         className="formtype"
                         label={t("Template")}
                         value={FormTemplate}
                         placeholder={t("Please enter Form Template")}
                         sx={{width:400}}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                         onChange={(e)=>setFormTemplate(e.target.value)}
                        //  onChange={(e) => {if(e.target.value.length>0){
                        //   setMeetingName(e.target.value)
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        //  }
                        // else
                        // {
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        // }}}
                         
                         focused
                        />
                         <FormControl fullWidth  sx={{marginTop:1}}>
                          <InputLabel id="demo-simple-select-label" shrink={true}>{t('Form Type')}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={FormType}
                             disabled={viewformval}
                            // label="Form Type"
                            onChange={(e)=>setFormType(e.target.value)}
                          >
                            <MenuItem value={"HTML"} className="dropdown-menu-item">{t("HTML")}</MenuItem>
                            <MenuItem value={"PDF"} className="dropdown-menu-item">{t("PDF")}</MenuItem>
                          </Select>
                        </FormControl>
                         <TextField
                         className="blobpath"
                         label={t("Blob file path")}
                         placeholder=""
                         sx={{width:400}}
                         value={FormBlobURL}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                         onChange={(e)=>setFormBlobURL(e.target.value)}
                        //  onChange={(e) => {if(e.target.value.length>0){
                        //   setMeetingName(e.target.value)
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        //  }
                        // else
                        // {
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        // }}}
                         
                         focused
                        />
                                            
                        <TextField
                         className="baseurl"
                         label={t("Base URL")}
                         value={Formloc}
                         placeholder=""
                         sx={{width:400}}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                         onChange={(e)=>setFormstorageloc(e.target.value)}
                        //  onChange={(e) => {if(e.target.value.length>0){
                        //   setMeetingName(e.target.value)
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        //  }
                        // else
                        // {
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        // }}}
                         
                         focused
                        />
                        <div className="selecttype-radio-btns form-radio-select form-input">
                            <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Signature Capture Required?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={signcapture==true?"yes":"no"}
                              className="formsign"
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setSignCapture(false)
                                      : setSignCapture(true);
                                  }}
                              >
                                
                                <FormControlLabel                      
                                  value="yes"
                                  disabled={viewformval}                               
                                  control={<BpRadio />}
                                  label={t("Yes")}
                                  className="formcontrol"
                                />
                                <FormControlLabel
                                  value="no"
                                  disabled={viewformval}
                                  control={<BpRadio />}
                                  label={t("No")}
                                  className="formcontrol"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Do you want prepopulate form?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={prepform==true?"yes":"no"}
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setPrepform(false)
                                      : setPrepform(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Do you want this form in Media?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={maptoMedia==true?"yes":"no"}
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setMaptoMedia(false)
                                      : setMaptoMedia(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol"
                                />
                              </RadioGroup>
                            </FormControl>
                            
                            {/* {signcapture==true?<><div><SignatureCanvas canvasProps={{width: 500, height: 200}} /></div></>:<><div></div></>} */}
                         </div>    
                         <div className="selecttype-radio-btns form-radio-select form-input">
                         <FormControl className="saleforce">
                              <FormLabel id="formdemo-customized-radios">{t('Is it a salesforce form?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                // value={salesforce}
                                value={salesforce==true?"yes":"no"}
                                name="customized-radios"
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setsalesforceform(false)
                                      : setsalesforceform(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol-salesforce"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol-salesforce"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Do you want this form visible?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={activeformflag==true?"yes":"no"}
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setActiveform(false)
                                      : setActiveform(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol"
                                />
                              </RadioGroup>
                            </FormControl>
                            </div> 
                        {/* <TextField
                         className="formtype"
                         label="Base URL"
                         value={Formloc}
                         placeholder=""
                         sx={{width:400}}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                         onChange={(e)=>setFormstorageloc(e.target.value)}
                        //  onChange={(e) => {if(e.target.value.length>0){
                        //   setMeetingName(e.target.value)
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        //  }
                        // else
                        // {
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        // }}}
                         
                         focused
                        /> */}
                           
                              <div className="FormDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}> 
                                {viewformval == true?                                 
                                  <DatePicker
                                    views={['day']}
                                    readOnly
                                    label={t("Effective start date")}
                                    value={startdatevalue}
                                    onChange={(newValue) => {
                                        setStartDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null}  disabled={true}/>}
                                  />:
                                  <DatePicker
                                  views={['day']}
                                  label={t("Effective start date")}
                                  value={startdatevalue}
                                  onChange={(newValue) => {
                                      setStartDateValue(newValue);
                                  }}
                                  renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                />
                              }
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              <div className="FormDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                  {viewformval == true? <DatePicker
                                    views={['day']}
                                    label={t("Effective end date")}
                                    readOnly
                                    value={enddatevalue}
                                    onChange={(newValue) => {
                                       setEndDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />:                             
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective end date")}
                                    value={enddatevalue}
                                    onChange={(newValue) => {
                                       setEndDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                }  
                                </Stack>
                              </LocalizationProvider>
                              </div>
                            
                    {/* </form> */}
                </div>
                <div className="save">
                            <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" onClick={handleNextTab}>{t('Next')}</button>
                 </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo" onClick={handleTabClick}>
                  {t("Fields")}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" className={fieldformval} aria-labelledby="panelsStayOpen-headingTwo">
                <div className="accordion-body">
                {/* <button className="additem" onClick={addTableRows}>Add</button>
                <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" onClick={handleNextTab}>Next</button>
                 */}
               
              
                <div className="tabledata">
                      <TableBody>
                          <Box margin={1}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                {/* {isEdit ? ( */}
                                  <div className="table-btn">
                                     <button className="additem" onClick={handleAdd}><i className="material-icons add">&#xe145;</i>{t('ADD')}</button>                                  
                                     
                                        {/* {disable ? (
                                          // <Button disabled align="right" onClick={handleSave}>
                                          //   <DoneIcon />
                                          //   SAVE
                                          // </Button>
                                          <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" onClick={handleNextTab}>Next</button>
                                        ) : (
                                          // <Button align="right" onClick={handleSave}>
                                          //   <DoneIcon />
                                          //   SAVE
                                          // </Button>
                                          <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" onClick={handleNextTab}>Next</button>
                                        )} */}
                                    
                                    
                                  </div>
                                {/* ) : (
                                  <div> */}
                                    {/* <Button onClick={handleAdd}>
                                      <AddBoxIcon onClick={handleAdd} />
                                      ADD
                                    </Button> */}
                                    {/* <button className="additem" onClick={handleAdd}>Add</button>
                                    <button className="additem" onClick={handleEdit}>Edit</button> */}
                                    {/* <Button onClick={handleEdit}>
                                      <CreateIcon />
                                      EDIT
                                    </Button> */}
                                  {/* </div>
                                )} */}
                              </div>
                            </div>
                         
                      
                            <Table
                              className={classes.table}
                              size="medium"
                              aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('Name')}</TableCell>
                                  <TableCell>{t('Type')}</TableCell>
                                  <TableCell>{t('Value')}</TableCell>
                                  <TableCell align="center">{t('Query')}</TableCell>
                                  <TableCell>{t('Prepopulate')}</TableCell>
                                  <TableCell>{t('Active')}</TableCell>
                                  <TableCell>{t('Start date')}</TableCell>
                                  <TableCell>{t('End date')}</TableCell>
                                  {/* <TableCell align="center">City</TableCell>
                                  <TableCell align="center"> </TableCell> */}
                                </TableRow>
                              </TableHead>
                              {viewformval==false?   
                              <TableBody>
                                {rows.map((row, i) => {
                                  return (
                                 
                                      <TableRow>
                                            <TableCell padding="none">
                                              <input
                                                value={row.Name}
                                                name="Name"
                                                onChange={(e) => handleInputChange(e, i)}
                                                style={{border:"none"}}
                                              />
                                            </TableCell>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Type"
                                                value={row.Type}
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                <option value=""></option>
                                                <option value="Date">{t("Date")}</option>
                                                <option value="String">{t("String")}</option>
                                                <option value="Signature">{t("Signature")}</option>
                                                {/* <option value="Amaravati">Amaravati</option>
                                                <option value="Pulgaon">Pulgaon</option> */}
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                            <select
                                                style={{ width: "100px" }}
                                                name="Value"
                                                defaultValue={'Yes'}
                                                value={row.Value=='Yes'?"Yes":"No"}
                                      
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                {/* <option value=""></option> */}
                                                <option value="Yes">{t("Yes")}</option>
                                                <option value="No">{t("No")}</option>                                       
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                              <input
                                                value={row.Query}
                                                name="Query"
                                                style={{border:"none"}}
                                                onChange={(e) => handleInputChange(e, i)}
                                              />
                                            </TableCell>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Prepopulate"
                                                value={row.Prepopulate=='Yes'?"Yes":"No"}
                                                
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                {/* <option value=""></option> */}
                                                <option value="Yes">{t("Yes")}</option>
                                                <option value="No">{t("No")}</option>                                       
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Active"
                                               value={row.Active=="Yes"?"Yes":"No"}
                                                defaultValue={"Yes"}
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                {/* <option value=""></option> */}
                                                <option value="Yes">{t("Yes")}</option>
                                                <option value="No">{t("No")}</option>                                       
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                              
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input name="EffectiveStartDate" className="date" value={row.EffectiveStartDate.toString()} type="date" onChange={(e) => handleInputChange(e, i)}/>
                                            </TableCell>
                                            <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input  name="EffectiveEndDate" className="date" value={row.EffectiveEndDate.toString()}  type="date" onChange={(e) => handleInputChange(e, i)}/>
                                            </TableCell>
                                            {/* <TableCell component="th" scope="row" align="center">
                                              {row.Name}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                              {row.Type}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                              {row.Value}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                              {row.Query}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                              {row.Prepopulate}
                                            </TableCell> */}
                                            {/* <TableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            ></TableCell> */}
                                          
                                        
                                        {/* {isEdit ? ( */}
                                        {row.NewlyAdded?	
                                          <Button className="mr10" onClick={handleConfirm}>	
                                            <ClearIcon />	
                                          </Button>:null}
                                        
                                        {showConfirm && (
                                          <div>
                                            <Dialog
                                              open={showConfirm}
                                              onClose={handleNo}
                                              aria-labelledby="alert-dialog-title"
                                              aria-describedby="alert-dialog-description"
                                            >
                                              <DialogTitle id="alert-dialog-title">
                                                {"Confirm Delete"}
                                              </DialogTitle>
                                              <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                  {t("Are you sure to delete")}
                                                </DialogContentText>
                                              </DialogContent>
                                              <DialogActions>
                                                <Button
                                                  onClick={() => handleRemoveClick(i)}
                                                  color="primary"
                                                  autoFocus
                                                >
                                                  {t("Yes")}
                                                </Button>
                                                <Button
                                                  onClick={handleNo}
                                                  color="primary"
                                                  autoFocus
                                                >
                                                  {t("No")}
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </div>
                                        )}
                                      </TableRow>
                                    
                                  );
                                })}
                              </TableBody>:
                                 <TableBody>
                                 {rows.map((row, i) => {
                                   return (
                                  
                                       <TableRow>
                                             <TableCell padding="none">
                                               <input
                                                 value={row.Name}
                                                 name="Name"
                                                //  onChange={(e) => handleInputChange(e, i)}
                                                 style={{border:"none"}}
                                               />
                                             </TableCell>
                                             <TableCell padding="none">
                                               <select
                                                 style={{ width: "100px" }}
                                                 name="Type"
                                                 value={row.Type}
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 <option value=""></option>
                                                 <option value="Date">{t("Date")}</option>
                                                 <option value="String">{t("String")}</option>
                                                 <option value="Signature">{t("Signature")}</option>
                                                 {/* <option value="Amaravati">Amaravati</option>
                                                 <option value="Pulgaon">Pulgaon</option> */}
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                             <select
                                                 style={{ width: "100px" }}
                                                 name="Value"
                                                 value={row.Value=='Yes'?"Yes":"No"}
                                       
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 {/* <option value=""></option> */}
                                                 <option value="Yes">{t("Yes")}</option>
                                                 <option value="No">{t("No")}</option>                                       
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                               <input
                                                 value={row.Query}
                                                 name="Query"
                                                 style={{border:"none"}}
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               />
                                             </TableCell>
                                             <TableCell padding="none">
                                               <select
                                                 style={{ width: "100px" }}
                                                 name="Prepopulate"
                                                 value={row.Prepopulate=='Yes'?"Yes":"No"}
                                       
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 {/* <option value=""></option> */}
                                                 <option value="Yes">{t("Yes")}</option>
                                                 <option value="No">{t("No")}</option>                                       
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                               <select
                                                 style={{ width: "100px" }}
                                                 name="Active"
                                                 value={row.Active=="Yes"?"Yes":"No"}
                                       
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 {/* <option value=""></option> */}
                                                 <option value="Yes">{t("Yes")}</option>
                                                 <option value="No">{t("No")}</option>                                       
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input name="EffectiveStartDate" type="date" value={row.EffectiveStartDate.toString()} />
                                            </TableCell>
                                            <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input  name="EffectiveEndDate" type="date" value={row.EffectiveEndDate.toString()}/>
                                            </TableCell>
                                        
                                             {/* <TableCell component="th" scope="row" align="center">
                                               {row.Name}
                                             </TableCell>
                                             <TableCell component="th" scope="row" align="center">
                                               {row.Type}
                                             </TableCell>
                                             <TableCell component="th" scope="row" align="center">
                                               {row.Value}
                                             </TableCell>
                                             <TableCell component="th" scope="row" align="center">
                                               {row.Query}
                                             </TableCell>
                                             <TableCell component="th" scope="row" align="center">
                                               {row.Prepopulate}
                                             </TableCell> */}
                                             {/* <TableCell
                                               component="th"
                                               scope="row"
                                               align="center"
                                             ></TableCell> */}
                                           
                                         
                                         {/* {isEdit ? ( */}
                                           <Button className="mr10" onClick={handleConfirm}>
                                             <ClearIcon />
                                           </Button>
                                       
                                         {showConfirm && (
                                           <div>
                                             <Dialog
                                               open={showConfirm}
                                               onClose={handleNo}
                                               aria-labelledby="alert-dialog-title"
                                               aria-describedby="alert-dialog-description"
                                             >
                                               <DialogTitle id="alert-dialog-title">
                                                 {"Confirm Delete"}
                                               </DialogTitle>
                                               <DialogContent>
                                                 <DialogContentText id="alert-dialog-description">
                                                  {t("Are you sure to delete")}
                                                 </DialogContentText>
                                               </DialogContent>
                                               <DialogActions>
                                                 <Button
                                                   onClick={() => handleRemoveClick(i)}
                                                   color="primary"
                                                   autoFocus
                                                 >
                                                   {t("Yes")}
                                                 </Button>
                                                 <Button
                                                   onClick={handleNo}
                                                   color="primary"
                                                   autoFocus
                                                 >
                                                   {t("No")}
                                                 </Button>
                                               </DialogActions>
                                             </Dialog>
                                           </div>
                                         )}
                                       </TableRow>
                                     
                                   );
                                 })}
                               </TableBody>}
                              
                            </Table>
                            <div className="save">
                                    <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" onClick={handleNextTab}>{t('Next')}</button>
                               </div>
                          </Box>
                      </TableBody>
                </div>
                
                   
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" onClick={handleTabClick}> 
                  {t("Wave Association")}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseThree" className={waveval} aria-labelledby="panelsStayOpen-headingThree">
                <div className="wave-accordion-body">
                {/* <label className="wavecontent">Wave Name</label> */}
                {/* <MultiSelect
                   disabled={viewformval}
                  //  selectedItems={form.selectedItems}
                    onChange={(e)=>handleOnchange(e)}
                    options={options}
                    
                  /> */}
                   <MultiselectDropdown
                label={t("Wave Name")}
                selectedData={activeWaves}
                data={options}
                name="label"              
                id="value"
                func={(e) => {
                    selectActiveWaves(e);
                }}
              />

                  <div className="WaveFormDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>                                 
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective start date")}
                                    value={startwavedate}
                                    onChange={(newValue) => {
                                        setStartWaveDate(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              
                  <div className="selecttype-radio-btns form-radio-select form-input">
                  <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Do you want this form visible?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={activewaveflag==true?"yes":"no"}
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setActiveWave(false)
                                      : setActiveWave(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  className="radio-formcontrol"
                                  label={t("Yes")}
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  className="radio-formcontrol"
                                  label={t("No")}
                                />
                              </RadioGroup>
                            </FormControl>

                  </div>
                              <div className="WaveFormEndDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>                                 
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective end date")}
                                    value={endwavedate}
                                    onChange={(newValue) => {
                                      setEndWaveDate(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              
                </div>
                <div className="save">
                              <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" onClick={handleNextTab}>{t('Next')}</button>
                              </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" onClick={handleTabClick}>
                  {t("Platform")}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseFour" className={platfrmtab} aria-labelledby="panelsStayOpen-headingThree">
                <div className="accordion-body">
                 {/* <form> */}
                 <TextField
                         className="formtype"
                         label={t("Wave Name")}
                         placeholder=""
                         sx={{width:400}}
                         value={platform}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                         onChange={handlePlatform}
                        //  onChange={(e) => {if(e.target.value.length>0){
                        //   setMeetingName(e.target.value)
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        //  }
                        // else
                        // {
                        //   checkDisabled(assoPrescName.length>0,othersPhone.length>0,meetingname)
                        // }}}
                         
                         focused
                        />
                        <div className="PlatFormStartDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>                                 
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective start date")}
                                    value={startplatformdate}
                                    onChange={(newValue) => {
                                        setStartPlatformDate(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              </div>
                             
                            
                  <div  className="preview-values"> <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Do you want this form visible?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={activeplatformflag==true?"yes":"no"}
                                onClick={(e: any) => {
                                    e.target.value === "no"
                                      ? setActivePlatform(false)
                                      : setActivePlatform(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol"
                                />
                              </RadioGroup>
                            </FormControl></div>
                            <div className="PlatFormEndDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>                                 
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective end date")}
                                    value={endplatformdate}
                                    onChange={(newValue) => {
                                      setEndPlatformDate(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              </div>   
                       
                    
                 {/* </form> */}
                </div>
                <div className="save">
                <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" onClick={handleNextTab}>{t("Next")}</button>
                 </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" onClick={handleTabClick}>
                  {t('Delivery')}
                </button>
              </h2>
              
              <div id="panelsStayOpen-collapseFive" className={deltab} aria-labelledby="panelsStayOpen-headingThree">
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                  <div className="table-btn">
                                     <button className="additem" onClick={handleAddDeliveries}><i className="material-icons add">&#xe145;</i>{t('ADD')}</button>
                                  </div>
                              </div>
                            </div> */}
                {
  //                 deliveryRows.map((x:any,index:any)=>(
  //                 <div key={'del-'+x.FormDelivery_Id} id={'del-'+x.FormDelivery_Id}>
  //                   {
  //                     deliveryRows.length>1?
  //                     <div style={{ display: "flex", justifyContent: "space-between" }}>
  //                             <div>
  //                                 <div className="table-btn">
  //                                    <button className="additem" onClick={()=>{handleRemoveDeliveries(x,index)}}><i className="material-icons minus">&#xe145;</i>{t('REMOVE')}</button>
  //                                 </div>
  //                             </div>
  //                           </div>:null
  //                   }
                    
  //                 <div className="accordion-bodyDelivery" >
                    
  //                       <FormControl fullWidth>
  //                         <InputLabel id="demo-simple-select-label" shrink={true}>{t('Form Event')}</InputLabel>
  //                         <Select
  //                           labelId="demo-simple-select-label"
  //                           id="demo-simple-select"
  //                           value={x.FormDeliveryEvent_Typ?x.FormDeliveryEvent_Typ:''}
  //                           disabled={viewformval}
  //                           // label="Form Type"
  //                           key={x.FormDeliveryEvent_Typ?x.FormDeliveryEvent_Typ:''}
  //                           onChange={(e)=>{
  //                             let t=deliveryRows;
  //                             t[index].FormDeliveryEvent_Typ=e.target.value;
  //                             setDeliveryRows(t);
  //                           }}
  //                         >
  //                           <MenuItem value={'Open'}>Open</MenuItem>
  //                           <MenuItem value={'Submit'}>Submit</MenuItem>
  //                         </Select>
  //                       </FormControl>
              
                             
  //                         <FormControl fullWidth>
  //                         <InputLabel id="demo-simple-select-label" shrink={true}>{t('Form Event')}</InputLabel>
  //                         <Select
  //                           labelId="demo-simple-select-label"
  //                           id="demo-simple-select"
  //                           value={x.FormDelivery_Typ?x.FormDelivery_Typ:''}
  //                           disabled={viewformval}
  //                           // label="Form Type"
  //                           key={x.FormDelivery_Typ?x.FormDelivery_Typ:''}
  //                           onChange={(e)=>{
  //                             let t=deliveryRows;
  //                             t[index].FormDelivery_Typ=e.target.value;
  //                             setDeliveryRows(t);
  //                           }}
  //                         >
  //                           <MenuItem value={'SMS'}> SMS</MenuItem>
  // <MenuItem value={'Email'}> Email</MenuItem>
  // <MenuItem value={'Fax'}> Fax</MenuItem>
  // <MenuItem value={'Salesforce'}> Salesforce</MenuItem>
  // <MenuItem value={'Veeva'}> Veeva</MenuItem>
  // <MenuItem value={'Webhook'}> Webhook</MenuItem>
  //                         </Select>
  //                       </FormControl>
  //                         {
  //                           x.ShowPost_Url || x.Post_Url.length>0?
  //                           <TextField
  //                        className="roundborder"
  //                        label={t("Url")}
  //                        placeholder={t("Please enter Post Url")}
  //                        value={x.Post_Url}
  //                        sx={{width:400}}
  //                        onChange={(e)=>{
  //                         let t=deliveryRows;
  //                         t[index].Post_Url=e.target.value;
  //                         setDeliveryRows(t);
  //                        }}
  //                        inputProps={
  //                         { readOnly: viewformval, }
  //                       }
  //                        focused
  //                       />:null

  //                         }
  //                   {/* </div> */}
  //                   {/* <div  className="delivery-values"> 
  //                   <FormControl>
  //                             <FormLabel id="formdemo-customized-radios">{t('Do you want this form visible?')}</FormLabel>
  //                             <RadioGroup
  //                               row
  //                               defaultValue="no"
  //                               aria-labelledby="demo-customized-radios"
  //                               name="customized-radios"
  //                               value={activeDelflag==true?"yes":"no"}
  //                               onClick={(e: any) => {
  //                                   e.target.value === "no"
  //                                     ? setActiveDelivery(false)
  //                                     : setActiveDelivery(true);
  //                                 }}
  //                             >
  //                               <FormControlLabel                      
  //                                 value="yes"
  //                                 control={<BpRadio />}
  //                                 disabled={viewformval}
  //                                 label={t("Yes")}
  //                                 className="radio-formcontrol"
  //                               />
  //                               <FormControlLabel
  //                                 value="no"
  //                                 control={<BpRadio />}
  //                                 disabled={viewformval}
  //                                 label={t("No")}
  //                                 className="radio-formcontrol"
  //                               />
  //                             </RadioGroup>
  //                           </FormControl></div>
  //                           <div className="FormDate">
  //                             <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                               <Stack spacing={3}>                                 
  //                                 <DatePicker
  //                                   views={['day']}
  //                                   label={t("Effective start date")}
  //                                   value={startdeldate}
  //                                   onChange={(newValue) => {
  //                                       setStartDeliveryDate(newValue);
  //                                   }}
  //                                   renderInput={(params) => <DateTextField {...params} helperText={null} />}
  //                                 />
  //                               </Stack>
  //                             </LocalizationProvider>
  //                             </div>
  //                             <div className="FormDate">
  //                             <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                               <Stack spacing={3}>                                 
  //                                 <DatePicker
  //                                   views={['day']}
  //                                   label={t("Effective end date")}
  //                                   value={enddeldate}
  //                                   onChange={(newValue) => {
  //                                     setEndDeliveryDate(newValue);
  //                                   }}
  //                                   renderInput={(params) => <DateTextField {...params} helperText={null} />}
  //                                 />
  //                               </Stack>
  //                             </LocalizationProvider>
  //                             </div>    */}
  //                 </div>
  //                 </div>
  //                 ))
                }
                 <div className="tabledata">
                      <TableBody>
                          <Box margin={1}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                  <div className="table-btn">
                                     <button className="additem" onClick={handleAddDeliveries}><i className="material-icons add">&#xe145;</i>{t('ADD')}</button>                                    
                                  </div>
                              </div>
                            </div>
                         
                      
                            <Table
                              className={classes.table}
                              size="medium"
                              aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('Event')}</TableCell>
                                  <TableCell>{t('Action')}</TableCell>
                                  <TableCell>{t('Url')}</TableCell>
                                  
                                </TableRow>
                              </TableHead>
                              
                              <TableBody>
                                {deliveryRows.map((row:any, i:any) => {
                                  if(row.Active_Flg=="Y")
                                  return (
                                      <TableRow>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Form Event"
                                                value={row.FormDeliveryEvent_Typ}
                                                onChange={(e) =>{
    
                                                        const { name, value } = e.target;
                                                        const list = [...deliveryRows];
                                                        list[i]['FormDeliveryEvent_Typ'] = value;
                                                        console.log(list);
                                                        setDeliveryRows(list);
                                                }}
                                              >
                                                <option value=""></option>
                                                <option value="Open">{t("Open")}</option>
                                                <option value="Submit">{t("Submit")}</option>
                                                <option value="Signature">{t("Signature")}</option>
                                              </select>
                                            </TableCell>
                                            
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Action"
                                                value={row.FormDelivery_Typ}
                                                
                                                onChange={(e) =>{
                                                        const { name, value } = e.target;
                                                        const list = [...deliveryRows];
                                                        list[i]['FormDelivery_Typ'] = value;
                                                        console.log(list);
                                                        setDeliveryRows(list);
                                                }}
                                              >
                                               <option value=""></option>
                                                <option value="SMS">{t("SMS")}</option>
                                                <option value="Email">{t("Email")}</option>
                                                <option value="Salesforce">{t("Salesforce")}</option>  
                                                <option value="Veeva">{t("Veeva")}</option>   
                                                <option value="Fax">{t("Fax")}</option>
                                                <option value="Webhook">{t("Webhook")}</option>                                      
                                              </select>
                                            </TableCell>
                                            
                                            <TableCell padding="none">
                                              <input name="Post Url" style={{ width: "100%" }}  value={row.FormDeliveryAction_Url}  onChange={(e) => {
                                                 const { name, value } = e.target;
                                                 const list = [...deliveryRows];
                                                 list[i]['FormDeliveryAction_Url'] = value;
                                                 console.log(list);
                                                 setDeliveryRows(list);
                                              }}/>
                                            </TableCell>
                                            
                                          <Button className="mr10" onClick={()=>{setSelectedDelForDelete(row); setConfirmDeliveries(true)}}>
                                            <ClearIcon />
                                          </Button>
                                        
                                        {confirmDeliveries && (
                                          <div>
                                            <Dialog
                                              open={confirmDeliveries}
                                              onClose={handleNo}
                                              aria-labelledby="alert-dialog-title"
                                              aria-describedby="alert-dialog-description"
                                            >
                                              <DialogTitle id="alert-dialog-title">
                                                {"Confirm Delete"}
                                              </DialogTitle>
                                              <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                  {t("Are you sure to delete")}
                                                </DialogContentText>
                                              </DialogContent>
                                              <DialogActions>
                                                <Button
                                                  onClick={() => handleRemoveDeliveries(row)}
                                                  color="primary"
                                                  autoFocus
                                                >
                                                  {t("Yes")}
                                                </Button>
                                                <Button
                                                  onClick={handleNo}
                                                  color="primary"
                                                  autoFocus
                                                >
                                                  {t("No")}
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </div>
                                        )}
                                      </TableRow>
                                    
                                  );
                                })}
                              </TableBody>
                                
                            </Table>
                          </Box>
                      </TableBody>
                </div>
                    {viewformval==false?<div className="save">
                            <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" onClick={handleNextTab}>{t('Save')}</button>
                            </div>:
                            null}
                    
                {/* </div> */}
              </div>
            </div>
          </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
