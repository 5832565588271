import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../app/slice/modalSlice";
import React from "react";

const MyModal = () => {

  const dispatch = useDispatch();
  const { show, title, path, actions }: any = useSelector((state: any) => state.modalSlice)

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleButtonClick = (onClick?: () => void) => {
    onClick && onClick();
    handleClose();
  };


  const LazyComponentWrapper= () => {
    console.log(show, title, path)
    const LazyComponents: any = React.lazy(() => import("./" + path + ".tsx"));
    return path ? (
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyComponents />
      </React.Suspense>
    ) : null;
  };

  return (
    <>
      <Modal dialogClassName="d-table w-auto mobile-modal" show={show} onHide={handleClose} size='lg'>
        {title && (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        {path && <Modal.Body>
          <LazyComponentWrapper />
        </Modal.Body>}
        {actions?.length&&<Modal.Footer>
          {actions &&
            actions.map(({ label, action, variant }, index) => (
              <button
                className={`btn btn-${variant}`}
                key={index}
                onClick={() => handleButtonClick(action)}
              >
                {label}
              </button>
            ))}
        </Modal.Footer>}
      </Modal>
    </>
  );
};

export default MyModal;