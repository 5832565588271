import * as React from "react";

import { Avatar, Backdrop, Box, Container, Fade, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListSubheader, Modal, Typography } from "@mui/material";
import { BoltOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { getDeviceType } from '../../utils/deviceTypeUtil';
import { getMailById } from "../../apis/Email/Email";
import config from "../../env.json";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function AlignItemsList({ email,handleClose }) {
  const current = new Date();
  const [mobileStyle, setMobileStyle] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [popup, closePopup] = useState(true)
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  // const getModalDimensions = () => {
  //   let div: any = document.querySelector('#main')
  //   let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
  //   let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
  //   let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
  //   let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

  //   let device = getDeviceType()
  //   if (device == "mobile" || device == "tablet" || config.REACT_APP_IS_CROSS_PLATFORM) {
  //     setMobileStyle(true);
  //     setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
  //     setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
  //   }
  // }
  const [apicalled, setapicalled] = React.useState(false);
  const fetchData = () => {
    getMailById(email.EmailConversation_Id, (resp: any) => {

      setapicalled(!apicalled)
    });
  }

  const getDate = (Update_DtTm) => {
    const date = new Date(Update_DtTm)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
  }

 
  useEffect(() => {
    console.log(email,handleClose)
    if (!apicalled && email) {
      fetchData();
    }
  }, [email]);
  return (
    // <div className="EmailText" style={{ textAlign: "start" }}>
    //   {" "}
    //   <Box
    //     sx={{
    //       bgcolor: "background.paper",
    //       height: "100vh",
    //       width: "100%",
    //       borderBlockEndStyle: 25,
    //       borderBlockStartStyle: 25,
    //     }}
    //   >
    //     <List sx={{ width: "100%" }}>
    //       <ListSubheader sx={{ bgcolor: "background.paper", fontSize: 20 }}>
    //         {"Induction"}
    //       </ListSubheader>
    //       <ListItem alignItems="flex-start">
    //         <ListItemAvatar>
    //           <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
    //         </ListItemAvatar>
    //         <ListItemText
    //           primary="Brunch this weekend"
    //           secondary={
    //             <React.Fragment>
    //               <Typography
    //                 sx={{ display: "inline" }}
    //                 component="span"
    //                 variant="body2"
    //                 color="text.primary"
    //               >
    //                 To: Ali Connors
    //               </Typography>
    //             </React.Fragment>
    //           }
    //         />

    //         <ListItemIcon>
    //         <p  style={{ fontSize: 12 }}>{date}</p>
    //           {/* <ReplyIcon style={{ margin: 5 }} />
    //           <ReplyAllIcon style={{ margin: 5 }} />
    //           <ShortcutIcon style={{margin: 5 }} /> */}
    //         </ListItemIcon>

    //       </ListItem>
    //     </List>
    //     <div className="mailContent" style={{ padding: 16 }}>
    //       <p
    //         style={{
    //           fontFamily: "-apple-system",
    //           fontSize: "1rem",
    //         }}
    //       >
    //         Hi Everyone, {email.primary}
    //       </p>

    //       <p
    //         style={{
    //           fontFamily: "-apple-system",
    //           fontSize: "1rem",
    //         }}
    //       >
    //         Hope you all are doing well.
    //       </p>

    //       <p
    //         style={{
    //           fontFamily: "-apple-system",
    //           fontSize: "1rem",
    //         }}
    //       >
    //         A kind reminder that you have February and March left to plan your
    //         regional meet for this quarter. Please keep us informed well in
    //         advance for your regional meet over an email for this quarter.{" "}
    //       </p>

    //       <p
    //         style={{
    //           fontFamily: "-apple-system",
    //           fontSize: "1rem",
    //         }}
    //       >
    //         Note: Attendance of every employee attending the meet is required
    //         along with the group picture for reimbursement.
    //       </p>

    //       <p
    //         style={{
    //           fontFamily: "-apple-system",
    //           fontSize: "1rem",
    //         }}
    //       >
    //         {" "}
    //         Thank you.
    //       </p>
    //     </div>
    //   </Box>
    // </div>
    <div className="modal">
      <Modal
        open={email}

      >
        <Box
          sx={{
            bgcolor: "background.paper",
            height: "100vh",
            width: "100%",
            borderBlockEndStyle: 25,
            borderBlockStartStyle: 25,
          }}
        >
    
          <div className="modal-header d-flex ">
            <span
              onClick={() => {
                console.log("===================")
               handleClose()
              }}
             
            >
           <ArrowBackIcon/>
            </span>
          </div>
          <List sx={{ width: "100%" }}>

            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={email.Subject}
                secondary={<React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    To: {email?.EmailTo_Email} <br />
                    cc: {email?.EmailCC_Email}
                  </Typography>

                </React.Fragment>} />

              <ListItemIcon>
                <p style={{ fontSize: 12 }}>{getDate(email.Update_DtTm)}</p>
              </ListItemIcon>
            </ListItem>
          </List>
          <div className="mailContent" style={{ padding: "20px", textAlign: "start" }}>
            <p>
              {email.Email_Bdy}
            </p>
          </div>
        </Box>
      </Modal>
    </div>


  );
}
