import React, { useEffect, useState } from 'react';
import '../../components/errors/ErrorAuth.css';
import authorizeKey from '../../assets/icons/authorizeKey.svg';
import {msalInstance} from '../../index';
import { useNavigate} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
import  config  from '../../env.json';
import {capacitormsal} from "capacitormsal";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { updatePushToken } from '../../apis/notificationApi/notificationApi';
import { ForgerockLogout } from '../../apis/forgerockAuthApi/forgerockauthApi';

export default function ErrorAuth(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    useEffect(() => {
        // let timer1 = setTimeout(() => setShow(true), delay * 1000);
        let timer1 = setTimeout(() => {
             if(config.REACT_APP_IS_CROSS_PLATFORM) {
                updatePushToken({
                    "DeviceToken_Val": sessionStorage.getItem("pushToken"),
                    "Device_Typ": "iOS",
                    "is_active": 'N'
                  },(res)=>{       
                  })
                if(sessionStorage.getItem("appAuthType")=="AD") {
                    capacitormsal.signOut({
                        authority: config.REACT_APP_AD_AUTHORITY as string,
                        clientId: config.REACT_APP_AD_CLIENTID as string,
                        redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
                        scope: config.REACT_APP_CROSS_SCOPE as string,
                        })
                        .then(async (response) => {
                            sessionStorage.setItem("login", "");
                            sessionStorage.setItem("appAuthType", "");
                            deleteUserDetails().then(() => {
                                window.location.href = "/"  
                            });              
                        })
                        .catch(error => {
                            sessionStorage.setItem("login", "");
                            sessionStorage.setItem("appAuthType", "");
                            deleteUserDetails().then(() => {
                                window.location.href = "/"  
                            });
                        })
                }             
                if(sessionStorage.getItem("appAuthType")=="forgerock"){
                    ForgerockLogout((res)=>{
                      if(res){
                        sessionStorage.setItem("login", "");
                        sessionStorage.setItem("appAuthType", "");
                        deleteUserDetails().then(() => {
                          window.location.href = "/"  
                        });
                      }
                    })
                }
                else {
                    let browser = InAppBrowser.create(config.REACT_APP_PING_LOGOUT_URL, '_blank', 'location=no');
                    browser.on('loadstop').subscribe(async event => {
                      browser.close();
                    });
                    sessionStorage.setItem("login", "");
                    sessionStorage.setItem("appAuthType", "");
                    deleteUserDetails().then(() => {
                      window.location.href = "/"  
                    });
                }

              } else {
               if(sessionStorage.getItem("appAuthType")=="AD"){
                let account=sessionStorage.getItem('loginName') as string
                msalInstance.logoutRedirect({
                    // navigate('/logout')
                    account: msalInstance.getAccountByUsername('/logout')
                }).catch((e:any) => {
                console.log(e);
                });
             }
             if(sessionStorage.getItem("appAuthType")=="forgerock"){
                ForgerockLogout((res)=>{
                  if(res){
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.href =config.REACT_APP_FORGEROCK_REDIRECT_URL;      
                  }
                })
              }
            else
            {
                sessionStorage.clear();
                localStorage.clear();
                var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
                window.location.href = logoutUrl     
                 window.location.href =config.REACT_APP_PING_REDIRECT_URL;
              //window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
            }
              }
        },  5000);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
          clearTimeout(timer1);
        };
    })
    
    return (
        <>
            <div className='errordiv'>
                <div className='error'>
                    <img src={authorizeKey} />
                    <h1>{t('Unauthorized Access')}</h1>
                    <p>{t('you are not a valid user to access the resource')}</p>
                </div>
            </div>
        </>
    )
}


