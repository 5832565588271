import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    videoCallName:string,
    videoCallToken:string,
    videoCallId:string,
    videoCallUrl:string,
    videoCallShowFloater:boolean,
    videoCallCallStarted:boolean,
    videoFloaterChaton:boolean,
    videoLocalParticipant:any,
    videoOtherParticipants:any
    videoCallRoom:any,
    // videoCallStarted:boolean,
    videoCallEnded:boolean,
    videoCallScreenTrack:any,
    videoCallScreenShareStarted:boolean,
    videoCallShareUserId:any,
    videoCallAnnotaion:string,
    videoCallPdfTronStarted:boolean,
    videoCallChats:any,
    RandomToken:string,
    OTP: string,
    UserName:string,
    disconnectedParticipants:any,
    Id:any,
    meetingName:any,
    EmployeeName:any,
    StartDateTime:any,
    CallId:any,
    isFullScreenOn:boolean,
    videoCallRecordingStarted:boolean,
    videoCallRecordingStartedTime:any,
    TrnCallId:any,
    RecordName:any
  }
export const initialState: loginState = {
    videoCallName:"",
    videoCallToken:"",
    videoCallId:"",
    videoCallUrl:"",
    videoCallShowFloater:false,
    videoCallCallStarted:false,
    videoFloaterChaton:false,
    videoLocalParticipant:null,
    videoOtherParticipants:[],
    videoCallRoom:null,
    // videoCallStarted:false,
    videoCallEnded:false,
    videoCallScreenTrack:null,
    videoCallScreenShareStarted:false,
    videoCallPdfTronStarted:false,
    videoCallShareUserId:null,
    videoCallAnnotaion:'',
    videoCallChats:[],
    RandomToken:'',
    OTP: '',
    UserName:'',
    disconnectedParticipants:[],
    Id:"",
    meetingName:"",
    EmployeeName:"",
    StartDateTime:"",
    CallId:"",
    isFullScreenOn:false,
    videoCallRecordingStarted:false,
    videoCallRecordingStartedTime:null,
    TrnCallId:"",
    RecordName:''
};
export const floaterVideoCall = createSlice({
  name: 'floaterVideoCall',
  initialState: initialState,
  reducers: {
      setVideoCallDetails: (state,action: PayloadAction<any>) => { 
        state.videoCallName=action.payload.videoCallName
        state.videoCallToken=action.payload.videoCallToken
        state.videoCallId=action.payload.videoCallId
        state.videoCallUrl=action.payload.videoCallUrl
      },
      setVideoCallShowFloater: (state,action: PayloadAction<any>) => { 
        state.videoCallShowFloater=action.payload
      },
      setVideoCallCallStarted:(state,action: PayloadAction<any>) => { 
        state.videoCallCallStarted=action.payload;
      },
      setVideoFloaterChaton:(state,action: PayloadAction<any>) => { 
        state.videoFloaterChaton=action.payload;
      },
      setVideoLocalParticipant:(state,action: PayloadAction<any>) => { 
        state.videoLocalParticipant=action.payload;
      },
      setVideoOtherParticipants:(state,action: PayloadAction<any>) => { 
        state.videoOtherParticipants=action.payload;
      },
      setVideoCallRoom:(state,action: PayloadAction<any>) => { 
        state.videoCallRoom=action.payload;
      },
      setVideoCall:(state,action: PayloadAction<any>) => { 
        state.CallId=action.payload;
      },
      // setVideoCallStarted:(state,action: PayloadAction<any>) => { 
      //   state.videoCallCallStarted=action.payload;
      // },
      setVideoCallEnded:(state,action: PayloadAction<any>) => { 
        state.videoCallEnded=action.payload;
      },
      setVideoCallScreenTrack:(state,action: PayloadAction<any>) => { 
        state.videoCallScreenTrack=action.payload;
      },

      setVideoCallScreenShareStarted:(state,action: PayloadAction<any>) => { 
        state.videoCallScreenShareStarted=action.payload;
      },
      setVideoCallShareUserId:(state,action: PayloadAction<any>) => { 
        state.videoCallShareUserId=action.payload;
      },
      setVideoCallAnnotaion:(state,action: PayloadAction<any>) => { 
        state.videoCallAnnotaion=action.payload;
      },

      setVideoCallPdfTronStarted:(state,action: PayloadAction<any>) => { 
        state.videoCallPdfTronStarted=action.payload;
      },
      setVideoCallChats:(state,action: PayloadAction<any>) => { 
        state.videoCallChats=action.payload;
      },
      setDisconnectedParticipants:(state,action: PayloadAction<any>) => { 
        state.disconnectedParticipants=action.payload;
      },
      setVideoMobileDetails:(state,action: PayloadAction<any>) => { 
        state.Id=action.payload.Id
        state.meetingName=action.payload.meetingName
        state.EmployeeName=action.payload.EmployeeName
        state.StartDateTime=action.payload.StartDateTime
        state.TrnCallId=action.payload.TrnCallId
        state.RecordName=action.payload.RecordName
      },
      setIsFullScreenOn:(state,action: PayloadAction<any>) => { 
        state.isFullScreenOn=action.payload;
      },
      setVideoCallRecordingStarted:(state,action: PayloadAction<any>) => { 
        state.videoCallRecordingStarted=action.payload;
      },
      setVideoCallRecordingStartedTime:(state,action: PayloadAction<any>) => { 
        state.videoCallRecordingStartedTime=action.payload;
      },
    }
});

// this is for dispatch
export const { setVideoCallDetails, 
  setVideoCallShowFloater,
  setVideoCallCallStarted ,
  setVideoFloaterChaton,
  setVideoLocalParticipant,
  setVideoCallPdfTronStarted,
  setVideoCallChats,
  setVideoOtherParticipants,setVideoCallRoom,setVideoCallEnded
  //,setVideoCallStarted
  ,setVideoCallScreenTrack, setVideoCallScreenShareStarted,setVideoCallShareUserId,setVideoCall,setVideoCallAnnotaion,setDisconnectedParticipants,setVideoMobileDetails,setIsFullScreenOn,setVideoCallRecordingStarted,setVideoCallRecordingStartedTime} = floaterVideoCall.actions;

// this is for configureStore
export default floaterVideoCall.reducer;